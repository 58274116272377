<section
  class="emoji-mart {{ darkMode ? 'emoji-mart-dark' : '' }}"
  [style.width]="getWidth()"
  [ngStyle]="style"
>
  <div class="emoji-mart-bar">
    <emoji-mart-anchors
      [categories]="categories"
      (anchorClick)="handleAnchorClick($event)"
      [color]="color"
      [selected]="selected"
      [i18n]="i18n"
      [icons]="categoriesIcons"
    ></emoji-mart-anchors>
  </div>
  <emoji-search
    *ngIf="enableSearch"
    [i18n]="i18n"
    (searchResults)="handleSearch($event)"
    (enterKey)="handleEnterKey($event)"
    [include]="include"
    [exclude]="exclude"
    [custom]="custom"
    [autoFocus]="autoFocus"
    [icons]="searchIcons"
    [emojisToShowFilter]="emojisToShowFilter"
  ></emoji-search>
  <section #scrollRef class="emoji-mart-scroll" [attr.aria-label]="i18n.emojilist">
    <emoji-category
      *ngFor="let category of activeCategories; let idx = index; trackBy: categoryTrack"
      [id]="category.id"
      [name]="category.name"
      [emojis]="category.emojis"
      [perLine]="perLine"
      [totalFrequentLines]="totalFrequentLines"
      [hasStickyPosition]="isNative"
      [i18n]="i18n"
      [hideObsolete]="hideObsolete"
      [notFoundEmoji]="notFoundEmoji"
      [custom]="category.id === RECENT_CATEGORY.id ? CUSTOM_CATEGORY.emojis : undefined"
      [recent]="category.id === RECENT_CATEGORY.id ? recent : undefined"
      [virtualize]="virtualize"
      [virtualizeOffset]="virtualizeOffset"
      [emojiIsNative]="isNative"
      [emojiSkin]="skin"
      [emojiSize]="emojiSize"
      [emojiSet]="set"
      [emojiSheetSize]="sheetSize"
      [emojiForceSize]="isNative"
      [emojiTooltip]="emojiTooltip"
      [emojiBackgroundImageFn]="backgroundImageFn"
      [emojiImageUrlFn]="imageUrlFn"
      [emojiUseButton]="useButton"
      (emojiOver)="handleEmojiOver($event)"
      (emojiLeave)="handleEmojiLeave()"
      (emojiClick)="handleEmojiClick($event)"
    ></emoji-category>
  </section>
  <div class="emoji-mart-bar" *ngIf="showPreview">
    <emoji-preview
      [title]="title"
      [emoji]="previewEmoji"
      [idleEmoji]="emoji"
      [emojiIsNative]="isNative"
      [emojiSize]="38"
      [emojiSkin]="skin"
      [emojiSet]="set"
      [i18n]="i18n"
      [emojiSheetSize]="sheetSize"
      [emojiBackgroundImageFn]="backgroundImageFn"
      [emojiImageUrlFn]="imageUrlFn"
      (skinChange)="handleSkinChange($event)"
    ></emoji-preview>
  </div>
</section>
