import { CompressedEmojiData } from './data.interfaces';
export const emojis: CompressedEmojiData[] = [
  {
    name: 'Grinning Face',
    unified: '1F600',
    text: ':D',
    keywords: [
      'grinning_face',
      'face',
      'smile',
      'happy',
      'joy',
      ':D',
      'grin'
    ],
    sheet: [30, 32],
    shortName: 'grinning'
  },
  {
    name: 'Smiling Face with Open Mouth',
    unified: '1F603',
    text: ':)',
    emoticons: ['=)', '=-)'],
    keywords: [
      'grinning_face_with_big_eyes',
      'face',
      'happy',
      'joy',
      'haha',
      ':D',
      ':)',
      'smile',
      'funny'
    ],
    sheet: [30, 35],
    shortName: 'smiley'
  },
  {
    name: 'Smiling Face with Open Mouth and Smiling Eyes',
    unified: '1F604',
    text: ':)',
    emoticons: ['C:', 'c:', ':D', ':-D'],
    keywords: [
      'grinning_face_with_smiling_eyes',
      'face',
      'happy',
      'joy',
      'funny',
      'haha',
      'laugh',
      'like',
      ':D',
      ':)',
      'smile'
    ],
    sheet: [30, 36],
    shortName: 'smile'
  },
  {
    name: 'Grinning Face with Smiling Eyes',
    unified: '1F601',
    keywords: [
      'beaming_face_with_smiling_eyes',
      'face',
      'happy',
      'smile',
      'joy',
      'kawaii'
    ],
    sheet: [30, 33],
    shortName: 'grin'
  },
  {
    name: 'Smiling Face with Open Mouth and Tightly-Closed Eyes',
    unified: '1F606',
    emoticons: [':>', ':->'],
    keywords: [
      'grinning_squinting_face',
      'happy',
      'joy',
      'lol',
      'satisfied',
      'haha',
      'face',
      'glad',
      'XD',
      'laugh'
    ],
    sheet: [30, 38],
    shortNames: ['satisfied'],
    shortName: 'laughing'
  },
  {
    name: 'Smiling Face with Open Mouth and Cold Sweat',
    unified: '1F605',
    keywords: [
      'grinning_face_with_sweat',
      'face',
      'hot',
      'happy',
      'laugh',
      'sweat',
      'smile',
      'relief'
    ],
    sheet: [30, 37],
    shortName: 'sweat_smile'
  },
  {
    name: 'Rolling on the Floor Laughing',
    unified: '1F923',
    keywords: [
      'rolling_on_the_floor_laughing',
      'face',
      'rolling',
      'floor',
      'laughing',
      'lol',
      'haha',
      'rofl'
    ],
    sheet: [38, 19],
    shortName: 'rolling_on_the_floor_laughing'
  },
  {
    name: 'Face with Tears of Joy',
    unified: '1F602',
    keywords: [
      'face_with_tears_of_joy',
      'face',
      'cry',
      'tears',
      'weep',
      'happy',
      'happytears',
      'haha'
    ],
    sheet: [30, 34],
    shortName: 'joy'
  },
  {
    name: 'Slightly Smiling Face',
    unified: '1F642',
    emoticons: [':)', '(:', ':-)'],
    keywords: [
      'slightly_smiling_face',
      'face',
      'smile'
    ],
    sheet: [31, 40],
    shortName: 'slightly_smiling_face'
  },
  {
    name: 'Upside-Down Face',
    unified: '1F643',
    keywords: [
      'upside_down_face',
      'face',
      'flipped',
      'silly',
      'smile'
    ],
    sheet: [31, 41],
    shortName: 'upside_down_face'
  },
  {
    name: 'Winking Face',
    unified: '1F609',
    text: ';)',
    emoticons: [';)', ';-)'],
    keywords: [
      'winking_face',
      'face',
      'happy',
      'mischievous',
      'secret',
      ';)',
      'smile',
      'eye'
    ],
    sheet: [30, 41],
    shortName: 'wink'
  },
  {
    name: 'Smiling Face with Smiling Eyes',
    unified: '1F60A',
    text: ':)',
    keywords: [
      'smiling_face_with_smiling_eyes',
      'face',
      'smile',
      'happy',
      'flushed',
      'crush',
      'embarrassed',
      'shy',
      'joy'
    ],
    sheet: [30, 42],
    shortName: 'blush'
  },
  {
    name: 'Smiling Face with Halo',
    unified: '1F607',
    keywords: [
      'smiling_face_with_halo',
      'face',
      'angel',
      'heaven',
      'halo'
    ],
    sheet: [30, 39],
    shortName: 'innocent'
  },
  {
    name: 'Smiling Face with Smiling Eyes and Three Hearts',
    unified: '1F970',
    keywords: [
      'smiling_face_with_hearts',
      'face',
      'love',
      'like',
      'affection',
      'valentines',
      'infatuation',
      'crush',
      'hearts',
      'adore'
    ],
    sheet: [42, 13],
    shortName: 'smiling_face_with_3_hearts'
  },
  {
    name: 'Smiling Face with Heart-Shaped Eyes',
    unified: '1F60D',
    keywords: [
      'smiling_face_with_heart_eyes',
      'face',
      'love',
      'like',
      'affection',
      'valentines',
      'infatuation',
      'crush',
      'heart'
    ],
    sheet: [30, 45],
    shortName: 'heart_eyes'
  },
  {
    name: 'Grinning Face with Star Eyes',
    unified: '1F929',
    keywords: [
      'star_struck',
      'face',
      'smile',
      'starry',
      'eyes',
      'grinning'
    ],
    sheet: [38, 42],
    shortNames: [
      'grinning_face_with_star_eyes'
    ],
    shortName: 'star-struck'
  },
  {
    name: 'Face Throwing a Kiss',
    unified: '1F618',
    emoticons: [':*', ':-*'],
    keywords: [
      'face_blowing_a_kiss',
      'face',
      'love',
      'like',
      'affection',
      'valentines',
      'infatuation',
      'kiss'
    ],
    sheet: [30, 56],
    shortName: 'kissing_heart'
  },
  {
    name: 'Kissing Face',
    unified: '1F617',
    keywords: [
      'kissing_face',
      'love',
      'like',
      'face',
      '3',
      'valentines',
      'infatuation',
      'kiss'
    ],
    sheet: [30, 55],
    shortName: 'kissing'
  },
  {
    name: 'White Smiling Face',
    unified: '263A-FE0F',
    keywords: [
      'smiling_face',
      'face',
      'blush',
      'massage',
      'happiness'
    ],
    sheet: [54, 22],
    shortName: 'relaxed'
  },
  {
    name: 'Kissing Face with Closed Eyes',
    unified: '1F61A',
    keywords: [
      'kissing_face_with_closed_eyes',
      'face',
      'love',
      'like',
      'affection',
      'valentines',
      'infatuation',
      'kiss'
    ],
    sheet: [31, 0],
    shortName: 'kissing_closed_eyes'
  },
  {
    name: 'Kissing Face with Smiling Eyes',
    unified: '1F619',
    keywords: [
      'kissing_face_with_smiling_eyes',
      'face',
      'affection',
      'valentines',
      'infatuation',
      'kiss'
    ],
    sheet: [30, 57],
    shortName: 'kissing_smiling_eyes'
  },
  {
    name: 'Smiling Face with Tear',
    unified: '1F972',
    keywords: [
      'smiling face with tear',
      'sad',
      'cry',
      'pretend'
    ],
    sheet: [42, 15],
    shortName: 'smiling_face_with_tear'
  },
  {
    name: 'Face Savouring Delicious Food',
    unified: '1F60B',
    keywords: [
      'face_savoring_food',
      'happy',
      'joy',
      'tongue',
      'smile',
      'face',
      'silly',
      'yummy',
      'nom',
      'delicious',
      'savouring'
    ],
    sheet: [30, 43],
    shortName: 'yum'
  },
  {
    name: 'Face with Stuck-out Tongue',
    unified: '1F61B',
    text: ':p',
    emoticons: [
      ':p',
      ':-p',
      ':P',
      ':-P',
      ':b',
      ':-b'
    ],
    keywords: [
      'face_with_tongue',
      'face',
      'prank',
      'childish',
      'playful',
      'mischievous',
      'smile',
      'tongue'
    ],
    sheet: [31, 1],
    shortName: 'stuck_out_tongue'
  },
  {
    name: 'Face with Stuck-out Tongue and Winking Eye',
    unified: '1F61C',
    text: ';p',
    emoticons: [
      ';p',
      ';-p',
      ';b',
      ';-b',
      ';P',
      ';-P'
    ],
    keywords: [
      'winking_face_with_tongue',
      'face',
      'prank',
      'childish',
      'playful',
      'mischievous',
      'smile',
      'wink',
      'tongue'
    ],
    sheet: [31, 2],
    shortName: 'stuck_out_tongue_winking_eye'
  },
  {
    name: 'Grinning Face with One Large and One Small Eye',
    unified: '1F92A',
    keywords: [
      'zany_face',
      'face',
      'goofy',
      'crazy'
    ],
    sheet: [38, 43],
    shortNames: [
      'grinning_face_with_one_large_and_one_small_eye'
    ],
    shortName: 'zany_face'
  },
  {
    name: 'Face with Stuck-out Tongue and Tightly-Closed Eyes',
    unified: '1F61D',
    keywords: [
      'squinting_face_with_tongue',
      'face',
      'prank',
      'playful',
      'mischievous',
      'smile',
      'tongue'
    ],
    sheet: [31, 3],
    shortName: 'stuck_out_tongue_closed_eyes'
  },
  {
    name: 'Money-Mouth Face',
    unified: '1F911',
    keywords: [
      'money_mouth_face',
      'face',
      'rich',
      'dollar',
      'money'
    ],
    sheet: [37, 24],
    shortName: 'money_mouth_face'
  },
  {
    name: 'Hugging Face',
    unified: '1F917',
    keywords: [
      'hugging_face',
      'face',
      'smile',
      'hug'
    ],
    sheet: [37, 30],
    shortName: 'hugging_face'
  },
  {
    name: 'Smiling Face with Smiling Eyes and Hand Covering Mouth',
    unified: '1F92D',
    keywords: [
      'face_with_hand_over_mouth',
      'face',
      'whoops',
      'shock',
      'surprise'
    ],
    sheet: [38, 46],
    shortNames: [
      'smiling_face_with_smiling_eyes_and_hand_covering_mouth'
    ],
    shortName: 'face_with_hand_over_mouth'
  },
  {
    name: 'Face with Finger Covering Closed Lips',
    unified: '1F92B',
    keywords: [
      'shushing_face',
      'face',
      'quiet',
      'shhh'
    ],
    sheet: [38, 44],
    shortNames: [
      'face_with_finger_covering_closed_lips'
    ],
    shortName: 'shushing_face'
  },
  {
    name: 'Thinking Face',
    unified: '1F914',
    keywords: [
      'thinking_face',
      'face',
      'hmmm',
      'think',
      'consider'
    ],
    sheet: [37, 27],
    shortName: 'thinking_face'
  },
  {
    name: 'Zipper-Mouth Face',
    unified: '1F910',
    keywords: [
      'zipper_mouth_face',
      'face',
      'sealed',
      'zipper',
      'secret'
    ],
    sheet: [37, 23],
    shortName: 'zipper_mouth_face'
  },
  {
    name: 'Face with One Eyebrow Raised',
    unified: '1F928',
    keywords: [
      'face_with_raised_eyebrow',
      'face',
      'distrust',
      'scepticism',
      'disapproval',
      'disbelief',
      'surprise'
    ],
    sheet: [38, 41],
    shortNames: [
      'face_with_one_eyebrow_raised'
    ],
    shortName: 'face_with_raised_eyebrow'
  },
  {
    name: 'Neutral Face',
    unified: '1F610',
    emoticons: [':|', ':-|'],
    keywords: [
      'neutral_face',
      'indifference',
      'meh',
      ':|',
      'neutral'
    ],
    sheet: [30, 48],
    shortName: 'neutral_face'
  },
  {
    name: 'Expressionless Face',
    unified: '1F611',
    keywords: [
      'expressionless_face',
      'face',
      'indifferent',
      '-_-',
      'meh',
      'deadpan'
    ],
    sheet: [30, 49],
    shortName: 'expressionless'
  },
  {
    name: 'Face Without Mouth',
    unified: '1F636',
    keywords: [
      'face_without_mouth',
      'face',
      'hellokitty'
    ],
    sheet: [31, 28],
    shortName: 'no_mouth'
  },
  {
    name: 'Smirking Face',
    unified: '1F60F',
    keywords: [
      'smirking_face',
      'face',
      'smile',
      'mean',
      'prank',
      'smug',
      'sarcasm'
    ],
    sheet: [30, 47],
    shortName: 'smirk'
  },
  {
    name: 'Unamused Face',
    unified: '1F612',
    text: ':(',
    keywords: [
      'unamused_face',
      'indifference',
      'bored',
      'straight face',
      'serious',
      'sarcasm',
      'unimpressed',
      'skeptical',
      'dubious',
      'side_eye'
    ],
    sheet: [30, 50],
    shortName: 'unamused'
  },
  {
    name: 'Face with Rolling Eyes',
    unified: '1F644',
    keywords: [
      'face_with_rolling_eyes',
      'face',
      'eyeroll',
      'frustrated'
    ],
    sheet: [31, 42],
    shortName: 'face_with_rolling_eyes'
  },
  {
    name: 'Grimacing Face',
    unified: '1F62C',
    keywords: [
      'grimacing_face',
      'face',
      'grimace',
      'teeth'
    ],
    sheet: [31, 18],
    shortName: 'grimacing'
  },
  {
    name: 'Lying Face',
    unified: '1F925',
    keywords: [
      'lying_face',
      'face',
      'lie',
      'pinocchio'
    ],
    sheet: [38, 21],
    shortName: 'lying_face'
  },
  {
    name: 'Relieved Face',
    unified: '1F60C',
    keywords: [
      'relieved_face',
      'face',
      'relaxed',
      'phew',
      'massage',
      'happiness'
    ],
    sheet: [30, 44],
    shortName: 'relieved'
  },
  {
    name: 'Pensive Face',
    unified: '1F614',
    keywords: [
      'pensive_face',
      'face',
      'sad',
      'depressed',
      'upset'
    ],
    sheet: [30, 52],
    shortName: 'pensive'
  },
  {
    name: 'Sleepy Face',
    unified: '1F62A',
    keywords: [
      'sleepy_face',
      'face',
      'tired',
      'rest',
      'nap'
    ],
    sheet: [31, 16],
    shortName: 'sleepy'
  },
  {
    name: 'Drooling Face',
    unified: '1F924',
    keywords: ['drooling_face', 'face'],
    sheet: [38, 20],
    shortName: 'drooling_face'
  },
  {
    name: 'Sleeping Face',
    unified: '1F634',
    keywords: [
      'sleeping_face',
      'face',
      'tired',
      'sleepy',
      'night',
      'zzz'
    ],
    sheet: [31, 26],
    shortName: 'sleeping'
  },
  {
    name: 'Face with Medical Mask',
    unified: '1F637',
    keywords: [
      'face_with_medical_mask',
      'face',
      'sick',
      'ill',
      'disease'
    ],
    sheet: [31, 29],
    shortName: 'mask'
  },
  {
    name: 'Face with Thermometer',
    unified: '1F912',
    keywords: [
      'face_with_thermometer',
      'sick',
      'temperature',
      'thermometer',
      'cold',
      'fever'
    ],
    sheet: [37, 25],
    shortName: 'face_with_thermometer'
  },
  {
    name: 'Face with Head-Bandage',
    unified: '1F915',
    keywords: [
      'face_with_head_bandage',
      'injured',
      'clumsy',
      'bandage',
      'hurt'
    ],
    sheet: [37, 28],
    shortName: 'face_with_head_bandage'
  },
  {
    name: 'Nauseated Face',
    unified: '1F922',
    keywords: [
      'nauseated_face',
      'face',
      'vomit',
      'gross',
      'green',
      'sick',
      'throw up',
      'ill'
    ],
    sheet: [38, 18],
    shortName: 'nauseated_face'
  },
  {
    name: 'Face with Open Mouth Vomiting',
    unified: '1F92E',
    keywords: [
      'face_vomiting',
      'face',
      'sick'
    ],
    sheet: [38, 47],
    shortNames: [
      'face_with_open_mouth_vomiting'
    ],
    shortName: 'face_vomiting'
  },
  {
    name: 'Sneezing Face',
    unified: '1F927',
    keywords: [
      'sneezing_face',
      'face',
      'gesundheit',
      'sneeze',
      'sick',
      'allergy'
    ],
    sheet: [38, 40],
    shortName: 'sneezing_face'
  },
  {
    name: 'Overheated Face',
    unified: '1F975',
    keywords: [
      'hot_face',
      'face',
      'feverish',
      'heat',
      'red',
      'sweating'
    ],
    sheet: [42, 18],
    shortName: 'hot_face'
  },
  {
    name: 'Freezing Face',
    unified: '1F976',
    keywords: [
      'cold_face',
      'face',
      'blue',
      'freezing',
      'frozen',
      'frostbite',
      'icicles'
    ],
    sheet: [42, 19],
    shortName: 'cold_face'
  },
  {
    name: 'Face with Uneven Eyes and Wavy Mouth',
    unified: '1F974',
    keywords: [
      'woozy_face',
      'face',
      'dizzy',
      'intoxicated',
      'tipsy',
      'wavy'
    ],
    sheet: [42, 17],
    shortName: 'woozy_face'
  },
  {
    name: 'Dizzy Face',
    unified: '1F635',
    keywords: [
      'dizzy_face',
      'spent',
      'unconscious',
      'xox',
      'dizzy'
    ],
    sheet: [31, 27],
    shortName: 'dizzy_face'
  },
  {
    name: 'Shocked Face with Exploding Head',
    unified: '1F92F',
    keywords: [
      'exploding_head',
      'face',
      'shocked',
      'mind',
      'blown'
    ],
    sheet: [38, 48],
    shortNames: [
      'shocked_face_with_exploding_head'
    ],
    shortName: 'exploding_head'
  },
  {
    name: 'Face with Cowboy Hat',
    unified: '1F920',
    keywords: [
      'cowboy_hat_face',
      'face',
      'cowgirl',
      'hat'
    ],
    sheet: [38, 16],
    shortName: 'face_with_cowboy_hat'
  },
  {
    name: 'Face with Party Horn and Party Hat',
    unified: '1F973',
    keywords: [
      'partying_face',
      'face',
      'celebration',
      'woohoo'
    ],
    sheet: [42, 16],
    shortName: 'partying_face'
  },
  {
    name: 'Disguised Face',
    unified: '1F978',
    keywords: [
      'disguised face',
      'pretent',
      'brows',
      'glasses',
      'moustache'
    ],
    sheet: [42, 26],
    shortName: 'disguised_face'
  },
  {
    name: 'Smiling Face with Sunglasses',
    unified: '1F60E',
    emoticons: ['8)'],
    keywords: [
      'smiling_face_with_sunglasses',
      'face',
      'cool',
      'smile',
      'summer',
      'beach',
      'sunglass'
    ],
    sheet: [30, 46],
    shortName: 'sunglasses'
  },
  {
    name: 'Nerd Face',
    unified: '1F913',
    keywords: [
      'nerd_face',
      'face',
      'nerdy',
      'geek',
      'dork'
    ],
    sheet: [37, 26],
    shortName: 'nerd_face'
  },
  {
    name: 'Face with Monocle',
    unified: '1F9D0',
    keywords: [
      'face_with_monocle',
      'face',
      'stuffy',
      'wealthy'
    ],
    sheet: [45, 34],
    shortName: 'face_with_monocle'
  },
  {
    name: 'Confused Face',
    unified: '1F615',
    emoticons: [
      ':\\',
      ':-\\',
      ':/',
      ':-/'
    ],
    keywords: [
      'confused_face',
      'face',
      'indifference',
      'huh',
      'weird',
      'hmmm',
      ':/'
    ],
    sheet: [30, 53],
    shortName: 'confused'
  },
  {
    name: 'Worried Face',
    unified: '1F61F',
    keywords: [
      'worried_face',
      'face',
      'concern',
      'nervous',
      ':('
    ],
    sheet: [31, 5],
    shortName: 'worried'
  },
  {
    name: 'Slightly Frowning Face',
    unified: '1F641',
    keywords: [
      'slightly_frowning_face',
      'face',
      'frowning',
      'disappointed',
      'sad',
      'upset'
    ],
    sheet: [31, 39],
    shortName: 'slightly_frowning_face'
  },
  {
    name: 'Frowning Face',
    unified: '2639-FE0F',
    keywords: [
      'frowning_face',
      'face',
      'sad',
      'upset',
      'frown'
    ],
    sheet: [54, 21],
    shortName: 'white_frowning_face'
  },
  {
    name: 'Face with Open Mouth',
    unified: '1F62E',
    emoticons: [
      ':o',
      ':-o',
      ':O',
      ':-O'
    ],
    keywords: [
      'face_with_open_mouth',
      'face',
      'surprise',
      'impressed',
      'wow',
      'whoa',
      ':O'
    ],
    sheet: [31, 20],
    shortName: 'open_mouth'
  },
  {
    name: 'Hushed Face',
    unified: '1F62F',
    keywords: [
      'hushed_face',
      'face',
      'woo',
      'shh'
    ],
    sheet: [31, 21],
    shortName: 'hushed'
  },
  {
    name: 'Astonished Face',
    unified: '1F632',
    keywords: [
      'astonished_face',
      'face',
      'xox',
      'surprised',
      'poisoned'
    ],
    sheet: [31, 24],
    shortName: 'astonished'
  },
  {
    name: 'Flushed Face',
    unified: '1F633',
    keywords: [
      'flushed_face',
      'face',
      'blush',
      'shy',
      'flattered'
    ],
    sheet: [31, 25],
    shortName: 'flushed'
  },
  {
    name: 'Face with Pleading Eyes',
    unified: '1F97A',
    keywords: [
      'pleading_face',
      'face',
      'begging',
      'mercy'
    ],
    sheet: [42, 27],
    shortName: 'pleading_face'
  },
  {
    name: 'Frowning Face with Open Mouth',
    unified: '1F626',
    keywords: [
      'frowning_face_with_open_mouth',
      'face',
      'aw',
      'what'
    ],
    sheet: [31, 12],
    shortName: 'frowning'
  },
  {
    name: 'Anguished Face',
    unified: '1F627',
    emoticons: ['D:'],
    keywords: [
      'anguished_face',
      'face',
      'stunned',
      'nervous'
    ],
    sheet: [31, 13],
    shortName: 'anguished'
  },
  {
    name: 'Fearful Face',
    unified: '1F628',
    keywords: [
      'fearful_face',
      'face',
      'scared',
      'terrified',
      'nervous',
      'oops',
      'huh'
    ],
    sheet: [31, 14],
    shortName: 'fearful'
  },
  {
    name: 'Face with Open Mouth and Cold Sweat',
    unified: '1F630',
    keywords: [
      'anxious_face_with_sweat',
      'face',
      'nervous',
      'sweat'
    ],
    sheet: [31, 22],
    shortName: 'cold_sweat'
  },
  {
    name: 'Disappointed but Relieved Face',
    unified: '1F625',
    keywords: [
      'sad_but_relieved_face',
      'face',
      'phew',
      'sweat',
      'nervous'
    ],
    sheet: [31, 11],
    shortName: 'disappointed_relieved'
  },
  {
    name: 'Crying Face',
    unified: '1F622',
    text: ':\'(',
    emoticons: [':\'('],
    keywords: [
      'crying_face',
      'face',
      'tears',
      'sad',
      'depressed',
      'upset',
      ':\'('
    ],
    sheet: [31, 8],
    shortName: 'cry'
  },
  {
    name: 'Loudly Crying Face',
    unified: '1F62D',
    text: ':\'(',
    keywords: [
      'loudly_crying_face',
      'face',
      'cry',
      'tears',
      'sad',
      'upset',
      'depressed'
    ],
    sheet: [31, 19],
    shortName: 'sob'
  },
  {
    name: 'Face Screaming in Fear',
    unified: '1F631',
    keywords: [
      'face_screaming_in_fear',
      'face',
      'munch',
      'scared',
      'omg'
    ],
    sheet: [31, 23],
    shortName: 'scream'
  },
  {
    name: 'Confounded Face',
    unified: '1F616',
    keywords: [
      'confounded_face',
      'face',
      'confused',
      'sick',
      'unwell',
      'oops',
      ':S'
    ],
    sheet: [30, 54],
    shortName: 'confounded'
  },
  {
    name: 'Persevering Face',
    unified: '1F623',
    keywords: [
      'persevering_face',
      'face',
      'sick',
      'no',
      'upset',
      'oops'
    ],
    sheet: [31, 9],
    shortName: 'persevere'
  },
  {
    name: 'Disappointed Face',
    unified: '1F61E',
    text: ':(',
    emoticons: ['):', ':(', ':-('],
    keywords: [
      'disappointed_face',
      'face',
      'sad',
      'upset',
      'depressed',
      ':('
    ],
    sheet: [31, 4],
    shortName: 'disappointed'
  },
  {
    name: 'Face with Cold Sweat',
    unified: '1F613',
    keywords: [
      'downcast_face_with_sweat',
      'face',
      'hot',
      'sad',
      'tired',
      'exercise'
    ],
    sheet: [30, 51],
    shortName: 'sweat'
  },
  {
    name: 'Weary Face',
    unified: '1F629',
    keywords: [
      'weary_face',
      'face',
      'tired',
      'sleepy',
      'sad',
      'frustrated',
      'upset'
    ],
    sheet: [31, 15],
    shortName: 'weary'
  },
  {
    name: 'Tired Face',
    unified: '1F62B',
    keywords: [
      'tired_face',
      'sick',
      'whine',
      'upset',
      'frustrated'
    ],
    sheet: [31, 17],
    shortName: 'tired_face'
  },
  {
    name: 'Yawning Face',
    unified: '1F971',
    keywords: [
      'yawning_face',
      'tired',
      'sleepy'
    ],
    sheet: [42, 14],
    shortName: 'yawning_face'
  },
  {
    name: 'Face with Look of Triumph',
    unified: '1F624',
    keywords: [
      'face_with_steam_from_nose',
      'face',
      'gas',
      'phew',
      'proud',
      'pride'
    ],
    sheet: [31, 10],
    shortName: 'triumph'
  },
  {
    name: 'Pouting Face',
    unified: '1F621',
    keywords: [
      'pouting_face',
      'angry',
      'mad',
      'hate',
      'despise'
    ],
    sheet: [31, 7],
    shortName: 'rage'
  },
  {
    name: 'Angry Face',
    unified: '1F620',
    emoticons: ['>:(', '>:-('],
    keywords: [
      'angry_face',
      'mad',
      'face',
      'annoyed',
      'frustrated'
    ],
    sheet: [31, 6],
    shortName: 'angry'
  },
  {
    name: 'Serious Face with Symbols Covering Mouth',
    unified: '1F92C',
    keywords: [
      'face_with_symbols_on_mouth',
      'face',
      'swearing',
      'cursing',
      'cussing',
      'profanity',
      'expletive'
    ],
    sheet: [38, 45],
    shortNames: [
      'serious_face_with_symbols_covering_mouth'
    ],
    shortName: 'face_with_symbols_on_mouth'
  },
  {
    name: 'Smiling Face with Horns',
    unified: '1F608',
    keywords: [
      'smiling_face_with_horns',
      'devil',
      'horns'
    ],
    sheet: [30, 40],
    shortName: 'smiling_imp'
  },
  {
    name: 'Imp',
    unified: '1F47F',
    keywords: [
      'angry_face_with_horns',
      'devil',
      'angry',
      'horns'
    ],
    sheet: [23, 49],
    shortName: 'imp'
  },
  {
    name: 'Skull',
    unified: '1F480',
    keywords: [
      'skull',
      'dead',
      'skeleton',
      'creepy',
      'death'
    ],
    sheet: [23, 50],
    shortName: 'skull'
  },
  {
    name: 'Skull and Crossbones',
    unified: '2620-FE0F',
    keywords: [
      'skull_and_crossbones',
      'poison',
      'danger',
      'deadly',
      'scary',
      'death',
      'pirate',
      'evil'
    ],
    sheet: [54, 13],
    shortName: 'skull_and_crossbones'
  },
  {
    name: 'Pile of Poo',
    unified: '1F4A9',
    keywords: [
      'pile_of_poo',
      'hankey',
      'shitface',
      'fail',
      'turd',
      'shit'
    ],
    sheet: [25, 53],
    shortNames: ['poop', 'shit'],
    shortName: 'hankey'
  },
  {
    name: 'Clown Face',
    unified: '1F921',
    keywords: ['clown_face', 'face'],
    sheet: [38, 17],
    shortName: 'clown_face'
  },
  {
    name: 'Japanese Ogre',
    unified: '1F479',
    keywords: [
      'ogre',
      'monster',
      'red',
      'mask',
      'halloween',
      'scary',
      'creepy',
      'devil',
      'demon',
      'japanese',
      'ogre'
    ],
    sheet: [23, 38],
    shortName: 'japanese_ogre'
  },
  {
    name: 'Japanese Goblin',
    unified: '1F47A',
    keywords: [
      'goblin',
      'red',
      'evil',
      'mask',
      'monster',
      'scary',
      'creepy',
      'japanese',
      'goblin'
    ],
    sheet: [23, 39],
    shortName: 'japanese_goblin'
  },
  {
    name: 'Ghost',
    unified: '1F47B',
    keywords: [
      'ghost',
      'halloween',
      'spooky',
      'scary'
    ],
    sheet: [23, 40],
    shortName: 'ghost'
  },
  {
    name: 'Extraterrestrial Alien',
    unified: '1F47D',
    keywords: [
      'alien',
      'UFO',
      'paul',
      'weird',
      'outer_space'
    ],
    sheet: [23, 47],
    shortName: 'alien'
  },
  {
    name: 'Alien Monster',
    unified: '1F47E',
    keywords: [
      'alien_monster',
      'game',
      'arcade',
      'play'
    ],
    sheet: [23, 48],
    shortName: 'space_invader'
  },
  {
    name: 'Robot Face',
    unified: '1F916',
    keywords: [
      'robot',
      'computer',
      'machine',
      'bot'
    ],
    sheet: [37, 29],
    shortName: 'robot_face'
  },
  {
    name: 'Smiling Cat Face with Open Mouth',
    unified: '1F63A',
    keywords: [
      'grinning_cat',
      'animal',
      'cats',
      'happy',
      'smile'
    ],
    sheet: [31, 32],
    shortName: 'smiley_cat'
  },
  {
    name: 'Grinning Cat Face with Smiling Eyes',
    unified: '1F638',
    keywords: [
      'grinning_cat_with_smiling_eyes',
      'animal',
      'cats',
      'smile'
    ],
    sheet: [31, 30],
    shortName: 'smile_cat'
  },
  {
    name: 'Cat Face with Tears of Joy',
    unified: '1F639',
    keywords: [
      'cat_with_tears_of_joy',
      'animal',
      'cats',
      'haha',
      'happy',
      'tears'
    ],
    sheet: [31, 31],
    shortName: 'joy_cat'
  },
  {
    name: 'Smiling Cat Face with Heart-Shaped Eyes',
    unified: '1F63B',
    keywords: [
      'smiling_cat_with_heart_eyes',
      'animal',
      'love',
      'like',
      'affection',
      'cats',
      'valentines',
      'heart'
    ],
    sheet: [31, 33],
    shortName: 'heart_eyes_cat'
  },
  {
    name: 'Cat Face with Wry Smile',
    unified: '1F63C',
    keywords: [
      'cat_with_wry_smile',
      'animal',
      'cats',
      'smirk'
    ],
    sheet: [31, 34],
    shortName: 'smirk_cat'
  },
  {
    name: 'Kissing Cat Face with Closed Eyes',
    unified: '1F63D',
    keywords: [
      'kissing_cat',
      'animal',
      'cats',
      'kiss'
    ],
    sheet: [31, 35],
    shortName: 'kissing_cat'
  },
  {
    name: 'Weary Cat Face',
    unified: '1F640',
    keywords: [
      'weary_cat',
      'animal',
      'cats',
      'munch',
      'scared',
      'scream'
    ],
    sheet: [31, 38],
    shortName: 'scream_cat'
  },
  {
    name: 'Crying Cat Face',
    unified: '1F63F',
    keywords: [
      'crying_cat',
      'animal',
      'tears',
      'weep',
      'sad',
      'cats',
      'upset',
      'cry'
    ],
    sheet: [31, 37],
    shortName: 'crying_cat_face'
  },
  {
    name: 'Pouting Cat Face',
    unified: '1F63E',
    keywords: [
      'pouting_cat',
      'animal',
      'cats'
    ],
    sheet: [31, 36],
    shortName: 'pouting_cat'
  },
  {
    name: 'See-No-Evil Monkey',
    unified: '1F648',
    keywords: [
      'see_no_evil_monkey',
      'monkey',
      'animal',
      'nature',
      'haha'
    ],
    sheet: [32, 39],
    shortName: 'see_no_evil'
  },
  {
    name: 'Hear-No-Evil Monkey',
    unified: '1F649',
    keywords: [
      'hear_no_evil_monkey',
      'animal',
      'monkey',
      'nature'
    ],
    sheet: [32, 40],
    shortName: 'hear_no_evil'
  },
  {
    name: 'Speak-No-Evil Monkey',
    unified: '1F64A',
    keywords: [
      'speak_no_evil_monkey',
      'monkey',
      'animal',
      'nature',
      'omg'
    ],
    sheet: [32, 41],
    shortName: 'speak_no_evil'
  },
  {
    name: 'Kiss Mark',
    unified: '1F48B',
    keywords: [
      'kiss_mark',
      'face',
      'lips',
      'love',
      'like',
      'affection',
      'valentines'
    ],
    sheet: [25, 23],
    shortName: 'kiss'
  },
  {
    name: 'Love Letter',
    unified: '1F48C',
    keywords: [
      'love_letter',
      'email',
      'like',
      'affection',
      'envelope',
      'valentines'
    ],
    sheet: [25, 24],
    shortName: 'love_letter'
  },
  {
    name: 'Heart with Arrow',
    unified: '1F498',
    keywords: [
      'heart_with_arrow',
      'love',
      'like',
      'heart',
      'affection',
      'valentines'
    ],
    sheet: [25, 36],
    shortName: 'cupid'
  },
  {
    name: 'Heart with Ribbon',
    unified: '1F49D',
    keywords: [
      'heart_with_ribbon',
      'love',
      'valentines'
    ],
    sheet: [25, 41],
    shortName: 'gift_heart'
  },
  {
    name: 'Sparkling Heart',
    unified: '1F496',
    keywords: [
      'sparkling_heart',
      'love',
      'like',
      'affection',
      'valentines'
    ],
    sheet: [25, 34],
    shortName: 'sparkling_heart'
  },
  {
    name: 'Growing Heart',
    unified: '1F497',
    keywords: [
      'growing_heart',
      'like',
      'love',
      'affection',
      'valentines',
      'pink'
    ],
    sheet: [25, 35],
    shortName: 'heartpulse'
  },
  {
    name: 'Beating Heart',
    unified: '1F493',
    keywords: [
      'beating_heart',
      'love',
      'like',
      'affection',
      'valentines',
      'pink',
      'heart'
    ],
    sheet: [25, 31],
    shortName: 'heartbeat'
  },
  {
    name: 'Revolving Hearts',
    unified: '1F49E',
    keywords: [
      'revolving_hearts',
      'love',
      'like',
      'affection',
      'valentines'
    ],
    sheet: [25, 42],
    shortName: 'revolving_hearts'
  },
  {
    name: 'Two Hearts',
    unified: '1F495',
    keywords: [
      'two_hearts',
      'love',
      'like',
      'affection',
      'valentines',
      'heart'
    ],
    sheet: [25, 33],
    shortName: 'two_hearts'
  },
  {
    name: 'Heart Decoration',
    unified: '1F49F',
    keywords: [
      'heart_decoration',
      'purple-square',
      'love',
      'like'
    ],
    sheet: [25, 43],
    shortName: 'heart_decoration'
  },
  {
    name: 'Heart Exclamation',
    unified: '2763-FE0F',
    keywords: [
      'heart_exclamation',
      'decoration',
      'love'
    ],
    sheet: [56, 31],
    shortName: 'heavy_heart_exclamation_mark_ornament'
  },
  {
    name: 'Broken Heart',
    unified: '1F494',
    text: '</3',
    emoticons: ['</3'],
    keywords: [
      'broken_heart',
      'sad',
      'sorry',
      'break',
      'heart',
      'heartbreak'
    ],
    sheet: [25, 32],
    shortName: 'broken_heart'
  },
  {
    name: 'Heavy Black Heart',
    unified: '2764-FE0F',
    text: '<3',
    emoticons: ['<3'],
    keywords: [
      'red_heart',
      'love',
      'like',
      'valentines'
    ],
    sheet: [56, 32],
    shortName: 'heart'
  },
  {
    name: 'Orange Heart',
    unified: '1F9E1',
    keywords: [
      'orange_heart',
      'love',
      'like',
      'affection',
      'valentines'
    ],
    sheet: [51, 44],
    shortName: 'orange_heart'
  },
  {
    name: 'Yellow Heart',
    unified: '1F49B',
    text: '<3',
    keywords: [
      'yellow_heart',
      'love',
      'like',
      'affection',
      'valentines'
    ],
    sheet: [25, 39],
    shortName: 'yellow_heart'
  },
  {
    name: 'Green Heart',
    unified: '1F49A',
    text: '<3',
    keywords: [
      'green_heart',
      'love',
      'like',
      'affection',
      'valentines'
    ],
    sheet: [25, 38],
    shortName: 'green_heart'
  },
  {
    name: 'Blue Heart',
    unified: '1F499',
    text: '<3',
    keywords: [
      'blue_heart',
      'love',
      'like',
      'affection',
      'valentines'
    ],
    sheet: [25, 37],
    shortName: 'blue_heart'
  },
  {
    name: 'Purple Heart',
    unified: '1F49C',
    text: '<3',
    keywords: [
      'purple_heart',
      'love',
      'like',
      'affection',
      'valentines'
    ],
    sheet: [25, 40],
    shortName: 'purple_heart'
  },
  {
    name: 'Brown Heart',
    unified: '1F90E',
    keywords: ['brown_heart', 'coffee'],
    sheet: [37, 16],
    shortName: 'brown_heart'
  },
  {
    name: 'Black Heart',
    unified: '1F5A4',
    keywords: ['black_heart', 'evil'],
    sheet: [30, 6],
    shortName: 'black_heart'
  },
  {
    name: 'White Heart',
    unified: '1F90D',
    keywords: ['white_heart', 'pure'],
    sheet: [37, 15],
    shortName: 'white_heart'
  },
  {
    name: 'Hundred Points Symbol',
    unified: '1F4AF',
    keywords: [
      'hundred_points',
      'score',
      'perfect',
      'numbers',
      'century',
      'exam',
      'quiz',
      'test',
      'pass',
      'hundred'
    ],
    sheet: [26, 6],
    shortName: '100'
  },
  {
    name: 'Anger Symbol',
    unified: '1F4A2',
    keywords: [
      'anger_symbol',
      'angry',
      'mad'
    ],
    sheet: [25, 46],
    shortName: 'anger'
  },
  {
    name: 'Collision Symbol',
    unified: '1F4A5',
    keywords: [
      'collision',
      'bomb',
      'explode',
      'explosion',
      'collision',
      'blown'
    ],
    sheet: [25, 49],
    shortNames: ['collision'],
    shortName: 'boom'
  },
  {
    name: 'Dizzy Symbol',
    unified: '1F4AB',
    keywords: [
      'dizzy',
      'star',
      'sparkle',
      'shoot',
      'magic'
    ],
    sheet: [26, 2],
    shortName: 'dizzy'
  },
  {
    name: 'Splashing Sweat Symbol',
    unified: '1F4A6',
    keywords: [
      'sweat_droplets',
      'water',
      'drip',
      'oops'
    ],
    sheet: [25, 50],
    shortName: 'sweat_drops'
  },
  {
    name: 'Dash Symbol',
    unified: '1F4A8',
    keywords: [
      'dashing_away',
      'wind',
      'air',
      'fast',
      'shoo',
      'fart',
      'smoke',
      'puff'
    ],
    sheet: [25, 52],
    shortName: 'dash'
  },
  {
    name: 'Hole',
    unified: '1F573-FE0F',
    keywords: ['hole', 'embarrassing'],
    sheet: [29, 6],
    shortName: 'hole'
  },
  {
    name: 'Bomb',
    unified: '1F4A3',
    keywords: [
      'bomb',
      'boom',
      'explode',
      'explosion',
      'terrorism'
    ],
    sheet: [25, 47],
    shortName: 'bomb'
  },
  {
    name: 'Speech Balloon',
    unified: '1F4AC',
    keywords: [
      'speech_balloon',
      'bubble',
      'words',
      'message',
      'talk',
      'chatting'
    ],
    sheet: [26, 3],
    shortName: 'speech_balloon'
  },
  {
    name: 'Eye in Speech Bubble',
    unified: '1F441-FE0F-200D-1F5E8-FE0F',
    keywords: [
      'eye_in_speech_bubble',
      'info'
    ],
    sheet: [12, 28],
    hidden: ['twitter', 'facebook'],
    shortName: 'eye-in-speech-bubble'
  },
  {
    name: 'Left Speech Bubble',
    unified: '1F5E8-FE0F',
    keywords: [
      'left_speech_bubble',
      'words',
      'message',
      'talk',
      'chatting'
    ],
    sheet: [30, 23],
    shortName: 'left_speech_bubble'
  },
  {
    name: 'Right Anger Bubble',
    unified: '1F5EF-FE0F',
    keywords: [
      'right_anger_bubble',
      'caption',
      'speech',
      'thinking',
      'mad'
    ],
    sheet: [30, 24],
    shortName: 'right_anger_bubble'
  },
  {
    name: 'Thought Balloon',
    unified: '1F4AD',
    keywords: [
      'thought_balloon',
      'bubble',
      'cloud',
      'speech',
      'thinking',
      'dream'
    ],
    sheet: [26, 4],
    shortName: 'thought_balloon'
  },
  {
    name: 'Sleeping Symbol',
    unified: '1F4A4',
    keywords: [
      'zzz',
      'sleepy',
      'tired',
      'dream'
    ],
    sheet: [25, 48],
    shortName: 'zzz'
  },
  {
    name: 'Waving Hand Sign',
    unified: '1F44B',
    keywords: [
      'waving_hand',
      'hands',
      'gesture',
      'goodbye',
      'solong',
      'farewell',
      'hello',
      'hi',
      'palm'
    ],
    sheet: [13, 16],
    skinVariations: [
      {
        unified: '1F44B-1F3FB',
        sheet: [13, 17]
      },
      {
        unified: '1F44B-1F3FC',
        sheet: [13, 18]
      },
      {
        unified: '1F44B-1F3FD',
        sheet: [13, 19]
      },
      {
        unified: '1F44B-1F3FE',
        sheet: [13, 20]
      },
      {
        unified: '1F44B-1F3FF',
        sheet: [13, 21]
      }
    ],
    shortName: 'wave'
  },
  {
    name: 'Raised Back of Hand',
    unified: '1F91A',
    keywords: [
      'raised_back_of_hand',
      'fingers',
      'raised',
      'backhand'
    ],
    sheet: [37, 43],
    skinVariations: [
      {
        unified: '1F91A-1F3FB',
        sheet: [37, 44]
      },
      {
        unified: '1F91A-1F3FC',
        sheet: [37, 45]
      },
      {
        unified: '1F91A-1F3FD',
        sheet: [37, 46]
      },
      {
        unified: '1F91A-1F3FE',
        sheet: [37, 47]
      },
      {
        unified: '1F91A-1F3FF',
        sheet: [37, 48]
      }
    ],
    shortName: 'raised_back_of_hand'
  },
  {
    name: 'Hand with Fingers Splayed',
    unified: '1F590-FE0F',
    keywords: [
      'hand_with_fingers_splayed',
      'hand',
      'fingers',
      'palm'
    ],
    sheet: [29, 46],
    skinVariations: [
      {
        unified: '1F590-1F3FB',
        sheet: [29, 47]
      },
      {
        unified: '1F590-1F3FC',
        sheet: [29, 48]
      },
      {
        unified: '1F590-1F3FD',
        sheet: [29, 49]
      },
      {
        unified: '1F590-1F3FE',
        sheet: [29, 50]
      },
      {
        unified: '1F590-1F3FF',
        sheet: [29, 51]
      }
    ],
    shortName: 'raised_hand_with_fingers_splayed'
  },
  {
    name: 'Raised Hand',
    unified: '270B',
    keywords: [
      'raised_hand',
      'fingers',
      'stop',
      'highfive',
      'palm',
      'ban'
    ],
    sheet: [55, 54],
    skinVariations: [
      {
        unified: '270B-1F3FB',
        sheet: [55, 55]
      },
      {
        unified: '270B-1F3FC',
        sheet: [55, 56]
      },
      {
        unified: '270B-1F3FD',
        sheet: [55, 57]
      },
      {
        unified: '270B-1F3FE',
        sheet: [56, 0]
      },
      {
        unified: '270B-1F3FF',
        sheet: [56, 1]
      }
    ],
    shortNames: ['raised_hand'],
    shortName: 'hand'
  },
  {
    name: 'Raised Hand with Part Between Middle and Ring Fingers',
    unified: '1F596',
    keywords: [
      'vulcan_salute',
      'hand',
      'fingers',
      'spock',
      'star trek'
    ],
    sheet: [30, 0],
    skinVariations: [
      {
        unified: '1F596-1F3FB',
        sheet: [30, 1]
      },
      {
        unified: '1F596-1F3FC',
        sheet: [30, 2]
      },
      {
        unified: '1F596-1F3FD',
        sheet: [30, 3]
      },
      {
        unified: '1F596-1F3FE',
        sheet: [30, 4]
      },
      {
        unified: '1F596-1F3FF',
        sheet: [30, 5]
      }
    ],
    shortName: 'spock-hand'
  },
  {
    name: 'Ok Hand Sign',
    unified: '1F44C',
    keywords: [
      'ok_hand',
      'fingers',
      'limbs',
      'perfect',
      'ok',
      'okay'
    ],
    sheet: [13, 22],
    skinVariations: [
      {
        unified: '1F44C-1F3FB',
        sheet: [13, 23]
      },
      {
        unified: '1F44C-1F3FC',
        sheet: [13, 24]
      },
      {
        unified: '1F44C-1F3FD',
        sheet: [13, 25]
      },
      {
        unified: '1F44C-1F3FE',
        sheet: [13, 26]
      },
      {
        unified: '1F44C-1F3FF',
        sheet: [13, 27]
      }
    ],
    shortName: 'ok_hand'
  },
  {
    name: 'Pinched Fingers',
    unified: '1F90C',
    keywords: [
      'pinched fingers',
      'size',
      'tiny',
      'small'
    ],
    sheet: [37, 9],
    skinVariations: [
      {
        unified: '1F90C-1F3FB',
        sheet: [37, 10]
      },
      {
        unified: '1F90C-1F3FC',
        sheet: [37, 11]
      },
      {
        unified: '1F90C-1F3FD',
        sheet: [37, 12]
      },
      {
        unified: '1F90C-1F3FE',
        sheet: [37, 13]
      },
      {
        unified: '1F90C-1F3FF',
        sheet: [37, 14]
      }
    ],
    shortName: 'pinched_fingers'
  },
  {
    name: 'Pinching Hand',
    unified: '1F90F',
    keywords: [
      'pinching_hand',
      'tiny',
      'small',
      'size'
    ],
    sheet: [37, 17],
    skinVariations: [
      {
        unified: '1F90F-1F3FB',
        sheet: [37, 18]
      },
      {
        unified: '1F90F-1F3FC',
        sheet: [37, 19]
      },
      {
        unified: '1F90F-1F3FD',
        sheet: [37, 20]
      },
      {
        unified: '1F90F-1F3FE',
        sheet: [37, 21]
      },
      {
        unified: '1F90F-1F3FF',
        sheet: [37, 22]
      }
    ],
    shortName: 'pinching_hand'
  },
  {
    name: 'Victory Hand',
    unified: '270C-FE0F',
    keywords: [
      'victory_hand',
      'fingers',
      'ohyeah',
      'hand',
      'peace',
      'victory',
      'two'
    ],
    sheet: [56, 2],
    skinVariations: [
      {
        unified: '270C-1F3FB',
        sheet: [56, 3]
      },
      {
        unified: '270C-1F3FC',
        sheet: [56, 4]
      },
      {
        unified: '270C-1F3FD',
        sheet: [56, 5]
      },
      {
        unified: '270C-1F3FE',
        sheet: [56, 6]
      },
      {
        unified: '270C-1F3FF',
        sheet: [56, 7]
      }
    ],
    shortName: 'v'
  },
  {
    name: 'Hand with Index and Middle Fingers Crossed',
    unified: '1F91E',
    keywords: [
      'crossed_fingers',
      'good',
      'lucky'
    ],
    sheet: [38, 4],
    skinVariations: [
      {
        unified: '1F91E-1F3FB',
        sheet: [38, 5]
      },
      {
        unified: '1F91E-1F3FC',
        sheet: [38, 6]
      },
      {
        unified: '1F91E-1F3FD',
        sheet: [38, 7]
      },
      {
        unified: '1F91E-1F3FE',
        sheet: [38, 8]
      },
      {
        unified: '1F91E-1F3FF',
        sheet: [38, 9]
      }
    ],
    shortNames: [
      'hand_with_index_and_middle_fingers_crossed'
    ],
    shortName: 'crossed_fingers'
  },
  {
    name: 'I Love You Hand Sign',
    unified: '1F91F',
    keywords: [
      'love_you_gesture',
      'hand',
      'fingers',
      'gesture'
    ],
    sheet: [38, 10],
    skinVariations: [
      {
        unified: '1F91F-1F3FB',
        sheet: [38, 11]
      },
      {
        unified: '1F91F-1F3FC',
        sheet: [38, 12]
      },
      {
        unified: '1F91F-1F3FD',
        sheet: [38, 13]
      },
      {
        unified: '1F91F-1F3FE',
        sheet: [38, 14]
      },
      {
        unified: '1F91F-1F3FF',
        sheet: [38, 15]
      }
    ],
    shortName: 'i_love_you_hand_sign'
  },
  {
    name: 'Sign of the Horns',
    unified: '1F918',
    keywords: [
      'sign_of_the_horns',
      'hand',
      'fingers',
      'evil_eye',
      'sign_of_horns',
      'rock_on'
    ],
    sheet: [37, 31],
    skinVariations: [
      {
        unified: '1F918-1F3FB',
        sheet: [37, 32]
      },
      {
        unified: '1F918-1F3FC',
        sheet: [37, 33]
      },
      {
        unified: '1F918-1F3FD',
        sheet: [37, 34]
      },
      {
        unified: '1F918-1F3FE',
        sheet: [37, 35]
      },
      {
        unified: '1F918-1F3FF',
        sheet: [37, 36]
      }
    ],
    shortNames: ['sign_of_the_horns'],
    shortName: 'the_horns'
  },
  {
    name: 'Call Me Hand',
    unified: '1F919',
    keywords: [
      'call_me_hand',
      'hands',
      'gesture'
    ],
    sheet: [37, 37],
    skinVariations: [
      {
        unified: '1F919-1F3FB',
        sheet: [37, 38]
      },
      {
        unified: '1F919-1F3FC',
        sheet: [37, 39]
      },
      {
        unified: '1F919-1F3FD',
        sheet: [37, 40]
      },
      {
        unified: '1F919-1F3FE',
        sheet: [37, 41]
      },
      {
        unified: '1F919-1F3FF',
        sheet: [37, 42]
      }
    ],
    shortName: 'call_me_hand'
  },
  {
    name: 'White Left Pointing Backhand Index',
    unified: '1F448',
    keywords: [
      'backhand_index_pointing_left',
      'direction',
      'fingers',
      'hand',
      'left'
    ],
    sheet: [12, 56],
    skinVariations: [
      {
        unified: '1F448-1F3FB',
        sheet: [12, 57]
      },
      {
        unified: '1F448-1F3FC',
        sheet: [13, 0]
      },
      {
        unified: '1F448-1F3FD',
        sheet: [13, 1]
      },
      {
        unified: '1F448-1F3FE',
        sheet: [13, 2]
      },
      {
        unified: '1F448-1F3FF',
        sheet: [13, 3]
      }
    ],
    shortName: 'point_left'
  },
  {
    name: 'White Right Pointing Backhand Index',
    unified: '1F449',
    keywords: [
      'backhand_index_pointing_right',
      'fingers',
      'hand',
      'direction',
      'right'
    ],
    sheet: [13, 4],
    skinVariations: [
      {
        unified: '1F449-1F3FB',
        sheet: [13, 5]
      },
      {
        unified: '1F449-1F3FC',
        sheet: [13, 6]
      },
      {
        unified: '1F449-1F3FD',
        sheet: [13, 7]
      },
      {
        unified: '1F449-1F3FE',
        sheet: [13, 8]
      },
      {
        unified: '1F449-1F3FF',
        sheet: [13, 9]
      }
    ],
    shortName: 'point_right'
  },
  {
    name: 'White Up Pointing Backhand Index',
    unified: '1F446',
    keywords: [
      'backhand_index_pointing_up',
      'fingers',
      'hand',
      'direction',
      'up'
    ],
    sheet: [12, 44],
    skinVariations: [
      {
        unified: '1F446-1F3FB',
        sheet: [12, 45]
      },
      {
        unified: '1F446-1F3FC',
        sheet: [12, 46]
      },
      {
        unified: '1F446-1F3FD',
        sheet: [12, 47]
      },
      {
        unified: '1F446-1F3FE',
        sheet: [12, 48]
      },
      {
        unified: '1F446-1F3FF',
        sheet: [12, 49]
      }
    ],
    shortName: 'point_up_2'
  },
  {
    name: 'Reversed Hand with Middle Finger Extended',
    unified: '1F595',
    keywords: [
      'middle_finger',
      'hand',
      'fingers',
      'rude',
      'middle',
      'flipping'
    ],
    sheet: [29, 52],
    skinVariations: [
      {
        unified: '1F595-1F3FB',
        sheet: [29, 53]
      },
      {
        unified: '1F595-1F3FC',
        sheet: [29, 54]
      },
      {
        unified: '1F595-1F3FD',
        sheet: [29, 55]
      },
      {
        unified: '1F595-1F3FE',
        sheet: [29, 56]
      },
      {
        unified: '1F595-1F3FF',
        sheet: [29, 57]
      }
    ],
    shortNames: [
      'reversed_hand_with_middle_finger_extended'
    ],
    shortName: 'middle_finger'
  },
  {
    name: 'White Down Pointing Backhand Index',
    unified: '1F447',
    keywords: [
      'backhand_index_pointing_down',
      'fingers',
      'hand',
      'direction',
      'down'
    ],
    sheet: [12, 50],
    skinVariations: [
      {
        unified: '1F447-1F3FB',
        sheet: [12, 51]
      },
      {
        unified: '1F447-1F3FC',
        sheet: [12, 52]
      },
      {
        unified: '1F447-1F3FD',
        sheet: [12, 53]
      },
      {
        unified: '1F447-1F3FE',
        sheet: [12, 54]
      },
      {
        unified: '1F447-1F3FF',
        sheet: [12, 55]
      }
    ],
    shortName: 'point_down'
  },
  {
    name: 'White Up Pointing Index',
    unified: '261D-FE0F',
    keywords: [
      'index_pointing_up',
      'hand',
      'fingers',
      'direction',
      'up'
    ],
    sheet: [54, 7],
    skinVariations: [
      {
        unified: '261D-1F3FB',
        sheet: [54, 8]
      },
      {
        unified: '261D-1F3FC',
        sheet: [54, 9]
      },
      {
        unified: '261D-1F3FD',
        sheet: [54, 10]
      },
      {
        unified: '261D-1F3FE',
        sheet: [54, 11]
      },
      {
        unified: '261D-1F3FF',
        sheet: [54, 12]
      }
    ],
    shortName: 'point_up'
  },
  {
    name: 'Thumbs Up Sign',
    unified: '1F44D',
    keywords: [
      'thumbs_up',
      'thumbsup',
      'yes',
      'awesome',
      'good',
      'agree',
      'accept',
      'cool',
      'hand',
      'like',
      '+1'
    ],
    sheet: [13, 28],
    skinVariations: [
      {
        unified: '1F44D-1F3FB',
        sheet: [13, 29]
      },
      {
        unified: '1F44D-1F3FC',
        sheet: [13, 30]
      },
      {
        unified: '1F44D-1F3FD',
        sheet: [13, 31]
      },
      {
        unified: '1F44D-1F3FE',
        sheet: [13, 32]
      },
      {
        unified: '1F44D-1F3FF',
        sheet: [13, 33]
      }
    ],
    shortNames: ['thumbsup'],
    shortName: '+1'
  },
  {
    name: 'Thumbs Down Sign',
    unified: '1F44E',
    keywords: [
      'thumbs_down',
      'thumbsdown',
      'no',
      'dislike',
      'hand',
      '-1'
    ],
    sheet: [13, 34],
    skinVariations: [
      {
        unified: '1F44E-1F3FB',
        sheet: [13, 35]
      },
      {
        unified: '1F44E-1F3FC',
        sheet: [13, 36]
      },
      {
        unified: '1F44E-1F3FD',
        sheet: [13, 37]
      },
      {
        unified: '1F44E-1F3FE',
        sheet: [13, 38]
      },
      {
        unified: '1F44E-1F3FF',
        sheet: [13, 39]
      }
    ],
    shortNames: ['thumbsdown'],
    shortName: '-1'
  },
  {
    name: 'Raised Fist',
    unified: '270A',
    keywords: [
      'raised_fist',
      'fingers',
      'hand',
      'grasp'
    ],
    sheet: [55, 48],
    skinVariations: [
      {
        unified: '270A-1F3FB',
        sheet: [55, 49]
      },
      {
        unified: '270A-1F3FC',
        sheet: [55, 50]
      },
      {
        unified: '270A-1F3FD',
        sheet: [55, 51]
      },
      {
        unified: '270A-1F3FE',
        sheet: [55, 52]
      },
      {
        unified: '270A-1F3FF',
        sheet: [55, 53]
      }
    ],
    shortName: 'fist'
  },
  {
    name: 'Fisted Hand Sign',
    unified: '1F44A',
    keywords: [
      'oncoming_fist',
      'angry',
      'violence',
      'fist',
      'hit',
      'attack',
      'hand'
    ],
    sheet: [13, 10],
    skinVariations: [
      {
        unified: '1F44A-1F3FB',
        sheet: [13, 11]
      },
      {
        unified: '1F44A-1F3FC',
        sheet: [13, 12]
      },
      {
        unified: '1F44A-1F3FD',
        sheet: [13, 13]
      },
      {
        unified: '1F44A-1F3FE',
        sheet: [13, 14]
      },
      {
        unified: '1F44A-1F3FF',
        sheet: [13, 15]
      }
    ],
    shortNames: ['punch'],
    shortName: 'facepunch'
  },
  {
    name: 'Left-Facing Fist',
    unified: '1F91B',
    keywords: [
      'left_facing_fist',
      'hand',
      'fistbump'
    ],
    sheet: [37, 49],
    skinVariations: [
      {
        unified: '1F91B-1F3FB',
        sheet: [37, 50]
      },
      {
        unified: '1F91B-1F3FC',
        sheet: [37, 51]
      },
      {
        unified: '1F91B-1F3FD',
        sheet: [37, 52]
      },
      {
        unified: '1F91B-1F3FE',
        sheet: [37, 53]
      },
      {
        unified: '1F91B-1F3FF',
        sheet: [37, 54]
      }
    ],
    shortName: 'left-facing_fist'
  },
  {
    name: 'Right-Facing Fist',
    unified: '1F91C',
    keywords: [
      'right_facing_fist',
      'hand',
      'fistbump'
    ],
    sheet: [37, 55],
    skinVariations: [
      {
        unified: '1F91C-1F3FB',
        sheet: [37, 56]
      },
      {
        unified: '1F91C-1F3FC',
        sheet: [37, 57]
      },
      {
        unified: '1F91C-1F3FD',
        sheet: [38, 0]
      },
      {
        unified: '1F91C-1F3FE',
        sheet: [38, 1]
      },
      {
        unified: '1F91C-1F3FF',
        sheet: [38, 2]
      }
    ],
    shortName: 'right-facing_fist'
  },
  {
    name: 'Clapping Hands Sign',
    unified: '1F44F',
    keywords: [
      'clapping_hands',
      'hands',
      'praise',
      'applause',
      'congrats',
      'yay'
    ],
    sheet: [13, 40],
    skinVariations: [
      {
        unified: '1F44F-1F3FB',
        sheet: [13, 41]
      },
      {
        unified: '1F44F-1F3FC',
        sheet: [13, 42]
      },
      {
        unified: '1F44F-1F3FD',
        sheet: [13, 43]
      },
      {
        unified: '1F44F-1F3FE',
        sheet: [13, 44]
      },
      {
        unified: '1F44F-1F3FF',
        sheet: [13, 45]
      }
    ],
    shortName: 'clap'
  },
  {
    name: 'Person Raising Both Hands in Celebration',
    unified: '1F64C',
    keywords: [
      'raising_hands',
      'gesture',
      'hooray',
      'yea',
      'celebration',
      'hands'
    ],
    sheet: [33, 2],
    skinVariations: [
      {
        unified: '1F64C-1F3FB',
        sheet: [33, 3]
      },
      {
        unified: '1F64C-1F3FC',
        sheet: [33, 4]
      },
      {
        unified: '1F64C-1F3FD',
        sheet: [33, 5]
      },
      {
        unified: '1F64C-1F3FE',
        sheet: [33, 6]
      },
      {
        unified: '1F64C-1F3FF',
        sheet: [33, 7]
      }
    ],
    shortName: 'raised_hands'
  },
  {
    name: 'Open Hands Sign',
    unified: '1F450',
    keywords: [
      'open_hands',
      'fingers',
      'butterfly',
      'hands',
      'open'
    ],
    sheet: [13, 46],
    skinVariations: [
      {
        unified: '1F450-1F3FB',
        sheet: [13, 47]
      },
      {
        unified: '1F450-1F3FC',
        sheet: [13, 48]
      },
      {
        unified: '1F450-1F3FD',
        sheet: [13, 49]
      },
      {
        unified: '1F450-1F3FE',
        sheet: [13, 50]
      },
      {
        unified: '1F450-1F3FF',
        sheet: [13, 51]
      }
    ],
    shortName: 'open_hands'
  },
  {
    name: 'Palms Up Together',
    unified: '1F932',
    keywords: [
      'palms_up_together',
      'hands',
      'gesture',
      'cupped',
      'prayer'
    ],
    sheet: [39, 3],
    skinVariations: [
      {
        unified: '1F932-1F3FB',
        sheet: [39, 4]
      },
      {
        unified: '1F932-1F3FC',
        sheet: [39, 5]
      },
      {
        unified: '1F932-1F3FD',
        sheet: [39, 6]
      },
      {
        unified: '1F932-1F3FE',
        sheet: [39, 7]
      },
      {
        unified: '1F932-1F3FF',
        sheet: [39, 8]
      }
    ],
    shortName: 'palms_up_together'
  },
  {
    name: 'Handshake',
    unified: '1F91D',
    keywords: [
      'handshake',
      'agreement',
      'shake'
    ],
    sheet: [38, 3],
    shortName: 'handshake'
  },
  {
    name: 'Person with Folded Hands',
    unified: '1F64F',
    keywords: [
      'folded_hands',
      'please',
      'hope',
      'wish',
      'namaste',
      'highfive'
    ],
    sheet: [33, 44],
    skinVariations: [
      {
        unified: '1F64F-1F3FB',
        sheet: [33, 45]
      },
      {
        unified: '1F64F-1F3FC',
        sheet: [33, 46]
      },
      {
        unified: '1F64F-1F3FD',
        sheet: [33, 47]
      },
      {
        unified: '1F64F-1F3FE',
        sheet: [33, 48]
      },
      {
        unified: '1F64F-1F3FF',
        sheet: [33, 49]
      }
    ],
    shortName: 'pray'
  },
  {
    name: 'Writing Hand',
    unified: '270D-FE0F',
    keywords: [
      'writing_hand',
      'lower_left_ballpoint_pen',
      'stationery',
      'write',
      'compose'
    ],
    sheet: [56, 8],
    skinVariations: [
      {
        unified: '270D-1F3FB',
        sheet: [56, 9]
      },
      {
        unified: '270D-1F3FC',
        sheet: [56, 10]
      },
      {
        unified: '270D-1F3FD',
        sheet: [56, 11]
      },
      {
        unified: '270D-1F3FE',
        sheet: [56, 12]
      },
      {
        unified: '270D-1F3FF',
        sheet: [56, 13]
      }
    ],
    shortName: 'writing_hand'
  },
  {
    name: 'Nail Polish',
    unified: '1F485',
    keywords: [
      'nail_polish',
      'beauty',
      'manicure',
      'finger',
      'fashion',
      'nail'
    ],
    sheet: [24, 36],
    skinVariations: [
      {
        unified: '1F485-1F3FB',
        sheet: [24, 37]
      },
      {
        unified: '1F485-1F3FC',
        sheet: [24, 38]
      },
      {
        unified: '1F485-1F3FD',
        sheet: [24, 39]
      },
      {
        unified: '1F485-1F3FE',
        sheet: [24, 40]
      },
      {
        unified: '1F485-1F3FF',
        sheet: [24, 41]
      }
    ],
    shortName: 'nail_care'
  },
  {
    name: 'Selfie',
    unified: '1F933',
    keywords: [
      'selfie',
      'camera',
      'phone'
    ],
    sheet: [39, 9],
    skinVariations: [
      {
        unified: '1F933-1F3FB',
        sheet: [39, 10]
      },
      {
        unified: '1F933-1F3FC',
        sheet: [39, 11]
      },
      {
        unified: '1F933-1F3FD',
        sheet: [39, 12]
      },
      {
        unified: '1F933-1F3FE',
        sheet: [39, 13]
      },
      {
        unified: '1F933-1F3FF',
        sheet: [39, 14]
      }
    ],
    shortName: 'selfie'
  },
  {
    name: 'Flexed Biceps',
    unified: '1F4AA',
    keywords: [
      'flexed_biceps',
      'arm',
      'flex',
      'hand',
      'summer',
      'strong',
      'biceps'
    ],
    sheet: [25, 54],
    skinVariations: [
      {
        unified: '1F4AA-1F3FB',
        sheet: [25, 55]
      },
      {
        unified: '1F4AA-1F3FC',
        sheet: [25, 56]
      },
      {
        unified: '1F4AA-1F3FD',
        sheet: [25, 57]
      },
      {
        unified: '1F4AA-1F3FE',
        sheet: [26, 0]
      },
      {
        unified: '1F4AA-1F3FF',
        sheet: [26, 1]
      }
    ],
    shortName: 'muscle'
  },
  {
    name: 'Mechanical Arm',
    unified: '1F9BE',
    keywords: [
      'mechanical_arm',
      'accessibility'
    ],
    sheet: [44, 24],
    shortName: 'mechanical_arm'
  },
  {
    name: 'Mechanical Leg',
    unified: '1F9BF',
    keywords: [
      'mechanical_leg',
      'accessibility'
    ],
    sheet: [44, 25],
    shortName: 'mechanical_leg'
  },
  {
    name: 'Leg',
    unified: '1F9B5',
    keywords: ['leg', 'kick', 'limb'],
    sheet: [43, 24],
    skinVariations: [
      {
        unified: '1F9B5-1F3FB',
        sheet: [43, 25]
      },
      {
        unified: '1F9B5-1F3FC',
        sheet: [43, 26]
      },
      {
        unified: '1F9B5-1F3FD',
        sheet: [43, 27]
      },
      {
        unified: '1F9B5-1F3FE',
        sheet: [43, 28]
      },
      {
        unified: '1F9B5-1F3FF',
        sheet: [43, 29]
      }
    ],
    shortName: 'leg'
  },
  {
    name: 'Foot',
    unified: '1F9B6',
    keywords: ['foot', 'kick', 'stomp'],
    sheet: [43, 30],
    skinVariations: [
      {
        unified: '1F9B6-1F3FB',
        sheet: [43, 31]
      },
      {
        unified: '1F9B6-1F3FC',
        sheet: [43, 32]
      },
      {
        unified: '1F9B6-1F3FD',
        sheet: [43, 33]
      },
      {
        unified: '1F9B6-1F3FE',
        sheet: [43, 34]
      },
      {
        unified: '1F9B6-1F3FF',
        sheet: [43, 35]
      }
    ],
    shortName: 'foot'
  },
  {
    name: 'Ear',
    unified: '1F442',
    keywords: [
      'ear',
      'face',
      'hear',
      'sound',
      'listen'
    ],
    sheet: [12, 30],
    skinVariations: [
      {
        unified: '1F442-1F3FB',
        sheet: [12, 31]
      },
      {
        unified: '1F442-1F3FC',
        sheet: [12, 32]
      },
      {
        unified: '1F442-1F3FD',
        sheet: [12, 33]
      },
      {
        unified: '1F442-1F3FE',
        sheet: [12, 34]
      },
      {
        unified: '1F442-1F3FF',
        sheet: [12, 35]
      }
    ],
    shortName: 'ear'
  },
  {
    name: 'Ear with Hearing Aid',
    unified: '1F9BB',
    keywords: [
      'ear_with_hearing_aid',
      'accessibility'
    ],
    sheet: [44, 16],
    skinVariations: [
      {
        unified: '1F9BB-1F3FB',
        sheet: [44, 17]
      },
      {
        unified: '1F9BB-1F3FC',
        sheet: [44, 18]
      },
      {
        unified: '1F9BB-1F3FD',
        sheet: [44, 19]
      },
      {
        unified: '1F9BB-1F3FE',
        sheet: [44, 20]
      },
      {
        unified: '1F9BB-1F3FF',
        sheet: [44, 21]
      }
    ],
    shortName: 'ear_with_hearing_aid'
  },
  {
    name: 'Nose',
    unified: '1F443',
    keywords: [
      'nose',
      'smell',
      'sniff'
    ],
    sheet: [12, 36],
    skinVariations: [
      {
        unified: '1F443-1F3FB',
        sheet: [12, 37]
      },
      {
        unified: '1F443-1F3FC',
        sheet: [12, 38]
      },
      {
        unified: '1F443-1F3FD',
        sheet: [12, 39]
      },
      {
        unified: '1F443-1F3FE',
        sheet: [12, 40]
      },
      {
        unified: '1F443-1F3FF',
        sheet: [12, 41]
      }
    ],
    shortName: 'nose'
  },
  {
    name: 'Brain',
    unified: '1F9E0',
    keywords: [
      'brain',
      'smart',
      'intelligent'
    ],
    sheet: [51, 43],
    shortName: 'brain'
  },
  {
    name: 'Anatomical Heart',
    unified: '1FAC0',
    keywords: [
      'anatomical heart',
      'health',
      'heartbeat'
    ],
    sheet: [53, 6],
    shortName: 'anatomical_heart'
  },
  {
    name: 'Lungs',
    unified: '1FAC1',
    keywords: ['lungs', 'breathe'],
    sheet: [53, 7],
    shortName: 'lungs'
  },
  {
    name: 'Tooth',
    unified: '1F9B7',
    keywords: [
      'tooth',
      'teeth',
      'dentist'
    ],
    sheet: [43, 36],
    shortName: 'tooth'
  },
  {
    name: 'Bone',
    unified: '1F9B4',
    keywords: ['bone', 'skeleton'],
    sheet: [43, 23],
    shortName: 'bone'
  },
  {
    name: 'Eyes',
    unified: '1F440',
    keywords: [
      'eyes',
      'look',
      'watch',
      'stalk',
      'peek',
      'see'
    ],
    sheet: [12, 27],
    shortName: 'eyes'
  },
  {
    name: 'Eye',
    unified: '1F441-FE0F',
    keywords: [
      'eye',
      'face',
      'look',
      'see',
      'watch',
      'stare'
    ],
    sheet: [12, 29],
    shortName: 'eye'
  },
  {
    name: 'Tongue',
    unified: '1F445',
    keywords: [
      'tongue',
      'mouth',
      'playful'
    ],
    sheet: [12, 43],
    shortName: 'tongue'
  },
  {
    name: 'Mouth',
    unified: '1F444',
    keywords: [
      'mouth',
      'mouth',
      'kiss'
    ],
    sheet: [12, 42],
    shortName: 'lips'
  },
  {
    name: 'Baby',
    unified: '1F476',
    keywords: [
      'baby',
      'child',
      'boy',
      'girl',
      'toddler'
    ],
    sheet: [23, 8],
    skinVariations: [
      {
        unified: '1F476-1F3FB',
        sheet: [23, 9]
      },
      {
        unified: '1F476-1F3FC',
        sheet: [23, 10]
      },
      {
        unified: '1F476-1F3FD',
        sheet: [23, 11]
      },
      {
        unified: '1F476-1F3FE',
        sheet: [23, 12]
      },
      {
        unified: '1F476-1F3FF',
        sheet: [23, 13]
      }
    ],
    shortName: 'baby'
  },
  {
    name: 'Child',
    unified: '1F9D2',
    keywords: [
      'child',
      'gender-neutral',
      'young'
    ],
    sheet: [48, 43],
    skinVariations: [
      {
        unified: '1F9D2-1F3FB',
        sheet: [48, 44]
      },
      {
        unified: '1F9D2-1F3FC',
        sheet: [48, 45]
      },
      {
        unified: '1F9D2-1F3FD',
        sheet: [48, 46]
      },
      {
        unified: '1F9D2-1F3FE',
        sheet: [48, 47]
      },
      {
        unified: '1F9D2-1F3FF',
        sheet: [48, 48]
      }
    ],
    shortName: 'child'
  },
  {
    name: 'Boy',
    unified: '1F466',
    keywords: [
      'boy',
      'man',
      'male',
      'guy',
      'teenager'
    ],
    sheet: [14, 15],
    skinVariations: [
      {
        unified: '1F466-1F3FB',
        sheet: [14, 16]
      },
      {
        unified: '1F466-1F3FC',
        sheet: [14, 17]
      },
      {
        unified: '1F466-1F3FD',
        sheet: [14, 18]
      },
      {
        unified: '1F466-1F3FE',
        sheet: [14, 19]
      },
      {
        unified: '1F466-1F3FF',
        sheet: [14, 20]
      }
    ],
    shortName: 'boy'
  },
  {
    name: 'Girl',
    unified: '1F467',
    keywords: [
      'girl',
      'female',
      'woman',
      'teenager'
    ],
    sheet: [14, 21],
    skinVariations: [
      {
        unified: '1F467-1F3FB',
        sheet: [14, 22]
      },
      {
        unified: '1F467-1F3FC',
        sheet: [14, 23]
      },
      {
        unified: '1F467-1F3FD',
        sheet: [14, 24]
      },
      {
        unified: '1F467-1F3FE',
        sheet: [14, 25]
      },
      {
        unified: '1F467-1F3FF',
        sheet: [14, 26]
      }
    ],
    shortName: 'girl'
  },
  {
    name: 'Adult',
    unified: '1F9D1',
    keywords: [
      'person',
      'gender-neutral',
      'person'
    ],
    sheet: [48, 37],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB',
        sheet: [48, 38]
      },
      {
        unified: '1F9D1-1F3FC',
        sheet: [48, 39]
      },
      {
        unified: '1F9D1-1F3FD',
        sheet: [48, 40]
      },
      {
        unified: '1F9D1-1F3FE',
        sheet: [48, 41]
      },
      {
        unified: '1F9D1-1F3FF',
        sheet: [48, 42]
      }
    ],
    shortName: 'adult'
  },
  {
    name: 'Person with Blond Hair',
    unified: '1F471',
    keywords: [
      'person_blond_hair',
      'hairstyle'
    ],
    sheet: [22, 24],
    skinVariations: [
      {
        unified: '1F471-1F3FB',
        sheet: [22, 25]
      },
      {
        unified: '1F471-1F3FC',
        sheet: [22, 26]
      },
      {
        unified: '1F471-1F3FD',
        sheet: [22, 27]
      },
      {
        unified: '1F471-1F3FE',
        sheet: [22, 28]
      },
      {
        unified: '1F471-1F3FF',
        sheet: [22, 29]
      }
    ],
    shortName: 'person_with_blond_hair',
    obsoletedBy: '1F471-200D-2642-FE0F'
  },
  {
    name: 'Man',
    unified: '1F468',
    keywords: [
      'man',
      'mustache',
      'father',
      'dad',
      'guy',
      'classy',
      'sir',
      'moustache'
    ],
    sheet: [17, 14],
    skinVariations: [
      {
        unified: '1F468-1F3FB',
        sheet: [17, 15]
      },
      {
        unified: '1F468-1F3FC',
        sheet: [17, 16]
      },
      {
        unified: '1F468-1F3FD',
        sheet: [17, 17]
      },
      {
        unified: '1F468-1F3FE',
        sheet: [17, 18]
      },
      {
        unified: '1F468-1F3FF',
        sheet: [17, 19]
      }
    ],
    shortName: 'man'
  },
  {
    name: 'Bearded Person',
    unified: '1F9D4',
    keywords: [
      'man_beard',
      'person',
      'bewhiskered'
    ],
    sheet: [48, 55],
    skinVariations: [
      {
        unified: '1F9D4-1F3FB',
        sheet: [48, 56]
      },
      {
        unified: '1F9D4-1F3FC',
        sheet: [48, 57]
      },
      {
        unified: '1F9D4-1F3FD',
        sheet: [49, 0]
      },
      {
        unified: '1F9D4-1F3FE',
        sheet: [49, 1]
      },
      {
        unified: '1F9D4-1F3FF',
        sheet: [49, 2]
      }
    ],
    shortName: 'bearded_person'
  },
  {
    name: 'Man: Red Hair',
    unified: '1F468-200D-1F9B0',
    keywords: [
      'man_red_hair',
      'hairstyle'
    ],
    sheet: [16, 16],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F9B0',
        sheet: [16, 17]
      },
      {
        unified: '1F468-1F3FC-200D-1F9B0',
        sheet: [16, 18]
      },
      {
        unified: '1F468-1F3FD-200D-1F9B0',
        sheet: [16, 19]
      },
      {
        unified: '1F468-1F3FE-200D-1F9B0',
        sheet: [16, 20]
      },
      {
        unified: '1F468-1F3FF-200D-1F9B0',
        sheet: [16, 21]
      }
    ],
    shortName: 'red_haired_man'
  },
  {
    name: 'Man: Curly Hair',
    unified: '1F468-200D-1F9B1',
    keywords: [
      'man_curly_hair',
      'hairstyle'
    ],
    sheet: [16, 22],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F9B1',
        sheet: [16, 23]
      },
      {
        unified: '1F468-1F3FC-200D-1F9B1',
        sheet: [16, 24]
      },
      {
        unified: '1F468-1F3FD-200D-1F9B1',
        sheet: [16, 25]
      },
      {
        unified: '1F468-1F3FE-200D-1F9B1',
        sheet: [16, 26]
      },
      {
        unified: '1F468-1F3FF-200D-1F9B1',
        sheet: [16, 27]
      }
    ],
    shortName: 'curly_haired_man'
  },
  {
    name: 'Man: White Hair',
    unified: '1F468-200D-1F9B3',
    keywords: [
      'man_white_hair',
      'old',
      'elder'
    ],
    sheet: [16, 34],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F9B3',
        sheet: [16, 35]
      },
      {
        unified: '1F468-1F3FC-200D-1F9B3',
        sheet: [16, 36]
      },
      {
        unified: '1F468-1F3FD-200D-1F9B3',
        sheet: [16, 37]
      },
      {
        unified: '1F468-1F3FE-200D-1F9B3',
        sheet: [16, 38]
      },
      {
        unified: '1F468-1F3FF-200D-1F9B3',
        sheet: [16, 39]
      }
    ],
    shortName: 'white_haired_man'
  },
  {
    name: 'Man: Bald',
    unified: '1F468-200D-1F9B2',
    keywords: ['man_bald', 'hairless'],
    sheet: [16, 28],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F9B2',
        sheet: [16, 29]
      },
      {
        unified: '1F468-1F3FC-200D-1F9B2',
        sheet: [16, 30]
      },
      {
        unified: '1F468-1F3FD-200D-1F9B2',
        sheet: [16, 31]
      },
      {
        unified: '1F468-1F3FE-200D-1F9B2',
        sheet: [16, 32]
      },
      {
        unified: '1F468-1F3FF-200D-1F9B2',
        sheet: [16, 33]
      }
    ],
    shortName: 'bald_man'
  },
  {
    name: 'Woman',
    unified: '1F469',
    keywords: [
      'woman',
      'female',
      'girls',
      'lady'
    ],
    sheet: [20, 4],
    skinVariations: [
      {
        unified: '1F469-1F3FB',
        sheet: [20, 5]
      },
      {
        unified: '1F469-1F3FC',
        sheet: [20, 6]
      },
      {
        unified: '1F469-1F3FD',
        sheet: [20, 7]
      },
      {
        unified: '1F469-1F3FE',
        sheet: [20, 8]
      },
      {
        unified: '1F469-1F3FF',
        sheet: [20, 9]
      }
    ],
    shortName: 'woman'
  },
  {
    name: 'Woman: Red Hair',
    unified: '1F469-200D-1F9B0',
    keywords: [
      'woman_red_hair',
      'hairstyle'
    ],
    sheet: [19, 4],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F9B0',
        sheet: [19, 5]
      },
      {
        unified: '1F469-1F3FC-200D-1F9B0',
        sheet: [19, 6]
      },
      {
        unified: '1F469-1F3FD-200D-1F9B0',
        sheet: [19, 7]
      },
      {
        unified: '1F469-1F3FE-200D-1F9B0',
        sheet: [19, 8]
      },
      {
        unified: '1F469-1F3FF-200D-1F9B0',
        sheet: [19, 9]
      }
    ],
    shortName: 'red_haired_woman'
  },
  {
    name: 'Person: Red Hair',
    unified: '1F9D1-200D-1F9B0',
    keywords: [
      'person_red_hair',
      'hairstyle'
    ],
    sheet: [47, 41],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F9B0',
        sheet: [47, 42]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F9B0',
        sheet: [47, 43]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F9B0',
        sheet: [47, 44]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F9B0',
        sheet: [47, 45]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F9B0',
        sheet: [47, 46]
      }
    ],
    shortName: 'red_haired_person'
  },
  {
    name: 'Woman: Curly Hair',
    unified: '1F469-200D-1F9B1',
    keywords: [
      'woman_curly_hair',
      'hairstyle'
    ],
    sheet: [19, 10],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F9B1',
        sheet: [19, 11]
      },
      {
        unified: '1F469-1F3FC-200D-1F9B1',
        sheet: [19, 12]
      },
      {
        unified: '1F469-1F3FD-200D-1F9B1',
        sheet: [19, 13]
      },
      {
        unified: '1F469-1F3FE-200D-1F9B1',
        sheet: [19, 14]
      },
      {
        unified: '1F469-1F3FF-200D-1F9B1',
        sheet: [19, 15]
      }
    ],
    shortName: 'curly_haired_woman'
  },
  {
    name: 'Person: Curly Hair',
    unified: '1F9D1-200D-1F9B1',
    keywords: [
      'person_curly_hair',
      'hairstyle'
    ],
    sheet: [47, 47],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F9B1',
        sheet: [47, 48]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F9B1',
        sheet: [47, 49]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F9B1',
        sheet: [47, 50]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F9B1',
        sheet: [47, 51]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F9B1',
        sheet: [47, 52]
      }
    ],
    shortName: 'curly_haired_person'
  },
  {
    name: 'Woman: White Hair',
    unified: '1F469-200D-1F9B3',
    keywords: [
      'woman_white_hair',
      'old',
      'elder'
    ],
    sheet: [19, 22],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F9B3',
        sheet: [19, 23]
      },
      {
        unified: '1F469-1F3FC-200D-1F9B3',
        sheet: [19, 24]
      },
      {
        unified: '1F469-1F3FD-200D-1F9B3',
        sheet: [19, 25]
      },
      {
        unified: '1F469-1F3FE-200D-1F9B3',
        sheet: [19, 26]
      },
      {
        unified: '1F469-1F3FF-200D-1F9B3',
        sheet: [19, 27]
      }
    ],
    shortName: 'white_haired_woman'
  },
  {
    name: 'Person: White Hair',
    unified: '1F9D1-200D-1F9B3',
    keywords: [
      'person_white_hair',
      'elder',
      'old'
    ],
    sheet: [48, 1],
    hidden: ['facebook'],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F9B3',
        sheet: [48, 2],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FC-200D-1F9B3',
        sheet: [48, 3],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FD-200D-1F9B3',
        sheet: [48, 4],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FE-200D-1F9B3',
        sheet: [48, 5],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FF-200D-1F9B3',
        sheet: [48, 6],
        hidden: ['facebook']
      }
    ],
    shortName: 'white_haired_person'
  },
  {
    name: 'Woman: Bald',
    unified: '1F469-200D-1F9B2',
    keywords: [
      'woman_bald',
      'hairless'
    ],
    sheet: [19, 16],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F9B2',
        sheet: [19, 17]
      },
      {
        unified: '1F469-1F3FC-200D-1F9B2',
        sheet: [19, 18]
      },
      {
        unified: '1F469-1F3FD-200D-1F9B2',
        sheet: [19, 19]
      },
      {
        unified: '1F469-1F3FE-200D-1F9B2',
        sheet: [19, 20]
      },
      {
        unified: '1F469-1F3FF-200D-1F9B2',
        sheet: [19, 21]
      }
    ],
    shortName: 'bald_woman'
  },
  {
    name: 'Person: Bald',
    unified: '1F9D1-200D-1F9B2',
    keywords: [
      'person_bald',
      'hairless'
    ],
    sheet: [47, 53],
    hidden: ['facebook'],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F9B2',
        sheet: [47, 54],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FC-200D-1F9B2',
        sheet: [47, 55],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FD-200D-1F9B2',
        sheet: [47, 56],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FE-200D-1F9B2',
        sheet: [47, 57],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FF-200D-1F9B2',
        sheet: [48, 0],
        hidden: ['facebook']
      }
    ],
    shortName: 'bald_person'
  },
  {
    name: 'Woman: Blond Hair',
    unified: '1F471-200D-2640-FE0F',
    keywords: [
      'woman_blond_hair',
      'woman',
      'female',
      'girl',
      'blonde',
      'person'
    ],
    sheet: [22, 12],
    skinVariations: [
      {
        unified: '1F471-1F3FB-200D-2640-FE0F',
        sheet: [22, 13]
      },
      {
        unified: '1F471-1F3FC-200D-2640-FE0F',
        sheet: [22, 14]
      },
      {
        unified: '1F471-1F3FD-200D-2640-FE0F',
        sheet: [22, 15]
      },
      {
        unified: '1F471-1F3FE-200D-2640-FE0F',
        sheet: [22, 16]
      },
      {
        unified: '1F471-1F3FF-200D-2640-FE0F',
        sheet: [22, 17]
      }
    ],
    shortName: 'blond-haired-woman'
  },
  {
    name: 'Man: Blond Hair',
    unified: '1F471-200D-2642-FE0F',
    obsoletes: '1F471',
    keywords: [
      'man_blond_hair',
      'man',
      'male',
      'boy',
      'blonde',
      'guy',
      'person'
    ],
    sheet: [22, 18],
    skinVariations: [
      {
        unified: '1F471-1F3FB-200D-2642-FE0F',
        sheet: [22, 19]
      },
      {
        unified: '1F471-1F3FC-200D-2642-FE0F',
        sheet: [22, 20]
      },
      {
        unified: '1F471-1F3FD-200D-2642-FE0F',
        sheet: [22, 21]
      },
      {
        unified: '1F471-1F3FE-200D-2642-FE0F',
        sheet: [22, 22]
      },
      {
        unified: '1F471-1F3FF-200D-2642-FE0F',
        sheet: [22, 23]
      }
    ],
    shortName: 'blond-haired-man'
  },
  {
    name: 'Older Adult',
    unified: '1F9D3',
    keywords: [
      'older_person',
      'human',
      'elder',
      'senior',
      'gender-neutral'
    ],
    sheet: [48, 49],
    skinVariations: [
      {
        unified: '1F9D3-1F3FB',
        sheet: [48, 50]
      },
      {
        unified: '1F9D3-1F3FC',
        sheet: [48, 51]
      },
      {
        unified: '1F9D3-1F3FD',
        sheet: [48, 52]
      },
      {
        unified: '1F9D3-1F3FE',
        sheet: [48, 53]
      },
      {
        unified: '1F9D3-1F3FF',
        sheet: [48, 54]
      }
    ],
    shortName: 'older_adult'
  },
  {
    name: 'Older Man',
    unified: '1F474',
    keywords: [
      'old_man',
      'human',
      'male',
      'men',
      'old',
      'elder',
      'senior'
    ],
    sheet: [22, 54],
    skinVariations: [
      {
        unified: '1F474-1F3FB',
        sheet: [22, 55]
      },
      {
        unified: '1F474-1F3FC',
        sheet: [22, 56]
      },
      {
        unified: '1F474-1F3FD',
        sheet: [22, 57]
      },
      {
        unified: '1F474-1F3FE',
        sheet: [23, 0]
      },
      {
        unified: '1F474-1F3FF',
        sheet: [23, 1]
      }
    ],
    shortName: 'older_man'
  },
  {
    name: 'Older Woman',
    unified: '1F475',
    keywords: [
      'old_woman',
      'human',
      'female',
      'women',
      'lady',
      'old',
      'elder',
      'senior'
    ],
    sheet: [23, 2],
    skinVariations: [
      {
        unified: '1F475-1F3FB',
        sheet: [23, 3]
      },
      {
        unified: '1F475-1F3FC',
        sheet: [23, 4]
      },
      {
        unified: '1F475-1F3FD',
        sheet: [23, 5]
      },
      {
        unified: '1F475-1F3FE',
        sheet: [23, 6]
      },
      {
        unified: '1F475-1F3FF',
        sheet: [23, 7]
      }
    ],
    shortName: 'older_woman'
  },
  {
    name: 'Person Frowning',
    unified: '1F64D',
    keywords: [
      'person_frowning',
      'worried'
    ],
    sheet: [33, 20],
    skinVariations: [
      {
        unified: '1F64D-1F3FB',
        sheet: [33, 21]
      },
      {
        unified: '1F64D-1F3FC',
        sheet: [33, 22]
      },
      {
        unified: '1F64D-1F3FD',
        sheet: [33, 23]
      },
      {
        unified: '1F64D-1F3FE',
        sheet: [33, 24]
      },
      {
        unified: '1F64D-1F3FF',
        sheet: [33, 25]
      }
    ],
    shortName: 'person_frowning',
    obsoletedBy: '1F64D-200D-2640-FE0F'
  },
  {
    name: 'Man Frowning',
    unified: '1F64D-200D-2642-FE0F',
    keywords: [
      'man_frowning',
      'male',
      'boy',
      'man',
      'sad',
      'depressed',
      'discouraged',
      'unhappy'
    ],
    sheet: [33, 14],
    skinVariations: [
      {
        unified: '1F64D-1F3FB-200D-2642-FE0F',
        sheet: [33, 15]
      },
      {
        unified: '1F64D-1F3FC-200D-2642-FE0F',
        sheet: [33, 16]
      },
      {
        unified: '1F64D-1F3FD-200D-2642-FE0F',
        sheet: [33, 17]
      },
      {
        unified: '1F64D-1F3FE-200D-2642-FE0F',
        sheet: [33, 18]
      },
      {
        unified: '1F64D-1F3FF-200D-2642-FE0F',
        sheet: [33, 19]
      }
    ],
    shortName: 'man-frowning'
  },
  {
    name: 'Woman Frowning',
    unified: '1F64D-200D-2640-FE0F',
    obsoletes: '1F64D',
    keywords: [
      'woman_frowning',
      'female',
      'girl',
      'woman',
      'sad',
      'depressed',
      'discouraged',
      'unhappy'
    ],
    sheet: [33, 8],
    skinVariations: [
      {
        unified: '1F64D-1F3FB-200D-2640-FE0F',
        sheet: [33, 9]
      },
      {
        unified: '1F64D-1F3FC-200D-2640-FE0F',
        sheet: [33, 10]
      },
      {
        unified: '1F64D-1F3FD-200D-2640-FE0F',
        sheet: [33, 11]
      },
      {
        unified: '1F64D-1F3FE-200D-2640-FE0F',
        sheet: [33, 12]
      },
      {
        unified: '1F64D-1F3FF-200D-2640-FE0F',
        sheet: [33, 13]
      }
    ],
    shortName: 'woman-frowning'
  },
  {
    name: 'Person with Pouting Face',
    unified: '1F64E',
    keywords: [
      'person_pouting',
      'upset'
    ],
    sheet: [33, 38],
    skinVariations: [
      {
        unified: '1F64E-1F3FB',
        sheet: [33, 39]
      },
      {
        unified: '1F64E-1F3FC',
        sheet: [33, 40]
      },
      {
        unified: '1F64E-1F3FD',
        sheet: [33, 41]
      },
      {
        unified: '1F64E-1F3FE',
        sheet: [33, 42]
      },
      {
        unified: '1F64E-1F3FF',
        sheet: [33, 43]
      }
    ],
    shortName: 'person_with_pouting_face',
    obsoletedBy: '1F64E-200D-2640-FE0F'
  },
  {
    name: 'Man Pouting',
    unified: '1F64E-200D-2642-FE0F',
    keywords: [
      'man_pouting',
      'male',
      'boy',
      'man'
    ],
    sheet: [33, 32],
    skinVariations: [
      {
        unified: '1F64E-1F3FB-200D-2642-FE0F',
        sheet: [33, 33]
      },
      {
        unified: '1F64E-1F3FC-200D-2642-FE0F',
        sheet: [33, 34]
      },
      {
        unified: '1F64E-1F3FD-200D-2642-FE0F',
        sheet: [33, 35]
      },
      {
        unified: '1F64E-1F3FE-200D-2642-FE0F',
        sheet: [33, 36]
      },
      {
        unified: '1F64E-1F3FF-200D-2642-FE0F',
        sheet: [33, 37]
      }
    ],
    shortName: 'man-pouting'
  },
  {
    name: 'Woman Pouting',
    unified: '1F64E-200D-2640-FE0F',
    obsoletes: '1F64E',
    keywords: [
      'woman_pouting',
      'female',
      'girl',
      'woman'
    ],
    sheet: [33, 26],
    skinVariations: [
      {
        unified: '1F64E-1F3FB-200D-2640-FE0F',
        sheet: [33, 27]
      },
      {
        unified: '1F64E-1F3FC-200D-2640-FE0F',
        sheet: [33, 28]
      },
      {
        unified: '1F64E-1F3FD-200D-2640-FE0F',
        sheet: [33, 29]
      },
      {
        unified: '1F64E-1F3FE-200D-2640-FE0F',
        sheet: [33, 30]
      },
      {
        unified: '1F64E-1F3FF-200D-2640-FE0F',
        sheet: [33, 31]
      }
    ],
    shortName: 'woman-pouting'
  },
  {
    name: 'Face with No Good Gesture',
    unified: '1F645',
    keywords: [
      'person_gesturing_no',
      'decline'
    ],
    sheet: [31, 55],
    skinVariations: [
      {
        unified: '1F645-1F3FB',
        sheet: [31, 56]
      },
      {
        unified: '1F645-1F3FC',
        sheet: [31, 57]
      },
      {
        unified: '1F645-1F3FD',
        sheet: [32, 0]
      },
      {
        unified: '1F645-1F3FE',
        sheet: [32, 1]
      },
      {
        unified: '1F645-1F3FF',
        sheet: [32, 2]
      }
    ],
    shortName: 'no_good',
    obsoletedBy: '1F645-200D-2640-FE0F'
  },
  {
    name: 'Man Gesturing No',
    unified: '1F645-200D-2642-FE0F',
    keywords: [
      'man_gesturing_no',
      'male',
      'boy',
      'man',
      'nope'
    ],
    sheet: [31, 49],
    skinVariations: [
      {
        unified: '1F645-1F3FB-200D-2642-FE0F',
        sheet: [31, 50]
      },
      {
        unified: '1F645-1F3FC-200D-2642-FE0F',
        sheet: [31, 51]
      },
      {
        unified: '1F645-1F3FD-200D-2642-FE0F',
        sheet: [31, 52]
      },
      {
        unified: '1F645-1F3FE-200D-2642-FE0F',
        sheet: [31, 53]
      },
      {
        unified: '1F645-1F3FF-200D-2642-FE0F',
        sheet: [31, 54]
      }
    ],
    shortName: 'man-gesturing-no'
  },
  {
    name: 'Woman Gesturing No',
    unified: '1F645-200D-2640-FE0F',
    obsoletes: '1F645',
    keywords: [
      'woman_gesturing_no',
      'female',
      'girl',
      'woman',
      'nope'
    ],
    sheet: [31, 43],
    skinVariations: [
      {
        unified: '1F645-1F3FB-200D-2640-FE0F',
        sheet: [31, 44]
      },
      {
        unified: '1F645-1F3FC-200D-2640-FE0F',
        sheet: [31, 45]
      },
      {
        unified: '1F645-1F3FD-200D-2640-FE0F',
        sheet: [31, 46]
      },
      {
        unified: '1F645-1F3FE-200D-2640-FE0F',
        sheet: [31, 47]
      },
      {
        unified: '1F645-1F3FF-200D-2640-FE0F',
        sheet: [31, 48]
      }
    ],
    shortName: 'woman-gesturing-no'
  },
  {
    name: 'Face with Ok Gesture',
    unified: '1F646',
    keywords: [
      'person_gesturing_ok',
      'agree'
    ],
    sheet: [32, 15],
    skinVariations: [
      {
        unified: '1F646-1F3FB',
        sheet: [32, 16]
      },
      {
        unified: '1F646-1F3FC',
        sheet: [32, 17]
      },
      {
        unified: '1F646-1F3FD',
        sheet: [32, 18]
      },
      {
        unified: '1F646-1F3FE',
        sheet: [32, 19]
      },
      {
        unified: '1F646-1F3FF',
        sheet: [32, 20]
      }
    ],
    shortName: 'ok_woman',
    obsoletedBy: '1F646-200D-2640-FE0F'
  },
  {
    name: 'Man Gesturing Ok',
    unified: '1F646-200D-2642-FE0F',
    keywords: [
      'man_gesturing_ok',
      'men',
      'boy',
      'male',
      'blue',
      'human',
      'man'
    ],
    sheet: [32, 9],
    skinVariations: [
      {
        unified: '1F646-1F3FB-200D-2642-FE0F',
        sheet: [32, 10]
      },
      {
        unified: '1F646-1F3FC-200D-2642-FE0F',
        sheet: [32, 11]
      },
      {
        unified: '1F646-1F3FD-200D-2642-FE0F',
        sheet: [32, 12]
      },
      {
        unified: '1F646-1F3FE-200D-2642-FE0F',
        sheet: [32, 13]
      },
      {
        unified: '1F646-1F3FF-200D-2642-FE0F',
        sheet: [32, 14]
      }
    ],
    shortName: 'man-gesturing-ok'
  },
  {
    name: 'Woman Gesturing Ok',
    unified: '1F646-200D-2640-FE0F',
    obsoletes: '1F646',
    keywords: [
      'woman_gesturing_ok',
      'women',
      'girl',
      'female',
      'pink',
      'human',
      'woman'
    ],
    sheet: [32, 3],
    skinVariations: [
      {
        unified: '1F646-1F3FB-200D-2640-FE0F',
        sheet: [32, 4]
      },
      {
        unified: '1F646-1F3FC-200D-2640-FE0F',
        sheet: [32, 5]
      },
      {
        unified: '1F646-1F3FD-200D-2640-FE0F',
        sheet: [32, 6]
      },
      {
        unified: '1F646-1F3FE-200D-2640-FE0F',
        sheet: [32, 7]
      },
      {
        unified: '1F646-1F3FF-200D-2640-FE0F',
        sheet: [32, 8]
      }
    ],
    shortName: 'woman-gesturing-ok'
  },
  {
    name: 'Information Desk Person',
    unified: '1F481',
    keywords: [
      'person_tipping_hand',
      'information'
    ],
    sheet: [24, 5],
    skinVariations: [
      {
        unified: '1F481-1F3FB',
        sheet: [24, 6]
      },
      {
        unified: '1F481-1F3FC',
        sheet: [24, 7]
      },
      {
        unified: '1F481-1F3FD',
        sheet: [24, 8]
      },
      {
        unified: '1F481-1F3FE',
        sheet: [24, 9]
      },
      {
        unified: '1F481-1F3FF',
        sheet: [24, 10]
      }
    ],
    shortName: 'information_desk_person',
    obsoletedBy: '1F481-200D-2640-FE0F'
  },
  {
    name: 'Man Tipping Hand',
    unified: '1F481-200D-2642-FE0F',
    keywords: [
      'man_tipping_hand',
      'male',
      'boy',
      'man',
      'human',
      'information'
    ],
    sheet: [23, 57],
    skinVariations: [
      {
        unified: '1F481-1F3FB-200D-2642-FE0F',
        sheet: [24, 0]
      },
      {
        unified: '1F481-1F3FC-200D-2642-FE0F',
        sheet: [24, 1]
      },
      {
        unified: '1F481-1F3FD-200D-2642-FE0F',
        sheet: [24, 2]
      },
      {
        unified: '1F481-1F3FE-200D-2642-FE0F',
        sheet: [24, 3]
      },
      {
        unified: '1F481-1F3FF-200D-2642-FE0F',
        sheet: [24, 4]
      }
    ],
    shortName: 'man-tipping-hand'
  },
  {
    name: 'Woman Tipping Hand',
    unified: '1F481-200D-2640-FE0F',
    obsoletes: '1F481',
    keywords: [
      'woman_tipping_hand',
      'female',
      'girl',
      'woman',
      'human',
      'information'
    ],
    sheet: [23, 51],
    skinVariations: [
      {
        unified: '1F481-1F3FB-200D-2640-FE0F',
        sheet: [23, 52]
      },
      {
        unified: '1F481-1F3FC-200D-2640-FE0F',
        sheet: [23, 53]
      },
      {
        unified: '1F481-1F3FD-200D-2640-FE0F',
        sheet: [23, 54]
      },
      {
        unified: '1F481-1F3FE-200D-2640-FE0F',
        sheet: [23, 55]
      },
      {
        unified: '1F481-1F3FF-200D-2640-FE0F',
        sheet: [23, 56]
      }
    ],
    shortName: 'woman-tipping-hand'
  },
  {
    name: 'Happy Person Raising One Hand',
    unified: '1F64B',
    keywords: [
      'person_raising_hand',
      'question'
    ],
    sheet: [32, 54],
    skinVariations: [
      {
        unified: '1F64B-1F3FB',
        sheet: [32, 55]
      },
      {
        unified: '1F64B-1F3FC',
        sheet: [32, 56]
      },
      {
        unified: '1F64B-1F3FD',
        sheet: [32, 57]
      },
      {
        unified: '1F64B-1F3FE',
        sheet: [33, 0]
      },
      {
        unified: '1F64B-1F3FF',
        sheet: [33, 1]
      }
    ],
    shortName: 'raising_hand',
    obsoletedBy: '1F64B-200D-2640-FE0F'
  },
  {
    name: 'Man Raising Hand',
    unified: '1F64B-200D-2642-FE0F',
    keywords: [
      'man_raising_hand',
      'male',
      'boy',
      'man'
    ],
    sheet: [32, 48],
    skinVariations: [
      {
        unified: '1F64B-1F3FB-200D-2642-FE0F',
        sheet: [32, 49]
      },
      {
        unified: '1F64B-1F3FC-200D-2642-FE0F',
        sheet: [32, 50]
      },
      {
        unified: '1F64B-1F3FD-200D-2642-FE0F',
        sheet: [32, 51]
      },
      {
        unified: '1F64B-1F3FE-200D-2642-FE0F',
        sheet: [32, 52]
      },
      {
        unified: '1F64B-1F3FF-200D-2642-FE0F',
        sheet: [32, 53]
      }
    ],
    shortName: 'man-raising-hand'
  },
  {
    name: 'Woman Raising Hand',
    unified: '1F64B-200D-2640-FE0F',
    obsoletes: '1F64B',
    keywords: [
      'woman_raising_hand',
      'female',
      'girl',
      'woman'
    ],
    sheet: [32, 42],
    skinVariations: [
      {
        unified: '1F64B-1F3FB-200D-2640-FE0F',
        sheet: [32, 43]
      },
      {
        unified: '1F64B-1F3FC-200D-2640-FE0F',
        sheet: [32, 44]
      },
      {
        unified: '1F64B-1F3FD-200D-2640-FE0F',
        sheet: [32, 45]
      },
      {
        unified: '1F64B-1F3FE-200D-2640-FE0F',
        sheet: [32, 46]
      },
      {
        unified: '1F64B-1F3FF-200D-2640-FE0F',
        sheet: [32, 47]
      }
    ],
    shortName: 'woman-raising-hand'
  },
  {
    name: 'Deaf Person',
    unified: '1F9CF',
    keywords: [
      'deaf_person',
      'accessibility'
    ],
    sheet: [45, 28],
    skinVariations: [
      {
        unified: '1F9CF-1F3FB',
        sheet: [45, 29]
      },
      {
        unified: '1F9CF-1F3FC',
        sheet: [45, 30]
      },
      {
        unified: '1F9CF-1F3FD',
        sheet: [45, 31]
      },
      {
        unified: '1F9CF-1F3FE',
        sheet: [45, 32]
      },
      {
        unified: '1F9CF-1F3FF',
        sheet: [45, 33]
      }
    ],
    shortName: 'deaf_person'
  },
  {
    name: 'Deaf Man',
    unified: '1F9CF-200D-2642-FE0F',
    keywords: [
      'deaf_man',
      'accessibility'
    ],
    sheet: [45, 22],
    skinVariations: [
      {
        unified: '1F9CF-1F3FB-200D-2642-FE0F',
        sheet: [45, 23]
      },
      {
        unified: '1F9CF-1F3FC-200D-2642-FE0F',
        sheet: [45, 24]
      },
      {
        unified: '1F9CF-1F3FD-200D-2642-FE0F',
        sheet: [45, 25]
      },
      {
        unified: '1F9CF-1F3FE-200D-2642-FE0F',
        sheet: [45, 26]
      },
      {
        unified: '1F9CF-1F3FF-200D-2642-FE0F',
        sheet: [45, 27]
      }
    ],
    shortName: 'deaf_man'
  },
  {
    name: 'Deaf Woman',
    unified: '1F9CF-200D-2640-FE0F',
    keywords: [
      'deaf_woman',
      'accessibility'
    ],
    sheet: [45, 16],
    skinVariations: [
      {
        unified: '1F9CF-1F3FB-200D-2640-FE0F',
        sheet: [45, 17]
      },
      {
        unified: '1F9CF-1F3FC-200D-2640-FE0F',
        sheet: [45, 18]
      },
      {
        unified: '1F9CF-1F3FD-200D-2640-FE0F',
        sheet: [45, 19]
      },
      {
        unified: '1F9CF-1F3FE-200D-2640-FE0F',
        sheet: [45, 20]
      },
      {
        unified: '1F9CF-1F3FF-200D-2640-FE0F',
        sheet: [45, 21]
      }
    ],
    shortName: 'deaf_woman'
  },
  {
    name: 'Person Bowing Deeply',
    unified: '1F647',
    keywords: [
      'person_bowing',
      'respectiful'
    ],
    sheet: [32, 33],
    skinVariations: [
      {
        unified: '1F647-1F3FB',
        sheet: [32, 34]
      },
      {
        unified: '1F647-1F3FC',
        sheet: [32, 35]
      },
      {
        unified: '1F647-1F3FD',
        sheet: [32, 36]
      },
      {
        unified: '1F647-1F3FE',
        sheet: [32, 37]
      },
      {
        unified: '1F647-1F3FF',
        sheet: [32, 38]
      }
    ],
    shortName: 'bow',
    obsoletedBy: '1F647-200D-2642-FE0F'
  },
  {
    name: 'Man Bowing',
    unified: '1F647-200D-2642-FE0F',
    obsoletes: '1F647',
    keywords: [
      'man_bowing',
      'man',
      'male',
      'boy'
    ],
    sheet: [32, 27],
    skinVariations: [
      {
        unified: '1F647-1F3FB-200D-2642-FE0F',
        sheet: [32, 28]
      },
      {
        unified: '1F647-1F3FC-200D-2642-FE0F',
        sheet: [32, 29]
      },
      {
        unified: '1F647-1F3FD-200D-2642-FE0F',
        sheet: [32, 30]
      },
      {
        unified: '1F647-1F3FE-200D-2642-FE0F',
        sheet: [32, 31]
      },
      {
        unified: '1F647-1F3FF-200D-2642-FE0F',
        sheet: [32, 32]
      }
    ],
    shortName: 'man-bowing'
  },
  {
    name: 'Woman Bowing',
    unified: '1F647-200D-2640-FE0F',
    keywords: [
      'woman_bowing',
      'woman',
      'female',
      'girl'
    ],
    sheet: [32, 21],
    skinVariations: [
      {
        unified: '1F647-1F3FB-200D-2640-FE0F',
        sheet: [32, 22]
      },
      {
        unified: '1F647-1F3FC-200D-2640-FE0F',
        sheet: [32, 23]
      },
      {
        unified: '1F647-1F3FD-200D-2640-FE0F',
        sheet: [32, 24]
      },
      {
        unified: '1F647-1F3FE-200D-2640-FE0F',
        sheet: [32, 25]
      },
      {
        unified: '1F647-1F3FF-200D-2640-FE0F',
        sheet: [32, 26]
      }
    ],
    shortName: 'woman-bowing'
  },
  {
    name: 'Face Palm',
    unified: '1F926',
    keywords: [
      'person_facepalming',
      'disappointed'
    ],
    sheet: [38, 34],
    skinVariations: [
      {
        unified: '1F926-1F3FB',
        sheet: [38, 35]
      },
      {
        unified: '1F926-1F3FC',
        sheet: [38, 36]
      },
      {
        unified: '1F926-1F3FD',
        sheet: [38, 37]
      },
      {
        unified: '1F926-1F3FE',
        sheet: [38, 38]
      },
      {
        unified: '1F926-1F3FF',
        sheet: [38, 39]
      }
    ],
    shortName: 'face_palm'
  },
  {
    name: 'Man Facepalming',
    unified: '1F926-200D-2642-FE0F',
    keywords: [
      'man_facepalming',
      'man',
      'male',
      'boy',
      'disbelief'
    ],
    sheet: [38, 28],
    skinVariations: [
      {
        unified: '1F926-1F3FB-200D-2642-FE0F',
        sheet: [38, 29]
      },
      {
        unified: '1F926-1F3FC-200D-2642-FE0F',
        sheet: [38, 30]
      },
      {
        unified: '1F926-1F3FD-200D-2642-FE0F',
        sheet: [38, 31]
      },
      {
        unified: '1F926-1F3FE-200D-2642-FE0F',
        sheet: [38, 32]
      },
      {
        unified: '1F926-1F3FF-200D-2642-FE0F',
        sheet: [38, 33]
      }
    ],
    shortName: 'man-facepalming'
  },
  {
    name: 'Woman Facepalming',
    unified: '1F926-200D-2640-FE0F',
    keywords: [
      'woman_facepalming',
      'woman',
      'female',
      'girl',
      'disbelief'
    ],
    sheet: [38, 22],
    skinVariations: [
      {
        unified: '1F926-1F3FB-200D-2640-FE0F',
        sheet: [38, 23]
      },
      {
        unified: '1F926-1F3FC-200D-2640-FE0F',
        sheet: [38, 24]
      },
      {
        unified: '1F926-1F3FD-200D-2640-FE0F',
        sheet: [38, 25]
      },
      {
        unified: '1F926-1F3FE-200D-2640-FE0F',
        sheet: [38, 26]
      },
      {
        unified: '1F926-1F3FF-200D-2640-FE0F',
        sheet: [38, 27]
      }
    ],
    shortName: 'woman-facepalming'
  },
  {
    name: 'Shrug',
    unified: '1F937',
    keywords: [
      'person_shrugging',
      'regardless'
    ],
    sheet: [39, 57],
    skinVariations: [
      {
        unified: '1F937-1F3FB',
        sheet: [40, 0]
      },
      {
        unified: '1F937-1F3FC',
        sheet: [40, 1]
      },
      {
        unified: '1F937-1F3FD',
        sheet: [40, 2]
      },
      {
        unified: '1F937-1F3FE',
        sheet: [40, 3]
      },
      {
        unified: '1F937-1F3FF',
        sheet: [40, 4]
      }
    ],
    shortName: 'shrug'
  },
  {
    name: 'Man Shrugging',
    unified: '1F937-200D-2642-FE0F',
    keywords: [
      'man_shrugging',
      'man',
      'male',
      'boy',
      'confused',
      'indifferent',
      'doubt'
    ],
    sheet: [39, 51],
    skinVariations: [
      {
        unified: '1F937-1F3FB-200D-2642-FE0F',
        sheet: [39, 52]
      },
      {
        unified: '1F937-1F3FC-200D-2642-FE0F',
        sheet: [39, 53]
      },
      {
        unified: '1F937-1F3FD-200D-2642-FE0F',
        sheet: [39, 54]
      },
      {
        unified: '1F937-1F3FE-200D-2642-FE0F',
        sheet: [39, 55]
      },
      {
        unified: '1F937-1F3FF-200D-2642-FE0F',
        sheet: [39, 56]
      }
    ],
    shortName: 'man-shrugging'
  },
  {
    name: 'Woman Shrugging',
    unified: '1F937-200D-2640-FE0F',
    keywords: [
      'woman_shrugging',
      'woman',
      'female',
      'girl',
      'confused',
      'indifferent',
      'doubt'
    ],
    sheet: [39, 45],
    skinVariations: [
      {
        unified: '1F937-1F3FB-200D-2640-FE0F',
        sheet: [39, 46]
      },
      {
        unified: '1F937-1F3FC-200D-2640-FE0F',
        sheet: [39, 47]
      },
      {
        unified: '1F937-1F3FD-200D-2640-FE0F',
        sheet: [39, 48]
      },
      {
        unified: '1F937-1F3FE-200D-2640-FE0F',
        sheet: [39, 49]
      },
      {
        unified: '1F937-1F3FF-200D-2640-FE0F',
        sheet: [39, 50]
      }
    ],
    shortName: 'woman-shrugging'
  },
  {
    name: 'Health Worker',
    unified: '1F9D1-200D-2695-FE0F',
    keywords: [
      'health_worker',
      'hospital'
    ],
    sheet: [48, 19],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-2695-FE0F',
        sheet: [48, 20]
      },
      {
        unified: '1F9D1-1F3FC-200D-2695-FE0F',
        sheet: [48, 21]
      },
      {
        unified: '1F9D1-1F3FD-200D-2695-FE0F',
        sheet: [48, 22]
      },
      {
        unified: '1F9D1-1F3FE-200D-2695-FE0F',
        sheet: [48, 23]
      },
      {
        unified: '1F9D1-1F3FF-200D-2695-FE0F',
        sheet: [48, 24]
      }
    ],
    shortName: 'health_worker'
  },
  {
    name: 'Man Health Worker',
    unified: '1F468-200D-2695-FE0F',
    keywords: [
      'man_health_worker',
      'doctor',
      'nurse',
      'therapist',
      'healthcare',
      'man',
      'human'
    ],
    sheet: [16, 52],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-2695-FE0F',
        sheet: [16, 53]
      },
      {
        unified: '1F468-1F3FC-200D-2695-FE0F',
        sheet: [16, 54]
      },
      {
        unified: '1F468-1F3FD-200D-2695-FE0F',
        sheet: [16, 55]
      },
      {
        unified: '1F468-1F3FE-200D-2695-FE0F',
        sheet: [16, 56]
      },
      {
        unified: '1F468-1F3FF-200D-2695-FE0F',
        sheet: [16, 57]
      }
    ],
    shortName: 'male-doctor'
  },
  {
    name: 'Woman Health Worker',
    unified: '1F469-200D-2695-FE0F',
    keywords: [
      'woman_health_worker',
      'doctor',
      'nurse',
      'therapist',
      'healthcare',
      'woman',
      'human'
    ],
    sheet: [19, 40],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-2695-FE0F',
        sheet: [19, 41]
      },
      {
        unified: '1F469-1F3FC-200D-2695-FE0F',
        sheet: [19, 42]
      },
      {
        unified: '1F469-1F3FD-200D-2695-FE0F',
        sheet: [19, 43]
      },
      {
        unified: '1F469-1F3FE-200D-2695-FE0F',
        sheet: [19, 44]
      },
      {
        unified: '1F469-1F3FF-200D-2695-FE0F',
        sheet: [19, 45]
      }
    ],
    shortName: 'female-doctor'
  },
  {
    name: 'Student',
    unified: '1F9D1-200D-1F393',
    keywords: ['student', 'learn'],
    sheet: [46, 1],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F393',
        sheet: [46, 2]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F393',
        sheet: [46, 3]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F393',
        sheet: [46, 4]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F393',
        sheet: [46, 5]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F393',
        sheet: [46, 6]
      }
    ],
    shortName: 'student'
  },
  {
    name: 'Man Student',
    unified: '1F468-200D-1F393',
    keywords: [
      'man_student',
      'graduate',
      'man',
      'human'
    ],
    sheet: [14, 45],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F393',
        sheet: [14, 46]
      },
      {
        unified: '1F468-1F3FC-200D-1F393',
        sheet: [14, 47]
      },
      {
        unified: '1F468-1F3FD-200D-1F393',
        sheet: [14, 48]
      },
      {
        unified: '1F468-1F3FE-200D-1F393',
        sheet: [14, 49]
      },
      {
        unified: '1F468-1F3FF-200D-1F393',
        sheet: [14, 50]
      }
    ],
    shortName: 'male-student'
  },
  {
    name: 'Woman Student',
    unified: '1F469-200D-1F393',
    keywords: [
      'woman_student',
      'graduate',
      'woman',
      'human'
    ],
    sheet: [17, 38],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F393',
        sheet: [17, 39]
      },
      {
        unified: '1F469-1F3FC-200D-1F393',
        sheet: [17, 40]
      },
      {
        unified: '1F469-1F3FD-200D-1F393',
        sheet: [17, 41]
      },
      {
        unified: '1F469-1F3FE-200D-1F393',
        sheet: [17, 42]
      },
      {
        unified: '1F469-1F3FF-200D-1F393',
        sheet: [17, 43]
      }
    ],
    shortName: 'female-student'
  },
  {
    name: 'Teacher',
    unified: '1F9D1-200D-1F3EB',
    keywords: ['teacher', 'professor'],
    sheet: [46, 19],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F3EB',
        sheet: [46, 20]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F3EB',
        sheet: [46, 21]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F3EB',
        sheet: [46, 22]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F3EB',
        sheet: [46, 23]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F3EB',
        sheet: [46, 24]
      }
    ],
    shortName: 'teacher'
  },
  {
    name: 'Man Teacher',
    unified: '1F468-200D-1F3EB',
    keywords: [
      'man_teacher',
      'instructor',
      'professor',
      'man',
      'human'
    ],
    sheet: [15, 5],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F3EB',
        sheet: [15, 6]
      },
      {
        unified: '1F468-1F3FC-200D-1F3EB',
        sheet: [15, 7]
      },
      {
        unified: '1F468-1F3FD-200D-1F3EB',
        sheet: [15, 8]
      },
      {
        unified: '1F468-1F3FE-200D-1F3EB',
        sheet: [15, 9]
      },
      {
        unified: '1F468-1F3FF-200D-1F3EB',
        sheet: [15, 10]
      }
    ],
    shortName: 'male-teacher'
  },
  {
    name: 'Woman Teacher',
    unified: '1F469-200D-1F3EB',
    keywords: [
      'woman_teacher',
      'instructor',
      'professor',
      'woman',
      'human'
    ],
    sheet: [17, 56],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F3EB',
        sheet: [17, 57]
      },
      {
        unified: '1F469-1F3FC-200D-1F3EB',
        sheet: [18, 0]
      },
      {
        unified: '1F469-1F3FD-200D-1F3EB',
        sheet: [18, 1]
      },
      {
        unified: '1F469-1F3FE-200D-1F3EB',
        sheet: [18, 2]
      },
      {
        unified: '1F469-1F3FF-200D-1F3EB',
        sheet: [18, 3]
      }
    ],
    shortName: 'female-teacher'
  },
  {
    name: 'Judge',
    unified: '1F9D1-200D-2696-FE0F',
    keywords: ['judge', 'law'],
    sheet: [48, 25],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-2696-FE0F',
        sheet: [48, 26]
      },
      {
        unified: '1F9D1-1F3FC-200D-2696-FE0F',
        sheet: [48, 27]
      },
      {
        unified: '1F9D1-1F3FD-200D-2696-FE0F',
        sheet: [48, 28]
      },
      {
        unified: '1F9D1-1F3FE-200D-2696-FE0F',
        sheet: [48, 29]
      },
      {
        unified: '1F9D1-1F3FF-200D-2696-FE0F',
        sheet: [48, 30]
      }
    ],
    shortName: 'judge'
  },
  {
    name: 'Man Judge',
    unified: '1F468-200D-2696-FE0F',
    keywords: [
      'man_judge',
      'justice',
      'court',
      'man',
      'human'
    ],
    sheet: [17, 0],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-2696-FE0F',
        sheet: [17, 1]
      },
      {
        unified: '1F468-1F3FC-200D-2696-FE0F',
        sheet: [17, 2]
      },
      {
        unified: '1F468-1F3FD-200D-2696-FE0F',
        sheet: [17, 3]
      },
      {
        unified: '1F468-1F3FE-200D-2696-FE0F',
        sheet: [17, 4]
      },
      {
        unified: '1F468-1F3FF-200D-2696-FE0F',
        sheet: [17, 5]
      }
    ],
    shortName: 'male-judge'
  },
  {
    name: 'Woman Judge',
    unified: '1F469-200D-2696-FE0F',
    keywords: [
      'woman_judge',
      'justice',
      'court',
      'woman',
      'human'
    ],
    sheet: [19, 46],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-2696-FE0F',
        sheet: [19, 47]
      },
      {
        unified: '1F469-1F3FC-200D-2696-FE0F',
        sheet: [19, 48]
      },
      {
        unified: '1F469-1F3FD-200D-2696-FE0F',
        sheet: [19, 49]
      },
      {
        unified: '1F469-1F3FE-200D-2696-FE0F',
        sheet: [19, 50]
      },
      {
        unified: '1F469-1F3FF-200D-2696-FE0F',
        sheet: [19, 51]
      }
    ],
    shortName: 'female-judge'
  },
  {
    name: 'Farmer',
    unified: '1F9D1-200D-1F33E',
    keywords: ['farmer', 'crops'],
    sheet: [45, 35],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F33E',
        sheet: [45, 36]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F33E',
        sheet: [45, 37]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F33E',
        sheet: [45, 38]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F33E',
        sheet: [45, 39]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F33E',
        sheet: [45, 40]
      }
    ],
    shortName: 'farmer'
  },
  {
    name: 'Man Farmer',
    unified: '1F468-200D-1F33E',
    keywords: [
      'man_farmer',
      'rancher',
      'gardener',
      'man',
      'human'
    ],
    sheet: [14, 27],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F33E',
        sheet: [14, 28]
      },
      {
        unified: '1F468-1F3FC-200D-1F33E',
        sheet: [14, 29]
      },
      {
        unified: '1F468-1F3FD-200D-1F33E',
        sheet: [14, 30]
      },
      {
        unified: '1F468-1F3FE-200D-1F33E',
        sheet: [14, 31]
      },
      {
        unified: '1F468-1F3FF-200D-1F33E',
        sheet: [14, 32]
      }
    ],
    shortName: 'male-farmer'
  },
  {
    name: 'Woman Farmer',
    unified: '1F469-200D-1F33E',
    keywords: [
      'woman_farmer',
      'rancher',
      'gardener',
      'woman',
      'human'
    ],
    sheet: [17, 20],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F33E',
        sheet: [17, 21]
      },
      {
        unified: '1F469-1F3FC-200D-1F33E',
        sheet: [17, 22]
      },
      {
        unified: '1F469-1F3FD-200D-1F33E',
        sheet: [17, 23]
      },
      {
        unified: '1F469-1F3FE-200D-1F33E',
        sheet: [17, 24]
      },
      {
        unified: '1F469-1F3FF-200D-1F33E',
        sheet: [17, 25]
      }
    ],
    shortName: 'female-farmer'
  },
  {
    name: 'Cook',
    unified: '1F9D1-200D-1F373',
    keywords: [
      'cook',
      'food',
      'kitchen',
      'culinary'
    ],
    sheet: [45, 41],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F373',
        sheet: [45, 42]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F373',
        sheet: [45, 43]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F373',
        sheet: [45, 44]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F373',
        sheet: [45, 45]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F373',
        sheet: [45, 46]
      }
    ],
    shortName: 'cook'
  },
  {
    name: 'Man Cook',
    unified: '1F468-200D-1F373',
    keywords: [
      'man_cook',
      'chef',
      'man',
      'human'
    ],
    sheet: [14, 33],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F373',
        sheet: [14, 34]
      },
      {
        unified: '1F468-1F3FC-200D-1F373',
        sheet: [14, 35]
      },
      {
        unified: '1F468-1F3FD-200D-1F373',
        sheet: [14, 36]
      },
      {
        unified: '1F468-1F3FE-200D-1F373',
        sheet: [14, 37]
      },
      {
        unified: '1F468-1F3FF-200D-1F373',
        sheet: [14, 38]
      }
    ],
    shortName: 'male-cook'
  },
  {
    name: 'Woman Cook',
    unified: '1F469-200D-1F373',
    keywords: [
      'woman_cook',
      'chef',
      'woman',
      'human'
    ],
    sheet: [17, 26],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F373',
        sheet: [17, 27]
      },
      {
        unified: '1F469-1F3FC-200D-1F373',
        sheet: [17, 28]
      },
      {
        unified: '1F469-1F3FD-200D-1F373',
        sheet: [17, 29]
      },
      {
        unified: '1F469-1F3FE-200D-1F373',
        sheet: [17, 30]
      },
      {
        unified: '1F469-1F3FF-200D-1F373',
        sheet: [17, 31]
      }
    ],
    shortName: 'female-cook'
  },
  {
    name: 'Mechanic',
    unified: '1F9D1-200D-1F527',
    keywords: [
      'mechanic',
      'worker',
      'technician'
    ],
    sheet: [46, 43],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F527',
        sheet: [46, 44]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F527',
        sheet: [46, 45]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F527',
        sheet: [46, 46]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F527',
        sheet: [46, 47]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F527',
        sheet: [46, 48]
      }
    ],
    shortName: 'mechanic'
  },
  {
    name: 'Man Mechanic',
    unified: '1F468-200D-1F527',
    keywords: [
      'man_mechanic',
      'plumber',
      'man',
      'human',
      'wrench'
    ],
    sheet: [15, 44],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F527',
        sheet: [15, 45]
      },
      {
        unified: '1F468-1F3FC-200D-1F527',
        sheet: [15, 46]
      },
      {
        unified: '1F468-1F3FD-200D-1F527',
        sheet: [15, 47]
      },
      {
        unified: '1F468-1F3FE-200D-1F527',
        sheet: [15, 48]
      },
      {
        unified: '1F468-1F3FF-200D-1F527',
        sheet: [15, 49]
      }
    ],
    shortName: 'male-mechanic'
  },
  {
    name: 'Woman Mechanic',
    unified: '1F469-200D-1F527',
    keywords: [
      'woman_mechanic',
      'plumber',
      'woman',
      'human',
      'wrench'
    ],
    sheet: [18, 32],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F527',
        sheet: [18, 33]
      },
      {
        unified: '1F469-1F3FC-200D-1F527',
        sheet: [18, 34]
      },
      {
        unified: '1F469-1F3FD-200D-1F527',
        sheet: [18, 35]
      },
      {
        unified: '1F469-1F3FE-200D-1F527',
        sheet: [18, 36]
      },
      {
        unified: '1F469-1F3FF-200D-1F527',
        sheet: [18, 37]
      }
    ],
    shortName: 'female-mechanic'
  },
  {
    name: 'Factory Worker',
    unified: '1F9D1-200D-1F3ED',
    keywords: [
      'factory_worker',
      'labor'
    ],
    sheet: [46, 25],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F3ED',
        sheet: [46, 26]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F3ED',
        sheet: [46, 27]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F3ED',
        sheet: [46, 28]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F3ED',
        sheet: [46, 29]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F3ED',
        sheet: [46, 30]
      }
    ],
    shortName: 'factory_worker'
  },
  {
    name: 'Man Factory Worker',
    unified: '1F468-200D-1F3ED',
    keywords: [
      'man_factory_worker',
      'assembly',
      'industrial',
      'man',
      'human'
    ],
    sheet: [15, 11],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F3ED',
        sheet: [15, 12]
      },
      {
        unified: '1F468-1F3FC-200D-1F3ED',
        sheet: [15, 13]
      },
      {
        unified: '1F468-1F3FD-200D-1F3ED',
        sheet: [15, 14]
      },
      {
        unified: '1F468-1F3FE-200D-1F3ED',
        sheet: [15, 15]
      },
      {
        unified: '1F468-1F3FF-200D-1F3ED',
        sheet: [15, 16]
      }
    ],
    shortName: 'male-factory-worker'
  },
  {
    name: 'Woman Factory Worker',
    unified: '1F469-200D-1F3ED',
    keywords: [
      'woman_factory_worker',
      'assembly',
      'industrial',
      'woman',
      'human'
    ],
    sheet: [18, 4],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F3ED',
        sheet: [18, 5]
      },
      {
        unified: '1F469-1F3FC-200D-1F3ED',
        sheet: [18, 6]
      },
      {
        unified: '1F469-1F3FD-200D-1F3ED',
        sheet: [18, 7]
      },
      {
        unified: '1F469-1F3FE-200D-1F3ED',
        sheet: [18, 8]
      },
      {
        unified: '1F469-1F3FF-200D-1F3ED',
        sheet: [18, 9]
      }
    ],
    shortName: 'female-factory-worker'
  },
  {
    name: 'Office Worker',
    unified: '1F9D1-200D-1F4BC',
    keywords: [
      'office_worker',
      'business'
    ],
    sheet: [46, 37],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F4BC',
        sheet: [46, 38]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F4BC',
        sheet: [46, 39]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F4BC',
        sheet: [46, 40]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F4BC',
        sheet: [46, 41]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F4BC',
        sheet: [46, 42]
      }
    ],
    shortName: 'office_worker'
  },
  {
    name: 'Man Office Worker',
    unified: '1F468-200D-1F4BC',
    keywords: [
      'man_office_worker',
      'business',
      'manager',
      'man',
      'human'
    ],
    sheet: [15, 38],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F4BC',
        sheet: [15, 39]
      },
      {
        unified: '1F468-1F3FC-200D-1F4BC',
        sheet: [15, 40]
      },
      {
        unified: '1F468-1F3FD-200D-1F4BC',
        sheet: [15, 41]
      },
      {
        unified: '1F468-1F3FE-200D-1F4BC',
        sheet: [15, 42]
      },
      {
        unified: '1F468-1F3FF-200D-1F4BC',
        sheet: [15, 43]
      }
    ],
    shortName: 'male-office-worker'
  },
  {
    name: 'Woman Office Worker',
    unified: '1F469-200D-1F4BC',
    keywords: [
      'woman_office_worker',
      'business',
      'manager',
      'woman',
      'human'
    ],
    sheet: [18, 26],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F4BC',
        sheet: [18, 27]
      },
      {
        unified: '1F469-1F3FC-200D-1F4BC',
        sheet: [18, 28]
      },
      {
        unified: '1F469-1F3FD-200D-1F4BC',
        sheet: [18, 29]
      },
      {
        unified: '1F469-1F3FE-200D-1F4BC',
        sheet: [18, 30]
      },
      {
        unified: '1F469-1F3FF-200D-1F4BC',
        sheet: [18, 31]
      }
    ],
    shortName: 'female-office-worker'
  },
  {
    name: 'Scientist',
    unified: '1F9D1-200D-1F52C',
    keywords: [
      'scientist',
      'chemistry'
    ],
    sheet: [46, 49],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F52C',
        sheet: [46, 50]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F52C',
        sheet: [46, 51]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F52C',
        sheet: [46, 52]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F52C',
        sheet: [46, 53]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F52C',
        sheet: [46, 54]
      }
    ],
    shortName: 'scientist'
  },
  {
    name: 'Man Scientist',
    unified: '1F468-200D-1F52C',
    keywords: [
      'man_scientist',
      'biologist',
      'chemist',
      'engineer',
      'physicist',
      'man',
      'human'
    ],
    sheet: [15, 50],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F52C',
        sheet: [15, 51]
      },
      {
        unified: '1F468-1F3FC-200D-1F52C',
        sheet: [15, 52]
      },
      {
        unified: '1F468-1F3FD-200D-1F52C',
        sheet: [15, 53]
      },
      {
        unified: '1F468-1F3FE-200D-1F52C',
        sheet: [15, 54]
      },
      {
        unified: '1F468-1F3FF-200D-1F52C',
        sheet: [15, 55]
      }
    ],
    shortName: 'male-scientist'
  },
  {
    name: 'Woman Scientist',
    unified: '1F469-200D-1F52C',
    keywords: [
      'woman_scientist',
      'biologist',
      'chemist',
      'engineer',
      'physicist',
      'woman',
      'human'
    ],
    sheet: [18, 38],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F52C',
        sheet: [18, 39]
      },
      {
        unified: '1F469-1F3FC-200D-1F52C',
        sheet: [18, 40]
      },
      {
        unified: '1F469-1F3FD-200D-1F52C',
        sheet: [18, 41]
      },
      {
        unified: '1F469-1F3FE-200D-1F52C',
        sheet: [18, 42]
      },
      {
        unified: '1F469-1F3FF-200D-1F52C',
        sheet: [18, 43]
      }
    ],
    shortName: 'female-scientist'
  },
  {
    name: 'Technologist',
    unified: '1F9D1-200D-1F4BB',
    keywords: [
      'technologist',
      'computer'
    ],
    sheet: [46, 31],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F4BB',
        sheet: [46, 32]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F4BB',
        sheet: [46, 33]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F4BB',
        sheet: [46, 34]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F4BB',
        sheet: [46, 35]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F4BB',
        sheet: [46, 36]
      }
    ],
    shortName: 'technologist'
  },
  {
    name: 'Man Technologist',
    unified: '1F468-200D-1F4BB',
    keywords: [
      'man_technologist',
      'coder',
      'developer',
      'engineer',
      'programmer',
      'software',
      'man',
      'human',
      'laptop',
      'computer'
    ],
    sheet: [15, 32],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F4BB',
        sheet: [15, 33]
      },
      {
        unified: '1F468-1F3FC-200D-1F4BB',
        sheet: [15, 34]
      },
      {
        unified: '1F468-1F3FD-200D-1F4BB',
        sheet: [15, 35]
      },
      {
        unified: '1F468-1F3FE-200D-1F4BB',
        sheet: [15, 36]
      },
      {
        unified: '1F468-1F3FF-200D-1F4BB',
        sheet: [15, 37]
      }
    ],
    shortName: 'male-technologist'
  },
  {
    name: 'Woman Technologist',
    unified: '1F469-200D-1F4BB',
    keywords: [
      'woman_technologist',
      'coder',
      'developer',
      'engineer',
      'programmer',
      'software',
      'woman',
      'human',
      'laptop',
      'computer'
    ],
    sheet: [18, 20],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F4BB',
        sheet: [18, 21]
      },
      {
        unified: '1F469-1F3FC-200D-1F4BB',
        sheet: [18, 22]
      },
      {
        unified: '1F469-1F3FD-200D-1F4BB',
        sheet: [18, 23]
      },
      {
        unified: '1F469-1F3FE-200D-1F4BB',
        sheet: [18, 24]
      },
      {
        unified: '1F469-1F3FF-200D-1F4BB',
        sheet: [18, 25]
      }
    ],
    shortName: 'female-technologist'
  },
  {
    name: 'Singer',
    unified: '1F9D1-200D-1F3A4',
    keywords: [
      'singer',
      'song',
      'artist',
      'performer'
    ],
    sheet: [46, 7],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F3A4',
        sheet: [46, 8]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F3A4',
        sheet: [46, 9]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F3A4',
        sheet: [46, 10]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F3A4',
        sheet: [46, 11]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F3A4',
        sheet: [46, 12]
      }
    ],
    shortName: 'singer'
  },
  {
    name: 'Man Singer',
    unified: '1F468-200D-1F3A4',
    keywords: [
      'man_singer',
      'rockstar',
      'entertainer',
      'man',
      'human'
    ],
    sheet: [14, 51],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F3A4',
        sheet: [14, 52]
      },
      {
        unified: '1F468-1F3FC-200D-1F3A4',
        sheet: [14, 53]
      },
      {
        unified: '1F468-1F3FD-200D-1F3A4',
        sheet: [14, 54]
      },
      {
        unified: '1F468-1F3FE-200D-1F3A4',
        sheet: [14, 55]
      },
      {
        unified: '1F468-1F3FF-200D-1F3A4',
        sheet: [14, 56]
      }
    ],
    shortName: 'male-singer'
  },
  {
    name: 'Woman Singer',
    unified: '1F469-200D-1F3A4',
    keywords: [
      'woman_singer',
      'rockstar',
      'entertainer',
      'woman',
      'human'
    ],
    sheet: [17, 44],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F3A4',
        sheet: [17, 45]
      },
      {
        unified: '1F469-1F3FC-200D-1F3A4',
        sheet: [17, 46]
      },
      {
        unified: '1F469-1F3FD-200D-1F3A4',
        sheet: [17, 47]
      },
      {
        unified: '1F469-1F3FE-200D-1F3A4',
        sheet: [17, 48]
      },
      {
        unified: '1F469-1F3FF-200D-1F3A4',
        sheet: [17, 49]
      }
    ],
    shortName: 'female-singer'
  },
  {
    name: 'Artist',
    unified: '1F9D1-200D-1F3A8',
    keywords: [
      'artist',
      'painting',
      'draw',
      'creativity'
    ],
    sheet: [46, 13],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F3A8',
        sheet: [46, 14]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F3A8',
        sheet: [46, 15]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F3A8',
        sheet: [46, 16]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F3A8',
        sheet: [46, 17]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F3A8',
        sheet: [46, 18]
      }
    ],
    shortName: 'artist'
  },
  {
    name: 'Man Artist',
    unified: '1F468-200D-1F3A8',
    keywords: [
      'man_artist',
      'painter',
      'man',
      'human'
    ],
    sheet: [14, 57],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F3A8',
        sheet: [15, 0]
      },
      {
        unified: '1F468-1F3FC-200D-1F3A8',
        sheet: [15, 1]
      },
      {
        unified: '1F468-1F3FD-200D-1F3A8',
        sheet: [15, 2]
      },
      {
        unified: '1F468-1F3FE-200D-1F3A8',
        sheet: [15, 3]
      },
      {
        unified: '1F468-1F3FF-200D-1F3A8',
        sheet: [15, 4]
      }
    ],
    shortName: 'male-artist'
  },
  {
    name: 'Woman Artist',
    unified: '1F469-200D-1F3A8',
    keywords: [
      'woman_artist',
      'painter',
      'woman',
      'human'
    ],
    sheet: [17, 50],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F3A8',
        sheet: [17, 51]
      },
      {
        unified: '1F469-1F3FC-200D-1F3A8',
        sheet: [17, 52]
      },
      {
        unified: '1F469-1F3FD-200D-1F3A8',
        sheet: [17, 53]
      },
      {
        unified: '1F469-1F3FE-200D-1F3A8',
        sheet: [17, 54]
      },
      {
        unified: '1F469-1F3FF-200D-1F3A8',
        sheet: [17, 55]
      }
    ],
    shortName: 'female-artist'
  },
  {
    name: 'Pilot',
    unified: '1F9D1-200D-2708-FE0F',
    keywords: [
      'pilot',
      'fly',
      'plane',
      'airplane'
    ],
    sheet: [48, 31],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-2708-FE0F',
        sheet: [48, 32]
      },
      {
        unified: '1F9D1-1F3FC-200D-2708-FE0F',
        sheet: [48, 33]
      },
      {
        unified: '1F9D1-1F3FD-200D-2708-FE0F',
        sheet: [48, 34]
      },
      {
        unified: '1F9D1-1F3FE-200D-2708-FE0F',
        sheet: [48, 35]
      },
      {
        unified: '1F9D1-1F3FF-200D-2708-FE0F',
        sheet: [48, 36]
      }
    ],
    shortName: 'pilot'
  },
  {
    name: 'Man Pilot',
    unified: '1F468-200D-2708-FE0F',
    keywords: [
      'man_pilot',
      'aviator',
      'plane',
      'man',
      'human'
    ],
    sheet: [17, 6],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-2708-FE0F',
        sheet: [17, 7]
      },
      {
        unified: '1F468-1F3FC-200D-2708-FE0F',
        sheet: [17, 8]
      },
      {
        unified: '1F468-1F3FD-200D-2708-FE0F',
        sheet: [17, 9]
      },
      {
        unified: '1F468-1F3FE-200D-2708-FE0F',
        sheet: [17, 10]
      },
      {
        unified: '1F468-1F3FF-200D-2708-FE0F',
        sheet: [17, 11]
      }
    ],
    shortName: 'male-pilot'
  },
  {
    name: 'Woman Pilot',
    unified: '1F469-200D-2708-FE0F',
    keywords: [
      'woman_pilot',
      'aviator',
      'plane',
      'woman',
      'human'
    ],
    sheet: [19, 52],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-2708-FE0F',
        sheet: [19, 53]
      },
      {
        unified: '1F469-1F3FC-200D-2708-FE0F',
        sheet: [19, 54]
      },
      {
        unified: '1F469-1F3FD-200D-2708-FE0F',
        sheet: [19, 55]
      },
      {
        unified: '1F469-1F3FE-200D-2708-FE0F',
        sheet: [19, 56]
      },
      {
        unified: '1F469-1F3FF-200D-2708-FE0F',
        sheet: [19, 57]
      }
    ],
    shortName: 'female-pilot'
  },
  {
    name: 'Astronaut',
    unified: '1F9D1-200D-1F680',
    keywords: [
      'astronaut',
      'outerspace'
    ],
    sheet: [46, 55],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F680',
        sheet: [46, 56]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F680',
        sheet: [46, 57]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F680',
        sheet: [47, 0]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F680',
        sheet: [47, 1]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F680',
        sheet: [47, 2]
      }
    ],
    shortName: 'astronaut'
  },
  {
    name: 'Man Astronaut',
    unified: '1F468-200D-1F680',
    keywords: [
      'man_astronaut',
      'space',
      'rocket',
      'man',
      'human'
    ],
    sheet: [15, 56],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F680',
        sheet: [15, 57]
      },
      {
        unified: '1F468-1F3FC-200D-1F680',
        sheet: [16, 0]
      },
      {
        unified: '1F468-1F3FD-200D-1F680',
        sheet: [16, 1]
      },
      {
        unified: '1F468-1F3FE-200D-1F680',
        sheet: [16, 2]
      },
      {
        unified: '1F468-1F3FF-200D-1F680',
        sheet: [16, 3]
      }
    ],
    shortName: 'male-astronaut'
  },
  {
    name: 'Woman Astronaut',
    unified: '1F469-200D-1F680',
    keywords: [
      'woman_astronaut',
      'space',
      'rocket',
      'woman',
      'human'
    ],
    sheet: [18, 44],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F680',
        sheet: [18, 45]
      },
      {
        unified: '1F469-1F3FC-200D-1F680',
        sheet: [18, 46]
      },
      {
        unified: '1F469-1F3FD-200D-1F680',
        sheet: [18, 47]
      },
      {
        unified: '1F469-1F3FE-200D-1F680',
        sheet: [18, 48]
      },
      {
        unified: '1F469-1F3FF-200D-1F680',
        sheet: [18, 49]
      }
    ],
    shortName: 'female-astronaut'
  },
  {
    name: 'Firefighter',
    unified: '1F9D1-200D-1F692',
    keywords: ['firefighter', 'fire'],
    sheet: [47, 3],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F692',
        sheet: [47, 4]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F692',
        sheet: [47, 5]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F692',
        sheet: [47, 6]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F692',
        sheet: [47, 7]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F692',
        sheet: [47, 8]
      }
    ],
    shortName: 'firefighter'
  },
  {
    name: 'Man Firefighter',
    unified: '1F468-200D-1F692',
    keywords: [
      'man_firefighter',
      'fireman',
      'man',
      'human'
    ],
    sheet: [16, 4],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F692',
        sheet: [16, 5]
      },
      {
        unified: '1F468-1F3FC-200D-1F692',
        sheet: [16, 6]
      },
      {
        unified: '1F468-1F3FD-200D-1F692',
        sheet: [16, 7]
      },
      {
        unified: '1F468-1F3FE-200D-1F692',
        sheet: [16, 8]
      },
      {
        unified: '1F468-1F3FF-200D-1F692',
        sheet: [16, 9]
      }
    ],
    shortName: 'male-firefighter'
  },
  {
    name: 'Woman Firefighter',
    unified: '1F469-200D-1F692',
    keywords: [
      'woman_firefighter',
      'fireman',
      'woman',
      'human'
    ],
    sheet: [18, 50],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F692',
        sheet: [18, 51]
      },
      {
        unified: '1F469-1F3FC-200D-1F692',
        sheet: [18, 52]
      },
      {
        unified: '1F469-1F3FD-200D-1F692',
        sheet: [18, 53]
      },
      {
        unified: '1F469-1F3FE-200D-1F692',
        sheet: [18, 54]
      },
      {
        unified: '1F469-1F3FF-200D-1F692',
        sheet: [18, 55]
      }
    ],
    shortName: 'female-firefighter'
  },
  {
    name: 'Police Officer',
    unified: '1F46E',
    keywords: ['police_officer', 'cop'],
    sheet: [21, 43],
    skinVariations: [
      {
        unified: '1F46E-1F3FB',
        sheet: [21, 44]
      },
      {
        unified: '1F46E-1F3FC',
        sheet: [21, 45]
      },
      {
        unified: '1F46E-1F3FD',
        sheet: [21, 46]
      },
      {
        unified: '1F46E-1F3FE',
        sheet: [21, 47]
      },
      {
        unified: '1F46E-1F3FF',
        sheet: [21, 48]
      }
    ],
    shortName: 'cop',
    obsoletedBy: '1F46E-200D-2642-FE0F'
  },
  {
    name: 'Man Police Officer',
    unified: '1F46E-200D-2642-FE0F',
    obsoletes: '1F46E',
    keywords: [
      'man_police_officer',
      'man',
      'police',
      'law',
      'legal',
      'enforcement',
      'arrest',
      '911'
    ],
    sheet: [21, 37],
    skinVariations: [
      {
        unified: '1F46E-1F3FB-200D-2642-FE0F',
        sheet: [21, 38]
      },
      {
        unified: '1F46E-1F3FC-200D-2642-FE0F',
        sheet: [21, 39]
      },
      {
        unified: '1F46E-1F3FD-200D-2642-FE0F',
        sheet: [21, 40]
      },
      {
        unified: '1F46E-1F3FE-200D-2642-FE0F',
        sheet: [21, 41]
      },
      {
        unified: '1F46E-1F3FF-200D-2642-FE0F',
        sheet: [21, 42]
      }
    ],
    shortName: 'male-police-officer'
  },
  {
    name: 'Woman Police Officer',
    unified: '1F46E-200D-2640-FE0F',
    keywords: [
      'woman_police_officer',
      'woman',
      'police',
      'law',
      'legal',
      'enforcement',
      'arrest',
      '911',
      'female'
    ],
    sheet: [21, 31],
    skinVariations: [
      {
        unified: '1F46E-1F3FB-200D-2640-FE0F',
        sheet: [21, 32]
      },
      {
        unified: '1F46E-1F3FC-200D-2640-FE0F',
        sheet: [21, 33]
      },
      {
        unified: '1F46E-1F3FD-200D-2640-FE0F',
        sheet: [21, 34]
      },
      {
        unified: '1F46E-1F3FE-200D-2640-FE0F',
        sheet: [21, 35]
      },
      {
        unified: '1F46E-1F3FF-200D-2640-FE0F',
        sheet: [21, 36]
      }
    ],
    shortName: 'female-police-officer'
  },
  {
    name: 'Detective',
    unified: '1F575-FE0F',
    keywords: [
      'detective',
      'human',
      'spy',
      'detective'
    ],
    sheet: [29, 25],
    skinVariations: [
      {
        unified: '1F575-1F3FB',
        sheet: [29, 26]
      },
      {
        unified: '1F575-1F3FC',
        sheet: [29, 27]
      },
      {
        unified: '1F575-1F3FD',
        sheet: [29, 28]
      },
      {
        unified: '1F575-1F3FE',
        sheet: [29, 29]
      },
      {
        unified: '1F575-1F3FF',
        sheet: [29, 30]
      }
    ],
    shortName: 'sleuth_or_spy',
    obsoletedBy: '1F575-FE0F-200D-2642-FE0F'
  },
  {
    name: 'Man Detective',
    unified: '1F575-FE0F-200D-2642-FE0F',
    obsoletes: '1F575-FE0F',
    keywords: [
      'man_detective',
      'crime'
    ],
    sheet: [29, 19],
    hidden: ['facebook'],
    skinVariations: [
      {
        unified: '1F575-1F3FB-200D-2642-FE0F',
        sheet: [29, 20]
      },
      {
        unified: '1F575-1F3FC-200D-2642-FE0F',
        sheet: [29, 21]
      },
      {
        unified: '1F575-1F3FD-200D-2642-FE0F',
        sheet: [29, 22]
      },
      {
        unified: '1F575-1F3FE-200D-2642-FE0F',
        sheet: [29, 23]
      },
      {
        unified: '1F575-1F3FF-200D-2642-FE0F',
        sheet: [29, 24]
      }
    ],
    shortName: 'male-detective'
  },
  {
    name: 'Woman Detective',
    unified: '1F575-FE0F-200D-2640-FE0F',
    keywords: [
      'woman_detective',
      'human',
      'spy',
      'detective',
      'female',
      'woman'
    ],
    sheet: [29, 13],
    hidden: ['facebook'],
    skinVariations: [
      {
        unified: '1F575-1F3FB-200D-2640-FE0F',
        sheet: [29, 14]
      },
      {
        unified: '1F575-1F3FC-200D-2640-FE0F',
        sheet: [29, 15]
      },
      {
        unified: '1F575-1F3FD-200D-2640-FE0F',
        sheet: [29, 16]
      },
      {
        unified: '1F575-1F3FE-200D-2640-FE0F',
        sheet: [29, 17]
      },
      {
        unified: '1F575-1F3FF-200D-2640-FE0F',
        sheet: [29, 18]
      }
    ],
    shortName: 'female-detective'
  },
  {
    name: 'Guardsman',
    unified: '1F482',
    keywords: ['guard', 'protect'],
    sheet: [24, 23],
    skinVariations: [
      {
        unified: '1F482-1F3FB',
        sheet: [24, 24]
      },
      {
        unified: '1F482-1F3FC',
        sheet: [24, 25]
      },
      {
        unified: '1F482-1F3FD',
        sheet: [24, 26]
      },
      {
        unified: '1F482-1F3FE',
        sheet: [24, 27]
      },
      {
        unified: '1F482-1F3FF',
        sheet: [24, 28]
      }
    ],
    shortName: 'guardsman',
    obsoletedBy: '1F482-200D-2642-FE0F'
  },
  {
    name: 'Man Guard',
    unified: '1F482-200D-2642-FE0F',
    obsoletes: '1F482',
    keywords: [
      'man_guard',
      'uk',
      'gb',
      'british',
      'male',
      'guy',
      'royal'
    ],
    sheet: [24, 17],
    skinVariations: [
      {
        unified: '1F482-1F3FB-200D-2642-FE0F',
        sheet: [24, 18]
      },
      {
        unified: '1F482-1F3FC-200D-2642-FE0F',
        sheet: [24, 19]
      },
      {
        unified: '1F482-1F3FD-200D-2642-FE0F',
        sheet: [24, 20]
      },
      {
        unified: '1F482-1F3FE-200D-2642-FE0F',
        sheet: [24, 21]
      },
      {
        unified: '1F482-1F3FF-200D-2642-FE0F',
        sheet: [24, 22]
      }
    ],
    shortName: 'male-guard'
  },
  {
    name: 'Woman Guard',
    unified: '1F482-200D-2640-FE0F',
    keywords: [
      'woman_guard',
      'uk',
      'gb',
      'british',
      'female',
      'royal',
      'woman'
    ],
    sheet: [24, 11],
    skinVariations: [
      {
        unified: '1F482-1F3FB-200D-2640-FE0F',
        sheet: [24, 12]
      },
      {
        unified: '1F482-1F3FC-200D-2640-FE0F',
        sheet: [24, 13]
      },
      {
        unified: '1F482-1F3FD-200D-2640-FE0F',
        sheet: [24, 14]
      },
      {
        unified: '1F482-1F3FE-200D-2640-FE0F',
        sheet: [24, 15]
      },
      {
        unified: '1F482-1F3FF-200D-2640-FE0F',
        sheet: [24, 16]
      }
    ],
    shortName: 'female-guard'
  },
  {
    name: 'Ninja',
    unified: '1F977',
    keywords: [
      'ninja',
      'ninjutsu',
      'skills',
      'japanese'
    ],
    sheet: [42, 20],
    skinVariations: [
      {
        unified: '1F977-1F3FB',
        sheet: [42, 21]
      },
      {
        unified: '1F977-1F3FC',
        sheet: [42, 22]
      },
      {
        unified: '1F977-1F3FD',
        sheet: [42, 23]
      },
      {
        unified: '1F977-1F3FE',
        sheet: [42, 24]
      },
      {
        unified: '1F977-1F3FF',
        sheet: [42, 25]
      }
    ],
    shortName: 'ninja'
  },
  {
    name: 'Construction Worker',
    unified: '1F477',
    keywords: [
      'construction_worker',
      'labor',
      'build'
    ],
    sheet: [23, 26],
    skinVariations: [
      {
        unified: '1F477-1F3FB',
        sheet: [23, 27]
      },
      {
        unified: '1F477-1F3FC',
        sheet: [23, 28]
      },
      {
        unified: '1F477-1F3FD',
        sheet: [23, 29]
      },
      {
        unified: '1F477-1F3FE',
        sheet: [23, 30]
      },
      {
        unified: '1F477-1F3FF',
        sheet: [23, 31]
      }
    ],
    shortName: 'construction_worker',
    obsoletedBy: '1F477-200D-2642-FE0F'
  },
  {
    name: 'Man Construction Worker',
    unified: '1F477-200D-2642-FE0F',
    obsoletes: '1F477',
    keywords: [
      'man_construction_worker',
      'male',
      'human',
      'wip',
      'guy',
      'build',
      'construction',
      'worker',
      'labor'
    ],
    sheet: [23, 20],
    skinVariations: [
      {
        unified: '1F477-1F3FB-200D-2642-FE0F',
        sheet: [23, 21]
      },
      {
        unified: '1F477-1F3FC-200D-2642-FE0F',
        sheet: [23, 22]
      },
      {
        unified: '1F477-1F3FD-200D-2642-FE0F',
        sheet: [23, 23]
      },
      {
        unified: '1F477-1F3FE-200D-2642-FE0F',
        sheet: [23, 24]
      },
      {
        unified: '1F477-1F3FF-200D-2642-FE0F',
        sheet: [23, 25]
      }
    ],
    shortName: 'male-construction-worker'
  },
  {
    name: 'Woman Construction Worker',
    unified: '1F477-200D-2640-FE0F',
    keywords: [
      'woman_construction_worker',
      'female',
      'human',
      'wip',
      'build',
      'construction',
      'worker',
      'labor',
      'woman'
    ],
    sheet: [23, 14],
    skinVariations: [
      {
        unified: '1F477-1F3FB-200D-2640-FE0F',
        sheet: [23, 15]
      },
      {
        unified: '1F477-1F3FC-200D-2640-FE0F',
        sheet: [23, 16]
      },
      {
        unified: '1F477-1F3FD-200D-2640-FE0F',
        sheet: [23, 17]
      },
      {
        unified: '1F477-1F3FE-200D-2640-FE0F',
        sheet: [23, 18]
      },
      {
        unified: '1F477-1F3FF-200D-2640-FE0F',
        sheet: [23, 19]
      }
    ],
    shortName: 'female-construction-worker'
  },
  {
    name: 'Prince',
    unified: '1F934',
    keywords: [
      'prince',
      'boy',
      'man',
      'male',
      'crown',
      'royal',
      'king'
    ],
    sheet: [39, 15],
    skinVariations: [
      {
        unified: '1F934-1F3FB',
        sheet: [39, 16]
      },
      {
        unified: '1F934-1F3FC',
        sheet: [39, 17]
      },
      {
        unified: '1F934-1F3FD',
        sheet: [39, 18]
      },
      {
        unified: '1F934-1F3FE',
        sheet: [39, 19]
      },
      {
        unified: '1F934-1F3FF',
        sheet: [39, 20]
      }
    ],
    shortName: 'prince'
  },
  {
    name: 'Princess',
    unified: '1F478',
    keywords: [
      'princess',
      'girl',
      'woman',
      'female',
      'blond',
      'crown',
      'royal',
      'queen'
    ],
    sheet: [23, 32],
    skinVariations: [
      {
        unified: '1F478-1F3FB',
        sheet: [23, 33]
      },
      {
        unified: '1F478-1F3FC',
        sheet: [23, 34]
      },
      {
        unified: '1F478-1F3FD',
        sheet: [23, 35]
      },
      {
        unified: '1F478-1F3FE',
        sheet: [23, 36]
      },
      {
        unified: '1F478-1F3FF',
        sheet: [23, 37]
      }
    ],
    shortName: 'princess'
  },
  {
    name: 'Man with Turban',
    unified: '1F473',
    keywords: [
      'person_wearing_turban',
      'headdress'
    ],
    sheet: [22, 48],
    skinVariations: [
      {
        unified: '1F473-1F3FB',
        sheet: [22, 49]
      },
      {
        unified: '1F473-1F3FC',
        sheet: [22, 50]
      },
      {
        unified: '1F473-1F3FD',
        sheet: [22, 51]
      },
      {
        unified: '1F473-1F3FE',
        sheet: [22, 52]
      },
      {
        unified: '1F473-1F3FF',
        sheet: [22, 53]
      }
    ],
    shortName: 'man_with_turban',
    obsoletedBy: '1F473-200D-2642-FE0F'
  },
  {
    name: 'Man Wearing Turban',
    unified: '1F473-200D-2642-FE0F',
    obsoletes: '1F473',
    keywords: [
      'man_wearing_turban',
      'male',
      'indian',
      'hinduism',
      'arabs'
    ],
    sheet: [22, 42],
    skinVariations: [
      {
        unified: '1F473-1F3FB-200D-2642-FE0F',
        sheet: [22, 43]
      },
      {
        unified: '1F473-1F3FC-200D-2642-FE0F',
        sheet: [22, 44]
      },
      {
        unified: '1F473-1F3FD-200D-2642-FE0F',
        sheet: [22, 45]
      },
      {
        unified: '1F473-1F3FE-200D-2642-FE0F',
        sheet: [22, 46]
      },
      {
        unified: '1F473-1F3FF-200D-2642-FE0F',
        sheet: [22, 47]
      }
    ],
    shortName: 'man-wearing-turban'
  },
  {
    name: 'Woman Wearing Turban',
    unified: '1F473-200D-2640-FE0F',
    keywords: [
      'woman_wearing_turban',
      'female',
      'indian',
      'hinduism',
      'arabs',
      'woman'
    ],
    sheet: [22, 36],
    skinVariations: [
      {
        unified: '1F473-1F3FB-200D-2640-FE0F',
        sheet: [22, 37]
      },
      {
        unified: '1F473-1F3FC-200D-2640-FE0F',
        sheet: [22, 38]
      },
      {
        unified: '1F473-1F3FD-200D-2640-FE0F',
        sheet: [22, 39]
      },
      {
        unified: '1F473-1F3FE-200D-2640-FE0F',
        sheet: [22, 40]
      },
      {
        unified: '1F473-1F3FF-200D-2640-FE0F',
        sheet: [22, 41]
      }
    ],
    shortName: 'woman-wearing-turban'
  },
  {
    name: 'Man with Gua Pi Mao',
    unified: '1F472',
    keywords: [
      'man_with_skullcap',
      'male',
      'boy',
      'chinese'
    ],
    sheet: [22, 30],
    skinVariations: [
      {
        unified: '1F472-1F3FB',
        sheet: [22, 31]
      },
      {
        unified: '1F472-1F3FC',
        sheet: [22, 32]
      },
      {
        unified: '1F472-1F3FD',
        sheet: [22, 33]
      },
      {
        unified: '1F472-1F3FE',
        sheet: [22, 34]
      },
      {
        unified: '1F472-1F3FF',
        sheet: [22, 35]
      }
    ],
    shortName: 'man_with_gua_pi_mao'
  },
  {
    name: 'Person with Headscarf',
    unified: '1F9D5',
    keywords: [
      'woman_with_headscarf',
      'female',
      'hijab',
      'mantilla',
      'tichel'
    ],
    sheet: [49, 3],
    skinVariations: [
      {
        unified: '1F9D5-1F3FB',
        sheet: [49, 4]
      },
      {
        unified: '1F9D5-1F3FC',
        sheet: [49, 5]
      },
      {
        unified: '1F9D5-1F3FD',
        sheet: [49, 6]
      },
      {
        unified: '1F9D5-1F3FE',
        sheet: [49, 7]
      },
      {
        unified: '1F9D5-1F3FF',
        sheet: [49, 8]
      }
    ],
    shortName: 'person_with_headscarf'
  },
  {
    name: 'Man in Tuxedo',
    unified: '1F935',
    keywords: [
      'man_in_tuxedo',
      'couple',
      'marriage',
      'wedding',
      'groom'
    ],
    sheet: [39, 33],
    skinVariations: [
      {
        unified: '1F935-1F3FB',
        sheet: [39, 34]
      },
      {
        unified: '1F935-1F3FC',
        sheet: [39, 35]
      },
      {
        unified: '1F935-1F3FD',
        sheet: [39, 36]
      },
      {
        unified: '1F935-1F3FE',
        sheet: [39, 37]
      },
      {
        unified: '1F935-1F3FF',
        sheet: [39, 38]
      }
    ],
    shortName: 'person_in_tuxedo'
  },
  {
    name: 'Man in Tuxedo',
    unified: '1F935-200D-2642-FE0F',
    keywords: [
      'man in tuxedo',
      'formal',
      'fashion'
    ],
    sheet: [39, 27],
    skinVariations: [
      {
        unified: '1F935-1F3FB-200D-2642-FE0F',
        sheet: [39, 28]
      },
      {
        unified: '1F935-1F3FC-200D-2642-FE0F',
        sheet: [39, 29]
      },
      {
        unified: '1F935-1F3FD-200D-2642-FE0F',
        sheet: [39, 30]
      },
      {
        unified: '1F935-1F3FE-200D-2642-FE0F',
        sheet: [39, 31]
      },
      {
        unified: '1F935-1F3FF-200D-2642-FE0F',
        sheet: [39, 32]
      }
    ],
    shortName: 'man_in_tuxedo'
  },
  {
    name: 'Woman in Tuxedo',
    unified: '1F935-200D-2640-FE0F',
    keywords: [
      'woman in tuxedo',
      'formal',
      'fashion'
    ],
    sheet: [39, 21],
    skinVariations: [
      {
        unified: '1F935-1F3FB-200D-2640-FE0F',
        sheet: [39, 22]
      },
      {
        unified: '1F935-1F3FC-200D-2640-FE0F',
        sheet: [39, 23]
      },
      {
        unified: '1F935-1F3FD-200D-2640-FE0F',
        sheet: [39, 24]
      },
      {
        unified: '1F935-1F3FE-200D-2640-FE0F',
        sheet: [39, 25]
      },
      {
        unified: '1F935-1F3FF-200D-2640-FE0F',
        sheet: [39, 26]
      }
    ],
    shortName: 'woman_in_tuxedo'
  },
  {
    name: 'Bride with Veil',
    unified: '1F470',
    keywords: [
      'bride_with_veil',
      'couple',
      'marriage',
      'wedding',
      'woman',
      'bride'
    ],
    sheet: [22, 6],
    skinVariations: [
      {
        unified: '1F470-1F3FB',
        sheet: [22, 7]
      },
      {
        unified: '1F470-1F3FC',
        sheet: [22, 8]
      },
      {
        unified: '1F470-1F3FD',
        sheet: [22, 9]
      },
      {
        unified: '1F470-1F3FE',
        sheet: [22, 10]
      },
      {
        unified: '1F470-1F3FF',
        sheet: [22, 11]
      }
    ],
    shortName: 'bride_with_veil'
  },
  {
    name: 'Man with Veil',
    unified: '1F470-200D-2642-FE0F',
    keywords: [
      'man with veil',
      'wedding',
      'marriage'
    ],
    sheet: [22, 0],
    skinVariations: [
      {
        unified: '1F470-1F3FB-200D-2642-FE0F',
        sheet: [22, 1]
      },
      {
        unified: '1F470-1F3FC-200D-2642-FE0F',
        sheet: [22, 2]
      },
      {
        unified: '1F470-1F3FD-200D-2642-FE0F',
        sheet: [22, 3]
      },
      {
        unified: '1F470-1F3FE-200D-2642-FE0F',
        sheet: [22, 4]
      },
      {
        unified: '1F470-1F3FF-200D-2642-FE0F',
        sheet: [22, 5]
      }
    ],
    shortName: 'man_with_veil'
  },
  {
    name: 'Woman with Veil',
    unified: '1F470-200D-2640-FE0F',
    keywords: [
      'woman with veil',
      'wedding',
      'marriage'
    ],
    sheet: [21, 52],
    skinVariations: [
      {
        unified: '1F470-1F3FB-200D-2640-FE0F',
        sheet: [21, 53]
      },
      {
        unified: '1F470-1F3FC-200D-2640-FE0F',
        sheet: [21, 54]
      },
      {
        unified: '1F470-1F3FD-200D-2640-FE0F',
        sheet: [21, 55]
      },
      {
        unified: '1F470-1F3FE-200D-2640-FE0F',
        sheet: [21, 56]
      },
      {
        unified: '1F470-1F3FF-200D-2640-FE0F',
        sheet: [21, 57]
      }
    ],
    shortName: 'woman_with_veil'
  },
  {
    name: 'Pregnant Woman',
    unified: '1F930',
    keywords: [
      'pregnant_woman',
      'baby'
    ],
    sheet: [38, 49],
    skinVariations: [
      {
        unified: '1F930-1F3FB',
        sheet: [38, 50]
      },
      {
        unified: '1F930-1F3FC',
        sheet: [38, 51]
      },
      {
        unified: '1F930-1F3FD',
        sheet: [38, 52]
      },
      {
        unified: '1F930-1F3FE',
        sheet: [38, 53]
      },
      {
        unified: '1F930-1F3FF',
        sheet: [38, 54]
      }
    ],
    shortName: 'pregnant_woman'
  },
  {
    name: 'Breast-Feeding',
    unified: '1F931',
    keywords: [
      'breast_feeding',
      'nursing',
      'baby'
    ],
    sheet: [38, 55],
    skinVariations: [
      {
        unified: '1F931-1F3FB',
        sheet: [38, 56]
      },
      {
        unified: '1F931-1F3FC',
        sheet: [38, 57]
      },
      {
        unified: '1F931-1F3FD',
        sheet: [39, 0]
      },
      {
        unified: '1F931-1F3FE',
        sheet: [39, 1]
      },
      {
        unified: '1F931-1F3FF',
        sheet: [39, 2]
      }
    ],
    shortName: 'breast-feeding'
  },
  {
    name: 'Woman Feeding Baby',
    unified: '1F469-200D-1F37C',
    keywords: [
      'woman feeding baby',
      'birth',
      'food'
    ],
    sheet: [17, 32],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F37C',
        sheet: [17, 33]
      },
      {
        unified: '1F469-1F3FC-200D-1F37C',
        sheet: [17, 34]
      },
      {
        unified: '1F469-1F3FD-200D-1F37C',
        sheet: [17, 35]
      },
      {
        unified: '1F469-1F3FE-200D-1F37C',
        sheet: [17, 36]
      },
      {
        unified: '1F469-1F3FF-200D-1F37C',
        sheet: [17, 37]
      }
    ],
    shortName: 'woman_feeding_baby'
  },
  {
    name: 'Man Feeding Baby',
    unified: '1F468-200D-1F37C',
    keywords: [
      'man feeding baby',
      'birth',
      'food'
    ],
    sheet: [14, 39],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F37C',
        sheet: [14, 40]
      },
      {
        unified: '1F468-1F3FC-200D-1F37C',
        sheet: [14, 41]
      },
      {
        unified: '1F468-1F3FD-200D-1F37C',
        sheet: [14, 42]
      },
      {
        unified: '1F468-1F3FE-200D-1F37C',
        sheet: [14, 43]
      },
      {
        unified: '1F468-1F3FF-200D-1F37C',
        sheet: [14, 44]
      }
    ],
    shortName: 'man_feeding_baby'
  },
  {
    name: 'Person Feeding Baby',
    unified: '1F9D1-200D-1F37C',
    keywords: [
      'person feeding baby',
      'birth',
      'food'
    ],
    sheet: [45, 47],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F37C',
        sheet: [45, 48]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F37C',
        sheet: [45, 49]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F37C',
        sheet: [45, 50]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F37C',
        sheet: [45, 51]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F37C',
        sheet: [45, 52]
      }
    ],
    shortName: 'person_feeding_baby'
  },
  {
    name: 'Baby Angel',
    unified: '1F47C',
    keywords: [
      'baby_angel',
      'heaven',
      'wings',
      'halo'
    ],
    sheet: [23, 41],
    skinVariations: [
      {
        unified: '1F47C-1F3FB',
        sheet: [23, 42]
      },
      {
        unified: '1F47C-1F3FC',
        sheet: [23, 43]
      },
      {
        unified: '1F47C-1F3FD',
        sheet: [23, 44]
      },
      {
        unified: '1F47C-1F3FE',
        sheet: [23, 45]
      },
      {
        unified: '1F47C-1F3FF',
        sheet: [23, 46]
      }
    ],
    shortName: 'angel'
  },
  {
    name: 'Father Christmas',
    unified: '1F385',
    keywords: [
      'santa_claus',
      'festival',
      'man',
      'male',
      'xmas',
      'father christmas'
    ],
    sheet: [7, 29],
    skinVariations: [
      {
        unified: '1F385-1F3FB',
        sheet: [7, 30]
      },
      {
        unified: '1F385-1F3FC',
        sheet: [7, 31]
      },
      {
        unified: '1F385-1F3FD',
        sheet: [7, 32]
      },
      {
        unified: '1F385-1F3FE',
        sheet: [7, 33]
      },
      {
        unified: '1F385-1F3FF',
        sheet: [7, 34]
      }
    ],
    shortName: 'santa'
  },
  {
    name: 'Mother Christmas',
    unified: '1F936',
    keywords: [
      'mrs_claus',
      'woman',
      'female',
      'xmas',
      'mother christmas'
    ],
    sheet: [39, 39],
    skinVariations: [
      {
        unified: '1F936-1F3FB',
        sheet: [39, 40]
      },
      {
        unified: '1F936-1F3FC',
        sheet: [39, 41]
      },
      {
        unified: '1F936-1F3FD',
        sheet: [39, 42]
      },
      {
        unified: '1F936-1F3FE',
        sheet: [39, 43]
      },
      {
        unified: '1F936-1F3FF',
        sheet: [39, 44]
      }
    ],
    shortNames: ['mother_christmas'],
    shortName: 'mrs_claus'
  },
  {
    name: 'Mx Claus',
    unified: '1F9D1-200D-1F384',
    keywords: ['mx claus', 'christmas'],
    sheet: [45, 53],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F384',
        sheet: [45, 54]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F384',
        sheet: [45, 55]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F384',
        sheet: [45, 56]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F384',
        sheet: [45, 57]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F384',
        sheet: [46, 0]
      }
    ],
    shortName: 'mx_claus'
  },
  {
    name: 'Superhero',
    unified: '1F9B8',
    keywords: ['superhero', 'marvel'],
    sheet: [43, 49],
    skinVariations: [
      {
        unified: '1F9B8-1F3FB',
        sheet: [43, 50]
      },
      {
        unified: '1F9B8-1F3FC',
        sheet: [43, 51]
      },
      {
        unified: '1F9B8-1F3FD',
        sheet: [43, 52]
      },
      {
        unified: '1F9B8-1F3FE',
        sheet: [43, 53]
      },
      {
        unified: '1F9B8-1F3FF',
        sheet: [43, 54]
      }
    ],
    shortName: 'superhero'
  },
  {
    name: 'Man Superhero',
    unified: '1F9B8-200D-2642-FE0F',
    keywords: [
      'man_superhero',
      'man',
      'male',
      'good',
      'hero',
      'superpowers'
    ],
    sheet: [43, 43],
    skinVariations: [
      {
        unified: '1F9B8-1F3FB-200D-2642-FE0F',
        sheet: [43, 44]
      },
      {
        unified: '1F9B8-1F3FC-200D-2642-FE0F',
        sheet: [43, 45]
      },
      {
        unified: '1F9B8-1F3FD-200D-2642-FE0F',
        sheet: [43, 46]
      },
      {
        unified: '1F9B8-1F3FE-200D-2642-FE0F',
        sheet: [43, 47]
      },
      {
        unified: '1F9B8-1F3FF-200D-2642-FE0F',
        sheet: [43, 48]
      }
    ],
    shortName: 'male_superhero'
  },
  {
    name: 'Woman Superhero',
    unified: '1F9B8-200D-2640-FE0F',
    keywords: [
      'woman_superhero',
      'woman',
      'female',
      'good',
      'heroine',
      'superpowers'
    ],
    sheet: [43, 37],
    skinVariations: [
      {
        unified: '1F9B8-1F3FB-200D-2640-FE0F',
        sheet: [43, 38]
      },
      {
        unified: '1F9B8-1F3FC-200D-2640-FE0F',
        sheet: [43, 39]
      },
      {
        unified: '1F9B8-1F3FD-200D-2640-FE0F',
        sheet: [43, 40]
      },
      {
        unified: '1F9B8-1F3FE-200D-2640-FE0F',
        sheet: [43, 41]
      },
      {
        unified: '1F9B8-1F3FF-200D-2640-FE0F',
        sheet: [43, 42]
      }
    ],
    shortName: 'female_superhero'
  },
  {
    name: 'Supervillain',
    unified: '1F9B9',
    keywords: [
      'supervillain',
      'marvel'
    ],
    sheet: [44, 9],
    skinVariations: [
      {
        unified: '1F9B9-1F3FB',
        sheet: [44, 10]
      },
      {
        unified: '1F9B9-1F3FC',
        sheet: [44, 11]
      },
      {
        unified: '1F9B9-1F3FD',
        sheet: [44, 12]
      },
      {
        unified: '1F9B9-1F3FE',
        sheet: [44, 13]
      },
      {
        unified: '1F9B9-1F3FF',
        sheet: [44, 14]
      }
    ],
    shortName: 'supervillain'
  },
  {
    name: 'Man Supervillain',
    unified: '1F9B9-200D-2642-FE0F',
    keywords: [
      'man_supervillain',
      'man',
      'male',
      'evil',
      'bad',
      'criminal',
      'hero',
      'superpowers'
    ],
    sheet: [44, 3],
    skinVariations: [
      {
        unified: '1F9B9-1F3FB-200D-2642-FE0F',
        sheet: [44, 4]
      },
      {
        unified: '1F9B9-1F3FC-200D-2642-FE0F',
        sheet: [44, 5]
      },
      {
        unified: '1F9B9-1F3FD-200D-2642-FE0F',
        sheet: [44, 6]
      },
      {
        unified: '1F9B9-1F3FE-200D-2642-FE0F',
        sheet: [44, 7]
      },
      {
        unified: '1F9B9-1F3FF-200D-2642-FE0F',
        sheet: [44, 8]
      }
    ],
    shortName: 'male_supervillain'
  },
  {
    name: 'Woman Supervillain',
    unified: '1F9B9-200D-2640-FE0F',
    keywords: [
      'woman_supervillain',
      'woman',
      'female',
      'evil',
      'bad',
      'criminal',
      'heroine',
      'superpowers'
    ],
    sheet: [43, 55],
    skinVariations: [
      {
        unified: '1F9B9-1F3FB-200D-2640-FE0F',
        sheet: [43, 56]
      },
      {
        unified: '1F9B9-1F3FC-200D-2640-FE0F',
        sheet: [43, 57]
      },
      {
        unified: '1F9B9-1F3FD-200D-2640-FE0F',
        sheet: [44, 0]
      },
      {
        unified: '1F9B9-1F3FE-200D-2640-FE0F',
        sheet: [44, 1]
      },
      {
        unified: '1F9B9-1F3FF-200D-2640-FE0F',
        sheet: [44, 2]
      }
    ],
    shortName: 'female_supervillain'
  },
  {
    name: 'Mage',
    unified: '1F9D9',
    keywords: ['mage', 'magic'],
    sheet: [50, 17],
    skinVariations: [
      {
        unified: '1F9D9-1F3FB',
        sheet: [50, 18]
      },
      {
        unified: '1F9D9-1F3FC',
        sheet: [50, 19]
      },
      {
        unified: '1F9D9-1F3FD',
        sheet: [50, 20]
      },
      {
        unified: '1F9D9-1F3FE',
        sheet: [50, 21]
      },
      {
        unified: '1F9D9-1F3FF',
        sheet: [50, 22]
      }
    ],
    shortName: 'mage',
    obsoletedBy: '1F9D9-200D-2640-FE0F'
  },
  {
    name: 'Man Mage',
    unified: '1F9D9-200D-2642-FE0F',
    keywords: [
      'man_mage',
      'man',
      'male',
      'mage',
      'sorcerer'
    ],
    sheet: [50, 11],
    skinVariations: [
      {
        unified: '1F9D9-1F3FB-200D-2642-FE0F',
        sheet: [50, 12]
      },
      {
        unified: '1F9D9-1F3FC-200D-2642-FE0F',
        sheet: [50, 13]
      },
      {
        unified: '1F9D9-1F3FD-200D-2642-FE0F',
        sheet: [50, 14]
      },
      {
        unified: '1F9D9-1F3FE-200D-2642-FE0F',
        sheet: [50, 15]
      },
      {
        unified: '1F9D9-1F3FF-200D-2642-FE0F',
        sheet: [50, 16]
      }
    ],
    shortName: 'male_mage'
  },
  {
    name: 'Woman Mage',
    unified: '1F9D9-200D-2640-FE0F',
    obsoletes: '1F9D9',
    keywords: [
      'woman_mage',
      'woman',
      'female',
      'mage',
      'witch'
    ],
    sheet: [50, 5],
    skinVariations: [
      {
        unified: '1F9D9-1F3FB-200D-2640-FE0F',
        sheet: [50, 6]
      },
      {
        unified: '1F9D9-1F3FC-200D-2640-FE0F',
        sheet: [50, 7]
      },
      {
        unified: '1F9D9-1F3FD-200D-2640-FE0F',
        sheet: [50, 8]
      },
      {
        unified: '1F9D9-1F3FE-200D-2640-FE0F',
        sheet: [50, 9]
      },
      {
        unified: '1F9D9-1F3FF-200D-2640-FE0F',
        sheet: [50, 10]
      }
    ],
    shortName: 'female_mage'
  },
  {
    name: 'Fairy',
    unified: '1F9DA',
    keywords: [
      'fairy',
      'wings',
      'magical'
    ],
    sheet: [50, 35],
    skinVariations: [
      {
        unified: '1F9DA-1F3FB',
        sheet: [50, 36]
      },
      {
        unified: '1F9DA-1F3FC',
        sheet: [50, 37]
      },
      {
        unified: '1F9DA-1F3FD',
        sheet: [50, 38]
      },
      {
        unified: '1F9DA-1F3FE',
        sheet: [50, 39]
      },
      {
        unified: '1F9DA-1F3FF',
        sheet: [50, 40]
      }
    ],
    shortName: 'fairy',
    obsoletedBy: '1F9DA-200D-2640-FE0F'
  },
  {
    name: 'Man Fairy',
    unified: '1F9DA-200D-2642-FE0F',
    keywords: [
      'man_fairy',
      'man',
      'male'
    ],
    sheet: [50, 29],
    skinVariations: [
      {
        unified: '1F9DA-1F3FB-200D-2642-FE0F',
        sheet: [50, 30]
      },
      {
        unified: '1F9DA-1F3FC-200D-2642-FE0F',
        sheet: [50, 31]
      },
      {
        unified: '1F9DA-1F3FD-200D-2642-FE0F',
        sheet: [50, 32]
      },
      {
        unified: '1F9DA-1F3FE-200D-2642-FE0F',
        sheet: [50, 33]
      },
      {
        unified: '1F9DA-1F3FF-200D-2642-FE0F',
        sheet: [50, 34]
      }
    ],
    shortName: 'male_fairy'
  },
  {
    name: 'Woman Fairy',
    unified: '1F9DA-200D-2640-FE0F',
    obsoletes: '1F9DA',
    keywords: [
      'woman_fairy',
      'woman',
      'female'
    ],
    sheet: [50, 23],
    skinVariations: [
      {
        unified: '1F9DA-1F3FB-200D-2640-FE0F',
        sheet: [50, 24]
      },
      {
        unified: '1F9DA-1F3FC-200D-2640-FE0F',
        sheet: [50, 25]
      },
      {
        unified: '1F9DA-1F3FD-200D-2640-FE0F',
        sheet: [50, 26]
      },
      {
        unified: '1F9DA-1F3FE-200D-2640-FE0F',
        sheet: [50, 27]
      },
      {
        unified: '1F9DA-1F3FF-200D-2640-FE0F',
        sheet: [50, 28]
      }
    ],
    shortName: 'female_fairy'
  },
  {
    name: 'Vampire',
    unified: '1F9DB',
    keywords: [
      'vampire',
      'blood',
      'twilight'
    ],
    sheet: [50, 53],
    skinVariations: [
      {
        unified: '1F9DB-1F3FB',
        sheet: [50, 54]
      },
      {
        unified: '1F9DB-1F3FC',
        sheet: [50, 55]
      },
      {
        unified: '1F9DB-1F3FD',
        sheet: [50, 56]
      },
      {
        unified: '1F9DB-1F3FE',
        sheet: [50, 57]
      },
      {
        unified: '1F9DB-1F3FF',
        sheet: [51, 0]
      }
    ],
    shortName: 'vampire',
    obsoletedBy: '1F9DB-200D-2640-FE0F'
  },
  {
    name: 'Man Vampire',
    unified: '1F9DB-200D-2642-FE0F',
    keywords: [
      'man_vampire',
      'man',
      'male',
      'dracula'
    ],
    sheet: [50, 47],
    skinVariations: [
      {
        unified: '1F9DB-1F3FB-200D-2642-FE0F',
        sheet: [50, 48]
      },
      {
        unified: '1F9DB-1F3FC-200D-2642-FE0F',
        sheet: [50, 49]
      },
      {
        unified: '1F9DB-1F3FD-200D-2642-FE0F',
        sheet: [50, 50]
      },
      {
        unified: '1F9DB-1F3FE-200D-2642-FE0F',
        sheet: [50, 51]
      },
      {
        unified: '1F9DB-1F3FF-200D-2642-FE0F',
        sheet: [50, 52]
      }
    ],
    shortName: 'male_vampire'
  },
  {
    name: 'Woman Vampire',
    unified: '1F9DB-200D-2640-FE0F',
    obsoletes: '1F9DB',
    keywords: [
      'woman_vampire',
      'woman',
      'female'
    ],
    sheet: [50, 41],
    skinVariations: [
      {
        unified: '1F9DB-1F3FB-200D-2640-FE0F',
        sheet: [50, 42]
      },
      {
        unified: '1F9DB-1F3FC-200D-2640-FE0F',
        sheet: [50, 43]
      },
      {
        unified: '1F9DB-1F3FD-200D-2640-FE0F',
        sheet: [50, 44]
      },
      {
        unified: '1F9DB-1F3FE-200D-2640-FE0F',
        sheet: [50, 45]
      },
      {
        unified: '1F9DB-1F3FF-200D-2640-FE0F',
        sheet: [50, 46]
      }
    ],
    shortName: 'female_vampire'
  },
  {
    name: 'Merperson',
    unified: '1F9DC',
    keywords: ['merperson', 'sea'],
    sheet: [51, 13],
    skinVariations: [
      {
        unified: '1F9DC-1F3FB',
        sheet: [51, 14]
      },
      {
        unified: '1F9DC-1F3FC',
        sheet: [51, 15]
      },
      {
        unified: '1F9DC-1F3FD',
        sheet: [51, 16]
      },
      {
        unified: '1F9DC-1F3FE',
        sheet: [51, 17]
      },
      {
        unified: '1F9DC-1F3FF',
        sheet: [51, 18]
      }
    ],
    shortName: 'merperson',
    obsoletedBy: '1F9DC-200D-2642-FE0F'
  },
  {
    name: 'Merman',
    unified: '1F9DC-200D-2642-FE0F',
    obsoletes: '1F9DC',
    keywords: [
      'merman',
      'man',
      'male',
      'triton'
    ],
    sheet: [51, 7],
    skinVariations: [
      {
        unified: '1F9DC-1F3FB-200D-2642-FE0F',
        sheet: [51, 8]
      },
      {
        unified: '1F9DC-1F3FC-200D-2642-FE0F',
        sheet: [51, 9]
      },
      {
        unified: '1F9DC-1F3FD-200D-2642-FE0F',
        sheet: [51, 10]
      },
      {
        unified: '1F9DC-1F3FE-200D-2642-FE0F',
        sheet: [51, 11]
      },
      {
        unified: '1F9DC-1F3FF-200D-2642-FE0F',
        sheet: [51, 12]
      }
    ],
    shortName: 'merman'
  },
  {
    name: 'Mermaid',
    unified: '1F9DC-200D-2640-FE0F',
    keywords: [
      'mermaid',
      'woman',
      'female',
      'merwoman',
      'ariel'
    ],
    sheet: [51, 1],
    skinVariations: [
      {
        unified: '1F9DC-1F3FB-200D-2640-FE0F',
        sheet: [51, 2]
      },
      {
        unified: '1F9DC-1F3FC-200D-2640-FE0F',
        sheet: [51, 3]
      },
      {
        unified: '1F9DC-1F3FD-200D-2640-FE0F',
        sheet: [51, 4]
      },
      {
        unified: '1F9DC-1F3FE-200D-2640-FE0F',
        sheet: [51, 5]
      },
      {
        unified: '1F9DC-1F3FF-200D-2640-FE0F',
        sheet: [51, 6]
      }
    ],
    shortName: 'mermaid'
  },
  {
    name: 'Elf',
    unified: '1F9DD',
    keywords: ['elf', 'magical'],
    sheet: [51, 31],
    skinVariations: [
      {
        unified: '1F9DD-1F3FB',
        sheet: [51, 32]
      },
      {
        unified: '1F9DD-1F3FC',
        sheet: [51, 33]
      },
      {
        unified: '1F9DD-1F3FD',
        sheet: [51, 34]
      },
      {
        unified: '1F9DD-1F3FE',
        sheet: [51, 35]
      },
      {
        unified: '1F9DD-1F3FF',
        sheet: [51, 36]
      }
    ],
    shortName: 'elf',
    obsoletedBy: '1F9DD-200D-2642-FE0F'
  },
  {
    name: 'Man Elf',
    unified: '1F9DD-200D-2642-FE0F',
    obsoletes: '1F9DD',
    keywords: [
      'man_elf',
      'man',
      'male'
    ],
    sheet: [51, 25],
    skinVariations: [
      {
        unified: '1F9DD-1F3FB-200D-2642-FE0F',
        sheet: [51, 26]
      },
      {
        unified: '1F9DD-1F3FC-200D-2642-FE0F',
        sheet: [51, 27]
      },
      {
        unified: '1F9DD-1F3FD-200D-2642-FE0F',
        sheet: [51, 28]
      },
      {
        unified: '1F9DD-1F3FE-200D-2642-FE0F',
        sheet: [51, 29]
      },
      {
        unified: '1F9DD-1F3FF-200D-2642-FE0F',
        sheet: [51, 30]
      }
    ],
    shortName: 'male_elf'
  },
  {
    name: 'Woman Elf',
    unified: '1F9DD-200D-2640-FE0F',
    keywords: [
      'woman_elf',
      'woman',
      'female'
    ],
    sheet: [51, 19],
    skinVariations: [
      {
        unified: '1F9DD-1F3FB-200D-2640-FE0F',
        sheet: [51, 20]
      },
      {
        unified: '1F9DD-1F3FC-200D-2640-FE0F',
        sheet: [51, 21]
      },
      {
        unified: '1F9DD-1F3FD-200D-2640-FE0F',
        sheet: [51, 22]
      },
      {
        unified: '1F9DD-1F3FE-200D-2640-FE0F',
        sheet: [51, 23]
      },
      {
        unified: '1F9DD-1F3FF-200D-2640-FE0F',
        sheet: [51, 24]
      }
    ],
    shortName: 'female_elf'
  },
  {
    name: 'Genie',
    unified: '1F9DE',
    keywords: [
      'genie',
      'magical',
      'wishes'
    ],
    sheet: [51, 39],
    shortName: 'genie',
    obsoletedBy: '1F9DE-200D-2642-FE0F'
  },
  {
    name: 'Man Genie',
    unified: '1F9DE-200D-2642-FE0F',
    obsoletes: '1F9DE',
    keywords: [
      'man_genie',
      'man',
      'male'
    ],
    sheet: [51, 38],
    shortName: 'male_genie'
  },
  {
    name: 'Woman Genie',
    unified: '1F9DE-200D-2640-FE0F',
    keywords: [
      'woman_genie',
      'woman',
      'female'
    ],
    sheet: [51, 37],
    shortName: 'female_genie'
  },
  {
    name: 'Zombie',
    unified: '1F9DF',
    keywords: ['zombie', 'dead'],
    sheet: [51, 42],
    shortName: 'zombie',
    obsoletedBy: '1F9DF-200D-2642-FE0F'
  },
  {
    name: 'Man Zombie',
    unified: '1F9DF-200D-2642-FE0F',
    obsoletes: '1F9DF',
    keywords: [
      'man_zombie',
      'man',
      'male',
      'dracula',
      'undead',
      'walking dead'
    ],
    sheet: [51, 41],
    shortName: 'male_zombie'
  },
  {
    name: 'Woman Zombie',
    unified: '1F9DF-200D-2640-FE0F',
    keywords: [
      'woman_zombie',
      'woman',
      'female',
      'undead',
      'walking dead'
    ],
    sheet: [51, 40],
    shortName: 'female_zombie'
  },
  {
    name: 'Face Massage',
    unified: '1F486',
    keywords: [
      'person_getting_massage',
      'relax'
    ],
    sheet: [24, 54],
    skinVariations: [
      {
        unified: '1F486-1F3FB',
        sheet: [24, 55]
      },
      {
        unified: '1F486-1F3FC',
        sheet: [24, 56]
      },
      {
        unified: '1F486-1F3FD',
        sheet: [24, 57]
      },
      {
        unified: '1F486-1F3FE',
        sheet: [25, 0]
      },
      {
        unified: '1F486-1F3FF',
        sheet: [25, 1]
      }
    ],
    shortName: 'massage',
    obsoletedBy: '1F486-200D-2640-FE0F'
  },
  {
    name: 'Man Getting Massage',
    unified: '1F486-200D-2642-FE0F',
    keywords: [
      'man_getting_massage',
      'male',
      'boy',
      'man',
      'head'
    ],
    sheet: [24, 48],
    skinVariations: [
      {
        unified: '1F486-1F3FB-200D-2642-FE0F',
        sheet: [24, 49]
      },
      {
        unified: '1F486-1F3FC-200D-2642-FE0F',
        sheet: [24, 50]
      },
      {
        unified: '1F486-1F3FD-200D-2642-FE0F',
        sheet: [24, 51]
      },
      {
        unified: '1F486-1F3FE-200D-2642-FE0F',
        sheet: [24, 52]
      },
      {
        unified: '1F486-1F3FF-200D-2642-FE0F',
        sheet: [24, 53]
      }
    ],
    shortName: 'man-getting-massage'
  },
  {
    name: 'Woman Getting Massage',
    unified: '1F486-200D-2640-FE0F',
    obsoletes: '1F486',
    keywords: [
      'woman_getting_massage',
      'female',
      'girl',
      'woman',
      'head'
    ],
    sheet: [24, 42],
    skinVariations: [
      {
        unified: '1F486-1F3FB-200D-2640-FE0F',
        sheet: [24, 43]
      },
      {
        unified: '1F486-1F3FC-200D-2640-FE0F',
        sheet: [24, 44]
      },
      {
        unified: '1F486-1F3FD-200D-2640-FE0F',
        sheet: [24, 45]
      },
      {
        unified: '1F486-1F3FE-200D-2640-FE0F',
        sheet: [24, 46]
      },
      {
        unified: '1F486-1F3FF-200D-2640-FE0F',
        sheet: [24, 47]
      }
    ],
    shortName: 'woman-getting-massage'
  },
  {
    name: 'Haircut',
    unified: '1F487',
    keywords: [
      'person_getting_haircut',
      'hairstyle'
    ],
    sheet: [25, 14],
    skinVariations: [
      {
        unified: '1F487-1F3FB',
        sheet: [25, 15]
      },
      {
        unified: '1F487-1F3FC',
        sheet: [25, 16]
      },
      {
        unified: '1F487-1F3FD',
        sheet: [25, 17]
      },
      {
        unified: '1F487-1F3FE',
        sheet: [25, 18]
      },
      {
        unified: '1F487-1F3FF',
        sheet: [25, 19]
      }
    ],
    shortName: 'haircut',
    obsoletedBy: '1F487-200D-2640-FE0F'
  },
  {
    name: 'Man Getting Haircut',
    unified: '1F487-200D-2642-FE0F',
    keywords: [
      'man_getting_haircut',
      'male',
      'boy',
      'man'
    ],
    sheet: [25, 8],
    skinVariations: [
      {
        unified: '1F487-1F3FB-200D-2642-FE0F',
        sheet: [25, 9]
      },
      {
        unified: '1F487-1F3FC-200D-2642-FE0F',
        sheet: [25, 10]
      },
      {
        unified: '1F487-1F3FD-200D-2642-FE0F',
        sheet: [25, 11]
      },
      {
        unified: '1F487-1F3FE-200D-2642-FE0F',
        sheet: [25, 12]
      },
      {
        unified: '1F487-1F3FF-200D-2642-FE0F',
        sheet: [25, 13]
      }
    ],
    shortName: 'man-getting-haircut'
  },
  {
    name: 'Woman Getting Haircut',
    unified: '1F487-200D-2640-FE0F',
    obsoletes: '1F487',
    keywords: [
      'woman_getting_haircut',
      'female',
      'girl',
      'woman'
    ],
    sheet: [25, 2],
    skinVariations: [
      {
        unified: '1F487-1F3FB-200D-2640-FE0F',
        sheet: [25, 3]
      },
      {
        unified: '1F487-1F3FC-200D-2640-FE0F',
        sheet: [25, 4]
      },
      {
        unified: '1F487-1F3FD-200D-2640-FE0F',
        sheet: [25, 5]
      },
      {
        unified: '1F487-1F3FE-200D-2640-FE0F',
        sheet: [25, 6]
      },
      {
        unified: '1F487-1F3FF-200D-2640-FE0F',
        sheet: [25, 7]
      }
    ],
    shortName: 'woman-getting-haircut'
  },
  {
    name: 'Pedestrian',
    unified: '1F6B6',
    keywords: [
      'person_walking',
      'move'
    ],
    sheet: [35, 51],
    skinVariations: [
      {
        unified: '1F6B6-1F3FB',
        sheet: [35, 52]
      },
      {
        unified: '1F6B6-1F3FC',
        sheet: [35, 53]
      },
      {
        unified: '1F6B6-1F3FD',
        sheet: [35, 54]
      },
      {
        unified: '1F6B6-1F3FE',
        sheet: [35, 55]
      },
      {
        unified: '1F6B6-1F3FF',
        sheet: [35, 56]
      }
    ],
    shortName: 'walking',
    obsoletedBy: '1F6B6-200D-2642-FE0F'
  },
  {
    name: 'Man Walking',
    unified: '1F6B6-200D-2642-FE0F',
    obsoletes: '1F6B6',
    keywords: [
      'man_walking',
      'human',
      'feet',
      'steps'
    ],
    sheet: [35, 45],
    skinVariations: [
      {
        unified: '1F6B6-1F3FB-200D-2642-FE0F',
        sheet: [35, 46]
      },
      {
        unified: '1F6B6-1F3FC-200D-2642-FE0F',
        sheet: [35, 47]
      },
      {
        unified: '1F6B6-1F3FD-200D-2642-FE0F',
        sheet: [35, 48]
      },
      {
        unified: '1F6B6-1F3FE-200D-2642-FE0F',
        sheet: [35, 49]
      },
      {
        unified: '1F6B6-1F3FF-200D-2642-FE0F',
        sheet: [35, 50]
      }
    ],
    shortName: 'man-walking'
  },
  {
    name: 'Woman Walking',
    unified: '1F6B6-200D-2640-FE0F',
    keywords: [
      'woman_walking',
      'human',
      'feet',
      'steps',
      'woman',
      'female'
    ],
    sheet: [35, 39],
    skinVariations: [
      {
        unified: '1F6B6-1F3FB-200D-2640-FE0F',
        sheet: [35, 40]
      },
      {
        unified: '1F6B6-1F3FC-200D-2640-FE0F',
        sheet: [35, 41]
      },
      {
        unified: '1F6B6-1F3FD-200D-2640-FE0F',
        sheet: [35, 42]
      },
      {
        unified: '1F6B6-1F3FE-200D-2640-FE0F',
        sheet: [35, 43]
      },
      {
        unified: '1F6B6-1F3FF-200D-2640-FE0F',
        sheet: [35, 44]
      }
    ],
    shortName: 'woman-walking'
  },
  {
    name: 'Standing Person',
    unified: '1F9CD',
    keywords: [
      'person_standing',
      'still'
    ],
    sheet: [44, 50],
    skinVariations: [
      {
        unified: '1F9CD-1F3FB',
        sheet: [44, 51]
      },
      {
        unified: '1F9CD-1F3FC',
        sheet: [44, 52]
      },
      {
        unified: '1F9CD-1F3FD',
        sheet: [44, 53]
      },
      {
        unified: '1F9CD-1F3FE',
        sheet: [44, 54]
      },
      {
        unified: '1F9CD-1F3FF',
        sheet: [44, 55]
      }
    ],
    shortName: 'standing_person'
  },
  {
    name: 'Man Standing',
    unified: '1F9CD-200D-2642-FE0F',
    keywords: ['man_standing', 'still'],
    sheet: [44, 44],
    skinVariations: [
      {
        unified: '1F9CD-1F3FB-200D-2642-FE0F',
        sheet: [44, 45]
      },
      {
        unified: '1F9CD-1F3FC-200D-2642-FE0F',
        sheet: [44, 46]
      },
      {
        unified: '1F9CD-1F3FD-200D-2642-FE0F',
        sheet: [44, 47]
      },
      {
        unified: '1F9CD-1F3FE-200D-2642-FE0F',
        sheet: [44, 48]
      },
      {
        unified: '1F9CD-1F3FF-200D-2642-FE0F',
        sheet: [44, 49]
      }
    ],
    shortName: 'man_standing'
  },
  {
    name: 'Woman Standing',
    unified: '1F9CD-200D-2640-FE0F',
    keywords: [
      'woman_standing',
      'still'
    ],
    sheet: [44, 38],
    skinVariations: [
      {
        unified: '1F9CD-1F3FB-200D-2640-FE0F',
        sheet: [44, 39]
      },
      {
        unified: '1F9CD-1F3FC-200D-2640-FE0F',
        sheet: [44, 40]
      },
      {
        unified: '1F9CD-1F3FD-200D-2640-FE0F',
        sheet: [44, 41]
      },
      {
        unified: '1F9CD-1F3FE-200D-2640-FE0F',
        sheet: [44, 42]
      },
      {
        unified: '1F9CD-1F3FF-200D-2640-FE0F',
        sheet: [44, 43]
      }
    ],
    shortName: 'woman_standing'
  },
  {
    name: 'Kneeling Person',
    unified: '1F9CE',
    keywords: [
      'person_kneeling',
      'pray',
      'respectful'
    ],
    sheet: [45, 10],
    skinVariations: [
      {
        unified: '1F9CE-1F3FB',
        sheet: [45, 11]
      },
      {
        unified: '1F9CE-1F3FC',
        sheet: [45, 12]
      },
      {
        unified: '1F9CE-1F3FD',
        sheet: [45, 13]
      },
      {
        unified: '1F9CE-1F3FE',
        sheet: [45, 14]
      },
      {
        unified: '1F9CE-1F3FF',
        sheet: [45, 15]
      }
    ],
    shortName: 'kneeling_person'
  },
  {
    name: 'Man Kneeling',
    unified: '1F9CE-200D-2642-FE0F',
    keywords: [
      'man_kneeling',
      'pray',
      'respectful'
    ],
    sheet: [45, 4],
    skinVariations: [
      {
        unified: '1F9CE-1F3FB-200D-2642-FE0F',
        sheet: [45, 5]
      },
      {
        unified: '1F9CE-1F3FC-200D-2642-FE0F',
        sheet: [45, 6]
      },
      {
        unified: '1F9CE-1F3FD-200D-2642-FE0F',
        sheet: [45, 7]
      },
      {
        unified: '1F9CE-1F3FE-200D-2642-FE0F',
        sheet: [45, 8]
      },
      {
        unified: '1F9CE-1F3FF-200D-2642-FE0F',
        sheet: [45, 9]
      }
    ],
    shortName: 'man_kneeling'
  },
  {
    name: 'Woman Kneeling',
    unified: '1F9CE-200D-2640-FE0F',
    keywords: [
      'woman_kneeling',
      'respectful',
      'pray'
    ],
    sheet: [44, 56],
    skinVariations: [
      {
        unified: '1F9CE-1F3FB-200D-2640-FE0F',
        sheet: [44, 57]
      },
      {
        unified: '1F9CE-1F3FC-200D-2640-FE0F',
        sheet: [45, 0]
      },
      {
        unified: '1F9CE-1F3FD-200D-2640-FE0F',
        sheet: [45, 1]
      },
      {
        unified: '1F9CE-1F3FE-200D-2640-FE0F',
        sheet: [45, 2]
      },
      {
        unified: '1F9CE-1F3FF-200D-2640-FE0F',
        sheet: [45, 3]
      }
    ],
    shortName: 'woman_kneeling'
  },
  {
    name: 'Person with White Cane',
    unified: '1F9D1-200D-1F9AF',
    keywords: [
      'person_with_probing_cane',
      'blind'
    ],
    sheet: [47, 35],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F9AF',
        sheet: [47, 36]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F9AF',
        sheet: [47, 37]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F9AF',
        sheet: [47, 38]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F9AF',
        sheet: [47, 39]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F9AF',
        sheet: [47, 40]
      }
    ],
    shortName: 'person_with_probing_cane'
  },
  {
    name: 'Man with White Cane',
    unified: '1F468-200D-1F9AF',
    keywords: [
      'man_with_probing_cane',
      'blind'
    ],
    sheet: [16, 10],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F9AF',
        sheet: [16, 11]
      },
      {
        unified: '1F468-1F3FC-200D-1F9AF',
        sheet: [16, 12]
      },
      {
        unified: '1F468-1F3FD-200D-1F9AF',
        sheet: [16, 13]
      },
      {
        unified: '1F468-1F3FE-200D-1F9AF',
        sheet: [16, 14]
      },
      {
        unified: '1F468-1F3FF-200D-1F9AF',
        sheet: [16, 15]
      }
    ],
    shortName: 'man_with_probing_cane'
  },
  {
    name: 'Woman with White Cane',
    unified: '1F469-200D-1F9AF',
    keywords: [
      'woman_with_probing_cane',
      'blind'
    ],
    sheet: [18, 56],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F9AF',
        sheet: [18, 57]
      },
      {
        unified: '1F469-1F3FC-200D-1F9AF',
        sheet: [19, 0]
      },
      {
        unified: '1F469-1F3FD-200D-1F9AF',
        sheet: [19, 1]
      },
      {
        unified: '1F469-1F3FE-200D-1F9AF',
        sheet: [19, 2]
      },
      {
        unified: '1F469-1F3FF-200D-1F9AF',
        sheet: [19, 3]
      }
    ],
    shortName: 'woman_with_probing_cane'
  },
  {
    name: 'Person in Motorized Wheelchair',
    unified: '1F9D1-200D-1F9BC',
    keywords: [
      'person_in_motorized_wheelchair',
      'disability',
      'accessibility'
    ],
    sheet: [48, 7],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F9BC',
        sheet: [48, 8]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F9BC',
        sheet: [48, 9]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F9BC',
        sheet: [48, 10]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F9BC',
        sheet: [48, 11]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F9BC',
        sheet: [48, 12]
      }
    ],
    shortName: 'person_in_motorized_wheelchair'
  },
  {
    name: 'Man in Motorized Wheelchair',
    unified: '1F468-200D-1F9BC',
    keywords: [
      'man_in_motorized_wheelchair',
      'disability',
      'accessibility'
    ],
    sheet: [16, 40],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F9BC',
        sheet: [16, 41]
      },
      {
        unified: '1F468-1F3FC-200D-1F9BC',
        sheet: [16, 42]
      },
      {
        unified: '1F468-1F3FD-200D-1F9BC',
        sheet: [16, 43]
      },
      {
        unified: '1F468-1F3FE-200D-1F9BC',
        sheet: [16, 44]
      },
      {
        unified: '1F468-1F3FF-200D-1F9BC',
        sheet: [16, 45]
      }
    ],
    shortName: 'man_in_motorized_wheelchair'
  },
  {
    name: 'Woman in Motorized Wheelchair',
    unified: '1F469-200D-1F9BC',
    keywords: [
      'woman_in_motorized_wheelchair',
      'disability',
      'accessibility'
    ],
    sheet: [19, 28],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F9BC',
        sheet: [19, 29]
      },
      {
        unified: '1F469-1F3FC-200D-1F9BC',
        sheet: [19, 30]
      },
      {
        unified: '1F469-1F3FD-200D-1F9BC',
        sheet: [19, 31]
      },
      {
        unified: '1F469-1F3FE-200D-1F9BC',
        sheet: [19, 32]
      },
      {
        unified: '1F469-1F3FF-200D-1F9BC',
        sheet: [19, 33]
      }
    ],
    shortName: 'woman_in_motorized_wheelchair'
  },
  {
    name: 'Person in Manual Wheelchair',
    unified: '1F9D1-200D-1F9BD',
    keywords: [
      'person_in_manual_wheelchair',
      'disability',
      'accessibility'
    ],
    sheet: [48, 13],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F9BD',
        sheet: [48, 14]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F9BD',
        sheet: [48, 15]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F9BD',
        sheet: [48, 16]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F9BD',
        sheet: [48, 17]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F9BD',
        sheet: [48, 18]
      }
    ],
    shortName: 'person_in_manual_wheelchair'
  },
  {
    name: 'Man in Manual Wheelchair',
    unified: '1F468-200D-1F9BD',
    keywords: [
      'man_in_manual_wheelchair',
      'disability',
      'accessibility'
    ],
    sheet: [16, 46],
    skinVariations: [
      {
        unified: '1F468-1F3FB-200D-1F9BD',
        sheet: [16, 47]
      },
      {
        unified: '1F468-1F3FC-200D-1F9BD',
        sheet: [16, 48]
      },
      {
        unified: '1F468-1F3FD-200D-1F9BD',
        sheet: [16, 49]
      },
      {
        unified: '1F468-1F3FE-200D-1F9BD',
        sheet: [16, 50]
      },
      {
        unified: '1F468-1F3FF-200D-1F9BD',
        sheet: [16, 51]
      }
    ],
    shortName: 'man_in_manual_wheelchair'
  },
  {
    name: 'Woman in Manual Wheelchair',
    unified: '1F469-200D-1F9BD',
    keywords: [
      'woman_in_manual_wheelchair',
      'disability',
      'accessibility'
    ],
    sheet: [19, 34],
    skinVariations: [
      {
        unified: '1F469-1F3FB-200D-1F9BD',
        sheet: [19, 35]
      },
      {
        unified: '1F469-1F3FC-200D-1F9BD',
        sheet: [19, 36]
      },
      {
        unified: '1F469-1F3FD-200D-1F9BD',
        sheet: [19, 37]
      },
      {
        unified: '1F469-1F3FE-200D-1F9BD',
        sheet: [19, 38]
      },
      {
        unified: '1F469-1F3FF-200D-1F9BD',
        sheet: [19, 39]
      }
    ],
    shortName: 'woman_in_manual_wheelchair'
  },
  {
    name: 'Runner',
    unified: '1F3C3',
    keywords: [
      'person_running',
      'move'
    ],
    sheet: [8, 50],
    skinVariations: [
      {
        unified: '1F3C3-1F3FB',
        sheet: [8, 51]
      },
      {
        unified: '1F3C3-1F3FC',
        sheet: [8, 52]
      },
      {
        unified: '1F3C3-1F3FD',
        sheet: [8, 53]
      },
      {
        unified: '1F3C3-1F3FE',
        sheet: [8, 54]
      },
      {
        unified: '1F3C3-1F3FF',
        sheet: [8, 55]
      }
    ],
    shortNames: ['running'],
    shortName: 'runner',
    obsoletedBy: '1F3C3-200D-2642-FE0F'
  },
  {
    name: 'Man Running',
    unified: '1F3C3-200D-2642-FE0F',
    obsoletes: '1F3C3',
    keywords: [
      'man_running',
      'man',
      'walking',
      'exercise',
      'race',
      'running'
    ],
    sheet: [8, 44],
    skinVariations: [
      {
        unified: '1F3C3-1F3FB-200D-2642-FE0F',
        sheet: [8, 45]
      },
      {
        unified: '1F3C3-1F3FC-200D-2642-FE0F',
        sheet: [8, 46]
      },
      {
        unified: '1F3C3-1F3FD-200D-2642-FE0F',
        sheet: [8, 47]
      },
      {
        unified: '1F3C3-1F3FE-200D-2642-FE0F',
        sheet: [8, 48]
      },
      {
        unified: '1F3C3-1F3FF-200D-2642-FE0F',
        sheet: [8, 49]
      }
    ],
    shortName: 'man-running'
  },
  {
    name: 'Woman Running',
    unified: '1F3C3-200D-2640-FE0F',
    keywords: [
      'woman_running',
      'woman',
      'walking',
      'exercise',
      'race',
      'running',
      'female'
    ],
    sheet: [8, 38],
    skinVariations: [
      {
        unified: '1F3C3-1F3FB-200D-2640-FE0F',
        sheet: [8, 39]
      },
      {
        unified: '1F3C3-1F3FC-200D-2640-FE0F',
        sheet: [8, 40]
      },
      {
        unified: '1F3C3-1F3FD-200D-2640-FE0F',
        sheet: [8, 41]
      },
      {
        unified: '1F3C3-1F3FE-200D-2640-FE0F',
        sheet: [8, 42]
      },
      {
        unified: '1F3C3-1F3FF-200D-2640-FE0F',
        sheet: [8, 43]
      }
    ],
    shortName: 'woman-running'
  },
  {
    name: 'Dancer',
    unified: '1F483',
    keywords: [
      'woman_dancing',
      'female',
      'girl',
      'woman',
      'fun'
    ],
    sheet: [24, 29],
    skinVariations: [
      {
        unified: '1F483-1F3FB',
        sheet: [24, 30]
      },
      {
        unified: '1F483-1F3FC',
        sheet: [24, 31]
      },
      {
        unified: '1F483-1F3FD',
        sheet: [24, 32]
      },
      {
        unified: '1F483-1F3FE',
        sheet: [24, 33]
      },
      {
        unified: '1F483-1F3FF',
        sheet: [24, 34]
      }
    ],
    shortName: 'dancer'
  },
  {
    name: 'Man Dancing',
    unified: '1F57A',
    keywords: [
      'man_dancing',
      'male',
      'boy',
      'fun',
      'dancer'
    ],
    sheet: [29, 35],
    skinVariations: [
      {
        unified: '1F57A-1F3FB',
        sheet: [29, 36]
      },
      {
        unified: '1F57A-1F3FC',
        sheet: [29, 37]
      },
      {
        unified: '1F57A-1F3FD',
        sheet: [29, 38]
      },
      {
        unified: '1F57A-1F3FE',
        sheet: [29, 39]
      },
      {
        unified: '1F57A-1F3FF',
        sheet: [29, 40]
      }
    ],
    shortName: 'man_dancing'
  },
  {
    name: 'Person in Suit Levitating',
    unified: '1F574-FE0F',
    keywords: [
      'man_in_suit_levitating',
      'suit',
      'business',
      'levitate',
      'hover',
      'jump'
    ],
    sheet: [29, 7],
    skinVariations: [
      {
        unified: '1F574-1F3FB',
        sheet: [29, 8]
      },
      {
        unified: '1F574-1F3FC',
        sheet: [29, 9]
      },
      {
        unified: '1F574-1F3FD',
        sheet: [29, 10]
      },
      {
        unified: '1F574-1F3FE',
        sheet: [29, 11]
      },
      {
        unified: '1F574-1F3FF',
        sheet: [29, 12]
      }
    ],
    shortName: 'man_in_business_suit_levitating'
  },
  {
    name: 'Woman with Bunny Ears',
    unified: '1F46F',
    keywords: [
      'people_with_bunny_ears',
      'perform',
      'costume'
    ],
    sheet: [21, 51],
    shortName: 'dancers',
    obsoletedBy: '1F46F-200D-2640-FE0F'
  },
  {
    name: 'Men with Bunny Ears',
    unified: '1F46F-200D-2642-FE0F',
    keywords: [
      'men_with_bunny_ears',
      'male',
      'bunny',
      'men',
      'boys'
    ],
    sheet: [21, 50],
    shortName: 'man-with-bunny-ears-partying'
  },
  {
    name: 'Women with Bunny Ears',
    unified: '1F46F-200D-2640-FE0F',
    obsoletes: '1F46F',
    keywords: [
      'women_with_bunny_ears',
      'female',
      'bunny',
      'women',
      'girls'
    ],
    sheet: [21, 49],
    shortName: 'woman-with-bunny-ears-partying'
  },
  {
    name: 'Person in Steamy Room',
    unified: '1F9D6',
    keywords: [
      'person_in_steamy_room',
      'relax',
      'spa'
    ],
    sheet: [49, 21],
    skinVariations: [
      {
        unified: '1F9D6-1F3FB',
        sheet: [49, 22]
      },
      {
        unified: '1F9D6-1F3FC',
        sheet: [49, 23]
      },
      {
        unified: '1F9D6-1F3FD',
        sheet: [49, 24]
      },
      {
        unified: '1F9D6-1F3FE',
        sheet: [49, 25]
      },
      {
        unified: '1F9D6-1F3FF',
        sheet: [49, 26]
      }
    ],
    shortName: 'person_in_steamy_room',
    obsoletedBy: '1F9D6-200D-2642-FE0F'
  },
  {
    name: 'Man in Steamy Room',
    unified: '1F9D6-200D-2642-FE0F',
    obsoletes: '1F9D6',
    keywords: [
      'man_in_steamy_room',
      'male',
      'man',
      'spa',
      'steamroom',
      'sauna'
    ],
    sheet: [49, 15],
    skinVariations: [
      {
        unified: '1F9D6-1F3FB-200D-2642-FE0F',
        sheet: [49, 16]
      },
      {
        unified: '1F9D6-1F3FC-200D-2642-FE0F',
        sheet: [49, 17]
      },
      {
        unified: '1F9D6-1F3FD-200D-2642-FE0F',
        sheet: [49, 18]
      },
      {
        unified: '1F9D6-1F3FE-200D-2642-FE0F',
        sheet: [49, 19]
      },
      {
        unified: '1F9D6-1F3FF-200D-2642-FE0F',
        sheet: [49, 20]
      }
    ],
    shortName: 'man_in_steamy_room'
  },
  {
    name: 'Woman in Steamy Room',
    unified: '1F9D6-200D-2640-FE0F',
    keywords: [
      'woman_in_steamy_room',
      'female',
      'woman',
      'spa',
      'steamroom',
      'sauna'
    ],
    sheet: [49, 9],
    skinVariations: [
      {
        unified: '1F9D6-1F3FB-200D-2640-FE0F',
        sheet: [49, 10]
      },
      {
        unified: '1F9D6-1F3FC-200D-2640-FE0F',
        sheet: [49, 11]
      },
      {
        unified: '1F9D6-1F3FD-200D-2640-FE0F',
        sheet: [49, 12]
      },
      {
        unified: '1F9D6-1F3FE-200D-2640-FE0F',
        sheet: [49, 13]
      },
      {
        unified: '1F9D6-1F3FF-200D-2640-FE0F',
        sheet: [49, 14]
      }
    ],
    shortName: 'woman_in_steamy_room'
  },
  {
    name: 'Person Climbing',
    unified: '1F9D7',
    keywords: [
      'person_climbing',
      'sport'
    ],
    sheet: [49, 39],
    skinVariations: [
      {
        unified: '1F9D7-1F3FB',
        sheet: [49, 40]
      },
      {
        unified: '1F9D7-1F3FC',
        sheet: [49, 41]
      },
      {
        unified: '1F9D7-1F3FD',
        sheet: [49, 42]
      },
      {
        unified: '1F9D7-1F3FE',
        sheet: [49, 43]
      },
      {
        unified: '1F9D7-1F3FF',
        sheet: [49, 44]
      }
    ],
    shortName: 'person_climbing',
    obsoletedBy: '1F9D7-200D-2640-FE0F'
  },
  {
    name: 'Man Climbing',
    unified: '1F9D7-200D-2642-FE0F',
    keywords: [
      'man_climbing',
      'sports',
      'hobby',
      'man',
      'male',
      'rock'
    ],
    sheet: [49, 33],
    skinVariations: [
      {
        unified: '1F9D7-1F3FB-200D-2642-FE0F',
        sheet: [49, 34]
      },
      {
        unified: '1F9D7-1F3FC-200D-2642-FE0F',
        sheet: [49, 35]
      },
      {
        unified: '1F9D7-1F3FD-200D-2642-FE0F',
        sheet: [49, 36]
      },
      {
        unified: '1F9D7-1F3FE-200D-2642-FE0F',
        sheet: [49, 37]
      },
      {
        unified: '1F9D7-1F3FF-200D-2642-FE0F',
        sheet: [49, 38]
      }
    ],
    shortName: 'man_climbing'
  },
  {
    name: 'Woman Climbing',
    unified: '1F9D7-200D-2640-FE0F',
    obsoletes: '1F9D7',
    keywords: [
      'woman_climbing',
      'sports',
      'hobby',
      'woman',
      'female',
      'rock'
    ],
    sheet: [49, 27],
    skinVariations: [
      {
        unified: '1F9D7-1F3FB-200D-2640-FE0F',
        sheet: [49, 28]
      },
      {
        unified: '1F9D7-1F3FC-200D-2640-FE0F',
        sheet: [49, 29]
      },
      {
        unified: '1F9D7-1F3FD-200D-2640-FE0F',
        sheet: [49, 30]
      },
      {
        unified: '1F9D7-1F3FE-200D-2640-FE0F',
        sheet: [49, 31]
      },
      {
        unified: '1F9D7-1F3FF-200D-2640-FE0F',
        sheet: [49, 32]
      }
    ],
    shortName: 'woman_climbing'
  },
  {
    name: 'Fencer',
    unified: '1F93A',
    keywords: [
      'person_fencing',
      'sports',
      'fencing',
      'sword'
    ],
    sheet: [40, 41],
    shortName: 'fencer'
  },
  {
    name: 'Horse Racing',
    unified: '1F3C7',
    keywords: [
      'horse_racing',
      'animal',
      'betting',
      'competition',
      'gambling',
      'luck'
    ],
    sheet: [9, 18],
    skinVariations: [
      {
        unified: '1F3C7-1F3FB',
        sheet: [9, 19]
      },
      {
        unified: '1F3C7-1F3FC',
        sheet: [9, 20]
      },
      {
        unified: '1F3C7-1F3FD',
        sheet: [9, 21]
      },
      {
        unified: '1F3C7-1F3FE',
        sheet: [9, 22]
      },
      {
        unified: '1F3C7-1F3FF',
        sheet: [9, 23]
      }
    ],
    shortName: 'horse_racing'
  },
  {
    name: 'Skier',
    unified: '26F7-FE0F',
    keywords: [
      'skier',
      'sports',
      'winter',
      'snow'
    ],
    sheet: [55, 22],
    shortName: 'skier'
  },
  {
    name: 'Snowboarder',
    unified: '1F3C2',
    keywords: [
      'snowboarder',
      'sports',
      'winter'
    ],
    sheet: [8, 32],
    skinVariations: [
      {
        unified: '1F3C2-1F3FB',
        sheet: [8, 33]
      },
      {
        unified: '1F3C2-1F3FC',
        sheet: [8, 34]
      },
      {
        unified: '1F3C2-1F3FD',
        sheet: [8, 35]
      },
      {
        unified: '1F3C2-1F3FE',
        sheet: [8, 36]
      },
      {
        unified: '1F3C2-1F3FF',
        sheet: [8, 37]
      }
    ],
    shortName: 'snowboarder'
  },
  {
    name: 'Person Golfing',
    unified: '1F3CC-FE0F',
    keywords: [
      'person_golfing',
      'sports',
      'business'
    ],
    sheet: [10, 16],
    skinVariations: [
      {
        unified: '1F3CC-1F3FB',
        sheet: [10, 17]
      },
      {
        unified: '1F3CC-1F3FC',
        sheet: [10, 18]
      },
      {
        unified: '1F3CC-1F3FD',
        sheet: [10, 19]
      },
      {
        unified: '1F3CC-1F3FE',
        sheet: [10, 20]
      },
      {
        unified: '1F3CC-1F3FF',
        sheet: [10, 21]
      }
    ],
    shortName: 'golfer',
    obsoletedBy: '1F3CC-FE0F-200D-2642-FE0F'
  },
  {
    name: 'Man Golfing',
    unified: '1F3CC-FE0F-200D-2642-FE0F',
    obsoletes: '1F3CC-FE0F',
    keywords: ['man_golfing', 'sport'],
    sheet: [10, 10],
    hidden: ['facebook'],
    skinVariations: [
      {
        unified: '1F3CC-1F3FB-200D-2642-FE0F',
        sheet: [10, 11]
      },
      {
        unified: '1F3CC-1F3FC-200D-2642-FE0F',
        sheet: [10, 12]
      },
      {
        unified: '1F3CC-1F3FD-200D-2642-FE0F',
        sheet: [10, 13]
      },
      {
        unified: '1F3CC-1F3FE-200D-2642-FE0F',
        sheet: [10, 14]
      },
      {
        unified: '1F3CC-1F3FF-200D-2642-FE0F',
        sheet: [10, 15]
      }
    ],
    shortName: 'man-golfing'
  },
  {
    name: 'Woman Golfing',
    unified: '1F3CC-FE0F-200D-2640-FE0F',
    keywords: [
      'woman_golfing',
      'sports',
      'business',
      'woman',
      'female'
    ],
    sheet: [10, 4],
    hidden: ['facebook'],
    skinVariations: [
      {
        unified: '1F3CC-1F3FB-200D-2640-FE0F',
        sheet: [10, 5]
      },
      {
        unified: '1F3CC-1F3FC-200D-2640-FE0F',
        sheet: [10, 6]
      },
      {
        unified: '1F3CC-1F3FD-200D-2640-FE0F',
        sheet: [10, 7]
      },
      {
        unified: '1F3CC-1F3FE-200D-2640-FE0F',
        sheet: [10, 8]
      },
      {
        unified: '1F3CC-1F3FF-200D-2640-FE0F',
        sheet: [10, 9]
      }
    ],
    shortName: 'woman-golfing'
  },
  {
    name: 'Surfer',
    unified: '1F3C4',
    keywords: [
      'person_surfing',
      'sport',
      'sea'
    ],
    sheet: [9, 10],
    skinVariations: [
      {
        unified: '1F3C4-1F3FB',
        sheet: [9, 11]
      },
      {
        unified: '1F3C4-1F3FC',
        sheet: [9, 12]
      },
      {
        unified: '1F3C4-1F3FD',
        sheet: [9, 13]
      },
      {
        unified: '1F3C4-1F3FE',
        sheet: [9, 14]
      },
      {
        unified: '1F3C4-1F3FF',
        sheet: [9, 15]
      }
    ],
    shortName: 'surfer',
    obsoletedBy: '1F3C4-200D-2642-FE0F'
  },
  {
    name: 'Man Surfing',
    unified: '1F3C4-200D-2642-FE0F',
    obsoletes: '1F3C4',
    keywords: [
      'man_surfing',
      'sports',
      'ocean',
      'sea',
      'summer',
      'beach'
    ],
    sheet: [9, 4],
    skinVariations: [
      {
        unified: '1F3C4-1F3FB-200D-2642-FE0F',
        sheet: [9, 5]
      },
      {
        unified: '1F3C4-1F3FC-200D-2642-FE0F',
        sheet: [9, 6]
      },
      {
        unified: '1F3C4-1F3FD-200D-2642-FE0F',
        sheet: [9, 7]
      },
      {
        unified: '1F3C4-1F3FE-200D-2642-FE0F',
        sheet: [9, 8]
      },
      {
        unified: '1F3C4-1F3FF-200D-2642-FE0F',
        sheet: [9, 9]
      }
    ],
    shortName: 'man-surfing'
  },
  {
    name: 'Woman Surfing',
    unified: '1F3C4-200D-2640-FE0F',
    keywords: [
      'woman_surfing',
      'sports',
      'ocean',
      'sea',
      'summer',
      'beach',
      'woman',
      'female'
    ],
    sheet: [8, 56],
    skinVariations: [
      {
        unified: '1F3C4-1F3FB-200D-2640-FE0F',
        sheet: [8, 57]
      },
      {
        unified: '1F3C4-1F3FC-200D-2640-FE0F',
        sheet: [9, 0]
      },
      {
        unified: '1F3C4-1F3FD-200D-2640-FE0F',
        sheet: [9, 1]
      },
      {
        unified: '1F3C4-1F3FE-200D-2640-FE0F',
        sheet: [9, 2]
      },
      {
        unified: '1F3C4-1F3FF-200D-2640-FE0F',
        sheet: [9, 3]
      }
    ],
    shortName: 'woman-surfing'
  },
  {
    name: 'Rowboat',
    unified: '1F6A3',
    keywords: [
      'person_rowing_boat',
      'sport',
      'move'
    ],
    sheet: [34, 39],
    skinVariations: [
      {
        unified: '1F6A3-1F3FB',
        sheet: [34, 40]
      },
      {
        unified: '1F6A3-1F3FC',
        sheet: [34, 41]
      },
      {
        unified: '1F6A3-1F3FD',
        sheet: [34, 42]
      },
      {
        unified: '1F6A3-1F3FE',
        sheet: [34, 43]
      },
      {
        unified: '1F6A3-1F3FF',
        sheet: [34, 44]
      }
    ],
    shortName: 'rowboat',
    obsoletedBy: '1F6A3-200D-2642-FE0F'
  },
  {
    name: 'Man Rowing Boat',
    unified: '1F6A3-200D-2642-FE0F',
    obsoletes: '1F6A3',
    keywords: [
      'man_rowing_boat',
      'sports',
      'hobby',
      'water',
      'ship'
    ],
    sheet: [34, 33],
    skinVariations: [
      {
        unified: '1F6A3-1F3FB-200D-2642-FE0F',
        sheet: [34, 34]
      },
      {
        unified: '1F6A3-1F3FC-200D-2642-FE0F',
        sheet: [34, 35]
      },
      {
        unified: '1F6A3-1F3FD-200D-2642-FE0F',
        sheet: [34, 36]
      },
      {
        unified: '1F6A3-1F3FE-200D-2642-FE0F',
        sheet: [34, 37]
      },
      {
        unified: '1F6A3-1F3FF-200D-2642-FE0F',
        sheet: [34, 38]
      }
    ],
    shortName: 'man-rowing-boat'
  },
  {
    name: 'Woman Rowing Boat',
    unified: '1F6A3-200D-2640-FE0F',
    keywords: [
      'woman_rowing_boat',
      'sports',
      'hobby',
      'water',
      'ship',
      'woman',
      'female'
    ],
    sheet: [34, 27],
    skinVariations: [
      {
        unified: '1F6A3-1F3FB-200D-2640-FE0F',
        sheet: [34, 28]
      },
      {
        unified: '1F6A3-1F3FC-200D-2640-FE0F',
        sheet: [34, 29]
      },
      {
        unified: '1F6A3-1F3FD-200D-2640-FE0F',
        sheet: [34, 30]
      },
      {
        unified: '1F6A3-1F3FE-200D-2640-FE0F',
        sheet: [34, 31]
      },
      {
        unified: '1F6A3-1F3FF-200D-2640-FE0F',
        sheet: [34, 32]
      }
    ],
    shortName: 'woman-rowing-boat'
  },
  {
    name: 'Swimmer',
    unified: '1F3CA',
    keywords: [
      'person_swimming',
      'sport',
      'pool'
    ],
    sheet: [9, 38],
    skinVariations: [
      {
        unified: '1F3CA-1F3FB',
        sheet: [9, 39]
      },
      {
        unified: '1F3CA-1F3FC',
        sheet: [9, 40]
      },
      {
        unified: '1F3CA-1F3FD',
        sheet: [9, 41]
      },
      {
        unified: '1F3CA-1F3FE',
        sheet: [9, 42]
      },
      {
        unified: '1F3CA-1F3FF',
        sheet: [9, 43]
      }
    ],
    shortName: 'swimmer',
    obsoletedBy: '1F3CA-200D-2642-FE0F'
  },
  {
    name: 'Man Swimming',
    unified: '1F3CA-200D-2642-FE0F',
    obsoletes: '1F3CA',
    keywords: [
      'man_swimming',
      'sports',
      'exercise',
      'human',
      'athlete',
      'water',
      'summer'
    ],
    sheet: [9, 32],
    skinVariations: [
      {
        unified: '1F3CA-1F3FB-200D-2642-FE0F',
        sheet: [9, 33]
      },
      {
        unified: '1F3CA-1F3FC-200D-2642-FE0F',
        sheet: [9, 34]
      },
      {
        unified: '1F3CA-1F3FD-200D-2642-FE0F',
        sheet: [9, 35]
      },
      {
        unified: '1F3CA-1F3FE-200D-2642-FE0F',
        sheet: [9, 36]
      },
      {
        unified: '1F3CA-1F3FF-200D-2642-FE0F',
        sheet: [9, 37]
      }
    ],
    shortName: 'man-swimming'
  },
  {
    name: 'Woman Swimming',
    unified: '1F3CA-200D-2640-FE0F',
    keywords: [
      'woman_swimming',
      'sports',
      'exercise',
      'human',
      'athlete',
      'water',
      'summer',
      'woman',
      'female'
    ],
    sheet: [9, 26],
    skinVariations: [
      {
        unified: '1F3CA-1F3FB-200D-2640-FE0F',
        sheet: [9, 27]
      },
      {
        unified: '1F3CA-1F3FC-200D-2640-FE0F',
        sheet: [9, 28]
      },
      {
        unified: '1F3CA-1F3FD-200D-2640-FE0F',
        sheet: [9, 29]
      },
      {
        unified: '1F3CA-1F3FE-200D-2640-FE0F',
        sheet: [9, 30]
      },
      {
        unified: '1F3CA-1F3FF-200D-2640-FE0F',
        sheet: [9, 31]
      }
    ],
    shortName: 'woman-swimming'
  },
  {
    name: 'Person Bouncing Ball',
    unified: '26F9-FE0F',
    keywords: [
      'person_bouncing_ball',
      'sports',
      'human'
    ],
    sheet: [55, 36],
    skinVariations: [
      {
        unified: '26F9-1F3FB',
        sheet: [55, 37]
      },
      {
        unified: '26F9-1F3FC',
        sheet: [55, 38]
      },
      {
        unified: '26F9-1F3FD',
        sheet: [55, 39]
      },
      {
        unified: '26F9-1F3FE',
        sheet: [55, 40]
      },
      {
        unified: '26F9-1F3FF',
        sheet: [55, 41]
      }
    ],
    shortName: 'person_with_ball',
    obsoletedBy: '26F9-FE0F-200D-2642-FE0F'
  },
  {
    name: 'Man Bouncing Ball',
    unified: '26F9-FE0F-200D-2642-FE0F',
    obsoletes: '26F9-FE0F',
    keywords: [
      'man_bouncing_ball',
      'sport'
    ],
    sheet: [55, 30],
    hidden: ['facebook'],
    skinVariations: [
      {
        unified: '26F9-1F3FB-200D-2642-FE0F',
        sheet: [55, 31]
      },
      {
        unified: '26F9-1F3FC-200D-2642-FE0F',
        sheet: [55, 32]
      },
      {
        unified: '26F9-1F3FD-200D-2642-FE0F',
        sheet: [55, 33]
      },
      {
        unified: '26F9-1F3FE-200D-2642-FE0F',
        sheet: [55, 34]
      },
      {
        unified: '26F9-1F3FF-200D-2642-FE0F',
        sheet: [55, 35]
      }
    ],
    shortName: 'man-bouncing-ball'
  },
  {
    name: 'Woman Bouncing Ball',
    unified: '26F9-FE0F-200D-2640-FE0F',
    keywords: [
      'woman_bouncing_ball',
      'sports',
      'human',
      'woman',
      'female'
    ],
    sheet: [55, 24],
    hidden: ['facebook'],
    skinVariations: [
      {
        unified: '26F9-1F3FB-200D-2640-FE0F',
        sheet: [55, 25]
      },
      {
        unified: '26F9-1F3FC-200D-2640-FE0F',
        sheet: [55, 26]
      },
      {
        unified: '26F9-1F3FD-200D-2640-FE0F',
        sheet: [55, 27]
      },
      {
        unified: '26F9-1F3FE-200D-2640-FE0F',
        sheet: [55, 28]
      },
      {
        unified: '26F9-1F3FF-200D-2640-FE0F',
        sheet: [55, 29]
      }
    ],
    shortName: 'woman-bouncing-ball'
  },
  {
    name: 'Person Lifting Weights',
    unified: '1F3CB-FE0F',
    keywords: [
      'person_lifting_weights',
      'sports',
      'training',
      'exercise'
    ],
    sheet: [9, 56],
    skinVariations: [
      {
        unified: '1F3CB-1F3FB',
        sheet: [9, 57]
      },
      {
        unified: '1F3CB-1F3FC',
        sheet: [10, 0]
      },
      {
        unified: '1F3CB-1F3FD',
        sheet: [10, 1]
      },
      {
        unified: '1F3CB-1F3FE',
        sheet: [10, 2]
      },
      {
        unified: '1F3CB-1F3FF',
        sheet: [10, 3]
      }
    ],
    shortName: 'weight_lifter',
    obsoletedBy: '1F3CB-FE0F-200D-2642-FE0F'
  },
  {
    name: 'Man Lifting Weights',
    unified: '1F3CB-FE0F-200D-2642-FE0F',
    obsoletes: '1F3CB-FE0F',
    keywords: [
      'man_lifting_weights',
      'sport'
    ],
    sheet: [9, 50],
    hidden: ['facebook'],
    skinVariations: [
      {
        unified: '1F3CB-1F3FB-200D-2642-FE0F',
        sheet: [9, 51]
      },
      {
        unified: '1F3CB-1F3FC-200D-2642-FE0F',
        sheet: [9, 52]
      },
      {
        unified: '1F3CB-1F3FD-200D-2642-FE0F',
        sheet: [9, 53]
      },
      {
        unified: '1F3CB-1F3FE-200D-2642-FE0F',
        sheet: [9, 54]
      },
      {
        unified: '1F3CB-1F3FF-200D-2642-FE0F',
        sheet: [9, 55]
      }
    ],
    shortName: 'man-lifting-weights'
  },
  {
    name: 'Woman Lifting Weights',
    unified: '1F3CB-FE0F-200D-2640-FE0F',
    keywords: [
      'woman_lifting_weights',
      'sports',
      'training',
      'exercise',
      'woman',
      'female'
    ],
    sheet: [9, 44],
    hidden: ['facebook'],
    skinVariations: [
      {
        unified: '1F3CB-1F3FB-200D-2640-FE0F',
        sheet: [9, 45]
      },
      {
        unified: '1F3CB-1F3FC-200D-2640-FE0F',
        sheet: [9, 46]
      },
      {
        unified: '1F3CB-1F3FD-200D-2640-FE0F',
        sheet: [9, 47]
      },
      {
        unified: '1F3CB-1F3FE-200D-2640-FE0F',
        sheet: [9, 48]
      },
      {
        unified: '1F3CB-1F3FF-200D-2640-FE0F',
        sheet: [9, 49]
      }
    ],
    shortName: 'woman-lifting-weights'
  },
  {
    name: 'Bicyclist',
    unified: '1F6B4',
    keywords: [
      'person_biking',
      'sport',
      'move'
    ],
    sheet: [35, 15],
    skinVariations: [
      {
        unified: '1F6B4-1F3FB',
        sheet: [35, 16]
      },
      {
        unified: '1F6B4-1F3FC',
        sheet: [35, 17]
      },
      {
        unified: '1F6B4-1F3FD',
        sheet: [35, 18]
      },
      {
        unified: '1F6B4-1F3FE',
        sheet: [35, 19]
      },
      {
        unified: '1F6B4-1F3FF',
        sheet: [35, 20]
      }
    ],
    shortName: 'bicyclist',
    obsoletedBy: '1F6B4-200D-2642-FE0F'
  },
  {
    name: 'Man Biking',
    unified: '1F6B4-200D-2642-FE0F',
    obsoletes: '1F6B4',
    keywords: [
      'man_biking',
      'sports',
      'bike',
      'exercise',
      'hipster'
    ],
    sheet: [35, 9],
    skinVariations: [
      {
        unified: '1F6B4-1F3FB-200D-2642-FE0F',
        sheet: [35, 10]
      },
      {
        unified: '1F6B4-1F3FC-200D-2642-FE0F',
        sheet: [35, 11]
      },
      {
        unified: '1F6B4-1F3FD-200D-2642-FE0F',
        sheet: [35, 12]
      },
      {
        unified: '1F6B4-1F3FE-200D-2642-FE0F',
        sheet: [35, 13]
      },
      {
        unified: '1F6B4-1F3FF-200D-2642-FE0F',
        sheet: [35, 14]
      }
    ],
    shortName: 'man-biking'
  },
  {
    name: 'Woman Biking',
    unified: '1F6B4-200D-2640-FE0F',
    keywords: [
      'woman_biking',
      'sports',
      'bike',
      'exercise',
      'hipster',
      'woman',
      'female'
    ],
    sheet: [35, 3],
    skinVariations: [
      {
        unified: '1F6B4-1F3FB-200D-2640-FE0F',
        sheet: [35, 4]
      },
      {
        unified: '1F6B4-1F3FC-200D-2640-FE0F',
        sheet: [35, 5]
      },
      {
        unified: '1F6B4-1F3FD-200D-2640-FE0F',
        sheet: [35, 6]
      },
      {
        unified: '1F6B4-1F3FE-200D-2640-FE0F',
        sheet: [35, 7]
      },
      {
        unified: '1F6B4-1F3FF-200D-2640-FE0F',
        sheet: [35, 8]
      }
    ],
    shortName: 'woman-biking'
  },
  {
    name: 'Mountain Bicyclist',
    unified: '1F6B5',
    keywords: [
      'person_mountain_biking',
      'sport',
      'move'
    ],
    sheet: [35, 33],
    skinVariations: [
      {
        unified: '1F6B5-1F3FB',
        sheet: [35, 34]
      },
      {
        unified: '1F6B5-1F3FC',
        sheet: [35, 35]
      },
      {
        unified: '1F6B5-1F3FD',
        sheet: [35, 36]
      },
      {
        unified: '1F6B5-1F3FE',
        sheet: [35, 37]
      },
      {
        unified: '1F6B5-1F3FF',
        sheet: [35, 38]
      }
    ],
    shortName: 'mountain_bicyclist',
    obsoletedBy: '1F6B5-200D-2642-FE0F'
  },
  {
    name: 'Man Mountain Biking',
    unified: '1F6B5-200D-2642-FE0F',
    obsoletes: '1F6B5',
    keywords: [
      'man_mountain_biking',
      'transportation',
      'sports',
      'human',
      'race',
      'bike'
    ],
    sheet: [35, 27],
    skinVariations: [
      {
        unified: '1F6B5-1F3FB-200D-2642-FE0F',
        sheet: [35, 28]
      },
      {
        unified: '1F6B5-1F3FC-200D-2642-FE0F',
        sheet: [35, 29]
      },
      {
        unified: '1F6B5-1F3FD-200D-2642-FE0F',
        sheet: [35, 30]
      },
      {
        unified: '1F6B5-1F3FE-200D-2642-FE0F',
        sheet: [35, 31]
      },
      {
        unified: '1F6B5-1F3FF-200D-2642-FE0F',
        sheet: [35, 32]
      }
    ],
    shortName: 'man-mountain-biking'
  },
  {
    name: 'Woman Mountain Biking',
    unified: '1F6B5-200D-2640-FE0F',
    keywords: [
      'woman_mountain_biking',
      'transportation',
      'sports',
      'human',
      'race',
      'bike',
      'woman',
      'female'
    ],
    sheet: [35, 21],
    skinVariations: [
      {
        unified: '1F6B5-1F3FB-200D-2640-FE0F',
        sheet: [35, 22]
      },
      {
        unified: '1F6B5-1F3FC-200D-2640-FE0F',
        sheet: [35, 23]
      },
      {
        unified: '1F6B5-1F3FD-200D-2640-FE0F',
        sheet: [35, 24]
      },
      {
        unified: '1F6B5-1F3FE-200D-2640-FE0F',
        sheet: [35, 25]
      },
      {
        unified: '1F6B5-1F3FF-200D-2640-FE0F',
        sheet: [35, 26]
      }
    ],
    shortName: 'woman-mountain-biking'
  },
  {
    name: 'Person Doing Cartwheel',
    unified: '1F938',
    keywords: [
      'person_cartwheeling',
      'sport',
      'gymnastic'
    ],
    sheet: [40, 17],
    skinVariations: [
      {
        unified: '1F938-1F3FB',
        sheet: [40, 18]
      },
      {
        unified: '1F938-1F3FC',
        sheet: [40, 19]
      },
      {
        unified: '1F938-1F3FD',
        sheet: [40, 20]
      },
      {
        unified: '1F938-1F3FE',
        sheet: [40, 21]
      },
      {
        unified: '1F938-1F3FF',
        sheet: [40, 22]
      }
    ],
    shortName: 'person_doing_cartwheel'
  },
  {
    name: 'Man Cartwheeling',
    unified: '1F938-200D-2642-FE0F',
    keywords: [
      'man_cartwheeling',
      'gymnastics'
    ],
    sheet: [40, 11],
    skinVariations: [
      {
        unified: '1F938-1F3FB-200D-2642-FE0F',
        sheet: [40, 12]
      },
      {
        unified: '1F938-1F3FC-200D-2642-FE0F',
        sheet: [40, 13]
      },
      {
        unified: '1F938-1F3FD-200D-2642-FE0F',
        sheet: [40, 14]
      },
      {
        unified: '1F938-1F3FE-200D-2642-FE0F',
        sheet: [40, 15]
      },
      {
        unified: '1F938-1F3FF-200D-2642-FE0F',
        sheet: [40, 16]
      }
    ],
    shortName: 'man-cartwheeling'
  },
  {
    name: 'Woman Cartwheeling',
    unified: '1F938-200D-2640-FE0F',
    keywords: [
      'woman_cartwheeling',
      'gymnastics'
    ],
    sheet: [40, 5],
    skinVariations: [
      {
        unified: '1F938-1F3FB-200D-2640-FE0F',
        sheet: [40, 6]
      },
      {
        unified: '1F938-1F3FC-200D-2640-FE0F',
        sheet: [40, 7]
      },
      {
        unified: '1F938-1F3FD-200D-2640-FE0F',
        sheet: [40, 8]
      },
      {
        unified: '1F938-1F3FE-200D-2640-FE0F',
        sheet: [40, 9]
      },
      {
        unified: '1F938-1F3FF-200D-2640-FE0F',
        sheet: [40, 10]
      }
    ],
    shortName: 'woman-cartwheeling'
  },
  {
    name: 'Wrestlers',
    unified: '1F93C',
    keywords: [
      'people_wrestling',
      'sport'
    ],
    sheet: [40, 44],
    shortName: 'wrestlers'
  },
  {
    name: 'Men Wrestling',
    unified: '1F93C-200D-2642-FE0F',
    keywords: [
      'men_wrestling',
      'sports',
      'wrestlers'
    ],
    sheet: [40, 43],
    shortName: 'man-wrestling'
  },
  {
    name: 'Women Wrestling',
    unified: '1F93C-200D-2640-FE0F',
    keywords: [
      'women_wrestling',
      'sports',
      'wrestlers'
    ],
    sheet: [40, 42],
    shortName: 'woman-wrestling'
  },
  {
    name: 'Water Polo',
    unified: '1F93D',
    keywords: [
      'person_playing_water_polo',
      'sport'
    ],
    sheet: [40, 57],
    skinVariations: [
      {
        unified: '1F93D-1F3FB',
        sheet: [41, 0]
      },
      {
        unified: '1F93D-1F3FC',
        sheet: [41, 1]
      },
      {
        unified: '1F93D-1F3FD',
        sheet: [41, 2]
      },
      {
        unified: '1F93D-1F3FE',
        sheet: [41, 3]
      },
      {
        unified: '1F93D-1F3FF',
        sheet: [41, 4]
      }
    ],
    shortName: 'water_polo'
  },
  {
    name: 'Man Playing Water Polo',
    unified: '1F93D-200D-2642-FE0F',
    keywords: [
      'man_playing_water_polo',
      'sports',
      'pool'
    ],
    sheet: [40, 51],
    skinVariations: [
      {
        unified: '1F93D-1F3FB-200D-2642-FE0F',
        sheet: [40, 52]
      },
      {
        unified: '1F93D-1F3FC-200D-2642-FE0F',
        sheet: [40, 53]
      },
      {
        unified: '1F93D-1F3FD-200D-2642-FE0F',
        sheet: [40, 54]
      },
      {
        unified: '1F93D-1F3FE-200D-2642-FE0F',
        sheet: [40, 55]
      },
      {
        unified: '1F93D-1F3FF-200D-2642-FE0F',
        sheet: [40, 56]
      }
    ],
    shortName: 'man-playing-water-polo'
  },
  {
    name: 'Woman Playing Water Polo',
    unified: '1F93D-200D-2640-FE0F',
    keywords: [
      'woman_playing_water_polo',
      'sports',
      'pool'
    ],
    sheet: [40, 45],
    skinVariations: [
      {
        unified: '1F93D-1F3FB-200D-2640-FE0F',
        sheet: [40, 46]
      },
      {
        unified: '1F93D-1F3FC-200D-2640-FE0F',
        sheet: [40, 47]
      },
      {
        unified: '1F93D-1F3FD-200D-2640-FE0F',
        sheet: [40, 48]
      },
      {
        unified: '1F93D-1F3FE-200D-2640-FE0F',
        sheet: [40, 49]
      },
      {
        unified: '1F93D-1F3FF-200D-2640-FE0F',
        sheet: [40, 50]
      }
    ],
    shortName: 'woman-playing-water-polo'
  },
  {
    name: 'Handball',
    unified: '1F93E',
    keywords: [
      'person_playing_handball',
      'sport'
    ],
    sheet: [41, 17],
    skinVariations: [
      {
        unified: '1F93E-1F3FB',
        sheet: [41, 18]
      },
      {
        unified: '1F93E-1F3FC',
        sheet: [41, 19]
      },
      {
        unified: '1F93E-1F3FD',
        sheet: [41, 20]
      },
      {
        unified: '1F93E-1F3FE',
        sheet: [41, 21]
      },
      {
        unified: '1F93E-1F3FF',
        sheet: [41, 22]
      }
    ],
    shortName: 'handball'
  },
  {
    name: 'Man Playing Handball',
    unified: '1F93E-200D-2642-FE0F',
    keywords: [
      'man_playing_handball',
      'sports'
    ],
    sheet: [41, 11],
    skinVariations: [
      {
        unified: '1F93E-1F3FB-200D-2642-FE0F',
        sheet: [41, 12]
      },
      {
        unified: '1F93E-1F3FC-200D-2642-FE0F',
        sheet: [41, 13]
      },
      {
        unified: '1F93E-1F3FD-200D-2642-FE0F',
        sheet: [41, 14]
      },
      {
        unified: '1F93E-1F3FE-200D-2642-FE0F',
        sheet: [41, 15]
      },
      {
        unified: '1F93E-1F3FF-200D-2642-FE0F',
        sheet: [41, 16]
      }
    ],
    shortName: 'man-playing-handball'
  },
  {
    name: 'Woman Playing Handball',
    unified: '1F93E-200D-2640-FE0F',
    keywords: [
      'woman_playing_handball',
      'sports'
    ],
    sheet: [41, 5],
    skinVariations: [
      {
        unified: '1F93E-1F3FB-200D-2640-FE0F',
        sheet: [41, 6]
      },
      {
        unified: '1F93E-1F3FC-200D-2640-FE0F',
        sheet: [41, 7]
      },
      {
        unified: '1F93E-1F3FD-200D-2640-FE0F',
        sheet: [41, 8]
      },
      {
        unified: '1F93E-1F3FE-200D-2640-FE0F',
        sheet: [41, 9]
      },
      {
        unified: '1F93E-1F3FF-200D-2640-FE0F',
        sheet: [41, 10]
      }
    ],
    shortName: 'woman-playing-handball'
  },
  {
    name: 'Juggling',
    unified: '1F939',
    keywords: [
      'person_juggling',
      'performance',
      'balance'
    ],
    sheet: [40, 35],
    skinVariations: [
      {
        unified: '1F939-1F3FB',
        sheet: [40, 36]
      },
      {
        unified: '1F939-1F3FC',
        sheet: [40, 37]
      },
      {
        unified: '1F939-1F3FD',
        sheet: [40, 38]
      },
      {
        unified: '1F939-1F3FE',
        sheet: [40, 39]
      },
      {
        unified: '1F939-1F3FF',
        sheet: [40, 40]
      }
    ],
    shortName: 'juggling'
  },
  {
    name: 'Man Juggling',
    unified: '1F939-200D-2642-FE0F',
    keywords: [
      'man_juggling',
      'juggle',
      'balance',
      'skill',
      'multitask'
    ],
    sheet: [40, 29],
    skinVariations: [
      {
        unified: '1F939-1F3FB-200D-2642-FE0F',
        sheet: [40, 30]
      },
      {
        unified: '1F939-1F3FC-200D-2642-FE0F',
        sheet: [40, 31]
      },
      {
        unified: '1F939-1F3FD-200D-2642-FE0F',
        sheet: [40, 32]
      },
      {
        unified: '1F939-1F3FE-200D-2642-FE0F',
        sheet: [40, 33]
      },
      {
        unified: '1F939-1F3FF-200D-2642-FE0F',
        sheet: [40, 34]
      }
    ],
    shortName: 'man-juggling'
  },
  {
    name: 'Woman Juggling',
    unified: '1F939-200D-2640-FE0F',
    keywords: [
      'woman_juggling',
      'juggle',
      'balance',
      'skill',
      'multitask'
    ],
    sheet: [40, 23],
    skinVariations: [
      {
        unified: '1F939-1F3FB-200D-2640-FE0F',
        sheet: [40, 24]
      },
      {
        unified: '1F939-1F3FC-200D-2640-FE0F',
        sheet: [40, 25]
      },
      {
        unified: '1F939-1F3FD-200D-2640-FE0F',
        sheet: [40, 26]
      },
      {
        unified: '1F939-1F3FE-200D-2640-FE0F',
        sheet: [40, 27]
      },
      {
        unified: '1F939-1F3FF-200D-2640-FE0F',
        sheet: [40, 28]
      }
    ],
    shortName: 'woman-juggling'
  },
  {
    name: 'Person in Lotus Position',
    unified: '1F9D8',
    keywords: [
      'person_in_lotus_position',
      'meditate'
    ],
    sheet: [49, 57],
    skinVariations: [
      {
        unified: '1F9D8-1F3FB',
        sheet: [50, 0]
      },
      {
        unified: '1F9D8-1F3FC',
        sheet: [50, 1]
      },
      {
        unified: '1F9D8-1F3FD',
        sheet: [50, 2]
      },
      {
        unified: '1F9D8-1F3FE',
        sheet: [50, 3]
      },
      {
        unified: '1F9D8-1F3FF',
        sheet: [50, 4]
      }
    ],
    shortName: 'person_in_lotus_position',
    obsoletedBy: '1F9D8-200D-2640-FE0F'
  },
  {
    name: 'Man in Lotus Position',
    unified: '1F9D8-200D-2642-FE0F',
    keywords: [
      'man_in_lotus_position',
      'man',
      'male',
      'meditation',
      'yoga',
      'serenity',
      'zen',
      'mindfulness'
    ],
    sheet: [49, 51],
    skinVariations: [
      {
        unified: '1F9D8-1F3FB-200D-2642-FE0F',
        sheet: [49, 52]
      },
      {
        unified: '1F9D8-1F3FC-200D-2642-FE0F',
        sheet: [49, 53]
      },
      {
        unified: '1F9D8-1F3FD-200D-2642-FE0F',
        sheet: [49, 54]
      },
      {
        unified: '1F9D8-1F3FE-200D-2642-FE0F',
        sheet: [49, 55]
      },
      {
        unified: '1F9D8-1F3FF-200D-2642-FE0F',
        sheet: [49, 56]
      }
    ],
    shortName: 'man_in_lotus_position'
  },
  {
    name: 'Woman in Lotus Position',
    unified: '1F9D8-200D-2640-FE0F',
    obsoletes: '1F9D8',
    keywords: [
      'woman_in_lotus_position',
      'woman',
      'female',
      'meditation',
      'yoga',
      'serenity',
      'zen',
      'mindfulness'
    ],
    sheet: [49, 45],
    skinVariations: [
      {
        unified: '1F9D8-1F3FB-200D-2640-FE0F',
        sheet: [49, 46]
      },
      {
        unified: '1F9D8-1F3FC-200D-2640-FE0F',
        sheet: [49, 47]
      },
      {
        unified: '1F9D8-1F3FD-200D-2640-FE0F',
        sheet: [49, 48]
      },
      {
        unified: '1F9D8-1F3FE-200D-2640-FE0F',
        sheet: [49, 49]
      },
      {
        unified: '1F9D8-1F3FF-200D-2640-FE0F',
        sheet: [49, 50]
      }
    ],
    shortName: 'woman_in_lotus_position'
  },
  {
    name: 'Bath',
    unified: '1F6C0',
    keywords: [
      'person_taking_bath',
      'clean',
      'shower',
      'bathroom'
    ],
    sheet: [36, 8],
    skinVariations: [
      {
        unified: '1F6C0-1F3FB',
        sheet: [36, 9]
      },
      {
        unified: '1F6C0-1F3FC',
        sheet: [36, 10]
      },
      {
        unified: '1F6C0-1F3FD',
        sheet: [36, 11]
      },
      {
        unified: '1F6C0-1F3FE',
        sheet: [36, 12]
      },
      {
        unified: '1F6C0-1F3FF',
        sheet: [36, 13]
      }
    ],
    shortName: 'bath'
  },
  {
    name: 'Sleeping Accommodation',
    unified: '1F6CC',
    keywords: [
      'person_in_bed',
      'bed',
      'rest'
    ],
    sheet: [36, 20],
    skinVariations: [
      {
        unified: '1F6CC-1F3FB',
        sheet: [36, 21]
      },
      {
        unified: '1F6CC-1F3FC',
        sheet: [36, 22]
      },
      {
        unified: '1F6CC-1F3FD',
        sheet: [36, 23]
      },
      {
        unified: '1F6CC-1F3FE',
        sheet: [36, 24]
      },
      {
        unified: '1F6CC-1F3FF',
        sheet: [36, 25]
      }
    ],
    shortName: 'sleeping_accommodation'
  },
  {
    name: 'People Holding Hands',
    unified: '1F9D1-200D-1F91D-200D-1F9D1',
    keywords: [
      'people_holding_hands',
      'friendship'
    ],
    sheet: [47, 9],
    skinVariations: [
      {
        unified: '1F9D1-1F3FB-200D-1F91D-200D-1F9D1-1F3FB',
        sheet: [47, 10]
      },
      {
        unified: '1F9D1-1F3FB-200D-1F91D-200D-1F9D1-1F3FC',
        sheet: [47, 11],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FB-200D-1F91D-200D-1F9D1-1F3FD',
        sheet: [47, 12],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FB-200D-1F91D-200D-1F9D1-1F3FE',
        sheet: [47, 13],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FB-200D-1F91D-200D-1F9D1-1F3FF',
        sheet: [47, 14],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FC-200D-1F91D-200D-1F9D1-1F3FB',
        sheet: [47, 15]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F91D-200D-1F9D1-1F3FC',
        sheet: [47, 16]
      },
      {
        unified: '1F9D1-1F3FC-200D-1F91D-200D-1F9D1-1F3FD',
        sheet: [47, 17],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FC-200D-1F91D-200D-1F9D1-1F3FE',
        sheet: [47, 18],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FC-200D-1F91D-200D-1F9D1-1F3FF',
        sheet: [47, 19],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FD-200D-1F91D-200D-1F9D1-1F3FB',
        sheet: [47, 20]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F91D-200D-1F9D1-1F3FC',
        sheet: [47, 21]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F91D-200D-1F9D1-1F3FD',
        sheet: [47, 22]
      },
      {
        unified: '1F9D1-1F3FD-200D-1F91D-200D-1F9D1-1F3FE',
        sheet: [47, 23],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FD-200D-1F91D-200D-1F9D1-1F3FF',
        sheet: [47, 24],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FE-200D-1F91D-200D-1F9D1-1F3FB',
        sheet: [47, 25]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F91D-200D-1F9D1-1F3FC',
        sheet: [47, 26]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F91D-200D-1F9D1-1F3FD',
        sheet: [47, 27]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F91D-200D-1F9D1-1F3FE',
        sheet: [47, 28]
      },
      {
        unified: '1F9D1-1F3FE-200D-1F91D-200D-1F9D1-1F3FF',
        sheet: [47, 29],
        hidden: ['facebook']
      },
      {
        unified: '1F9D1-1F3FF-200D-1F91D-200D-1F9D1-1F3FB',
        sheet: [47, 30]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F91D-200D-1F9D1-1F3FC',
        sheet: [47, 31]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F91D-200D-1F9D1-1F3FD',
        sheet: [47, 32]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F91D-200D-1F9D1-1F3FE',
        sheet: [47, 33]
      },
      {
        unified: '1F9D1-1F3FF-200D-1F91D-200D-1F9D1-1F3FF',
        sheet: [47, 34]
      }
    ],
    shortName: 'people_holding_hands'
  },
  {
    name: 'Two Women Holding Hands',
    unified: '1F46D',
    keywords: [
      'women_holding_hands',
      'pair',
      'friendship',
      'couple',
      'love',
      'like',
      'female',
      'people',
      'human'
    ],
    sheet: [21, 5],
    skinVariations: [
      {
        unified: '1F46D-1F3FB',
        sheet: [21, 6]
      },
      {
        unified: '1F46D-1F3FC',
        sheet: [21, 7]
      },
      {
        unified: '1F46D-1F3FD',
        sheet: [21, 8]
      },
      {
        unified: '1F46D-1F3FE',
        sheet: [21, 9]
      },
      {
        unified: '1F46D-1F3FF',
        sheet: [21, 10]
      },
      {
        unified: '1F469-1F3FB-200D-1F91D-200D-1F469-1F3FC',
        sheet: [21, 11],
        hidden: ['facebook']
      },
      {
        unified: '1F469-1F3FB-200D-1F91D-200D-1F469-1F3FD',
        sheet: [21, 12],
        hidden: ['facebook']
      },
      {
        unified: '1F469-1F3FB-200D-1F91D-200D-1F469-1F3FE',
        sheet: [21, 13],
        hidden: ['facebook']
      },
      {
        unified: '1F469-1F3FB-200D-1F91D-200D-1F469-1F3FF',
        sheet: [21, 14],
        hidden: ['facebook']
      },
      {
        unified: '1F469-1F3FC-200D-1F91D-200D-1F469-1F3FB',
        sheet: [21, 15]
      },
      {
        unified: '1F469-1F3FC-200D-1F91D-200D-1F469-1F3FD',
        sheet: [21, 16],
        hidden: ['facebook']
      },
      {
        unified: '1F469-1F3FC-200D-1F91D-200D-1F469-1F3FE',
        sheet: [21, 17],
        hidden: ['facebook']
      },
      {
        unified: '1F469-1F3FC-200D-1F91D-200D-1F469-1F3FF',
        sheet: [21, 18],
        hidden: ['facebook']
      },
      {
        unified: '1F469-1F3FD-200D-1F91D-200D-1F469-1F3FB',
        sheet: [21, 19]
      },
      {
        unified: '1F469-1F3FD-200D-1F91D-200D-1F469-1F3FC',
        sheet: [21, 20]
      },
      {
        unified: '1F469-1F3FD-200D-1F91D-200D-1F469-1F3FE',
        sheet: [21, 21],
        hidden: ['facebook']
      },
      {
        unified: '1F469-1F3FD-200D-1F91D-200D-1F469-1F3FF',
        sheet: [21, 22],
        hidden: ['facebook']
      },
      {
        unified: '1F469-1F3FE-200D-1F91D-200D-1F469-1F3FB',
        sheet: [21, 23]
      },
      {
        unified: '1F469-1F3FE-200D-1F91D-200D-1F469-1F3FC',
        sheet: [21, 24]
      },
      {
        unified: '1F469-1F3FE-200D-1F91D-200D-1F469-1F3FD',
        sheet: [21, 25]
      },
      {
        unified: '1F469-1F3FE-200D-1F91D-200D-1F469-1F3FF',
        sheet: [21, 26],
        hidden: ['facebook']
      },
      {
        unified: '1F469-1F3FF-200D-1F91D-200D-1F469-1F3FB',
        sheet: [21, 27]
      },
      {
        unified: '1F469-1F3FF-200D-1F91D-200D-1F469-1F3FC',
        sheet: [21, 28]
      },
      {
        unified: '1F469-1F3FF-200D-1F91D-200D-1F469-1F3FD',
        sheet: [21, 29]
      },
      {
        unified: '1F469-1F3FF-200D-1F91D-200D-1F469-1F3FE',
        sheet: [21, 30]
      }
    ],
    shortNames: ['women_holding_hands'],
    shortName: 'two_women_holding_hands'
  },
  {
    name: 'Man and Woman Holding Hands',
    unified: '1F46B',
    keywords: [
      'woman_and_man_holding_hands',
      'pair',
      'people',
      'human',
      'love',
      'date',
      'dating',
      'like',
      'affection',
      'valentines',
      'marriage'
    ],
    sheet: [20, 11],
    skinVariations: [
      {
        unified: '1F46B-1F3FB',
        sheet: [20, 12]
      },
      {
        unified: '1F46B-1F3FC',
        sheet: [20, 13]
      },
      {
        unified: '1F46B-1F3FD',
        sheet: [20, 14]
      },
      {
        unified: '1F46B-1F3FE',
        sheet: [20, 15]
      },
      {
        unified: '1F46B-1F3FF',
        sheet: [20, 16]
      },
      {
        unified: '1F469-1F3FB-200D-1F91D-200D-1F468-1F3FC',
        sheet: [20, 17]
      },
      {
        unified: '1F469-1F3FB-200D-1F91D-200D-1F468-1F3FD',
        sheet: [20, 18]
      },
      {
        unified: '1F469-1F3FB-200D-1F91D-200D-1F468-1F3FE',
        sheet: [20, 19]
      },
      {
        unified: '1F469-1F3FB-200D-1F91D-200D-1F468-1F3FF',
        sheet: [20, 20]
      },
      {
        unified: '1F469-1F3FC-200D-1F91D-200D-1F468-1F3FB',
        sheet: [20, 21]
      },
      {
        unified: '1F469-1F3FC-200D-1F91D-200D-1F468-1F3FD',
        sheet: [20, 22]
      },
      {
        unified: '1F469-1F3FC-200D-1F91D-200D-1F468-1F3FE',
        sheet: [20, 23]
      },
      {
        unified: '1F469-1F3FC-200D-1F91D-200D-1F468-1F3FF',
        sheet: [20, 24]
      },
      {
        unified: '1F469-1F3FD-200D-1F91D-200D-1F468-1F3FB',
        sheet: [20, 25]
      },
      {
        unified: '1F469-1F3FD-200D-1F91D-200D-1F468-1F3FC',
        sheet: [20, 26]
      },
      {
        unified: '1F469-1F3FD-200D-1F91D-200D-1F468-1F3FE',
        sheet: [20, 27]
      },
      {
        unified: '1F469-1F3FD-200D-1F91D-200D-1F468-1F3FF',
        sheet: [20, 28]
      },
      {
        unified: '1F469-1F3FE-200D-1F91D-200D-1F468-1F3FB',
        sheet: [20, 29]
      },
      {
        unified: '1F469-1F3FE-200D-1F91D-200D-1F468-1F3FC',
        sheet: [20, 30]
      },
      {
        unified: '1F469-1F3FE-200D-1F91D-200D-1F468-1F3FD',
        sheet: [20, 31]
      },
      {
        unified: '1F469-1F3FE-200D-1F91D-200D-1F468-1F3FF',
        sheet: [20, 32]
      },
      {
        unified: '1F469-1F3FF-200D-1F91D-200D-1F468-1F3FB',
        sheet: [20, 33]
      },
      {
        unified: '1F469-1F3FF-200D-1F91D-200D-1F468-1F3FC',
        sheet: [20, 34]
      },
      {
        unified: '1F469-1F3FF-200D-1F91D-200D-1F468-1F3FD',
        sheet: [20, 35]
      },
      {
        unified: '1F469-1F3FF-200D-1F91D-200D-1F468-1F3FE',
        sheet: [20, 36]
      }
    ],
    shortNames: [
      'woman_and_man_holding_hands',
      'couple'
    ],
    shortName: 'man_and_woman_holding_hands'
  },
  {
    name: 'Two Men Holding Hands',
    unified: '1F46C',
    keywords: [
      'men_holding_hands',
      'pair',
      'couple',
      'love',
      'like',
      'bromance',
      'friendship',
      'people',
      'human'
    ],
    sheet: [20, 37],
    skinVariations: [
      {
        unified: '1F46C-1F3FB',
        sheet: [20, 38]
      },
      {
        unified: '1F46C-1F3FC',
        sheet: [20, 39]
      },
      {
        unified: '1F46C-1F3FD',
        sheet: [20, 40]
      },
      {
        unified: '1F46C-1F3FE',
        sheet: [20, 41]
      },
      {
        unified: '1F46C-1F3FF',
        sheet: [20, 42]
      },
      {
        unified: '1F468-1F3FB-200D-1F91D-200D-1F468-1F3FC',
        sheet: [20, 43],
        hidden: ['facebook']
      },
      {
        unified: '1F468-1F3FB-200D-1F91D-200D-1F468-1F3FD',
        sheet: [20, 44],
        hidden: ['facebook']
      },
      {
        unified: '1F468-1F3FB-200D-1F91D-200D-1F468-1F3FE',
        sheet: [20, 45],
        hidden: ['facebook']
      },
      {
        unified: '1F468-1F3FB-200D-1F91D-200D-1F468-1F3FF',
        sheet: [20, 46],
        hidden: ['facebook']
      },
      {
        unified: '1F468-1F3FC-200D-1F91D-200D-1F468-1F3FB',
        sheet: [20, 47]
      },
      {
        unified: '1F468-1F3FC-200D-1F91D-200D-1F468-1F3FD',
        sheet: [20, 48],
        hidden: ['facebook']
      },
      {
        unified: '1F468-1F3FC-200D-1F91D-200D-1F468-1F3FE',
        sheet: [20, 49],
        hidden: ['facebook']
      },
      {
        unified: '1F468-1F3FC-200D-1F91D-200D-1F468-1F3FF',
        sheet: [20, 50],
        hidden: ['facebook']
      },
      {
        unified: '1F468-1F3FD-200D-1F91D-200D-1F468-1F3FB',
        sheet: [20, 51]
      },
      {
        unified: '1F468-1F3FD-200D-1F91D-200D-1F468-1F3FC',
        sheet: [20, 52]
      },
      {
        unified: '1F468-1F3FD-200D-1F91D-200D-1F468-1F3FE',
        sheet: [20, 53],
        hidden: ['facebook']
      },
      {
        unified: '1F468-1F3FD-200D-1F91D-200D-1F468-1F3FF',
        sheet: [20, 54],
        hidden: ['facebook']
      },
      {
        unified: '1F468-1F3FE-200D-1F91D-200D-1F468-1F3FB',
        sheet: [20, 55]
      },
      {
        unified: '1F468-1F3FE-200D-1F91D-200D-1F468-1F3FC',
        sheet: [20, 56]
      },
      {
        unified: '1F468-1F3FE-200D-1F91D-200D-1F468-1F3FD',
        sheet: [20, 57]
      },
      {
        unified: '1F468-1F3FE-200D-1F91D-200D-1F468-1F3FF',
        sheet: [21, 0],
        hidden: ['facebook']
      },
      {
        unified: '1F468-1F3FF-200D-1F91D-200D-1F468-1F3FB',
        sheet: [21, 1]
      },
      {
        unified: '1F468-1F3FF-200D-1F91D-200D-1F468-1F3FC',
        sheet: [21, 2]
      },
      {
        unified: '1F468-1F3FF-200D-1F91D-200D-1F468-1F3FD',
        sheet: [21, 3]
      },
      {
        unified: '1F468-1F3FF-200D-1F91D-200D-1F468-1F3FE',
        sheet: [21, 4]
      }
    ],
    shortNames: ['men_holding_hands'],
    shortName: 'two_men_holding_hands'
  },
  {
    name: 'Kiss',
    unified: '1F48F',
    keywords: [
      'kiss',
      'pair',
      'valentines',
      'love',
      'like',
      'dating',
      'marriage'
    ],
    sheet: [25, 27],
    shortName: 'couplekiss',
    obsoletedBy: '1F469-200D-2764-FE0F-200D-1F48B-200D-1F468'
  },
  {
    name: 'Kiss: Woman, Man',
    unified: '1F469-200D-2764-FE0F-200D-1F48B-200D-1F468',
    obsoletes: '1F48F',
    keywords: [
      'kiss_woman_man',
      'love'
    ],
    sheet: [20, 2],
    shortName: 'woman-kiss-man'
  },
  {
    name: 'Kiss: Man, Man',
    unified: '1F468-200D-2764-FE0F-200D-1F48B-200D-1F468',
    keywords: [
      'kiss_man_man',
      'pair',
      'valentines',
      'love',
      'like',
      'dating',
      'marriage'
    ],
    sheet: [17, 13],
    shortName: 'man-kiss-man'
  },
  {
    name: 'Kiss: Woman, Woman',
    unified: '1F469-200D-2764-FE0F-200D-1F48B-200D-1F469',
    keywords: [
      'kiss_woman_woman',
      'pair',
      'valentines',
      'love',
      'like',
      'dating',
      'marriage'
    ],
    sheet: [20, 3],
    shortName: 'woman-kiss-woman'
  },
  {
    name: 'Couple with Heart',
    unified: '1F491',
    keywords: [
      'couple_with_heart',
      'pair',
      'love',
      'like',
      'affection',
      'human',
      'dating',
      'valentines',
      'marriage'
    ],
    sheet: [25, 29],
    shortName: 'couple_with_heart',
    obsoletedBy: '1F469-200D-2764-FE0F-200D-1F468'
  },
  {
    name: 'Couple with Heart: Woman, Man',
    unified: '1F469-200D-2764-FE0F-200D-1F468',
    obsoletes: '1F491',
    keywords: [
      'couple_with_heart_woman_man',
      'love'
    ],
    sheet: [20, 0],
    shortName: 'woman-heart-man'
  },
  {
    name: 'Couple with Heart: Man, Man',
    unified: '1F468-200D-2764-FE0F-200D-1F468',
    keywords: [
      'couple_with_heart_man_man',
      'pair',
      'love',
      'like',
      'affection',
      'human',
      'dating',
      'valentines',
      'marriage'
    ],
    sheet: [17, 12],
    shortName: 'man-heart-man'
  },
  {
    name: 'Couple with Heart: Woman, Woman',
    unified: '1F469-200D-2764-FE0F-200D-1F469',
    keywords: [
      'couple_with_heart_woman_woman',
      'pair',
      'love',
      'like',
      'affection',
      'human',
      'dating',
      'valentines',
      'marriage'
    ],
    sheet: [20, 1],
    shortName: 'woman-heart-woman'
  },
  {
    name: 'Family',
    unified: '1F46A',
    keywords: [
      'family',
      'home',
      'parents',
      'child',
      'mom',
      'dad',
      'father',
      'mother',
      'people',
      'human'
    ],
    sheet: [20, 10],
    shortName: 'family',
    obsoletedBy: '1F468-200D-1F469-200D-1F466'
  },
  {
    name: 'Family: Man, Woman, Boy',
    unified: '1F468-200D-1F469-200D-1F466',
    obsoletes: '1F46A',
    keywords: [
      'family_man_woman_boy',
      'love'
    ],
    sheet: [15, 27],
    shortName: 'man-woman-boy'
  },
  {
    name: 'Family: Man, Woman, Girl',
    unified: '1F468-200D-1F469-200D-1F467',
    keywords: [
      'family_man_woman_girl',
      'home',
      'parents',
      'people',
      'human',
      'child'
    ],
    sheet: [15, 29],
    shortName: 'man-woman-girl'
  },
  {
    name: 'Family: Man, Woman, Girl, Boy',
    unified: '1F468-200D-1F469-200D-1F467-200D-1F466',
    keywords: [
      'family_man_woman_girl_boy',
      'home',
      'parents',
      'people',
      'human',
      'children'
    ],
    sheet: [15, 30],
    shortName: 'man-woman-girl-boy'
  },
  {
    name: 'Family: Man, Woman, Boy, Boy',
    unified: '1F468-200D-1F469-200D-1F466-200D-1F466',
    keywords: [
      'family_man_woman_boy_boy',
      'home',
      'parents',
      'people',
      'human',
      'children'
    ],
    sheet: [15, 28],
    shortName: 'man-woman-boy-boy'
  },
  {
    name: 'Family: Man, Woman, Girl, Girl',
    unified: '1F468-200D-1F469-200D-1F467-200D-1F467',
    keywords: [
      'family_man_woman_girl_girl',
      'home',
      'parents',
      'people',
      'human',
      'children'
    ],
    sheet: [15, 31],
    shortName: 'man-woman-girl-girl'
  },
  {
    name: 'Family: Man, Man, Boy',
    unified: '1F468-200D-1F468-200D-1F466',
    keywords: [
      'family_man_man_boy',
      'home',
      'parents',
      'people',
      'human',
      'children'
    ],
    sheet: [15, 22],
    shortName: 'man-man-boy'
  },
  {
    name: 'Family: Man, Man, Girl',
    unified: '1F468-200D-1F468-200D-1F467',
    keywords: [
      'family_man_man_girl',
      'home',
      'parents',
      'people',
      'human',
      'children'
    ],
    sheet: [15, 24],
    shortName: 'man-man-girl'
  },
  {
    name: 'Family: Man, Man, Girl, Boy',
    unified: '1F468-200D-1F468-200D-1F467-200D-1F466',
    keywords: [
      'family_man_man_girl_boy',
      'home',
      'parents',
      'people',
      'human',
      'children'
    ],
    sheet: [15, 25],
    shortName: 'man-man-girl-boy'
  },
  {
    name: 'Family: Man, Man, Boy, Boy',
    unified: '1F468-200D-1F468-200D-1F466-200D-1F466',
    keywords: [
      'family_man_man_boy_boy',
      'home',
      'parents',
      'people',
      'human',
      'children'
    ],
    sheet: [15, 23],
    shortName: 'man-man-boy-boy'
  },
  {
    name: 'Family: Man, Man, Girl, Girl',
    unified: '1F468-200D-1F468-200D-1F467-200D-1F467',
    keywords: [
      'family_man_man_girl_girl',
      'home',
      'parents',
      'people',
      'human',
      'children'
    ],
    sheet: [15, 26],
    shortName: 'man-man-girl-girl'
  },
  {
    name: 'Family: Woman, Woman, Boy',
    unified: '1F469-200D-1F469-200D-1F466',
    keywords: [
      'family_woman_woman_boy',
      'home',
      'parents',
      'people',
      'human',
      'children'
    ],
    sheet: [18, 15],
    shortName: 'woman-woman-boy'
  },
  {
    name: 'Family: Woman, Woman, Girl',
    unified: '1F469-200D-1F469-200D-1F467',
    keywords: [
      'family_woman_woman_girl',
      'home',
      'parents',
      'people',
      'human',
      'children'
    ],
    sheet: [18, 17],
    shortName: 'woman-woman-girl'
  },
  {
    name: 'Family: Woman, Woman, Girl, Boy',
    unified: '1F469-200D-1F469-200D-1F467-200D-1F466',
    keywords: [
      'family_woman_woman_girl_boy',
      'home',
      'parents',
      'people',
      'human',
      'children'
    ],
    sheet: [18, 18],
    shortName: 'woman-woman-girl-boy'
  },
  {
    name: 'Family: Woman, Woman, Boy, Boy',
    unified: '1F469-200D-1F469-200D-1F466-200D-1F466',
    keywords: [
      'family_woman_woman_boy_boy',
      'home',
      'parents',
      'people',
      'human',
      'children'
    ],
    sheet: [18, 16],
    shortName: 'woman-woman-boy-boy'
  },
  {
    name: 'Family: Woman, Woman, Girl, Girl',
    unified: '1F469-200D-1F469-200D-1F467-200D-1F467',
    keywords: [
      'family_woman_woman_girl_girl',
      'home',
      'parents',
      'people',
      'human',
      'children'
    ],
    sheet: [18, 19],
    shortName: 'woman-woman-girl-girl'
  },
  {
    name: 'Family: Man, Boy',
    unified: '1F468-200D-1F466',
    keywords: [
      'family_man_boy',
      'home',
      'parent',
      'people',
      'human',
      'child'
    ],
    sheet: [15, 18],
    shortName: 'man-boy'
  },
  {
    name: 'Family: Man, Boy, Boy',
    unified: '1F468-200D-1F466-200D-1F466',
    keywords: [
      'family_man_boy_boy',
      'home',
      'parent',
      'people',
      'human',
      'children'
    ],
    sheet: [15, 17],
    shortName: 'man-boy-boy'
  },
  {
    name: 'Family: Man, Girl',
    unified: '1F468-200D-1F467',
    keywords: [
      'family_man_girl',
      'home',
      'parent',
      'people',
      'human',
      'child'
    ],
    sheet: [15, 21],
    shortName: 'man-girl'
  },
  {
    name: 'Family: Man, Girl, Boy',
    unified: '1F468-200D-1F467-200D-1F466',
    keywords: [
      'family_man_girl_boy',
      'home',
      'parent',
      'people',
      'human',
      'children'
    ],
    sheet: [15, 19],
    shortName: 'man-girl-boy'
  },
  {
    name: 'Family: Man, Girl, Girl',
    unified: '1F468-200D-1F467-200D-1F467',
    keywords: [
      'family_man_girl_girl',
      'home',
      'parent',
      'people',
      'human',
      'children'
    ],
    sheet: [15, 20],
    shortName: 'man-girl-girl'
  },
  {
    name: 'Family: Woman, Boy',
    unified: '1F469-200D-1F466',
    keywords: [
      'family_woman_boy',
      'home',
      'parent',
      'people',
      'human',
      'child'
    ],
    sheet: [18, 11],
    shortName: 'woman-boy'
  },
  {
    name: 'Family: Woman, Boy, Boy',
    unified: '1F469-200D-1F466-200D-1F466',
    keywords: [
      'family_woman_boy_boy',
      'home',
      'parent',
      'people',
      'human',
      'children'
    ],
    sheet: [18, 10],
    shortName: 'woman-boy-boy'
  },
  {
    name: 'Family: Woman, Girl',
    unified: '1F469-200D-1F467',
    keywords: [
      'family_woman_girl',
      'home',
      'parent',
      'people',
      'human',
      'child'
    ],
    sheet: [18, 14],
    shortName: 'woman-girl'
  },
  {
    name: 'Family: Woman, Girl, Boy',
    unified: '1F469-200D-1F467-200D-1F466',
    keywords: [
      'family_woman_girl_boy',
      'home',
      'parent',
      'people',
      'human',
      'children'
    ],
    sheet: [18, 12],
    shortName: 'woman-girl-boy'
  },
  {
    name: 'Family: Woman, Girl, Girl',
    unified: '1F469-200D-1F467-200D-1F467',
    keywords: [
      'family_woman_girl_girl',
      'home',
      'parent',
      'people',
      'human',
      'children'
    ],
    sheet: [18, 13],
    shortName: 'woman-girl-girl'
  },
  {
    name: 'Speaking Head',
    unified: '1F5E3-FE0F',
    keywords: [
      'speaking_head',
      'user',
      'person',
      'human',
      'sing',
      'say',
      'talk'
    ],
    sheet: [30, 22],
    shortName: 'speaking_head_in_silhouette'
  },
  {
    name: 'Bust in Silhouette',
    unified: '1F464',
    keywords: [
      'bust_in_silhouette',
      'user',
      'person',
      'human'
    ],
    sheet: [14, 13],
    shortName: 'bust_in_silhouette'
  },
  {
    name: 'Busts in Silhouette',
    unified: '1F465',
    keywords: [
      'busts_in_silhouette',
      'user',
      'person',
      'human',
      'group',
      'team'
    ],
    sheet: [14, 14],
    shortName: 'busts_in_silhouette'
  },
  {
    name: 'People Hugging',
    unified: '1FAC2',
    keywords: [
      'people hugging',
      'care'
    ],
    sheet: [53, 8],
    shortName: 'people_hugging'
  },
  {
    name: 'Footprints',
    unified: '1F463',
    keywords: [
      'footprints',
      'feet',
      'tracking',
      'walking',
      'beach'
    ],
    sheet: [14, 12],
    shortName: 'footprints'
  },
  {
    name: 'Emoji Modifier Fitzpatrick Type-1-2',
    unified: '1F3FB',
    sheet: [11, 13],
    shortName: 'skin-tone-2'
  },
  {
    name: 'Emoji Modifier Fitzpatrick Type-3',
    unified: '1F3FC',
    sheet: [11, 14],
    shortName: 'skin-tone-3'
  },
  {
    name: 'Emoji Modifier Fitzpatrick Type-4',
    unified: '1F3FD',
    sheet: [11, 15],
    shortName: 'skin-tone-4'
  },
  {
    name: 'Emoji Modifier Fitzpatrick Type-5',
    unified: '1F3FE',
    sheet: [11, 16],
    shortName: 'skin-tone-5'
  },
  {
    name: 'Emoji Modifier Fitzpatrick Type-6',
    unified: '1F3FF',
    sheet: [11, 17],
    shortName: 'skin-tone-6'
  },
  {
    name: 'Monkey Face',
    unified: '1F435',
    emoticons: [':o)'],
    keywords: [
      'monkey_face',
      'animal',
      'nature',
      'circus'
    ],
    sheet: [12, 15],
    shortName: 'monkey_face'
  },
  {
    name: 'Monkey',
    unified: '1F412',
    keywords: [
      'monkey',
      'animal',
      'nature',
      'banana',
      'circus'
    ],
    sheet: [11, 37],
    shortName: 'monkey'
  },
  {
    name: 'Gorilla',
    unified: '1F98D',
    keywords: [
      'gorilla',
      'animal',
      'nature',
      'circus'
    ],
    sheet: [42, 46],
    shortName: 'gorilla'
  },
  {
    name: 'Orangutan',
    unified: '1F9A7',
    keywords: ['orangutan', 'animal'],
    sheet: [43, 14],
    shortName: 'orangutan'
  },
  {
    name: 'Dog Face',
    unified: '1F436',
    keywords: [
      'dog_face',
      'animal',
      'friend',
      'nature',
      'woof',
      'puppy',
      'pet',
      'faithful'
    ],
    sheet: [12, 16],
    shortName: 'dog'
  },
  {
    name: 'Dog',
    unified: '1F415',
    keywords: [
      'dog',
      'animal',
      'nature',
      'friend',
      'doge',
      'pet',
      'faithful'
    ],
    sheet: [11, 41],
    shortName: 'dog2'
  },
  {
    name: 'Guide Dog',
    unified: '1F9AE',
    keywords: [
      'guide_dog',
      'animal',
      'blind'
    ],
    sheet: [43, 21],
    shortName: 'guide_dog'
  },
  {
    name: 'Service Dog',
    unified: '1F415-200D-1F9BA',
    keywords: [
      'service_dog',
      'blind',
      'animal'
    ],
    sheet: [11, 40],
    shortName: 'service_dog'
  },
  {
    name: 'Poodle',
    unified: '1F429',
    keywords: [
      'poodle',
      'dog',
      'animal',
      '101',
      'nature',
      'pet'
    ],
    sheet: [12, 3],
    shortName: 'poodle'
  },
  {
    name: 'Wolf Face',
    unified: '1F43A',
    keywords: [
      'wolf',
      'animal',
      'nature',
      'wild'
    ],
    sheet: [12, 20],
    shortName: 'wolf'
  },
  {
    name: 'Fox Face',
    unified: '1F98A',
    keywords: [
      'fox',
      'animal',
      'nature',
      'face'
    ],
    sheet: [42, 43],
    shortName: 'fox_face'
  },
  {
    name: 'Raccoon',
    unified: '1F99D',
    keywords: [
      'raccoon',
      'animal',
      'nature'
    ],
    sheet: [43, 4],
    shortName: 'raccoon'
  },
  {
    name: 'Cat Face',
    unified: '1F431',
    keywords: [
      'cat_face',
      'animal',
      'meow',
      'nature',
      'pet',
      'kitten'
    ],
    sheet: [12, 11],
    shortName: 'cat'
  },
  {
    name: 'Cat',
    unified: '1F408',
    keywords: [
      'cat',
      'animal',
      'meow',
      'pet',
      'cats'
    ],
    sheet: [11, 27],
    shortName: 'cat2'
  },
  {
    name: 'Black Cat',
    unified: '1F408-200D-2B1B',
    keywords: [
      'black cat',
      'superstition',
      'luck'
    ],
    sheet: [11, 26],
    shortName: 'black_cat'
  },
  {
    name: 'Lion Face',
    unified: '1F981',
    keywords: [
      'lion',
      'animal',
      'nature'
    ],
    sheet: [42, 34],
    shortName: 'lion_face'
  },
  {
    name: 'Tiger Face',
    unified: '1F42F',
    keywords: [
      'tiger_face',
      'animal',
      'cat',
      'danger',
      'wild',
      'nature',
      'roar'
    ],
    sheet: [12, 9],
    shortName: 'tiger'
  },
  {
    name: 'Tiger',
    unified: '1F405',
    keywords: [
      'tiger',
      'animal',
      'nature',
      'roar'
    ],
    sheet: [11, 23],
    shortName: 'tiger2'
  },
  {
    name: 'Leopard',
    unified: '1F406',
    keywords: [
      'leopard',
      'animal',
      'nature'
    ],
    sheet: [11, 24],
    shortName: 'leopard'
  },
  {
    name: 'Horse Face',
    unified: '1F434',
    keywords: [
      'horse_face',
      'animal',
      'brown',
      'nature'
    ],
    sheet: [12, 14],
    shortName: 'horse'
  },
  {
    name: 'Horse',
    unified: '1F40E',
    keywords: [
      'horse',
      'animal',
      'gamble',
      'luck'
    ],
    sheet: [11, 33],
    shortName: 'racehorse'
  },
  {
    name: 'Unicorn Face',
    unified: '1F984',
    keywords: [
      'unicorn',
      'animal',
      'nature',
      'mystical'
    ],
    sheet: [42, 37],
    shortName: 'unicorn_face'
  },
  {
    name: 'Zebra Face',
    unified: '1F993',
    keywords: [
      'zebra',
      'animal',
      'nature',
      'stripes',
      'safari'
    ],
    sheet: [42, 52],
    shortName: 'zebra_face'
  },
  {
    name: 'Deer',
    unified: '1F98C',
    keywords: [
      'deer',
      'animal',
      'nature',
      'horns',
      'venison'
    ],
    sheet: [42, 45],
    shortName: 'deer'
  },
  {
    name: 'Bison',
    unified: '1F9AC',
    keywords: ['bison', 'ox'],
    sheet: [43, 19],
    shortName: 'bison'
  },
  {
    name: 'Cow Face',
    unified: '1F42E',
    keywords: [
      'cow_face',
      'beef',
      'ox',
      'animal',
      'nature',
      'moo',
      'milk'
    ],
    sheet: [12, 8],
    shortName: 'cow'
  },
  {
    name: 'Ox',
    unified: '1F402',
    keywords: [
      'ox',
      'animal',
      'cow',
      'beef'
    ],
    sheet: [11, 20],
    shortName: 'ox'
  },
  {
    name: 'Water Buffalo',
    unified: '1F403',
    keywords: [
      'water_buffalo',
      'animal',
      'nature',
      'ox',
      'cow'
    ],
    sheet: [11, 21],
    shortName: 'water_buffalo'
  },
  {
    name: 'Cow',
    unified: '1F404',
    keywords: [
      'cow',
      'beef',
      'ox',
      'animal',
      'nature',
      'moo',
      'milk'
    ],
    sheet: [11, 22],
    shortName: 'cow2'
  },
  {
    name: 'Pig Face',
    unified: '1F437',
    keywords: [
      'pig_face',
      'animal',
      'oink',
      'nature'
    ],
    sheet: [12, 17],
    shortName: 'pig'
  },
  {
    name: 'Pig',
    unified: '1F416',
    keywords: [
      'pig',
      'animal',
      'nature'
    ],
    sheet: [11, 42],
    shortName: 'pig2'
  },
  {
    name: 'Boar',
    unified: '1F417',
    keywords: [
      'boar',
      'animal',
      'nature'
    ],
    sheet: [11, 43],
    shortName: 'boar'
  },
  {
    name: 'Pig Nose',
    unified: '1F43D',
    keywords: [
      'pig_nose',
      'animal',
      'oink'
    ],
    sheet: [12, 24],
    shortName: 'pig_nose'
  },
  {
    name: 'Ram',
    unified: '1F40F',
    keywords: [
      'ram',
      'animal',
      'sheep',
      'nature'
    ],
    sheet: [11, 34],
    shortName: 'ram'
  },
  {
    name: 'Sheep',
    unified: '1F411',
    keywords: [
      'ewe',
      'animal',
      'nature',
      'wool',
      'shipit'
    ],
    sheet: [11, 36],
    shortName: 'sheep'
  },
  {
    name: 'Goat',
    unified: '1F410',
    keywords: [
      'goat',
      'animal',
      'nature'
    ],
    sheet: [11, 35],
    shortName: 'goat'
  },
  {
    name: 'Dromedary Camel',
    unified: '1F42A',
    keywords: [
      'camel',
      'animal',
      'hot',
      'desert',
      'hump'
    ],
    sheet: [12, 4],
    shortName: 'dromedary_camel'
  },
  {
    name: 'Bactrian Camel',
    unified: '1F42B',
    keywords: [
      'two_hump_camel',
      'animal',
      'nature',
      'hot',
      'desert',
      'hump'
    ],
    sheet: [12, 5],
    shortName: 'camel'
  },
  {
    name: 'Llama',
    unified: '1F999',
    keywords: [
      'llama',
      'animal',
      'nature',
      'alpaca'
    ],
    sheet: [43, 0],
    shortName: 'llama'
  },
  {
    name: 'Giraffe Face',
    unified: '1F992',
    keywords: [
      'giraffe',
      'animal',
      'nature',
      'spots',
      'safari'
    ],
    sheet: [42, 51],
    shortName: 'giraffe_face'
  },
  {
    name: 'Elephant',
    unified: '1F418',
    keywords: [
      'elephant',
      'animal',
      'nature',
      'nose',
      'th',
      'circus'
    ],
    sheet: [11, 44],
    shortName: 'elephant'
  },
  {
    name: 'Mammoth',
    unified: '1F9A3',
    keywords: [
      'mammoth',
      'elephant',
      'tusks'
    ],
    sheet: [43, 10],
    shortName: 'mammoth'
  },
  {
    name: 'Rhinoceros',
    unified: '1F98F',
    keywords: [
      'rhinoceros',
      'animal',
      'nature',
      'horn'
    ],
    sheet: [42, 48],
    shortName: 'rhinoceros'
  },
  {
    name: 'Hippopotamus',
    unified: '1F99B',
    keywords: [
      'hippopotamus',
      'animal',
      'nature'
    ],
    sheet: [43, 2],
    shortName: 'hippopotamus'
  },
  {
    name: 'Mouse Face',
    unified: '1F42D',
    keywords: [
      'mouse_face',
      'animal',
      'nature',
      'cheese_wedge',
      'rodent'
    ],
    sheet: [12, 7],
    shortName: 'mouse'
  },
  {
    name: 'Mouse',
    unified: '1F401',
    keywords: [
      'mouse',
      'animal',
      'nature',
      'rodent'
    ],
    sheet: [11, 19],
    shortName: 'mouse2'
  },
  {
    name: 'Rat',
    unified: '1F400',
    keywords: [
      'rat',
      'animal',
      'mouse',
      'rodent'
    ],
    sheet: [11, 18],
    shortName: 'rat'
  },
  {
    name: 'Hamster Face',
    unified: '1F439',
    keywords: [
      'hamster',
      'animal',
      'nature'
    ],
    sheet: [12, 19],
    shortName: 'hamster'
  },
  {
    name: 'Rabbit Face',
    unified: '1F430',
    keywords: [
      'rabbit_face',
      'animal',
      'nature',
      'pet',
      'spring',
      'magic',
      'bunny'
    ],
    sheet: [12, 10],
    shortName: 'rabbit'
  },
  {
    name: 'Rabbit',
    unified: '1F407',
    keywords: [
      'rabbit',
      'animal',
      'nature',
      'pet',
      'magic',
      'spring'
    ],
    sheet: [11, 25],
    shortName: 'rabbit2'
  },
  {
    name: 'Chipmunk',
    unified: '1F43F-FE0F',
    keywords: [
      'chipmunk',
      'animal',
      'nature',
      'rodent',
      'squirrel'
    ],
    sheet: [12, 26],
    shortName: 'chipmunk'
  },
  {
    name: 'Beaver',
    unified: '1F9AB',
    keywords: [
      'beaver',
      'animal',
      'rodent'
    ],
    sheet: [43, 18],
    shortName: 'beaver'
  },
  {
    name: 'Hedgehog',
    unified: '1F994',
    keywords: [
      'hedgehog',
      'animal',
      'nature',
      'spiny'
    ],
    sheet: [42, 53],
    shortName: 'hedgehog'
  },
  {
    name: 'Bat',
    unified: '1F987',
    keywords: [
      'bat',
      'animal',
      'nature',
      'blind',
      'vampire'
    ],
    sheet: [42, 40],
    shortName: 'bat'
  },
  {
    name: 'Bear Face',
    unified: '1F43B',
    keywords: [
      'bear',
      'animal',
      'nature',
      'wild'
    ],
    sheet: [12, 22],
    shortName: 'bear'
  },
  {
    name: 'Polar Bear',
    unified: '1F43B-200D-2744-FE0F',
    keywords: [
      'polar bear',
      'animal',
      'arctic'
    ],
    sheet: [12, 21],
    shortName: 'polar_bear'
  },
  {
    name: 'Koala',
    unified: '1F428',
    keywords: [
      'koala',
      'animal',
      'nature'
    ],
    sheet: [12, 2],
    shortName: 'koala'
  },
  {
    name: 'Panda Face',
    unified: '1F43C',
    keywords: [
      'panda',
      'animal',
      'nature',
      'panda'
    ],
    sheet: [12, 23],
    shortName: 'panda_face'
  },
  {
    name: 'Sloth',
    unified: '1F9A5',
    keywords: ['sloth', 'animal'],
    sheet: [43, 12],
    shortName: 'sloth'
  },
  {
    name: 'Otter',
    unified: '1F9A6',
    keywords: ['otter', 'animal'],
    sheet: [43, 13],
    shortName: 'otter'
  },
  {
    name: 'Skunk',
    unified: '1F9A8',
    keywords: ['skunk', 'animal'],
    sheet: [43, 15],
    shortName: 'skunk'
  },
  {
    name: 'Kangaroo',
    unified: '1F998',
    keywords: [
      'kangaroo',
      'animal',
      'nature',
      'australia',
      'joey',
      'hop',
      'marsupial'
    ],
    sheet: [42, 57],
    shortName: 'kangaroo'
  },
  {
    name: 'Badger',
    unified: '1F9A1',
    keywords: [
      'badger',
      'animal',
      'nature',
      'honey'
    ],
    sheet: [43, 8],
    shortName: 'badger'
  },
  {
    name: 'Paw Prints',
    unified: '1F43E',
    keywords: [
      'paw_prints',
      'animal',
      'tracking',
      'footprints',
      'dog',
      'cat',
      'pet',
      'feet'
    ],
    sheet: [12, 25],
    shortNames: ['paw_prints'],
    shortName: 'feet'
  },
  {
    name: 'Turkey',
    unified: '1F983',
    keywords: [
      'turkey',
      'animal',
      'bird'
    ],
    sheet: [42, 36],
    shortName: 'turkey'
  },
  {
    name: 'Chicken',
    unified: '1F414',
    keywords: [
      'chicken',
      'animal',
      'cluck',
      'nature',
      'bird'
    ],
    sheet: [11, 39],
    shortName: 'chicken'
  },
  {
    name: 'Rooster',
    unified: '1F413',
    keywords: [
      'rooster',
      'animal',
      'nature',
      'chicken'
    ],
    sheet: [11, 38],
    shortName: 'rooster'
  },
  {
    name: 'Hatching Chick',
    unified: '1F423',
    keywords: [
      'hatching_chick',
      'animal',
      'chicken',
      'egg',
      'born',
      'baby',
      'bird'
    ],
    sheet: [11, 55],
    shortName: 'hatching_chick'
  },
  {
    name: 'Baby Chick',
    unified: '1F424',
    keywords: [
      'baby_chick',
      'animal',
      'chicken',
      'bird'
    ],
    sheet: [11, 56],
    shortName: 'baby_chick'
  },
  {
    name: 'Front-Facing Baby Chick',
    unified: '1F425',
    keywords: [
      'front_facing_baby_chick',
      'animal',
      'chicken',
      'baby',
      'bird'
    ],
    sheet: [11, 57],
    shortName: 'hatched_chick'
  },
  {
    name: 'Bird',
    unified: '1F426',
    keywords: [
      'bird',
      'animal',
      'nature',
      'fly',
      'tweet',
      'spring'
    ],
    sheet: [12, 0],
    shortName: 'bird'
  },
  {
    name: 'Penguin',
    unified: '1F427',
    keywords: [
      'penguin',
      'animal',
      'nature'
    ],
    sheet: [12, 1],
    shortName: 'penguin'
  },
  {
    name: 'Dove',
    unified: '1F54A-FE0F',
    keywords: [
      'dove',
      'animal',
      'bird'
    ],
    sheet: [28, 33],
    shortName: 'dove_of_peace'
  },
  {
    name: 'Eagle',
    unified: '1F985',
    keywords: [
      'eagle',
      'animal',
      'nature',
      'bird'
    ],
    sheet: [42, 38],
    shortName: 'eagle'
  },
  {
    name: 'Duck',
    unified: '1F986',
    keywords: [
      'duck',
      'animal',
      'nature',
      'bird',
      'mallard'
    ],
    sheet: [42, 39],
    shortName: 'duck'
  },
  {
    name: 'Swan',
    unified: '1F9A2',
    keywords: [
      'swan',
      'animal',
      'nature',
      'bird'
    ],
    sheet: [43, 9],
    shortName: 'swan'
  },
  {
    name: 'Owl',
    unified: '1F989',
    keywords: [
      'owl',
      'animal',
      'nature',
      'bird',
      'hoot'
    ],
    sheet: [42, 42],
    shortName: 'owl'
  },
  {
    name: 'Dodo',
    unified: '1F9A4',
    keywords: [
      'dodo',
      'animal',
      'bird'
    ],
    sheet: [43, 11],
    shortName: 'dodo'
  },
  {
    name: 'Feather',
    unified: '1FAB6',
    keywords: [
      'feather',
      'bird',
      'fly'
    ],
    sheet: [53, 5],
    shortName: 'feather'
  },
  {
    name: 'Flamingo',
    unified: '1F9A9',
    keywords: ['flamingo', 'animal'],
    sheet: [43, 16],
    shortName: 'flamingo'
  },
  {
    name: 'Peacock',
    unified: '1F99A',
    keywords: [
      'peacock',
      'animal',
      'nature',
      'peahen',
      'bird'
    ],
    sheet: [43, 1],
    shortName: 'peacock'
  },
  {
    name: 'Parrot',
    unified: '1F99C',
    keywords: [
      'parrot',
      'animal',
      'nature',
      'bird',
      'pirate',
      'talk'
    ],
    sheet: [43, 3],
    shortName: 'parrot'
  },
  {
    name: 'Frog Face',
    unified: '1F438',
    keywords: [
      'frog',
      'animal',
      'nature',
      'croak',
      'toad'
    ],
    sheet: [12, 18],
    shortName: 'frog'
  },
  {
    name: 'Crocodile',
    unified: '1F40A',
    keywords: [
      'crocodile',
      'animal',
      'nature',
      'reptile',
      'lizard',
      'alligator'
    ],
    sheet: [11, 29],
    shortName: 'crocodile'
  },
  {
    name: 'Turtle',
    unified: '1F422',
    keywords: [
      'turtle',
      'animal',
      'slow',
      'nature',
      'tortoise'
    ],
    sheet: [11, 54],
    shortName: 'turtle'
  },
  {
    name: 'Lizard',
    unified: '1F98E',
    keywords: [
      'lizard',
      'animal',
      'nature',
      'reptile'
    ],
    sheet: [42, 47],
    shortName: 'lizard'
  },
  {
    name: 'Snake',
    unified: '1F40D',
    keywords: [
      'snake',
      'animal',
      'evil',
      'nature',
      'hiss',
      'python'
    ],
    sheet: [11, 32],
    shortName: 'snake'
  },
  {
    name: 'Dragon Face',
    unified: '1F432',
    keywords: [
      'dragon_face',
      'animal',
      'myth',
      'nature',
      'chinese',
      'green'
    ],
    sheet: [12, 12],
    shortName: 'dragon_face'
  },
  {
    name: 'Dragon',
    unified: '1F409',
    keywords: [
      'dragon',
      'animal',
      'myth',
      'nature',
      'chinese',
      'green'
    ],
    sheet: [11, 28],
    shortName: 'dragon'
  },
  {
    name: 'Sauropod',
    unified: '1F995',
    keywords: [
      'sauropod',
      'animal',
      'nature',
      'dinosaur',
      'brachiosaurus',
      'brontosaurus',
      'diplodocus',
      'extinct'
    ],
    sheet: [42, 54],
    shortName: 'sauropod'
  },
  {
    name: 'T-Rex',
    unified: '1F996',
    keywords: [
      't_rex',
      'animal',
      'nature',
      'dinosaur',
      'tyrannosaurus',
      'extinct'
    ],
    sheet: [42, 55],
    shortName: 't-rex'
  },
  {
    name: 'Spouting Whale',
    unified: '1F433',
    keywords: [
      'spouting_whale',
      'animal',
      'nature',
      'sea',
      'ocean'
    ],
    sheet: [12, 13],
    shortName: 'whale'
  },
  {
    name: 'Whale',
    unified: '1F40B',
    keywords: [
      'whale',
      'animal',
      'nature',
      'sea',
      'ocean'
    ],
    sheet: [11, 30],
    shortName: 'whale2'
  },
  {
    name: 'Dolphin',
    unified: '1F42C',
    keywords: [
      'dolphin',
      'animal',
      'nature',
      'fish',
      'sea',
      'ocean',
      'flipper',
      'fins',
      'beach'
    ],
    sheet: [12, 6],
    shortNames: ['flipper'],
    shortName: 'dolphin'
  },
  {
    name: 'Seal',
    unified: '1F9AD',
    keywords: [
      'seal',
      'animal',
      'creature',
      'sea'
    ],
    sheet: [43, 20],
    shortName: 'seal'
  },
  {
    name: 'Fish',
    unified: '1F41F',
    keywords: [
      'fish',
      'animal',
      'food',
      'nature'
    ],
    sheet: [11, 51],
    shortName: 'fish'
  },
  {
    name: 'Tropical Fish',
    unified: '1F420',
    keywords: [
      'tropical_fish',
      'animal',
      'swim',
      'ocean',
      'beach',
      'nemo'
    ],
    sheet: [11, 52],
    shortName: 'tropical_fish'
  },
  {
    name: 'Blowfish',
    unified: '1F421',
    keywords: [
      'blowfish',
      'animal',
      'nature',
      'food',
      'sea',
      'ocean'
    ],
    sheet: [11, 53],
    shortName: 'blowfish'
  },
  {
    name: 'Shark',
    unified: '1F988',
    keywords: [
      'shark',
      'animal',
      'nature',
      'fish',
      'sea',
      'ocean',
      'jaws',
      'fins',
      'beach'
    ],
    sheet: [42, 41],
    shortName: 'shark'
  },
  {
    name: 'Octopus',
    unified: '1F419',
    keywords: [
      'octopus',
      'animal',
      'creature',
      'ocean',
      'sea',
      'nature',
      'beach'
    ],
    sheet: [11, 45],
    shortName: 'octopus'
  },
  {
    name: 'Spiral Shell',
    unified: '1F41A',
    keywords: [
      'spiral_shell',
      'nature',
      'sea',
      'beach'
    ],
    sheet: [11, 46],
    shortName: 'shell'
  },
  {
    name: 'Snail',
    unified: '1F40C',
    keywords: [
      'snail',
      'slow',
      'animal',
      'shell'
    ],
    sheet: [11, 31],
    shortName: 'snail'
  },
  {
    name: 'Butterfly',
    unified: '1F98B',
    keywords: [
      'butterfly',
      'animal',
      'insect',
      'nature',
      'caterpillar'
    ],
    sheet: [42, 44],
    shortName: 'butterfly'
  },
  {
    name: 'Bug',
    unified: '1F41B',
    keywords: [
      'bug',
      'animal',
      'insect',
      'nature',
      'worm'
    ],
    sheet: [11, 47],
    shortName: 'bug'
  },
  {
    name: 'Ant',
    unified: '1F41C',
    keywords: [
      'ant',
      'animal',
      'insect',
      'nature',
      'bug'
    ],
    sheet: [11, 48],
    shortName: 'ant'
  },
  {
    name: 'Honeybee',
    unified: '1F41D',
    keywords: [
      'honeybee',
      'animal',
      'insect',
      'nature',
      'bug',
      'spring',
      'honey'
    ],
    sheet: [11, 49],
    shortNames: ['honeybee'],
    shortName: 'bee'
  },
  {
    name: 'Beetle',
    unified: '1FAB2',
    keywords: ['beetle', 'insect'],
    sheet: [53, 1],
    shortName: 'beetle'
  },
  {
    name: 'Lady Beetle',
    unified: '1F41E',
    keywords: [
      'lady_beetle',
      'animal',
      'insect',
      'nature',
      'ladybug'
    ],
    sheet: [11, 50],
    shortNames: ['lady_beetle'],
    shortName: 'ladybug'
  },
  {
    name: 'Cricket',
    unified: '1F997',
    keywords: [
      'cricket',
      'animal',
      'cricket',
      'chirp'
    ],
    sheet: [42, 56],
    shortName: 'cricket'
  },
  {
    name: 'Cockroach',
    unified: '1FAB3',
    keywords: [
      'cockroach',
      'insect',
      'pests'
    ],
    sheet: [53, 2],
    shortName: 'cockroach'
  },
  {
    name: 'Spider',
    unified: '1F577-FE0F',
    keywords: [
      'spider',
      'animal',
      'arachnid'
    ],
    sheet: [29, 32],
    shortName: 'spider'
  },
  {
    name: 'Spider Web',
    unified: '1F578-FE0F',
    keywords: [
      'spider_web',
      'animal',
      'insect',
      'arachnid',
      'silk'
    ],
    sheet: [29, 33],
    shortName: 'spider_web'
  },
  {
    name: 'Scorpion',
    unified: '1F982',
    keywords: [
      'scorpion',
      'animal',
      'arachnid'
    ],
    sheet: [42, 35],
    shortName: 'scorpion'
  },
  {
    name: 'Mosquito',
    unified: '1F99F',
    keywords: [
      'mosquito',
      'animal',
      'nature',
      'insect',
      'malaria'
    ],
    sheet: [43, 6],
    shortName: 'mosquito'
  },
  {
    name: 'Fly',
    unified: '1FAB0',
    keywords: ['fly', 'insect'],
    sheet: [52, 57],
    shortName: 'fly'
  },
  {
    name: 'Worm',
    unified: '1FAB1',
    keywords: ['worm', 'animal'],
    sheet: [53, 0],
    shortName: 'worm'
  },
  {
    name: 'Microbe',
    unified: '1F9A0',
    keywords: [
      'microbe',
      'amoeba',
      'bacteria',
      'germs'
    ],
    sheet: [43, 7],
    shortName: 'microbe'
  },
  {
    name: 'Bouquet',
    unified: '1F490',
    keywords: [
      'bouquet',
      'flowers',
      'nature',
      'spring'
    ],
    sheet: [25, 28],
    shortName: 'bouquet'
  },
  {
    name: 'Cherry Blossom',
    unified: '1F338',
    keywords: [
      'cherry_blossom',
      'nature',
      'plant',
      'spring',
      'flower'
    ],
    sheet: [6, 10],
    shortName: 'cherry_blossom'
  },
  {
    name: 'White Flower',
    unified: '1F4AE',
    keywords: [
      'white_flower',
      'japanese',
      'spring'
    ],
    sheet: [26, 5],
    shortName: 'white_flower'
  },
  {
    name: 'Rosette',
    unified: '1F3F5-FE0F',
    keywords: [
      'rosette',
      'flower',
      'decoration',
      'military'
    ],
    sheet: [11, 8],
    shortName: 'rosette'
  },
  {
    name: 'Rose',
    unified: '1F339',
    keywords: [
      'rose',
      'flowers',
      'valentines',
      'love',
      'spring'
    ],
    sheet: [6, 11],
    shortName: 'rose'
  },
  {
    name: 'Wilted Flower',
    unified: '1F940',
    keywords: [
      'wilted_flower',
      'plant',
      'nature',
      'flower'
    ],
    sheet: [41, 24],
    shortName: 'wilted_flower'
  },
  {
    name: 'Hibiscus',
    unified: '1F33A',
    keywords: [
      'hibiscus',
      'plant',
      'vegetable',
      'flowers',
      'beach'
    ],
    sheet: [6, 12],
    shortName: 'hibiscus'
  },
  {
    name: 'Sunflower',
    unified: '1F33B',
    keywords: [
      'sunflower',
      'nature',
      'plant',
      'fall'
    ],
    sheet: [6, 13],
    shortName: 'sunflower'
  },
  {
    name: 'Blossom',
    unified: '1F33C',
    keywords: [
      'blossom',
      'nature',
      'flowers',
      'yellow'
    ],
    sheet: [6, 14],
    shortName: 'blossom'
  },
  {
    name: 'Tulip',
    unified: '1F337',
    keywords: [
      'tulip',
      'flowers',
      'plant',
      'nature',
      'summer',
      'spring'
    ],
    sheet: [6, 9],
    shortName: 'tulip'
  },
  {
    name: 'Seedling',
    unified: '1F331',
    keywords: [
      'seedling',
      'plant',
      'nature',
      'grass',
      'lawn',
      'spring'
    ],
    sheet: [6, 3],
    shortName: 'seedling'
  },
  {
    name: 'Potted Plant',
    unified: '1FAB4',
    keywords: [
      'potted plant',
      'greenery',
      'house'
    ],
    sheet: [53, 3],
    shortName: 'potted_plant'
  },
  {
    name: 'Evergreen Tree',
    unified: '1F332',
    keywords: [
      'evergreen_tree',
      'plant',
      'nature'
    ],
    sheet: [6, 4],
    shortName: 'evergreen_tree'
  },
  {
    name: 'Deciduous Tree',
    unified: '1F333',
    keywords: [
      'deciduous_tree',
      'plant',
      'nature'
    ],
    sheet: [6, 5],
    shortName: 'deciduous_tree'
  },
  {
    name: 'Palm Tree',
    unified: '1F334',
    keywords: [
      'palm_tree',
      'plant',
      'vegetable',
      'nature',
      'summer',
      'beach',
      'mojito',
      'tropical'
    ],
    sheet: [6, 6],
    shortName: 'palm_tree'
  },
  {
    name: 'Cactus',
    unified: '1F335',
    keywords: [
      'cactus',
      'vegetable',
      'plant',
      'nature'
    ],
    sheet: [6, 7],
    shortName: 'cactus'
  },
  {
    name: 'Ear of Rice',
    unified: '1F33E',
    keywords: [
      'sheaf_of_rice',
      'nature',
      'plant'
    ],
    sheet: [6, 16],
    shortName: 'ear_of_rice'
  },
  {
    name: 'Herb',
    unified: '1F33F',
    keywords: [
      'herb',
      'vegetable',
      'plant',
      'medicine',
      'weed',
      'grass',
      'lawn'
    ],
    sheet: [6, 17],
    shortName: 'herb'
  },
  {
    name: 'Shamrock',
    unified: '2618-FE0F',
    keywords: [
      'shamrock',
      'vegetable',
      'plant',
      'nature',
      'irish',
      'clover'
    ],
    sheet: [54, 6],
    shortName: 'shamrock'
  },
  {
    name: 'Four Leaf Clover',
    unified: '1F340',
    keywords: [
      'four_leaf_clover',
      'vegetable',
      'plant',
      'nature',
      'lucky',
      'irish'
    ],
    sheet: [6, 18],
    shortName: 'four_leaf_clover'
  },
  {
    name: 'Maple Leaf',
    unified: '1F341',
    keywords: [
      'maple_leaf',
      'nature',
      'plant',
      'vegetable',
      'ca',
      'fall'
    ],
    sheet: [6, 19],
    shortName: 'maple_leaf'
  },
  {
    name: 'Fallen Leaf',
    unified: '1F342',
    keywords: [
      'fallen_leaf',
      'nature',
      'plant',
      'vegetable',
      'leaves'
    ],
    sheet: [6, 20],
    shortName: 'fallen_leaf'
  },
  {
    name: 'Leaf Fluttering in Wind',
    unified: '1F343',
    keywords: [
      'leaf_fluttering_in_wind',
      'nature',
      'plant',
      'tree',
      'vegetable',
      'grass',
      'lawn',
      'spring'
    ],
    sheet: [6, 21],
    shortName: 'leaves'
  },
  {
    name: 'Grapes',
    unified: '1F347',
    keywords: [
      'grapes',
      'fruit',
      'food',
      'wine'
    ],
    sheet: [6, 25],
    shortName: 'grapes'
  },
  {
    name: 'Melon',
    unified: '1F348',
    keywords: [
      'melon',
      'fruit',
      'nature',
      'food'
    ],
    sheet: [6, 26],
    shortName: 'melon'
  },
  {
    name: 'Watermelon',
    unified: '1F349',
    keywords: [
      'watermelon',
      'fruit',
      'food',
      'picnic',
      'summer'
    ],
    sheet: [6, 27],
    shortName: 'watermelon'
  },
  {
    name: 'Tangerine',
    unified: '1F34A',
    keywords: [
      'tangerine',
      'food',
      'fruit',
      'nature',
      'orange'
    ],
    sheet: [6, 28],
    shortName: 'tangerine'
  },
  {
    name: 'Lemon',
    unified: '1F34B',
    keywords: [
      'lemon',
      'fruit',
      'nature'
    ],
    sheet: [6, 29],
    shortName: 'lemon'
  },
  {
    name: 'Banana',
    unified: '1F34C',
    keywords: [
      'banana',
      'fruit',
      'food',
      'monkey'
    ],
    sheet: [6, 30],
    shortName: 'banana'
  },
  {
    name: 'Pineapple',
    unified: '1F34D',
    keywords: [
      'pineapple',
      'fruit',
      'nature',
      'food'
    ],
    sheet: [6, 31],
    shortName: 'pineapple'
  },
  {
    name: 'Mango',
    unified: '1F96D',
    keywords: [
      'mango',
      'fruit',
      'food',
      'tropical'
    ],
    sheet: [42, 10],
    shortName: 'mango'
  },
  {
    name: 'Red Apple',
    unified: '1F34E',
    keywords: [
      'red_apple',
      'fruit',
      'mac',
      'school'
    ],
    sheet: [6, 32],
    shortName: 'apple'
  },
  {
    name: 'Green Apple',
    unified: '1F34F',
    keywords: [
      'green_apple',
      'fruit',
      'nature'
    ],
    sheet: [6, 33],
    shortName: 'green_apple'
  },
  {
    name: 'Pear',
    unified: '1F350',
    keywords: [
      'pear',
      'fruit',
      'nature',
      'food'
    ],
    sheet: [6, 34],
    shortName: 'pear'
  },
  {
    name: 'Peach',
    unified: '1F351',
    keywords: [
      'peach',
      'fruit',
      'nature',
      'food'
    ],
    sheet: [6, 35],
    shortName: 'peach'
  },
  {
    name: 'Cherries',
    unified: '1F352',
    keywords: [
      'cherries',
      'food',
      'fruit'
    ],
    sheet: [6, 36],
    shortName: 'cherries'
  },
  {
    name: 'Strawberry',
    unified: '1F353',
    keywords: [
      'strawberry',
      'fruit',
      'food',
      'nature'
    ],
    sheet: [6, 37],
    shortName: 'strawberry'
  },
  {
    name: 'Blueberries',
    unified: '1FAD0',
    keywords: ['blueberries', 'fruit'],
    sheet: [53, 9],
    shortName: 'blueberries'
  },
  {
    name: 'Kiwifruit',
    unified: '1F95D',
    keywords: [
      'kiwi_fruit',
      'fruit',
      'food'
    ],
    sheet: [41, 52],
    shortName: 'kiwifruit'
  },
  {
    name: 'Tomato',
    unified: '1F345',
    keywords: [
      'tomato',
      'fruit',
      'vegetable',
      'nature',
      'food'
    ],
    sheet: [6, 23],
    shortName: 'tomato'
  },
  {
    name: 'Olive',
    unified: '1FAD2',
    keywords: ['olive', 'fruit'],
    sheet: [53, 11],
    shortName: 'olive'
  },
  {
    name: 'Coconut',
    unified: '1F965',
    keywords: [
      'coconut',
      'fruit',
      'nature',
      'food',
      'palm'
    ],
    sheet: [42, 2],
    shortName: 'coconut'
  },
  {
    name: 'Avocado',
    unified: '1F951',
    keywords: [
      'avocado',
      'fruit',
      'food'
    ],
    sheet: [41, 40],
    shortName: 'avocado'
  },
  {
    name: 'Aubergine',
    unified: '1F346',
    keywords: [
      'eggplant',
      'vegetable',
      'nature',
      'food',
      'aubergine'
    ],
    sheet: [6, 24],
    shortName: 'eggplant'
  },
  {
    name: 'Potato',
    unified: '1F954',
    keywords: [
      'potato',
      'food',
      'tuber',
      'vegatable',
      'starch'
    ],
    sheet: [41, 43],
    shortName: 'potato'
  },
  {
    name: 'Carrot',
    unified: '1F955',
    keywords: [
      'carrot',
      'vegetable',
      'food',
      'orange'
    ],
    sheet: [41, 44],
    shortName: 'carrot'
  },
  {
    name: 'Ear of Maize',
    unified: '1F33D',
    keywords: [
      'ear_of_corn',
      'food',
      'vegetable',
      'plant'
    ],
    sheet: [6, 15],
    shortName: 'corn'
  },
  {
    name: 'Hot Pepper',
    unified: '1F336-FE0F',
    keywords: [
      'hot_pepper',
      'food',
      'spicy',
      'chilli',
      'chili'
    ],
    sheet: [6, 8],
    shortName: 'hot_pepper'
  },
  {
    name: 'Bell Pepper',
    unified: '1FAD1',
    keywords: [
      'bell pepper',
      'fruit',
      'plant'
    ],
    sheet: [53, 10],
    shortName: 'bell_pepper'
  },
  {
    name: 'Cucumber',
    unified: '1F952',
    keywords: [
      'cucumber',
      'fruit',
      'food',
      'pickle'
    ],
    sheet: [41, 41],
    shortName: 'cucumber'
  },
  {
    name: 'Leafy Green',
    unified: '1F96C',
    keywords: [
      'leafy_green',
      'food',
      'vegetable',
      'plant',
      'bok choy',
      'cabbage',
      'kale',
      'lettuce'
    ],
    sheet: [42, 9],
    shortName: 'leafy_green'
  },
  {
    name: 'Broccoli',
    unified: '1F966',
    keywords: [
      'broccoli',
      'fruit',
      'food',
      'vegetable'
    ],
    sheet: [42, 3],
    shortName: 'broccoli'
  },
  {
    name: 'Garlic',
    unified: '1F9C4',
    keywords: [
      'garlic',
      'food',
      'spice',
      'cook'
    ],
    sheet: [44, 30],
    shortName: 'garlic'
  },
  {
    name: 'Onion',
    unified: '1F9C5',
    keywords: [
      'onion',
      'cook',
      'food',
      'spice'
    ],
    sheet: [44, 31],
    shortName: 'onion'
  },
  {
    name: 'Mushroom',
    unified: '1F344',
    keywords: [
      'mushroom',
      'plant',
      'vegetable'
    ],
    sheet: [6, 22],
    shortName: 'mushroom'
  },
  {
    name: 'Peanuts',
    unified: '1F95C',
    keywords: [
      'peanuts',
      'food',
      'nut'
    ],
    sheet: [41, 51],
    shortName: 'peanuts'
  },
  {
    name: 'Chestnut',
    unified: '1F330',
    keywords: [
      'chestnut',
      'food',
      'squirrel'
    ],
    sheet: [6, 2],
    shortName: 'chestnut'
  },
  {
    name: 'Bread',
    unified: '1F35E',
    keywords: [
      'bread',
      'food',
      'wheat',
      'breakfast',
      'toast'
    ],
    sheet: [6, 48],
    shortName: 'bread'
  },
  {
    name: 'Croissant',
    unified: '1F950',
    keywords: [
      'croissant',
      'food',
      'bread',
      'french'
    ],
    sheet: [41, 39],
    shortName: 'croissant'
  },
  {
    name: 'Baguette Bread',
    unified: '1F956',
    keywords: [
      'baguette_bread',
      'food',
      'bread',
      'french'
    ],
    sheet: [41, 45],
    shortName: 'baguette_bread'
  },
  {
    name: 'Flatbread',
    unified: '1FAD3',
    keywords: [
      'flatbread',
      'flour',
      'food'
    ],
    sheet: [53, 12],
    shortName: 'flatbread'
  },
  {
    name: 'Pretzel',
    unified: '1F968',
    keywords: [
      'pretzel',
      'food',
      'bread',
      'twisted'
    ],
    sheet: [42, 5],
    shortName: 'pretzel'
  },
  {
    name: 'Bagel',
    unified: '1F96F',
    keywords: [
      'bagel',
      'food',
      'bread',
      'bakery',
      'schmear'
    ],
    sheet: [42, 12],
    shortName: 'bagel'
  },
  {
    name: 'Pancakes',
    unified: '1F95E',
    keywords: [
      'pancakes',
      'food',
      'breakfast',
      'flapjacks',
      'hotcakes'
    ],
    sheet: [41, 53],
    shortName: 'pancakes'
  },
  {
    name: 'Waffle',
    unified: '1F9C7',
    keywords: [
      'waffle',
      'food',
      'breakfast'
    ],
    sheet: [44, 33],
    shortName: 'waffle'
  },
  {
    name: 'Cheese Wedge',
    unified: '1F9C0',
    keywords: [
      'cheese_wedge',
      'food',
      'chadder'
    ],
    sheet: [44, 26],
    shortName: 'cheese_wedge'
  },
  {
    name: 'Meat on Bone',
    unified: '1F356',
    keywords: [
      'meat_on_bone',
      'good',
      'food',
      'drumstick'
    ],
    sheet: [6, 40],
    shortName: 'meat_on_bone'
  },
  {
    name: 'Poultry Leg',
    unified: '1F357',
    keywords: [
      'poultry_leg',
      'food',
      'meat',
      'drumstick',
      'bird',
      'chicken',
      'turkey'
    ],
    sheet: [6, 41],
    shortName: 'poultry_leg'
  },
  {
    name: 'Cut of Meat',
    unified: '1F969',
    keywords: [
      'cut_of_meat',
      'food',
      'cow',
      'meat',
      'cut',
      'chop',
      'lambchop',
      'porkchop'
    ],
    sheet: [42, 6],
    shortName: 'cut_of_meat'
  },
  {
    name: 'Bacon',
    unified: '1F953',
    keywords: [
      'bacon',
      'food',
      'breakfast',
      'pork',
      'pig',
      'meat'
    ],
    sheet: [41, 42],
    shortName: 'bacon'
  },
  {
    name: 'Hamburger',
    unified: '1F354',
    keywords: [
      'hamburger',
      'meat',
      'fast food',
      'beef',
      'cheeseburger',
      'mcdonalds',
      'burger king'
    ],
    sheet: [6, 38],
    shortName: 'hamburger'
  },
  {
    name: 'French Fries',
    unified: '1F35F',
    keywords: [
      'french_fries',
      'chips',
      'snack',
      'fast food'
    ],
    sheet: [6, 49],
    shortName: 'fries'
  },
  {
    name: 'Slice of Pizza',
    unified: '1F355',
    keywords: [
      'pizza',
      'food',
      'party'
    ],
    sheet: [6, 39],
    shortName: 'pizza'
  },
  {
    name: 'Hot Dog',
    unified: '1F32D',
    keywords: [
      'hot_dog',
      'food',
      'frankfurter'
    ],
    sheet: [5, 57],
    shortName: 'hotdog'
  },
  {
    name: 'Sandwich',
    unified: '1F96A',
    keywords: [
      'sandwich',
      'food',
      'lunch',
      'bread'
    ],
    sheet: [42, 7],
    shortName: 'sandwich'
  },
  {
    name: 'Taco',
    unified: '1F32E',
    keywords: [
      'taco',
      'food',
      'mexican'
    ],
    sheet: [6, 0],
    shortName: 'taco'
  },
  {
    name: 'Burrito',
    unified: '1F32F',
    keywords: [
      'burrito',
      'food',
      'mexican'
    ],
    sheet: [6, 1],
    shortName: 'burrito'
  },
  {
    name: 'Tamale',
    unified: '1FAD4',
    keywords: [
      'tamale',
      'food',
      'masa'
    ],
    sheet: [53, 13],
    shortName: 'tamale'
  },
  {
    name: 'Stuffed Flatbread',
    unified: '1F959',
    keywords: [
      'stuffed_flatbread',
      'food',
      'flatbread',
      'stuffed',
      'gyro'
    ],
    sheet: [41, 48],
    shortName: 'stuffed_flatbread'
  },
  {
    name: 'Falafel',
    unified: '1F9C6',
    keywords: ['falafel', 'food'],
    sheet: [44, 32],
    shortName: 'falafel'
  },
  {
    name: 'Egg',
    unified: '1F95A',
    keywords: [
      'egg',
      'food',
      'chicken',
      'breakfast'
    ],
    sheet: [41, 49],
    shortName: 'egg'
  },
  {
    name: 'Cooking',
    unified: '1F373',
    keywords: [
      'cooking',
      'food',
      'breakfast',
      'kitchen',
      'egg'
    ],
    sheet: [7, 11],
    shortNames: ['cooking'],
    shortName: 'fried_egg'
  },
  {
    name: 'Shallow Pan of Food',
    unified: '1F958',
    keywords: [
      'shallow_pan_of_food',
      'food',
      'cooking',
      'casserole',
      'paella'
    ],
    sheet: [41, 47],
    shortName: 'shallow_pan_of_food'
  },
  {
    name: 'Pot of Food',
    unified: '1F372',
    keywords: [
      'pot_of_food',
      'food',
      'meat',
      'soup'
    ],
    sheet: [7, 10],
    shortName: 'stew'
  },
  {
    name: 'Fondue',
    unified: '1FAD5',
    keywords: [
      'fondue',
      'cheese',
      'pot',
      'food'
    ],
    sheet: [53, 14],
    shortName: 'fondue'
  },
  {
    name: 'Bowl with Spoon',
    unified: '1F963',
    keywords: [
      'bowl_with_spoon',
      'food',
      'breakfast',
      'cereal',
      'oatmeal',
      'porridge'
    ],
    sheet: [42, 0],
    shortName: 'bowl_with_spoon'
  },
  {
    name: 'Green Salad',
    unified: '1F957',
    keywords: [
      'green_salad',
      'food',
      'healthy',
      'lettuce'
    ],
    sheet: [41, 46],
    shortName: 'green_salad'
  },
  {
    name: 'Popcorn',
    unified: '1F37F',
    keywords: [
      'popcorn',
      'food',
      'movie theater',
      'films',
      'snack'
    ],
    sheet: [7, 23],
    shortName: 'popcorn'
  },
  {
    name: 'Butter',
    unified: '1F9C8',
    keywords: [
      'butter',
      'food',
      'cook'
    ],
    sheet: [44, 34],
    shortName: 'butter'
  },
  {
    name: 'Salt Shaker',
    unified: '1F9C2',
    keywords: [
      'salt',
      'condiment',
      'shaker'
    ],
    sheet: [44, 28],
    shortName: 'salt'
  },
  {
    name: 'Canned Food',
    unified: '1F96B',
    keywords: [
      'canned_food',
      'food',
      'soup'
    ],
    sheet: [42, 8],
    shortName: 'canned_food'
  },
  {
    name: 'Bento Box',
    unified: '1F371',
    keywords: [
      'bento_box',
      'food',
      'japanese',
      'box'
    ],
    sheet: [7, 9],
    shortName: 'bento'
  },
  {
    name: 'Rice Cracker',
    unified: '1F358',
    keywords: [
      'rice_cracker',
      'food',
      'japanese'
    ],
    sheet: [6, 42],
    shortName: 'rice_cracker'
  },
  {
    name: 'Rice Ball',
    unified: '1F359',
    keywords: [
      'rice_ball',
      'food',
      'japanese'
    ],
    sheet: [6, 43],
    shortName: 'rice_ball'
  },
  {
    name: 'Cooked Rice',
    unified: '1F35A',
    keywords: [
      'cooked_rice',
      'food',
      'china',
      'asian'
    ],
    sheet: [6, 44],
    shortName: 'rice'
  },
  {
    name: 'Curry and Rice',
    unified: '1F35B',
    keywords: [
      'curry_rice',
      'food',
      'spicy',
      'hot',
      'indian'
    ],
    sheet: [6, 45],
    shortName: 'curry'
  },
  {
    name: 'Steaming Bowl',
    unified: '1F35C',
    keywords: [
      'steaming_bowl',
      'food',
      'japanese',
      'noodle',
      'chopsticks'
    ],
    sheet: [6, 46],
    shortName: 'ramen'
  },
  {
    name: 'Spaghetti',
    unified: '1F35D',
    keywords: [
      'spaghetti',
      'food',
      'italian',
      'noodle'
    ],
    sheet: [6, 47],
    shortName: 'spaghetti'
  },
  {
    name: 'Roasted Sweet Potato',
    unified: '1F360',
    keywords: [
      'roasted_sweet_potato',
      'food',
      'nature'
    ],
    sheet: [6, 50],
    shortName: 'sweet_potato'
  },
  {
    name: 'Oden',
    unified: '1F362',
    keywords: [
      'oden',
      'food',
      'japanese'
    ],
    sheet: [6, 52],
    shortName: 'oden'
  },
  {
    name: 'Sushi',
    unified: '1F363',
    keywords: [
      'sushi',
      'food',
      'fish',
      'japanese',
      'rice'
    ],
    sheet: [6, 53],
    shortName: 'sushi'
  },
  {
    name: 'Fried Shrimp',
    unified: '1F364',
    keywords: [
      'fried_shrimp',
      'food',
      'animal',
      'appetizer',
      'summer'
    ],
    sheet: [6, 54],
    shortName: 'fried_shrimp'
  },
  {
    name: 'Fish Cake with Swirl Design',
    unified: '1F365',
    keywords: [
      'fish_cake_with_swirl',
      'food',
      'japan',
      'sea',
      'beach',
      'narutomaki',
      'pink',
      'swirl',
      'kamaboko',
      'surimi',
      'ramen'
    ],
    sheet: [6, 55],
    shortName: 'fish_cake'
  },
  {
    name: 'Moon Cake',
    unified: '1F96E',
    keywords: [
      'moon_cake',
      'food',
      'autumn'
    ],
    sheet: [42, 11],
    shortName: 'moon_cake'
  },
  {
    name: 'Dango',
    unified: '1F361',
    keywords: [
      'dango',
      'food',
      'dessert',
      'sweet',
      'japanese',
      'barbecue',
      'meat'
    ],
    sheet: [6, 51],
    shortName: 'dango'
  },
  {
    name: 'Dumpling',
    unified: '1F95F',
    keywords: [
      'dumpling',
      'food',
      'empanada',
      'pierogi',
      'potsticker'
    ],
    sheet: [41, 54],
    shortName: 'dumpling'
  },
  {
    name: 'Fortune Cookie',
    unified: '1F960',
    keywords: [
      'fortune_cookie',
      'food',
      'prophecy'
    ],
    sheet: [41, 55],
    shortName: 'fortune_cookie'
  },
  {
    name: 'Takeout Box',
    unified: '1F961',
    keywords: [
      'takeout_box',
      'food',
      'leftovers'
    ],
    sheet: [41, 56],
    shortName: 'takeout_box'
  },
  {
    name: 'Crab',
    unified: '1F980',
    keywords: [
      'crab',
      'animal',
      'crustacean'
    ],
    sheet: [42, 33],
    shortName: 'crab'
  },
  {
    name: 'Lobster',
    unified: '1F99E',
    keywords: [
      'lobster',
      'animal',
      'nature',
      'bisque',
      'claws',
      'seafood'
    ],
    sheet: [43, 5],
    shortName: 'lobster'
  },
  {
    name: 'Shrimp',
    unified: '1F990',
    keywords: [
      'shrimp',
      'animal',
      'ocean',
      'nature',
      'seafood'
    ],
    sheet: [42, 49],
    shortName: 'shrimp'
  },
  {
    name: 'Squid',
    unified: '1F991',
    keywords: [
      'squid',
      'animal',
      'nature',
      'ocean',
      'sea'
    ],
    sheet: [42, 50],
    shortName: 'squid'
  },
  {
    name: 'Oyster',
    unified: '1F9AA',
    keywords: ['oyster', 'food'],
    sheet: [43, 17],
    shortName: 'oyster'
  },
  {
    name: 'Soft Ice Cream',
    unified: '1F366',
    keywords: [
      'soft_ice_cream',
      'food',
      'hot',
      'dessert',
      'summer'
    ],
    sheet: [6, 56],
    shortName: 'icecream'
  },
  {
    name: 'Shaved Ice',
    unified: '1F367',
    keywords: [
      'shaved_ice',
      'hot',
      'dessert',
      'summer'
    ],
    sheet: [6, 57],
    shortName: 'shaved_ice'
  },
  {
    name: 'Ice Cream',
    unified: '1F368',
    keywords: [
      'ice_cream',
      'food',
      'hot',
      'dessert'
    ],
    sheet: [7, 0],
    shortName: 'ice_cream'
  },
  {
    name: 'Doughnut',
    unified: '1F369',
    keywords: [
      'doughnut',
      'food',
      'dessert',
      'snack',
      'sweet',
      'donut'
    ],
    sheet: [7, 1],
    shortName: 'doughnut'
  },
  {
    name: 'Cookie',
    unified: '1F36A',
    keywords: [
      'cookie',
      'food',
      'snack',
      'oreo',
      'chocolate',
      'sweet',
      'dessert'
    ],
    sheet: [7, 2],
    shortName: 'cookie'
  },
  {
    name: 'Birthday Cake',
    unified: '1F382',
    keywords: [
      'birthday_cake',
      'food',
      'dessert',
      'cake'
    ],
    sheet: [7, 26],
    shortName: 'birthday'
  },
  {
    name: 'Shortcake',
    unified: '1F370',
    keywords: [
      'shortcake',
      'food',
      'dessert'
    ],
    sheet: [7, 8],
    shortName: 'cake'
  },
  {
    name: 'Cupcake',
    unified: '1F9C1',
    keywords: [
      'cupcake',
      'food',
      'dessert',
      'bakery',
      'sweet'
    ],
    sheet: [44, 27],
    shortName: 'cupcake'
  },
  {
    name: 'Pie',
    unified: '1F967',
    keywords: [
      'pie',
      'food',
      'dessert',
      'pastry'
    ],
    sheet: [42, 4],
    shortName: 'pie'
  },
  {
    name: 'Chocolate Bar',
    unified: '1F36B',
    keywords: [
      'chocolate_bar',
      'food',
      'snack',
      'dessert',
      'sweet'
    ],
    sheet: [7, 3],
    shortName: 'chocolate_bar'
  },
  {
    name: 'Candy',
    unified: '1F36C',
    keywords: [
      'candy',
      'snack',
      'dessert',
      'sweet',
      'lolly'
    ],
    sheet: [7, 4],
    shortName: 'candy'
  },
  {
    name: 'Lollipop',
    unified: '1F36D',
    keywords: [
      'lollipop',
      'food',
      'snack',
      'candy',
      'sweet'
    ],
    sheet: [7, 5],
    shortName: 'lollipop'
  },
  {
    name: 'Custard',
    unified: '1F36E',
    keywords: [
      'custard',
      'dessert',
      'food'
    ],
    sheet: [7, 6],
    shortName: 'custard'
  },
  {
    name: 'Honey Pot',
    unified: '1F36F',
    keywords: [
      'honey_pot',
      'bees',
      'sweet',
      'kitchen'
    ],
    sheet: [7, 7],
    shortName: 'honey_pot'
  },
  {
    name: 'Baby Bottle',
    unified: '1F37C',
    keywords: [
      'baby_bottle',
      'food',
      'container',
      'milk'
    ],
    sheet: [7, 20],
    shortName: 'baby_bottle'
  },
  {
    name: 'Glass of Milk',
    unified: '1F95B',
    keywords: [
      'glass_of_milk',
      'beverage',
      'drink',
      'cow'
    ],
    sheet: [41, 50],
    shortName: 'glass_of_milk'
  },
  {
    name: 'Hot Beverage',
    unified: '2615',
    keywords: [
      'hot_beverage',
      'beverage',
      'caffeine',
      'latte',
      'espresso'
    ],
    sheet: [54, 5],
    shortName: 'coffee'
  },
  {
    name: 'Teapot',
    unified: '1FAD6',
    keywords: [
      'teapot',
      'drink',
      'hot'
    ],
    sheet: [53, 15],
    shortName: 'teapot'
  },
  {
    name: 'Teacup Without Handle',
    unified: '1F375',
    keywords: [
      'teacup_without_handle',
      'drink',
      'bowl',
      'breakfast',
      'green',
      'british'
    ],
    sheet: [7, 13],
    shortName: 'tea'
  },
  {
    name: 'Sake Bottle and Cup',
    unified: '1F376',
    keywords: [
      'sake',
      'wine',
      'drink',
      'drunk',
      'beverage',
      'japanese',
      'alcohol',
      'booze'
    ],
    sheet: [7, 14],
    shortName: 'sake'
  },
  {
    name: 'Bottle with Popping Cork',
    unified: '1F37E',
    keywords: [
      'bottle_with_popping_cork',
      'drink',
      'wine',
      'bottle',
      'celebration'
    ],
    sheet: [7, 22],
    shortName: 'champagne'
  },
  {
    name: 'Wine Glass',
    unified: '1F377',
    keywords: [
      'wine_glass',
      'drink',
      'beverage',
      'drunk',
      'alcohol',
      'booze'
    ],
    sheet: [7, 15],
    shortName: 'wine_glass'
  },
  {
    name: 'Cocktail Glass',
    unified: '1F378',
    keywords: [
      'cocktail_glass',
      'drink',
      'drunk',
      'alcohol',
      'beverage',
      'booze',
      'mojito'
    ],
    sheet: [7, 16],
    shortName: 'cocktail'
  },
  {
    name: 'Tropical Drink',
    unified: '1F379',
    keywords: [
      'tropical_drink',
      'beverage',
      'cocktail',
      'summer',
      'beach',
      'alcohol',
      'booze',
      'mojito'
    ],
    sheet: [7, 17],
    shortName: 'tropical_drink'
  },
  {
    name: 'Beer Mug',
    unified: '1F37A',
    keywords: [
      'beer_mug',
      'relax',
      'beverage',
      'drink',
      'drunk',
      'party',
      'pub',
      'summer',
      'alcohol',
      'booze'
    ],
    sheet: [7, 18],
    shortName: 'beer'
  },
  {
    name: 'Clinking Beer Mugs',
    unified: '1F37B',
    keywords: [
      'clinking_beer_mugs',
      'relax',
      'beverage',
      'drink',
      'drunk',
      'party',
      'pub',
      'summer',
      'alcohol',
      'booze'
    ],
    sheet: [7, 19],
    shortName: 'beers'
  },
  {
    name: 'Clinking Glasses',
    unified: '1F942',
    keywords: [
      'clinking_glasses',
      'beverage',
      'drink',
      'party',
      'alcohol',
      'celebrate',
      'cheers',
      'wine',
      'champagne',
      'toast'
    ],
    sheet: [41, 26],
    shortName: 'clinking_glasses'
  },
  {
    name: 'Tumbler Glass',
    unified: '1F943',
    keywords: [
      'tumbler_glass',
      'drink',
      'beverage',
      'drunk',
      'alcohol',
      'liquor',
      'booze',
      'bourbon',
      'scotch',
      'whisky',
      'glass',
      'shot'
    ],
    sheet: [41, 27],
    shortName: 'tumbler_glass'
  },
  {
    name: 'Cup with Straw',
    unified: '1F964',
    keywords: [
      'cup_with_straw',
      'drink',
      'soda'
    ],
    sheet: [42, 1],
    shortName: 'cup_with_straw'
  },
  {
    name: 'Bubble Tea',
    unified: '1F9CB',
    keywords: [
      'bubble tea',
      'taiwan',
      'boba',
      'milk tea',
      'straw'
    ],
    sheet: [44, 37],
    shortName: 'bubble_tea'
  },
  {
    name: 'Beverage Box',
    unified: '1F9C3',
    keywords: ['beverage_box', 'drink'],
    sheet: [44, 29],
    shortName: 'beverage_box'
  },
  {
    name: 'Mate Drink',
    unified: '1F9C9',
    keywords: [
      'mate',
      'drink',
      'tea',
      'beverage'
    ],
    sheet: [44, 35],
    shortName: 'mate_drink'
  },
  {
    name: 'Ice Cube',
    unified: '1F9CA',
    keywords: ['ice', 'water', 'cold'],
    sheet: [44, 36],
    shortName: 'ice_cube'
  },
  {
    name: 'Chopsticks',
    unified: '1F962',
    keywords: ['chopsticks', 'food'],
    sheet: [41, 57],
    shortName: 'chopsticks'
  },
  {
    name: 'Fork and Knife with Plate',
    unified: '1F37D-FE0F',
    keywords: [
      'fork_and_knife_with_plate',
      'food',
      'eat',
      'meal',
      'lunch',
      'dinner',
      'restaurant'
    ],
    sheet: [7, 21],
    shortName: 'knife_fork_plate'
  },
  {
    name: 'Fork and Knife',
    unified: '1F374',
    keywords: [
      'fork_and_knife',
      'cutlery',
      'kitchen'
    ],
    sheet: [7, 12],
    shortName: 'fork_and_knife'
  },
  {
    name: 'Spoon',
    unified: '1F944',
    keywords: [
      'spoon',
      'cutlery',
      'kitchen',
      'tableware'
    ],
    sheet: [41, 28],
    shortName: 'spoon'
  },
  {
    name: 'Hocho',
    unified: '1F52A',
    keywords: [
      'kitchen_knife',
      'knife',
      'blade',
      'cutlery',
      'kitchen',
      'weapon'
    ],
    sheet: [28, 12],
    shortNames: ['knife'],
    shortName: 'hocho'
  },
  {
    name: 'Amphora',
    unified: '1F3FA',
    keywords: [
      'amphora',
      'vase',
      'jar'
    ],
    sheet: [11, 12],
    shortName: 'amphora'
  },
  {
    name: 'Earth Globe Europe-Africa',
    unified: '1F30D',
    keywords: [
      'globe_showing_europe_africa',
      'globe',
      'world',
      'international'
    ],
    sheet: [5, 27],
    shortName: 'earth_africa'
  },
  {
    name: 'Earth Globe Americas',
    unified: '1F30E',
    keywords: [
      'globe_showing_americas',
      'globe',
      'world',
      'USA',
      'international'
    ],
    sheet: [5, 28],
    shortName: 'earth_americas'
  },
  {
    name: 'Earth Globe Asia-Australia',
    unified: '1F30F',
    keywords: [
      'globe_showing_asia_australia',
      'globe',
      'world',
      'east',
      'international'
    ],
    sheet: [5, 29],
    shortName: 'earth_asia'
  },
  {
    name: 'Globe with Meridians',
    unified: '1F310',
    keywords: [
      'globe_with_meridians',
      'earth',
      'international',
      'world',
      'internet',
      'interweb',
      'i18n'
    ],
    sheet: [5, 30],
    shortName: 'globe_with_meridians'
  },
  {
    name: 'World Map',
    unified: '1F5FA-FE0F',
    keywords: [
      'world_map',
      'location',
      'direction'
    ],
    sheet: [30, 26],
    shortName: 'world_map'
  },
  {
    name: 'Silhouette of Japan',
    unified: '1F5FE',
    keywords: [
      'map_of_japan',
      'nation',
      'country',
      'japanese',
      'asia'
    ],
    sheet: [30, 30],
    shortName: 'japan'
  },
  {
    name: 'Compass',
    unified: '1F9ED',
    keywords: [
      'compass',
      'magnetic',
      'navigation',
      'orienteering'
    ],
    sheet: [51, 56],
    shortName: 'compass'
  },
  {
    name: 'Snow-Capped Mountain',
    unified: '1F3D4-FE0F',
    keywords: [
      'snow_capped_mountain',
      'photo',
      'nature',
      'environment',
      'winter',
      'cold'
    ],
    sheet: [10, 29],
    shortName: 'snow_capped_mountain'
  },
  {
    name: 'Mountain',
    unified: '26F0-FE0F',
    keywords: [
      'mountain',
      'photo',
      'nature',
      'environment'
    ],
    sheet: [55, 16],
    shortName: 'mountain'
  },
  {
    name: 'Volcano',
    unified: '1F30B',
    keywords: [
      'volcano',
      'photo',
      'nature',
      'disaster'
    ],
    sheet: [5, 25],
    shortName: 'volcano'
  },
  {
    name: 'Mount Fuji',
    unified: '1F5FB',
    keywords: [
      'mount_fuji',
      'photo',
      'mountain',
      'nature',
      'japanese'
    ],
    sheet: [30, 27],
    shortName: 'mount_fuji'
  },
  {
    name: 'Camping',
    unified: '1F3D5-FE0F',
    keywords: [
      'camping',
      'photo',
      'outdoors',
      'tent'
    ],
    sheet: [10, 30],
    shortName: 'camping'
  },
  {
    name: 'Beach with Umbrella',
    unified: '1F3D6-FE0F',
    keywords: [
      'beach_with_umbrella',
      'weather',
      'summer',
      'sunny',
      'sand',
      'mojito'
    ],
    sheet: [10, 31],
    shortName: 'beach_with_umbrella'
  },
  {
    name: 'Desert',
    unified: '1F3DC-FE0F',
    keywords: [
      'desert',
      'photo',
      'warm',
      'saharah'
    ],
    sheet: [10, 37],
    shortName: 'desert'
  },
  {
    name: 'Desert Island',
    unified: '1F3DD-FE0F',
    keywords: [
      'desert_island',
      'photo',
      'tropical',
      'mojito'
    ],
    sheet: [10, 38],
    shortName: 'desert_island'
  },
  {
    name: 'National Park',
    unified: '1F3DE-FE0F',
    keywords: [
      'national_park',
      'photo',
      'environment',
      'nature'
    ],
    sheet: [10, 39],
    shortName: 'national_park'
  },
  {
    name: 'Stadium',
    unified: '1F3DF-FE0F',
    keywords: [
      'stadium',
      'photo',
      'place',
      'sports',
      'concert',
      'venue'
    ],
    sheet: [10, 40],
    shortName: 'stadium'
  },
  {
    name: 'Classical Building',
    unified: '1F3DB-FE0F',
    keywords: [
      'classical_building',
      'art',
      'culture',
      'history'
    ],
    sheet: [10, 36],
    shortName: 'classical_building'
  },
  {
    name: 'Building Construction',
    unified: '1F3D7-FE0F',
    keywords: [
      'building_construction',
      'wip',
      'working',
      'progress'
    ],
    sheet: [10, 32],
    shortName: 'building_construction'
  },
  {
    name: 'Brick',
    unified: '1F9F1',
    keywords: ['brick', 'bricks'],
    sheet: [52, 2],
    shortName: 'bricks'
  },
  {
    name: 'Rock',
    unified: '1FAA8',
    keywords: ['rock', 'stone'],
    sheet: [52, 56],
    shortName: 'rock'
  },
  {
    name: 'Wood',
    unified: '1FAB5',
    keywords: [
      'wood',
      'nature',
      'timber',
      'trunk'
    ],
    sheet: [53, 4],
    shortName: 'wood'
  },
  {
    name: 'Hut',
    unified: '1F6D6',
    keywords: [
      'hut',
      'house',
      'structure'
    ],
    sheet: [36, 33],
    shortName: 'hut'
  },
  {
    name: 'Houses',
    unified: '1F3D8-FE0F',
    keywords: [
      'houses',
      'buildings',
      'photo'
    ],
    sheet: [10, 33],
    shortName: 'house_buildings'
  },
  {
    name: 'Derelict House',
    unified: '1F3DA-FE0F',
    keywords: [
      'derelict_house',
      'abandon',
      'evict',
      'broken',
      'building'
    ],
    sheet: [10, 35],
    shortName: 'derelict_house_building'
  },
  {
    name: 'House Building',
    unified: '1F3E0',
    keywords: [
      'house',
      'building',
      'home'
    ],
    sheet: [10, 41],
    shortName: 'house'
  },
  {
    name: 'House with Garden',
    unified: '1F3E1',
    keywords: [
      'house_with_garden',
      'home',
      'plant',
      'nature'
    ],
    sheet: [10, 42],
    shortName: 'house_with_garden'
  },
  {
    name: 'Office Building',
    unified: '1F3E2',
    keywords: [
      'office_building',
      'building',
      'bureau',
      'work'
    ],
    sheet: [10, 43],
    shortName: 'office'
  },
  {
    name: 'Japanese Post Office',
    unified: '1F3E3',
    keywords: [
      'japanese_post_office',
      'building',
      'envelope',
      'communication'
    ],
    sheet: [10, 44],
    shortName: 'post_office'
  },
  {
    name: 'European Post Office',
    unified: '1F3E4',
    keywords: [
      'post_office',
      'building',
      'email'
    ],
    sheet: [10, 45],
    shortName: 'european_post_office'
  },
  {
    name: 'Hospital',
    unified: '1F3E5',
    keywords: [
      'hospital',
      'building',
      'health',
      'surgery',
      'doctor'
    ],
    sheet: [10, 46],
    shortName: 'hospital'
  },
  {
    name: 'Bank',
    unified: '1F3E6',
    keywords: [
      'bank',
      'building',
      'money',
      'sales',
      'cash',
      'business',
      'enterprise'
    ],
    sheet: [10, 47],
    shortName: 'bank'
  },
  {
    name: 'Hotel',
    unified: '1F3E8',
    keywords: [
      'hotel',
      'building',
      'accomodation',
      'checkin'
    ],
    sheet: [10, 49],
    shortName: 'hotel'
  },
  {
    name: 'Love Hotel',
    unified: '1F3E9',
    keywords: [
      'love_hotel',
      'like',
      'affection',
      'dating'
    ],
    sheet: [10, 50],
    shortName: 'love_hotel'
  },
  {
    name: 'Convenience Store',
    unified: '1F3EA',
    keywords: [
      'convenience_store',
      'building',
      'shopping',
      'groceries'
    ],
    sheet: [10, 51],
    shortName: 'convenience_store'
  },
  {
    name: 'School',
    unified: '1F3EB',
    keywords: [
      'school',
      'building',
      'student',
      'education',
      'learn',
      'teach'
    ],
    sheet: [10, 52],
    shortName: 'school'
  },
  {
    name: 'Department Store',
    unified: '1F3EC',
    keywords: [
      'department_store',
      'building',
      'shopping',
      'mall'
    ],
    sheet: [10, 53],
    shortName: 'department_store'
  },
  {
    name: 'Factory',
    unified: '1F3ED',
    keywords: [
      'factory',
      'building',
      'industry',
      'pollution',
      'smoke'
    ],
    sheet: [10, 54],
    shortName: 'factory'
  },
  {
    name: 'Japanese Castle',
    unified: '1F3EF',
    keywords: [
      'japanese_castle',
      'photo',
      'building'
    ],
    sheet: [10, 56],
    shortName: 'japanese_castle'
  },
  {
    name: 'European Castle',
    unified: '1F3F0',
    keywords: [
      'castle',
      'building',
      'royalty',
      'history'
    ],
    sheet: [10, 57],
    shortName: 'european_castle'
  },
  {
    name: 'Wedding',
    unified: '1F492',
    keywords: [
      'wedding',
      'love',
      'like',
      'affection',
      'couple',
      'marriage',
      'bride',
      'groom'
    ],
    sheet: [25, 30],
    shortName: 'wedding'
  },
  {
    name: 'Tokyo Tower',
    unified: '1F5FC',
    keywords: [
      'tokyo_tower',
      'photo',
      'japanese'
    ],
    sheet: [30, 28],
    shortName: 'tokyo_tower'
  },
  {
    name: 'Statue of Liberty',
    unified: '1F5FD',
    keywords: [
      'statue_of_liberty',
      'american',
      'newyork'
    ],
    sheet: [30, 29],
    shortName: 'statue_of_liberty'
  },
  {
    name: 'Church',
    unified: '26EA',
    keywords: [
      'church',
      'building',
      'religion',
      'christ'
    ],
    sheet: [55, 15],
    shortName: 'church'
  },
  {
    name: 'Mosque',
    unified: '1F54C',
    keywords: [
      'mosque',
      'islam',
      'worship',
      'minaret'
    ],
    sheet: [28, 35],
    shortName: 'mosque'
  },
  {
    name: 'Hindu Temple',
    unified: '1F6D5',
    keywords: [
      'hindu_temple',
      'religion'
    ],
    sheet: [36, 32],
    shortName: 'hindu_temple'
  },
  {
    name: 'Synagogue',
    unified: '1F54D',
    keywords: [
      'synagogue',
      'judaism',
      'worship',
      'temple',
      'jewish'
    ],
    sheet: [28, 36],
    shortName: 'synagogue'
  },
  {
    name: 'Shinto Shrine',
    unified: '26E9-FE0F',
    keywords: [
      'shinto_shrine',
      'temple',
      'japan',
      'kyoto'
    ],
    sheet: [55, 14],
    shortName: 'shinto_shrine'
  },
  {
    name: 'Kaaba',
    unified: '1F54B',
    keywords: [
      'kaaba',
      'mecca',
      'mosque',
      'islam'
    ],
    sheet: [28, 34],
    shortName: 'kaaba'
  },
  {
    name: 'Fountain',
    unified: '26F2',
    keywords: [
      'fountain',
      'photo',
      'summer',
      'water',
      'fresh'
    ],
    sheet: [55, 18],
    shortName: 'fountain'
  },
  {
    name: 'Tent',
    unified: '26FA',
    keywords: [
      'tent',
      'photo',
      'camping',
      'outdoors'
    ],
    sheet: [55, 42],
    shortName: 'tent'
  },
  {
    name: 'Foggy',
    unified: '1F301',
    keywords: [
      'foggy',
      'photo',
      'mountain'
    ],
    sheet: [5, 15],
    shortName: 'foggy'
  },
  {
    name: 'Night with Stars',
    unified: '1F303',
    keywords: [
      'night_with_stars',
      'evening',
      'city',
      'downtown'
    ],
    sheet: [5, 17],
    shortName: 'night_with_stars'
  },
  {
    name: 'Cityscape',
    unified: '1F3D9-FE0F',
    keywords: [
      'cityscape',
      'photo',
      'night life',
      'urban'
    ],
    sheet: [10, 34],
    shortName: 'cityscape'
  },
  {
    name: 'Sunrise over Mountains',
    unified: '1F304',
    keywords: [
      'sunrise_over_mountains',
      'view',
      'vacation',
      'photo'
    ],
    sheet: [5, 18],
    shortName: 'sunrise_over_mountains'
  },
  {
    name: 'Sunrise',
    unified: '1F305',
    keywords: [
      'sunrise',
      'morning',
      'view',
      'vacation',
      'photo'
    ],
    sheet: [5, 19],
    shortName: 'sunrise'
  },
  {
    name: 'Cityscape at Dusk',
    unified: '1F306',
    keywords: [
      'cityscape_at_dusk',
      'photo',
      'evening',
      'sky',
      'buildings'
    ],
    sheet: [5, 20],
    shortName: 'city_sunset'
  },
  {
    name: 'Sunset over Buildings',
    unified: '1F307',
    keywords: [
      'sunset',
      'photo',
      'good morning',
      'dawn'
    ],
    sheet: [5, 21],
    shortName: 'city_sunrise'
  },
  {
    name: 'Bridge at Night',
    unified: '1F309',
    keywords: [
      'bridge_at_night',
      'photo',
      'sanfrancisco'
    ],
    sheet: [5, 23],
    shortName: 'bridge_at_night'
  },
  {
    name: 'Hot Springs',
    unified: '2668-FE0F',
    keywords: [
      'hot_springs',
      'bath',
      'warm',
      'relax'
    ],
    sheet: [54, 42],
    shortName: 'hotsprings'
  },
  {
    name: 'Carousel Horse',
    unified: '1F3A0',
    keywords: [
      'carousel_horse',
      'photo',
      'carnival'
    ],
    sheet: [7, 56],
    shortName: 'carousel_horse'
  },
  {
    name: 'Ferris Wheel',
    unified: '1F3A1',
    keywords: [
      'ferris_wheel',
      'photo',
      'carnival',
      'londoneye'
    ],
    sheet: [7, 57],
    shortName: 'ferris_wheel'
  },
  {
    name: 'Roller Coaster',
    unified: '1F3A2',
    keywords: [
      'roller_coaster',
      'carnival',
      'playground',
      'photo',
      'fun'
    ],
    sheet: [8, 0],
    shortName: 'roller_coaster'
  },
  {
    name: 'Barber Pole',
    unified: '1F488',
    keywords: [
      'barber_pole',
      'hair',
      'salon',
      'style'
    ],
    sheet: [25, 20],
    shortName: 'barber'
  },
  {
    name: 'Circus Tent',
    unified: '1F3AA',
    keywords: [
      'circus_tent',
      'festival',
      'carnival',
      'party'
    ],
    sheet: [8, 8],
    shortName: 'circus_tent'
  },
  {
    name: 'Steam Locomotive',
    unified: '1F682',
    keywords: [
      'locomotive',
      'transportation',
      'vehicle',
      'train'
    ],
    sheet: [33, 52],
    shortName: 'steam_locomotive'
  },
  {
    name: 'Railway Car',
    unified: '1F683',
    keywords: [
      'railway_car',
      'transportation',
      'vehicle'
    ],
    sheet: [33, 53],
    shortName: 'railway_car'
  },
  {
    name: 'High-Speed Train',
    unified: '1F684',
    keywords: [
      'high_speed_train',
      'transportation',
      'vehicle'
    ],
    sheet: [33, 54],
    shortName: 'bullettrain_side'
  },
  {
    name: 'High-Speed Train with Bullet Nose',
    unified: '1F685',
    keywords: [
      'bullet_train',
      'transportation',
      'vehicle',
      'speed',
      'fast',
      'public',
      'travel'
    ],
    sheet: [33, 55],
    shortName: 'bullettrain_front'
  },
  {
    name: 'Train',
    unified: '1F686',
    keywords: [
      'train',
      'transportation',
      'vehicle'
    ],
    sheet: [33, 56],
    shortName: 'train2'
  },
  {
    name: 'Metro',
    unified: '1F687',
    keywords: [
      'metro',
      'transportation',
      'blue-square',
      'mrt',
      'underground',
      'tube'
    ],
    sheet: [33, 57],
    shortName: 'metro'
  },
  {
    name: 'Light Rail',
    unified: '1F688',
    keywords: [
      'light_rail',
      'transportation',
      'vehicle'
    ],
    sheet: [34, 0],
    shortName: 'light_rail'
  },
  {
    name: 'Station',
    unified: '1F689',
    keywords: [
      'station',
      'transportation',
      'vehicle',
      'public'
    ],
    sheet: [34, 1],
    shortName: 'station'
  },
  {
    name: 'Tram',
    unified: '1F68A',
    keywords: [
      'tram',
      'transportation',
      'vehicle'
    ],
    sheet: [34, 2],
    shortName: 'tram'
  },
  {
    name: 'Monorail',
    unified: '1F69D',
    keywords: [
      'monorail',
      'transportation',
      'vehicle'
    ],
    sheet: [34, 21],
    shortName: 'monorail'
  },
  {
    name: 'Mountain Railway',
    unified: '1F69E',
    keywords: [
      'mountain_railway',
      'transportation',
      'vehicle'
    ],
    sheet: [34, 22],
    shortName: 'mountain_railway'
  },
  {
    name: 'Tram Car',
    unified: '1F68B',
    keywords: [
      'tram_car',
      'transportation',
      'vehicle',
      'carriage',
      'public',
      'travel'
    ],
    sheet: [34, 3],
    shortName: 'train'
  },
  {
    name: 'Bus',
    unified: '1F68C',
    keywords: [
      'bus',
      'car',
      'vehicle',
      'transportation'
    ],
    sheet: [34, 4],
    shortName: 'bus'
  },
  {
    name: 'Oncoming Bus',
    unified: '1F68D',
    keywords: [
      'oncoming_bus',
      'vehicle',
      'transportation'
    ],
    sheet: [34, 5],
    shortName: 'oncoming_bus'
  },
  {
    name: 'Trolleybus',
    unified: '1F68E',
    keywords: [
      'trolleybus',
      'bart',
      'transportation',
      'vehicle'
    ],
    sheet: [34, 6],
    shortName: 'trolleybus'
  },
  {
    name: 'Minibus',
    unified: '1F690',
    keywords: [
      'minibus',
      'vehicle',
      'car',
      'transportation'
    ],
    sheet: [34, 8],
    shortName: 'minibus'
  },
  {
    name: 'Ambulance',
    unified: '1F691',
    keywords: [
      'ambulance',
      'health',
      '911',
      'hospital'
    ],
    sheet: [34, 9],
    shortName: 'ambulance'
  },
  {
    name: 'Fire Engine',
    unified: '1F692',
    keywords: [
      'fire_engine',
      'transportation',
      'cars',
      'vehicle'
    ],
    sheet: [34, 10],
    shortName: 'fire_engine'
  },
  {
    name: 'Police Car',
    unified: '1F693',
    keywords: [
      'police_car',
      'vehicle',
      'cars',
      'transportation',
      'law',
      'legal',
      'enforcement'
    ],
    sheet: [34, 11],
    shortName: 'police_car'
  },
  {
    name: 'Oncoming Police Car',
    unified: '1F694',
    keywords: [
      'oncoming_police_car',
      'vehicle',
      'law',
      'legal',
      'enforcement',
      '911'
    ],
    sheet: [34, 12],
    shortName: 'oncoming_police_car'
  },
  {
    name: 'Taxi',
    unified: '1F695',
    keywords: [
      'taxi',
      'uber',
      'vehicle',
      'cars',
      'transportation'
    ],
    sheet: [34, 13],
    shortName: 'taxi'
  },
  {
    name: 'Oncoming Taxi',
    unified: '1F696',
    keywords: [
      'oncoming_taxi',
      'vehicle',
      'cars',
      'uber'
    ],
    sheet: [34, 14],
    shortName: 'oncoming_taxi'
  },
  {
    name: 'Automobile',
    unified: '1F697',
    keywords: [
      'automobile',
      'red',
      'transportation',
      'vehicle'
    ],
    sheet: [34, 15],
    shortNames: ['red_car'],
    shortName: 'car'
  },
  {
    name: 'Oncoming Automobile',
    unified: '1F698',
    keywords: [
      'oncoming_automobile',
      'car',
      'vehicle',
      'transportation'
    ],
    sheet: [34, 16],
    shortName: 'oncoming_automobile'
  },
  {
    name: 'Recreational Vehicle',
    unified: '1F699',
    keywords: [
      'sport_utility_vehicle',
      'transportation',
      'vehicle'
    ],
    sheet: [34, 17],
    shortName: 'blue_car'
  },
  {
    name: 'Pickup Truck',
    unified: '1F6FB',
    keywords: [
      'pickup truck',
      'car',
      'transportation'
    ],
    sheet: [36, 53],
    shortName: 'pickup_truck'
  },
  {
    name: 'Delivery Truck',
    unified: '1F69A',
    keywords: [
      'delivery_truck',
      'cars',
      'transportation'
    ],
    sheet: [34, 18],
    shortName: 'truck'
  },
  {
    name: 'Articulated Lorry',
    unified: '1F69B',
    keywords: [
      'articulated_lorry',
      'vehicle',
      'cars',
      'transportation',
      'express'
    ],
    sheet: [34, 19],
    shortName: 'articulated_lorry'
  },
  {
    name: 'Tractor',
    unified: '1F69C',
    keywords: [
      'tractor',
      'vehicle',
      'car',
      'farming',
      'agriculture'
    ],
    sheet: [34, 20],
    shortName: 'tractor'
  },
  {
    name: 'Racing Car',
    unified: '1F3CE-FE0F',
    keywords: [
      'racing_car',
      'sports',
      'race',
      'fast',
      'formula',
      'f1'
    ],
    sheet: [10, 23],
    shortName: 'racing_car'
  },
  {
    name: 'Motorcycle',
    unified: '1F3CD-FE0F',
    keywords: [
      'motorcycle',
      'race',
      'sports',
      'fast'
    ],
    sheet: [10, 22],
    shortName: 'racing_motorcycle'
  },
  {
    name: 'Motor Scooter',
    unified: '1F6F5',
    keywords: [
      'motor_scooter',
      'vehicle',
      'vespa',
      'sasha'
    ],
    sheet: [36, 47],
    shortName: 'motor_scooter'
  },
  {
    name: 'Manual Wheelchair',
    unified: '1F9BD',
    keywords: [
      'manual_wheelchair',
      'accessibility'
    ],
    sheet: [44, 23],
    shortName: 'manual_wheelchair'
  },
  {
    name: 'Motorized Wheelchair',
    unified: '1F9BC',
    keywords: [
      'motorized_wheelchair',
      'accessibility'
    ],
    sheet: [44, 22],
    shortName: 'motorized_wheelchair'
  },
  {
    name: 'Auto Rickshaw',
    unified: '1F6FA',
    keywords: [
      'auto_rickshaw',
      'move',
      'transportation'
    ],
    sheet: [36, 52],
    shortName: 'auto_rickshaw'
  },
  {
    name: 'Bicycle',
    unified: '1F6B2',
    keywords: [
      'bicycle',
      'sports',
      'bicycle',
      'exercise',
      'hipster'
    ],
    sheet: [35, 1],
    shortName: 'bike'
  },
  {
    name: 'Scooter',
    unified: '1F6F4',
    keywords: [
      'kick_scooter',
      'vehicle',
      'kick',
      'razor'
    ],
    sheet: [36, 46],
    shortName: 'scooter'
  },
  {
    name: 'Skateboard',
    unified: '1F6F9',
    keywords: ['skateboard', 'board'],
    sheet: [36, 51],
    shortName: 'skateboard'
  },
  {
    name: 'Roller Skate',
    unified: '1F6FC',
    keywords: [
      'roller skate',
      'footwear',
      'sports'
    ],
    sheet: [36, 54],
    shortName: 'roller_skate'
  },
  {
    name: 'Bus Stop',
    unified: '1F68F',
    keywords: [
      'bus_stop',
      'transportation',
      'wait'
    ],
    sheet: [34, 7],
    shortName: 'busstop'
  },
  {
    name: 'Motorway',
    unified: '1F6E3-FE0F',
    keywords: [
      'motorway',
      'road',
      'cupertino',
      'interstate',
      'highway'
    ],
    sheet: [36, 38],
    shortName: 'motorway'
  },
  {
    name: 'Railway Track',
    unified: '1F6E4-FE0F',
    keywords: [
      'railway_track',
      'train',
      'transportation'
    ],
    sheet: [36, 39],
    shortName: 'railway_track'
  },
  {
    name: 'Oil Drum',
    unified: '1F6E2-FE0F',
    keywords: ['oil_drum', 'barrell'],
    sheet: [36, 37],
    shortName: 'oil_drum'
  },
  {
    name: 'Fuel Pump',
    unified: '26FD',
    keywords: [
      'fuel_pump',
      'gas station',
      'petroleum'
    ],
    sheet: [55, 43],
    shortName: 'fuelpump'
  },
  {
    name: 'Police Cars Revolving Light',
    unified: '1F6A8',
    keywords: [
      'police_car_light',
      'police',
      'ambulance',
      '911',
      'emergency',
      'alert',
      'error',
      'pinged',
      'law',
      'legal'
    ],
    sheet: [34, 49],
    shortName: 'rotating_light'
  },
  {
    name: 'Horizontal Traffic Light',
    unified: '1F6A5',
    keywords: [
      'horizontal_traffic_light',
      'transportation',
      'signal'
    ],
    sheet: [34, 46],
    shortName: 'traffic_light'
  },
  {
    name: 'Vertical Traffic Light',
    unified: '1F6A6',
    keywords: [
      'vertical_traffic_light',
      'transportation',
      'driving'
    ],
    sheet: [34, 47],
    shortName: 'vertical_traffic_light'
  },
  {
    name: 'Octagonal Sign',
    unified: '1F6D1',
    keywords: ['stop_sign', 'stop'],
    sheet: [36, 30],
    shortName: 'octagonal_sign'
  },
  {
    name: 'Construction Sign',
    unified: '1F6A7',
    keywords: [
      'construction',
      'wip',
      'progress',
      'caution',
      'warning'
    ],
    sheet: [34, 48],
    shortName: 'construction'
  },
  {
    name: 'Anchor',
    unified: '2693',
    keywords: [
      'anchor',
      'ship',
      'ferry',
      'sea',
      'boat'
    ],
    sheet: [54, 47],
    shortName: 'anchor'
  },
  {
    name: 'Sailboat',
    unified: '26F5',
    keywords: [
      'sailboat',
      'ship',
      'summer',
      'transportation',
      'water',
      'sailing'
    ],
    sheet: [55, 21],
    shortNames: ['sailboat'],
    shortName: 'boat'
  },
  {
    name: 'Canoe',
    unified: '1F6F6',
    keywords: [
      'canoe',
      'boat',
      'paddle',
      'water',
      'ship'
    ],
    sheet: [36, 48],
    shortName: 'canoe'
  },
  {
    name: 'Speedboat',
    unified: '1F6A4',
    keywords: [
      'speedboat',
      'ship',
      'transportation',
      'vehicle',
      'summer'
    ],
    sheet: [34, 45],
    shortName: 'speedboat'
  },
  {
    name: 'Passenger Ship',
    unified: '1F6F3-FE0F',
    keywords: [
      'passenger_ship',
      'yacht',
      'cruise',
      'ferry'
    ],
    sheet: [36, 45],
    shortName: 'passenger_ship'
  },
  {
    name: 'Ferry',
    unified: '26F4-FE0F',
    keywords: [
      'ferry',
      'boat',
      'ship',
      'yacht'
    ],
    sheet: [55, 20],
    shortName: 'ferry'
  },
  {
    name: 'Motor Boat',
    unified: '1F6E5-FE0F',
    keywords: ['motor_boat', 'ship'],
    sheet: [36, 40],
    shortName: 'motor_boat'
  },
  {
    name: 'Ship',
    unified: '1F6A2',
    keywords: [
      'ship',
      'transportation',
      'titanic',
      'deploy'
    ],
    sheet: [34, 26],
    shortName: 'ship'
  },
  {
    name: 'Airplane',
    unified: '2708-FE0F',
    keywords: [
      'airplane',
      'vehicle',
      'transportation',
      'flight',
      'fly'
    ],
    sheet: [55, 46],
    shortName: 'airplane'
  },
  {
    name: 'Small Airplane',
    unified: '1F6E9-FE0F',
    keywords: [
      'small_airplane',
      'flight',
      'transportation',
      'fly',
      'vehicle'
    ],
    sheet: [36, 41],
    shortName: 'small_airplane'
  },
  {
    name: 'Airplane Departure',
    unified: '1F6EB',
    keywords: [
      'airplane_departure',
      'airport',
      'flight',
      'landing'
    ],
    sheet: [36, 42],
    shortName: 'airplane_departure'
  },
  {
    name: 'Airplane Arriving',
    unified: '1F6EC',
    keywords: [
      'airplane_arrival',
      'airport',
      'flight',
      'boarding'
    ],
    sheet: [36, 43],
    shortName: 'airplane_arriving'
  },
  {
    name: 'Parachute',
    unified: '1FA82',
    keywords: [
      'parachute',
      'fly',
      'glide'
    ],
    sheet: [52, 27],
    shortName: 'parachute'
  },
  {
    name: 'Seat',
    unified: '1F4BA',
    keywords: [
      'seat',
      'sit',
      'airplane',
      'transport',
      'bus',
      'flight',
      'fly'
    ],
    sheet: [26, 17],
    shortName: 'seat'
  },
  {
    name: 'Helicopter',
    unified: '1F681',
    keywords: [
      'helicopter',
      'transportation',
      'vehicle',
      'fly'
    ],
    sheet: [33, 51],
    shortName: 'helicopter'
  },
  {
    name: 'Suspension Railway',
    unified: '1F69F',
    keywords: [
      'suspension_railway',
      'vehicle',
      'transportation'
    ],
    sheet: [34, 23],
    shortName: 'suspension_railway'
  },
  {
    name: 'Mountain Cableway',
    unified: '1F6A0',
    keywords: [
      'mountain_cableway',
      'transportation',
      'vehicle',
      'ski'
    ],
    sheet: [34, 24],
    shortName: 'mountain_cableway'
  },
  {
    name: 'Aerial Tramway',
    unified: '1F6A1',
    keywords: [
      'aerial_tramway',
      'transportation',
      'vehicle',
      'ski'
    ],
    sheet: [34, 25],
    shortName: 'aerial_tramway'
  },
  {
    name: 'Satellite',
    unified: '1F6F0-FE0F',
    keywords: [
      'satellite',
      'communication',
      'gps',
      'orbit',
      'spaceflight',
      'NASA',
      'ISS'
    ],
    sheet: [36, 44],
    shortName: 'satellite'
  },
  {
    name: 'Rocket',
    unified: '1F680',
    keywords: [
      'rocket',
      'launch',
      'ship',
      'staffmode',
      'NASA',
      'outer space',
      'outer_space',
      'fly'
    ],
    sheet: [33, 50],
    shortName: 'rocket'
  },
  {
    name: 'Flying Saucer',
    unified: '1F6F8',
    keywords: [
      'flying_saucer',
      'transportation',
      'vehicle',
      'ufo'
    ],
    sheet: [36, 50],
    shortName: 'flying_saucer'
  },
  {
    name: 'Bellhop Bell',
    unified: '1F6CE-FE0F',
    keywords: [
      'bellhop_bell',
      'service'
    ],
    sheet: [36, 27],
    shortName: 'bellhop_bell'
  },
  {
    name: 'Luggage',
    unified: '1F9F3',
    keywords: [
      'luggage',
      'packing',
      'travel'
    ],
    sheet: [52, 4],
    shortName: 'luggage'
  },
  {
    name: 'Hourglass',
    unified: '231B',
    keywords: [
      'hourglass_done',
      'time',
      'clock',
      'oldschool',
      'limit',
      'exam',
      'quiz',
      'test'
    ],
    sheet: [53, 29],
    shortName: 'hourglass'
  },
  {
    name: 'Hourglass with Flowing Sand',
    unified: '23F3',
    keywords: [
      'hourglass_not_done',
      'oldschool',
      'time',
      'countdown'
    ],
    sheet: [53, 42],
    shortName: 'hourglass_flowing_sand'
  },
  {
    name: 'Watch',
    unified: '231A',
    keywords: [
      'watch',
      'time',
      'accessories'
    ],
    sheet: [53, 28],
    shortName: 'watch'
  },
  {
    name: 'Alarm Clock',
    unified: '23F0',
    keywords: [
      'alarm_clock',
      'time',
      'wake'
    ],
    sheet: [53, 39],
    shortName: 'alarm_clock'
  },
  {
    name: 'Stopwatch',
    unified: '23F1-FE0F',
    keywords: [
      'stopwatch',
      'time',
      'deadline'
    ],
    sheet: [53, 40],
    shortName: 'stopwatch'
  },
  {
    name: 'Timer Clock',
    unified: '23F2-FE0F',
    keywords: ['timer_clock', 'alarm'],
    sheet: [53, 41],
    shortName: 'timer_clock'
  },
  {
    name: 'Mantelpiece Clock',
    unified: '1F570-FE0F',
    keywords: [
      'mantelpiece_clock',
      'time'
    ],
    sheet: [29, 5],
    shortName: 'mantelpiece_clock'
  },
  {
    name: 'Clock Face Twelve Oclock',
    unified: '1F55B',
    keywords: [
      'twelve_o_clock',
      'time',
      'noon',
      'midnight',
      'midday',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 49],
    shortName: 'clock12'
  },
  {
    name: 'Clock Face Twelve-Thirty',
    unified: '1F567',
    keywords: [
      'twelve_thirty',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [29, 3],
    shortName: 'clock1230'
  },
  {
    name: 'Clock Face One Oclock',
    unified: '1F550',
    keywords: [
      'one_o_clock',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 38],
    shortName: 'clock1'
  },
  {
    name: 'Clock Face One-Thirty',
    unified: '1F55C',
    keywords: [
      'one_thirty',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 50],
    shortName: 'clock130'
  },
  {
    name: 'Clock Face Two Oclock',
    unified: '1F551',
    keywords: [
      'two_o_clock',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 39],
    shortName: 'clock2'
  },
  {
    name: 'Clock Face Two-Thirty',
    unified: '1F55D',
    keywords: [
      'two_thirty',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 51],
    shortName: 'clock230'
  },
  {
    name: 'Clock Face Three Oclock',
    unified: '1F552',
    keywords: [
      'three_o_clock',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 40],
    shortName: 'clock3'
  },
  {
    name: 'Clock Face Three-Thirty',
    unified: '1F55E',
    keywords: [
      'three_thirty',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 52],
    shortName: 'clock330'
  },
  {
    name: 'Clock Face Four Oclock',
    unified: '1F553',
    keywords: [
      'four_o_clock',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 41],
    shortName: 'clock4'
  },
  {
    name: 'Clock Face Four-Thirty',
    unified: '1F55F',
    keywords: [
      'four_thirty',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 53],
    shortName: 'clock430'
  },
  {
    name: 'Clock Face Five Oclock',
    unified: '1F554',
    keywords: [
      'five_o_clock',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 42],
    shortName: 'clock5'
  },
  {
    name: 'Clock Face Five-Thirty',
    unified: '1F560',
    keywords: [
      'five_thirty',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 54],
    shortName: 'clock530'
  },
  {
    name: 'Clock Face Six Oclock',
    unified: '1F555',
    keywords: [
      'six_o_clock',
      'time',
      'late',
      'early',
      'schedule',
      'dawn',
      'dusk'
    ],
    sheet: [28, 43],
    shortName: 'clock6'
  },
  {
    name: 'Clock Face Six-Thirty',
    unified: '1F561',
    keywords: [
      'six_thirty',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 55],
    shortName: 'clock630'
  },
  {
    name: 'Clock Face Seven Oclock',
    unified: '1F556',
    keywords: [
      'seven_o_clock',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 44],
    shortName: 'clock7'
  },
  {
    name: 'Clock Face Seven-Thirty',
    unified: '1F562',
    keywords: [
      'seven_thirty',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 56],
    shortName: 'clock730'
  },
  {
    name: 'Clock Face Eight Oclock',
    unified: '1F557',
    keywords: [
      'eight_o_clock',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 45],
    shortName: 'clock8'
  },
  {
    name: 'Clock Face Eight-Thirty',
    unified: '1F563',
    keywords: [
      'eight_thirty',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 57],
    shortName: 'clock830'
  },
  {
    name: 'Clock Face Nine Oclock',
    unified: '1F558',
    keywords: [
      'nine_o_clock',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 46],
    shortName: 'clock9'
  },
  {
    name: 'Clock Face Nine-Thirty',
    unified: '1F564',
    keywords: [
      'nine_thirty',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [29, 0],
    shortName: 'clock930'
  },
  {
    name: 'Clock Face Ten Oclock',
    unified: '1F559',
    keywords: [
      'ten_o_clock',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 47],
    shortName: 'clock10'
  },
  {
    name: 'Clock Face Ten-Thirty',
    unified: '1F565',
    keywords: [
      'ten_thirty',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [29, 1],
    shortName: 'clock1030'
  },
  {
    name: 'Clock Face Eleven Oclock',
    unified: '1F55A',
    keywords: [
      'eleven_o_clock',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [28, 48],
    shortName: 'clock11'
  },
  {
    name: 'Clock Face Eleven-Thirty',
    unified: '1F566',
    keywords: [
      'eleven_thirty',
      'time',
      'late',
      'early',
      'schedule'
    ],
    sheet: [29, 2],
    shortName: 'clock1130'
  },
  {
    name: 'New Moon Symbol',
    unified: '1F311',
    keywords: [
      'new_moon',
      'nature',
      'twilight',
      'planet',
      'space',
      'night',
      'evening',
      'sleep'
    ],
    sheet: [5, 31],
    shortName: 'new_moon'
  },
  {
    name: 'Waxing Crescent Moon Symbol',
    unified: '1F312',
    keywords: [
      'waxing_crescent_moon',
      'nature',
      'twilight',
      'planet',
      'space',
      'night',
      'evening',
      'sleep'
    ],
    sheet: [5, 32],
    shortName: 'waxing_crescent_moon'
  },
  {
    name: 'First Quarter Moon Symbol',
    unified: '1F313',
    keywords: [
      'first_quarter_moon',
      'nature',
      'twilight',
      'planet',
      'space',
      'night',
      'evening',
      'sleep'
    ],
    sheet: [5, 33],
    shortName: 'first_quarter_moon'
  },
  {
    name: 'Waxing Gibbous Moon Symbol',
    unified: '1F314',
    keywords: [
      'waxing_gibbous_moon',
      'nature',
      'night',
      'sky',
      'gray',
      'twilight',
      'planet',
      'space',
      'evening',
      'sleep'
    ],
    sheet: [5, 34],
    shortNames: ['waxing_gibbous_moon'],
    shortName: 'moon'
  },
  {
    name: 'Full Moon Symbol',
    unified: '1F315',
    keywords: [
      'full_moon',
      'nature',
      'yellow',
      'twilight',
      'planet',
      'space',
      'night',
      'evening',
      'sleep'
    ],
    sheet: [5, 35],
    shortName: 'full_moon'
  },
  {
    name: 'Waning Gibbous Moon Symbol',
    unified: '1F316',
    keywords: [
      'waning_gibbous_moon',
      'nature',
      'twilight',
      'planet',
      'space',
      'night',
      'evening',
      'sleep',
      'waxing_gibbous_moon'
    ],
    sheet: [5, 36],
    shortName: 'waning_gibbous_moon'
  },
  {
    name: 'Last Quarter Moon Symbol',
    unified: '1F317',
    keywords: [
      'last_quarter_moon',
      'nature',
      'twilight',
      'planet',
      'space',
      'night',
      'evening',
      'sleep'
    ],
    sheet: [5, 37],
    shortName: 'last_quarter_moon'
  },
  {
    name: 'Waning Crescent Moon Symbol',
    unified: '1F318',
    keywords: [
      'waning_crescent_moon',
      'nature',
      'twilight',
      'planet',
      'space',
      'night',
      'evening',
      'sleep'
    ],
    sheet: [5, 38],
    shortName: 'waning_crescent_moon'
  },
  {
    name: 'Crescent Moon',
    unified: '1F319',
    keywords: [
      'crescent_moon',
      'night',
      'sleep',
      'sky',
      'evening',
      'magic'
    ],
    sheet: [5, 39],
    shortName: 'crescent_moon'
  },
  {
    name: 'New Moon with Face',
    unified: '1F31A',
    keywords: [
      'new_moon_face',
      'nature',
      'twilight',
      'planet',
      'space',
      'night',
      'evening',
      'sleep'
    ],
    sheet: [5, 40],
    shortName: 'new_moon_with_face'
  },
  {
    name: 'First Quarter Moon with Face',
    unified: '1F31B',
    keywords: [
      'first_quarter_moon_face',
      'nature',
      'twilight',
      'planet',
      'space',
      'night',
      'evening',
      'sleep'
    ],
    sheet: [5, 41],
    shortName: 'first_quarter_moon_with_face'
  },
  {
    name: 'Last Quarter Moon with Face',
    unified: '1F31C',
    keywords: [
      'last_quarter_moon_face',
      'nature',
      'twilight',
      'planet',
      'space',
      'night',
      'evening',
      'sleep'
    ],
    sheet: [5, 42],
    shortName: 'last_quarter_moon_with_face'
  },
  {
    name: 'Thermometer',
    unified: '1F321-FE0F',
    keywords: [
      'thermometer',
      'weather',
      'temperature',
      'hot',
      'cold'
    ],
    sheet: [5, 47],
    shortName: 'thermometer'
  },
  {
    name: 'Black Sun with Rays',
    unified: '2600-FE0F',
    keywords: [
      'sun',
      'weather',
      'nature',
      'brightness',
      'summer',
      'beach',
      'spring'
    ],
    sheet: [53, 55],
    shortName: 'sunny'
  },
  {
    name: 'Full Moon with Face',
    unified: '1F31D',
    keywords: [
      'full_moon_face',
      'nature',
      'twilight',
      'planet',
      'space',
      'night',
      'evening',
      'sleep'
    ],
    sheet: [5, 43],
    shortName: 'full_moon_with_face'
  },
  {
    name: 'Sun with Face',
    unified: '1F31E',
    keywords: [
      'sun_with_face',
      'nature',
      'morning',
      'sky'
    ],
    sheet: [5, 44],
    shortName: 'sun_with_face'
  },
  {
    name: 'Ringed Planet',
    unified: '1FA90',
    keywords: [
      'ringed_planet',
      'outerspace'
    ],
    sheet: [52, 32],
    shortName: 'ringed_planet'
  },
  {
    name: 'White Medium Star',
    unified: '2B50',
    keywords: [
      'star',
      'night',
      'yellow'
    ],
    sheet: [56, 46],
    shortName: 'star'
  },
  {
    name: 'Glowing Star',
    unified: '1F31F',
    keywords: [
      'glowing_star',
      'night',
      'sparkle',
      'awesome',
      'good',
      'magic'
    ],
    sheet: [5, 45],
    shortName: 'star2'
  },
  {
    name: 'Shooting Star',
    unified: '1F320',
    keywords: [
      'shooting_star',
      'night',
      'photo'
    ],
    sheet: [5, 46],
    shortName: 'stars'
  },
  {
    name: 'Milky Way',
    unified: '1F30C',
    keywords: [
      'milky_way',
      'photo',
      'space',
      'stars'
    ],
    sheet: [5, 26],
    shortName: 'milky_way'
  },
  {
    name: 'Cloud',
    unified: '2601-FE0F',
    keywords: [
      'cloud',
      'weather',
      'sky'
    ],
    sheet: [53, 56],
    shortName: 'cloud'
  },
  {
    name: 'Sun Behind Cloud',
    unified: '26C5',
    keywords: [
      'sun_behind_cloud',
      'weather',
      'nature',
      'cloudy',
      'morning',
      'fall',
      'spring'
    ],
    sheet: [55, 7],
    shortName: 'partly_sunny'
  },
  {
    name: 'Cloud with Lightning and Rain',
    unified: '26C8-FE0F',
    keywords: [
      'cloud_with_lightning_and_rain',
      'weather',
      'lightning'
    ],
    sheet: [55, 8],
    shortName: 'thunder_cloud_and_rain'
  },
  {
    name: 'Sun Behind Small Cloud',
    unified: '1F324-FE0F',
    keywords: [
      'sun_behind_small_cloud',
      'weather'
    ],
    sheet: [5, 48],
    shortNames: ['sun_small_cloud'],
    shortName: 'mostly_sunny'
  },
  {
    name: 'Sun Behind Large Cloud',
    unified: '1F325-FE0F',
    keywords: [
      'sun_behind_large_cloud',
      'weather'
    ],
    sheet: [5, 49],
    shortNames: ['sun_behind_cloud'],
    shortName: 'barely_sunny'
  },
  {
    name: 'Sun Behind Rain Cloud',
    unified: '1F326-FE0F',
    keywords: [
      'sun_behind_rain_cloud',
      'weather'
    ],
    sheet: [5, 50],
    shortNames: ['sun_behind_rain_cloud'],
    shortName: 'partly_sunny_rain'
  },
  {
    name: 'Cloud with Rain',
    unified: '1F327-FE0F',
    keywords: [
      'cloud_with_rain',
      'weather'
    ],
    sheet: [5, 51],
    shortName: 'rain_cloud'
  },
  {
    name: 'Cloud with Snow',
    unified: '1F328-FE0F',
    keywords: [
      'cloud_with_snow',
      'weather'
    ],
    sheet: [5, 52],
    shortName: 'snow_cloud'
  },
  {
    name: 'Cloud with Lightning',
    unified: '1F329-FE0F',
    keywords: [
      'cloud_with_lightning',
      'weather',
      'thunder'
    ],
    sheet: [5, 53],
    shortNames: ['lightning_cloud'],
    shortName: 'lightning'
  },
  {
    name: 'Tornado',
    unified: '1F32A-FE0F',
    keywords: [
      'tornado',
      'weather',
      'cyclone',
      'twister'
    ],
    sheet: [5, 54],
    shortNames: ['tornado_cloud'],
    shortName: 'tornado'
  },
  {
    name: 'Fog',
    unified: '1F32B-FE0F',
    keywords: ['fog', 'weather'],
    sheet: [5, 55],
    shortName: 'fog'
  },
  {
    name: 'Wind Face',
    unified: '1F32C-FE0F',
    keywords: [
      'wind_face',
      'gust',
      'air'
    ],
    sheet: [5, 56],
    shortName: 'wind_blowing_face'
  },
  {
    name: 'Cyclone',
    unified: '1F300',
    keywords: [
      'cyclone',
      'weather',
      'swirl',
      'blue',
      'cloud',
      'vortex',
      'spiral',
      'whirlpool',
      'spin',
      'tornado',
      'hurricane',
      'typhoon'
    ],
    sheet: [5, 14],
    shortName: 'cyclone'
  },
  {
    name: 'Rainbow',
    unified: '1F308',
    keywords: [
      'rainbow',
      'nature',
      'happy',
      'unicorn_face',
      'photo',
      'sky',
      'spring'
    ],
    sheet: [5, 22],
    shortName: 'rainbow'
  },
  {
    name: 'Closed Umbrella',
    unified: '1F302',
    keywords: [
      'closed_umbrella',
      'weather',
      'rain',
      'drizzle'
    ],
    sheet: [5, 16],
    shortName: 'closed_umbrella'
  },
  {
    name: 'Umbrella',
    unified: '2602-FE0F',
    keywords: [
      'umbrella',
      'weather',
      'spring'
    ],
    sheet: [53, 57],
    shortName: 'umbrella'
  },
  {
    name: 'Umbrella with Rain Drops',
    unified: '2614',
    keywords: [
      'umbrella_with_rain_drops',
      'rainy',
      'weather',
      'spring'
    ],
    sheet: [54, 4],
    shortName: 'umbrella_with_rain_drops'
  },
  {
    name: 'Umbrella on Ground',
    unified: '26F1-FE0F',
    keywords: [
      'umbrella_on_ground',
      'weather',
      'summer'
    ],
    sheet: [55, 17],
    shortName: 'umbrella_on_ground'
  },
  {
    name: 'High Voltage Sign',
    unified: '26A1',
    keywords: [
      'high_voltage',
      'thunder',
      'weather',
      'lightning bolt',
      'fast'
    ],
    sheet: [54, 56],
    shortName: 'zap'
  },
  {
    name: 'Snowflake',
    unified: '2744-FE0F',
    keywords: [
      'snowflake',
      'winter',
      'season',
      'cold',
      'weather',
      'christmas',
      'xmas'
    ],
    sheet: [56, 23],
    shortName: 'snowflake'
  },
  {
    name: 'Snowman',
    unified: '2603-FE0F',
    keywords: [
      'snowman',
      'winter',
      'season',
      'cold',
      'weather',
      'christmas',
      'xmas',
      'frozen'
    ],
    sheet: [54, 0],
    shortName: 'snowman'
  },
  {
    name: 'Snowman Without Snow',
    unified: '26C4',
    keywords: [
      'snowman_without_snow',
      'winter',
      'season',
      'cold',
      'weather',
      'christmas',
      'xmas',
      'frozen',
      'without_snow'
    ],
    sheet: [55, 6],
    shortName: 'snowman_without_snow'
  },
  {
    name: 'Comet',
    unified: '2604-FE0F',
    keywords: ['comet', 'space'],
    sheet: [54, 1],
    shortName: 'comet'
  },
  {
    name: 'Fire',
    unified: '1F525',
    keywords: [
      'fire',
      'hot',
      'cook',
      'flame'
    ],
    sheet: [28, 7],
    shortName: 'fire'
  },
  {
    name: 'Droplet',
    unified: '1F4A7',
    keywords: [
      'droplet',
      'water',
      'drip',
      'faucet',
      'spring'
    ],
    sheet: [25, 51],
    shortName: 'droplet'
  },
  {
    name: 'Water Wave',
    unified: '1F30A',
    keywords: [
      'water_wave',
      'sea',
      'water',
      'wave',
      'nature',
      'tsunami',
      'disaster'
    ],
    sheet: [5, 24],
    shortName: 'ocean'
  },
  {
    name: 'Jack-O-Lantern',
    unified: '1F383',
    keywords: [
      'jack_o_lantern',
      'halloween',
      'light',
      'pumpkin',
      'creepy',
      'fall'
    ],
    sheet: [7, 27],
    shortName: 'jack_o_lantern'
  },
  {
    name: 'Christmas Tree',
    unified: '1F384',
    keywords: [
      'christmas_tree',
      'festival',
      'vacation',
      'december',
      'xmas',
      'celebration'
    ],
    sheet: [7, 28],
    shortName: 'christmas_tree'
  },
  {
    name: 'Fireworks',
    unified: '1F386',
    keywords: [
      'fireworks',
      'photo',
      'festival',
      'carnival',
      'congratulations'
    ],
    sheet: [7, 35],
    shortName: 'fireworks'
  },
  {
    name: 'Firework Sparkler',
    unified: '1F387',
    keywords: [
      'sparkler',
      'stars',
      'night',
      'shine'
    ],
    sheet: [7, 36],
    shortName: 'sparkler'
  },
  {
    name: 'Firecracker',
    unified: '1F9E8',
    keywords: [
      'firecracker',
      'dynamite',
      'boom',
      'explode',
      'explosion',
      'explosive'
    ],
    sheet: [51, 51],
    shortName: 'firecracker'
  },
  {
    name: 'Sparkles',
    unified: '2728',
    keywords: [
      'sparkles',
      'stars',
      'shine',
      'shiny',
      'cool',
      'awesome',
      'good',
      'magic'
    ],
    sheet: [56, 20],
    shortName: 'sparkles'
  },
  {
    name: 'Balloon',
    unified: '1F388',
    keywords: [
      'balloon',
      'party',
      'celebration',
      'birthday',
      'circus'
    ],
    sheet: [7, 37],
    shortName: 'balloon'
  },
  {
    name: 'Party Popper',
    unified: '1F389',
    keywords: [
      'party_popper',
      'party',
      'congratulations',
      'birthday',
      'magic',
      'circus',
      'celebration',
      'tada'
    ],
    sheet: [7, 38],
    shortName: 'tada'
  },
  {
    name: 'Confetti Ball',
    unified: '1F38A',
    keywords: [
      'confetti_ball',
      'festival',
      'party',
      'birthday',
      'circus'
    ],
    sheet: [7, 39],
    shortName: 'confetti_ball'
  },
  {
    name: 'Tanabata Tree',
    unified: '1F38B',
    keywords: [
      'tanabata_tree',
      'plant',
      'nature',
      'branch',
      'summer'
    ],
    sheet: [7, 40],
    shortName: 'tanabata_tree'
  },
  {
    name: 'Pine Decoration',
    unified: '1F38D',
    keywords: [
      'pine_decoration',
      'plant',
      'nature',
      'vegetable',
      'panda',
      'pine_decoration'
    ],
    sheet: [7, 42],
    shortName: 'bamboo'
  },
  {
    name: 'Japanese Dolls',
    unified: '1F38E',
    keywords: [
      'japanese_dolls',
      'japanese',
      'toy',
      'kimono'
    ],
    sheet: [7, 43],
    shortName: 'dolls'
  },
  {
    name: 'Carp Streamer',
    unified: '1F38F',
    keywords: [
      'carp_streamer',
      'fish',
      'japanese',
      'koinobori',
      'carp',
      'banner'
    ],
    sheet: [7, 44],
    shortName: 'flags'
  },
  {
    name: 'Wind Chime',
    unified: '1F390',
    keywords: [
      'wind_chime',
      'nature',
      'ding',
      'spring',
      'bell'
    ],
    sheet: [7, 45],
    shortName: 'wind_chime'
  },
  {
    name: 'Moon Viewing Ceremony',
    unified: '1F391',
    keywords: [
      'moon_viewing_ceremony',
      'photo',
      'japan',
      'asia',
      'tsukimi'
    ],
    sheet: [7, 46],
    shortName: 'rice_scene'
  },
  {
    name: 'Red Gift Envelope',
    unified: '1F9E7',
    keywords: ['red_envelope', 'gift'],
    sheet: [51, 50],
    shortName: 'red_envelope'
  },
  {
    name: 'Ribbon',
    unified: '1F380',
    keywords: [
      'ribbon',
      'decoration',
      'pink',
      'girl',
      'bowtie'
    ],
    sheet: [7, 24],
    shortName: 'ribbon'
  },
  {
    name: 'Wrapped Present',
    unified: '1F381',
    keywords: [
      'wrapped_gift',
      'present',
      'birthday',
      'christmas',
      'xmas'
    ],
    sheet: [7, 25],
    shortName: 'gift'
  },
  {
    name: 'Reminder Ribbon',
    unified: '1F397-FE0F',
    keywords: [
      'reminder_ribbon',
      'sports',
      'cause',
      'support',
      'awareness'
    ],
    sheet: [7, 50],
    shortName: 'reminder_ribbon'
  },
  {
    name: 'Admission Tickets',
    unified: '1F39F-FE0F',
    keywords: [
      'admission_tickets',
      'sports',
      'concert',
      'entrance'
    ],
    sheet: [7, 55],
    shortName: 'admission_tickets'
  },
  {
    name: 'Ticket',
    unified: '1F3AB',
    keywords: [
      'ticket',
      'event',
      'concert',
      'pass'
    ],
    sheet: [8, 9],
    shortName: 'ticket'
  },
  {
    name: 'Military Medal',
    unified: '1F396-FE0F',
    keywords: [
      'military_medal',
      'award',
      'winning',
      'army'
    ],
    sheet: [7, 49],
    shortName: 'medal'
  },
  {
    name: 'Trophy',
    unified: '1F3C6',
    keywords: [
      'trophy',
      'win',
      'award',
      'contest',
      'place',
      'ftw',
      'ceremony'
    ],
    sheet: [9, 17],
    shortName: 'trophy'
  },
  {
    name: 'Sports Medal',
    unified: '1F3C5',
    keywords: [
      'sports_medal',
      'award',
      'winning'
    ],
    sheet: [9, 16],
    shortName: 'sports_medal'
  },
  {
    name: 'First Place Medal',
    unified: '1F947',
    keywords: [
      '1st_place_medal',
      'award',
      'winning',
      'first'
    ],
    sheet: [41, 30],
    shortName: 'first_place_medal'
  },
  {
    name: 'Second Place Medal',
    unified: '1F948',
    keywords: [
      '2nd_place_medal',
      'award',
      'second'
    ],
    sheet: [41, 31],
    shortName: 'second_place_medal'
  },
  {
    name: 'Third Place Medal',
    unified: '1F949',
    keywords: [
      '3rd_place_medal',
      'award',
      'third'
    ],
    sheet: [41, 32],
    shortName: 'third_place_medal'
  },
  {
    name: 'Soccer Ball',
    unified: '26BD',
    keywords: [
      'soccer_ball',
      'sports',
      'football'
    ],
    sheet: [55, 4],
    shortName: 'soccer'
  },
  {
    name: 'Baseball',
    unified: '26BE',
    keywords: [
      'baseball',
      'sports',
      'balls'
    ],
    sheet: [55, 5],
    shortName: 'baseball'
  },
  {
    name: 'Softball',
    unified: '1F94E',
    keywords: [
      'softball',
      'sports',
      'balls'
    ],
    sheet: [41, 37],
    shortName: 'softball'
  },
  {
    name: 'Basketball and Hoop',
    unified: '1F3C0',
    keywords: [
      'basketball',
      'sports',
      'balls',
      'NBA'
    ],
    sheet: [8, 30],
    shortName: 'basketball'
  },
  {
    name: 'Volleyball',
    unified: '1F3D0',
    keywords: [
      'volleyball',
      'sports',
      'balls'
    ],
    sheet: [10, 25],
    shortName: 'volleyball'
  },
  {
    name: 'American Football',
    unified: '1F3C8',
    keywords: [
      'american_football',
      'sports',
      'balls',
      'NFL'
    ],
    sheet: [9, 24],
    shortName: 'football'
  },
  {
    name: 'Rugby Football',
    unified: '1F3C9',
    keywords: [
      'rugby_football',
      'sports',
      'team'
    ],
    sheet: [9, 25],
    shortName: 'rugby_football'
  },
  {
    name: 'Tennis Racquet and Ball',
    unified: '1F3BE',
    keywords: [
      'tennis',
      'sports',
      'balls',
      'green'
    ],
    sheet: [8, 28],
    shortName: 'tennis'
  },
  {
    name: 'Flying Disc',
    unified: '1F94F',
    keywords: [
      'flying_disc',
      'sports',
      'frisbee',
      'ultimate'
    ],
    sheet: [41, 38],
    shortName: 'flying_disc'
  },
  {
    name: 'Bowling',
    unified: '1F3B3',
    keywords: [
      'bowling',
      'sports',
      'fun',
      'play'
    ],
    sheet: [8, 17],
    shortName: 'bowling'
  },
  {
    name: 'Cricket Bat and Ball',
    unified: '1F3CF',
    keywords: [
      'cricket_game',
      'sports'
    ],
    sheet: [10, 24],
    shortName: 'cricket_bat_and_ball'
  },
  {
    name: 'Field Hockey Stick and Ball',
    unified: '1F3D1',
    keywords: [
      'field_hockey',
      'sports'
    ],
    sheet: [10, 26],
    shortName: 'field_hockey_stick_and_ball'
  },
  {
    name: 'Ice Hockey Stick and Puck',
    unified: '1F3D2',
    keywords: ['ice_hockey', 'sports'],
    sheet: [10, 27],
    shortName: 'ice_hockey_stick_and_puck'
  },
  {
    name: 'Lacrosse Stick and Ball',
    unified: '1F94D',
    keywords: [
      'lacrosse',
      'sports',
      'ball',
      'stick'
    ],
    sheet: [41, 36],
    shortName: 'lacrosse'
  },
  {
    name: 'Table Tennis Paddle and Ball',
    unified: '1F3D3',
    keywords: [
      'ping_pong',
      'sports',
      'pingpong'
    ],
    sheet: [10, 28],
    shortName: 'table_tennis_paddle_and_ball'
  },
  {
    name: 'Badminton Racquet and Shuttlecock',
    unified: '1F3F8',
    keywords: ['badminton', 'sports'],
    sheet: [11, 10],
    shortName: 'badminton_racquet_and_shuttlecock'
  },
  {
    name: 'Boxing Glove',
    unified: '1F94A',
    keywords: [
      'boxing_glove',
      'sports',
      'fighting'
    ],
    sheet: [41, 33],
    shortName: 'boxing_glove'
  },
  {
    name: 'Martial Arts Uniform',
    unified: '1F94B',
    keywords: [
      'martial_arts_uniform',
      'judo',
      'karate',
      'taekwondo'
    ],
    sheet: [41, 34],
    shortName: 'martial_arts_uniform'
  },
  {
    name: 'Goal Net',
    unified: '1F945',
    keywords: ['goal_net', 'sports'],
    sheet: [41, 29],
    shortName: 'goal_net'
  },
  {
    name: 'Flag in Hole',
    unified: '26F3',
    keywords: [
      'flag_in_hole',
      'sports',
      'business',
      'flag',
      'hole',
      'summer'
    ],
    sheet: [55, 19],
    shortName: 'golf'
  },
  {
    name: 'Ice Skate',
    unified: '26F8-FE0F',
    keywords: ['ice_skate', 'sports'],
    sheet: [55, 23],
    shortName: 'ice_skate'
  },
  {
    name: 'Fishing Pole and Fish',
    unified: '1F3A3',
    keywords: [
      'fishing_pole',
      'food',
      'hobby',
      'summer'
    ],
    sheet: [8, 1],
    shortName: 'fishing_pole_and_fish'
  },
  {
    name: 'Diving Mask',
    unified: '1F93F',
    keywords: [
      'diving_mask',
      'sport',
      'ocean'
    ],
    sheet: [41, 23],
    shortName: 'diving_mask'
  },
  {
    name: 'Running Shirt with Sash',
    unified: '1F3BD',
    keywords: [
      'running_shirt',
      'play',
      'pageant'
    ],
    sheet: [8, 27],
    shortName: 'running_shirt_with_sash'
  },
  {
    name: 'Ski and Ski Boot',
    unified: '1F3BF',
    keywords: [
      'skis',
      'sports',
      'winter',
      'cold',
      'snow'
    ],
    sheet: [8, 29],
    shortName: 'ski'
  },
  {
    name: 'Sled',
    unified: '1F6F7',
    keywords: [
      'sled',
      'sleigh',
      'luge',
      'toboggan'
    ],
    sheet: [36, 49],
    shortName: 'sled'
  },
  {
    name: 'Curling Stone',
    unified: '1F94C',
    keywords: [
      'curling_stone',
      'sports'
    ],
    sheet: [41, 35],
    shortName: 'curling_stone'
  },
  {
    name: 'Direct Hit',
    unified: '1F3AF',
    keywords: [
      'direct_hit',
      'game',
      'play',
      'bar',
      'target',
      'bullseye'
    ],
    sheet: [8, 13],
    shortName: 'dart'
  },
  {
    name: 'Yo-Yo',
    unified: '1FA80',
    keywords: ['yo_yo', 'toy'],
    sheet: [52, 25],
    shortName: 'yo-yo'
  },
  {
    name: 'Kite',
    unified: '1FA81',
    keywords: ['kite', 'wind', 'fly'],
    sheet: [52, 26],
    shortName: 'kite'
  },
  {
    name: 'Billiards',
    unified: '1F3B1',
    keywords: [
      'pool_8_ball',
      'pool',
      'hobby',
      'game',
      'luck',
      'magic'
    ],
    sheet: [8, 15],
    shortName: '8ball'
  },
  {
    name: 'Crystal Ball',
    unified: '1F52E',
    keywords: [
      'crystal_ball',
      'disco',
      'party',
      'magic',
      'circus',
      'fortune_teller'
    ],
    sheet: [28, 16],
    shortName: 'crystal_ball'
  },
  {
    name: 'Magic Wand',
    unified: '1FA84',
    keywords: [
      'magic wand',
      'supernature',
      'power'
    ],
    sheet: [52, 29],
    shortName: 'magic_wand'
  },
  {
    name: 'Nazar Amulet',
    unified: '1F9FF',
    keywords: [
      'nazar_amulet',
      'bead',
      'charm'
    ],
    sheet: [52, 16],
    shortName: 'nazar_amulet'
  },
  {
    name: 'Video Game',
    unified: '1F3AE',
    keywords: [
      'video_game',
      'play',
      'console',
      'PS4',
      'controller'
    ],
    sheet: [8, 12],
    shortName: 'video_game'
  },
  {
    name: 'Joystick',
    unified: '1F579-FE0F',
    keywords: [
      'joystick',
      'game',
      'play'
    ],
    sheet: [29, 34],
    shortName: 'joystick'
  },
  {
    name: 'Slot Machine',
    unified: '1F3B0',
    keywords: [
      'slot_machine',
      'bet',
      'gamble',
      'vegas',
      'fruit machine',
      'luck',
      'casino'
    ],
    sheet: [8, 14],
    shortName: 'slot_machine'
  },
  {
    name: 'Game Die',
    unified: '1F3B2',
    keywords: [
      'game_die',
      'dice',
      'random',
      'tabletop',
      'play',
      'luck'
    ],
    sheet: [8, 16],
    shortName: 'game_die'
  },
  {
    name: 'Jigsaw Puzzle Piece',
    unified: '1F9E9',
    keywords: [
      'puzzle_piece',
      'interlocking',
      'puzzle',
      'piece'
    ],
    sheet: [51, 52],
    shortName: 'jigsaw'
  },
  {
    name: 'Teddy Bear',
    unified: '1F9F8',
    keywords: [
      'teddy_bear',
      'plush',
      'stuffed'
    ],
    sheet: [52, 9],
    shortName: 'teddy_bear'
  },
  {
    name: 'Pinata',
    unified: '1FA85',
    keywords: [
      'pinata',
      'mexico',
      'candy',
      'celebration'
    ],
    sheet: [52, 30],
    shortName: 'pinata'
  },
  {
    name: 'Nesting Dolls',
    unified: '1FA86',
    keywords: [
      'nesting dolls',
      'matryoshka',
      'toy'
    ],
    sheet: [52, 31],
    shortName: 'nesting_dolls'
  },
  {
    name: 'Black Spade Suit',
    unified: '2660-FE0F',
    keywords: [
      'spade_suit',
      'poker',
      'cards',
      'suits',
      'magic'
    ],
    sheet: [54, 38],
    shortName: 'spades'
  },
  {
    name: 'Black Heart Suit',
    unified: '2665-FE0F',
    keywords: [
      'heart_suit',
      'poker',
      'cards',
      'magic',
      'suits'
    ],
    sheet: [54, 40],
    shortName: 'hearts'
  },
  {
    name: 'Black Diamond Suit',
    unified: '2666-FE0F',
    keywords: [
      'diamond_suit',
      'poker',
      'cards',
      'magic',
      'suits'
    ],
    sheet: [54, 41],
    shortName: 'diamonds'
  },
  {
    name: 'Black Club Suit',
    unified: '2663-FE0F',
    keywords: [
      'club_suit',
      'poker',
      'cards',
      'magic',
      'suits'
    ],
    sheet: [54, 39],
    shortName: 'clubs'
  },
  {
    name: 'Chess Pawn',
    unified: '265F-FE0F',
    keywords: [
      'chess_pawn',
      'expendable'
    ],
    sheet: [54, 37],
    shortName: 'chess_pawn'
  },
  {
    name: 'Playing Card Black Joker',
    unified: '1F0CF',
    keywords: [
      'joker',
      'poker',
      'cards',
      'game',
      'play',
      'magic'
    ],
    sheet: [0, 15],
    shortName: 'black_joker'
  },
  {
    name: 'Mahjong Tile Red Dragon',
    unified: '1F004',
    keywords: [
      'mahjong_red_dragon',
      'game',
      'play',
      'chinese',
      'kanji'
    ],
    sheet: [0, 14],
    shortName: 'mahjong'
  },
  {
    name: 'Flower Playing Cards',
    unified: '1F3B4',
    keywords: [
      'flower_playing_cards',
      'game',
      'sunset',
      'red'
    ],
    sheet: [8, 18],
    shortName: 'flower_playing_cards'
  },
  {
    name: 'Performing Arts',
    unified: '1F3AD',
    keywords: [
      'performing_arts',
      'acting',
      'theater',
      'drama'
    ],
    sheet: [8, 11],
    shortName: 'performing_arts'
  },
  {
    name: 'Framed Picture',
    unified: '1F5BC-FE0F',
    keywords: [
      'framed_picture',
      'photography'
    ],
    sheet: [30, 11],
    shortName: 'frame_with_picture'
  },
  {
    name: 'Artist Palette',
    unified: '1F3A8',
    keywords: [
      'artist_palette',
      'design',
      'paint',
      'draw',
      'colors'
    ],
    sheet: [8, 6],
    shortName: 'art'
  },
  {
    name: 'Spool of Thread',
    unified: '1F9F5',
    keywords: [
      'thread',
      'needle',
      'sewing',
      'spool',
      'string'
    ],
    sheet: [52, 6],
    shortName: 'thread'
  },
  {
    name: 'Sewing Needle',
    unified: '1FAA1',
    keywords: [
      'sewing needle',
      'stitches'
    ],
    sheet: [52, 49],
    shortName: 'sewing_needle'
  },
  {
    name: 'Ball of Yarn',
    unified: '1F9F6',
    keywords: [
      'yarn',
      'ball',
      'crochet',
      'knit'
    ],
    sheet: [52, 7],
    shortName: 'yarn'
  },
  {
    name: 'Knot',
    unified: '1FAA2',
    keywords: ['knot', 'rope', 'scout'],
    sheet: [52, 50],
    shortName: 'knot'
  },
  {
    name: 'Eyeglasses',
    unified: '1F453',
    keywords: [
      'glasses',
      'fashion',
      'accessories',
      'eyesight',
      'nerdy',
      'dork',
      'geek'
    ],
    sheet: [13, 54],
    shortName: 'eyeglasses'
  },
  {
    name: 'Sunglasses',
    unified: '1F576-FE0F',
    keywords: [
      'sunglasses',
      'face',
      'cool',
      'accessories'
    ],
    sheet: [29, 31],
    shortName: 'dark_sunglasses'
  },
  {
    name: 'Goggles',
    unified: '1F97D',
    keywords: [
      'goggles',
      'eyes',
      'protection',
      'safety'
    ],
    sheet: [42, 30],
    shortName: 'goggles'
  },
  {
    name: 'Lab Coat',
    unified: '1F97C',
    keywords: [
      'lab_coat',
      'doctor',
      'experiment',
      'scientist',
      'chemist'
    ],
    sheet: [42, 29],
    shortName: 'lab_coat'
  },
  {
    name: 'Safety Vest',
    unified: '1F9BA',
    keywords: [
      'safety_vest',
      'protection'
    ],
    sheet: [44, 15],
    shortName: 'safety_vest'
  },
  {
    name: 'Necktie',
    unified: '1F454',
    keywords: [
      'necktie',
      'shirt',
      'suitup',
      'formal',
      'fashion',
      'cloth',
      'business'
    ],
    sheet: [13, 55],
    shortName: 'necktie'
  },
  {
    name: 'T-Shirt',
    unified: '1F455',
    keywords: [
      't_shirt',
      'fashion',
      'cloth',
      'casual',
      'shirt',
      'tee'
    ],
    sheet: [13, 56],
    shortNames: ['tshirt'],
    shortName: 'shirt'
  },
  {
    name: 'Jeans',
    unified: '1F456',
    keywords: [
      'jeans',
      'fashion',
      'shopping'
    ],
    sheet: [13, 57],
    shortName: 'jeans'
  },
  {
    name: 'Scarf',
    unified: '1F9E3',
    keywords: [
      'scarf',
      'neck',
      'winter',
      'clothes'
    ],
    sheet: [51, 46],
    shortName: 'scarf'
  },
  {
    name: 'Gloves',
    unified: '1F9E4',
    keywords: [
      'gloves',
      'hands',
      'winter',
      'clothes'
    ],
    sheet: [51, 47],
    shortName: 'gloves'
  },
  {
    name: 'Coat',
    unified: '1F9E5',
    keywords: ['coat', 'jacket'],
    sheet: [51, 48],
    shortName: 'coat'
  },
  {
    name: 'Socks',
    unified: '1F9E6',
    keywords: [
      'socks',
      'stockings',
      'clothes'
    ],
    sheet: [51, 49],
    shortName: 'socks'
  },
  {
    name: 'Dress',
    unified: '1F457',
    keywords: [
      'dress',
      'clothes',
      'fashion',
      'shopping'
    ],
    sheet: [14, 0],
    shortName: 'dress'
  },
  {
    name: 'Kimono',
    unified: '1F458',
    keywords: [
      'kimono',
      'dress',
      'fashion',
      'women',
      'female',
      'japanese'
    ],
    sheet: [14, 1],
    shortName: 'kimono'
  },
  {
    name: 'Sari',
    unified: '1F97B',
    keywords: ['sari', 'dress'],
    sheet: [42, 28],
    shortName: 'sari'
  },
  {
    name: 'One-Piece Swimsuit',
    unified: '1FA71',
    keywords: [
      'one_piece_swimsuit',
      'fashion'
    ],
    sheet: [52, 18],
    shortName: 'one-piece_swimsuit'
  },
  {
    name: 'Briefs',
    unified: '1FA72',
    keywords: ['briefs', 'clothing'],
    sheet: [52, 19],
    shortName: 'briefs'
  },
  {
    name: 'Shorts',
    unified: '1FA73',
    keywords: ['shorts', 'clothing'],
    sheet: [52, 20],
    shortName: 'shorts'
  },
  {
    name: 'Bikini',
    unified: '1F459',
    keywords: [
      'bikini',
      'swimming',
      'female',
      'woman',
      'girl',
      'fashion',
      'beach',
      'summer'
    ],
    sheet: [14, 2],
    shortName: 'bikini'
  },
  {
    name: 'Womans Clothes',
    unified: '1F45A',
    keywords: [
      'woman_s_clothes',
      'fashion',
      'shopping_bags',
      'female'
    ],
    sheet: [14, 3],
    shortName: 'womans_clothes'
  },
  {
    name: 'Purse',
    unified: '1F45B',
    keywords: [
      'purse',
      'fashion',
      'accessories',
      'money',
      'sales',
      'shopping'
    ],
    sheet: [14, 4],
    shortName: 'purse'
  },
  {
    name: 'Handbag',
    unified: '1F45C',
    keywords: [
      'handbag',
      'fashion',
      'accessory',
      'accessories',
      'shopping'
    ],
    sheet: [14, 5],
    shortName: 'handbag'
  },
  {
    name: 'Pouch',
    unified: '1F45D',
    keywords: [
      'clutch_bag',
      'bag',
      'accessories',
      'shopping'
    ],
    sheet: [14, 6],
    shortName: 'pouch'
  },
  {
    name: 'Shopping Bags',
    unified: '1F6CD-FE0F',
    keywords: [
      'shopping_bags',
      'mall',
      'buy',
      'purchase'
    ],
    sheet: [36, 26],
    shortName: 'shopping_bags'
  },
  {
    name: 'School Satchel',
    unified: '1F392',
    keywords: [
      'backpack',
      'student',
      'education',
      'bag',
      'backpack'
    ],
    sheet: [7, 47],
    shortName: 'school_satchel'
  },
  {
    name: 'Thong Sandal',
    unified: '1FA74',
    keywords: [
      'thong sandal',
      'footwear',
      'summer'
    ],
    sheet: [52, 21],
    shortName: 'thong_sandal'
  },
  {
    name: 'Mans Shoe',
    unified: '1F45E',
    keywords: [
      'man_s_shoe',
      'fashion',
      'male'
    ],
    sheet: [14, 7],
    shortNames: ['shoe'],
    shortName: 'mans_shoe'
  },
  {
    name: 'Athletic Shoe',
    unified: '1F45F',
    keywords: [
      'running_shoe',
      'shoes',
      'sports',
      'sneakers'
    ],
    sheet: [14, 8],
    shortName: 'athletic_shoe'
  },
  {
    name: 'Hiking Boot',
    unified: '1F97E',
    keywords: [
      'hiking_boot',
      'backpacking',
      'camping',
      'hiking'
    ],
    sheet: [42, 31],
    shortName: 'hiking_boot'
  },
  {
    name: 'Flat Shoe',
    unified: '1F97F',
    keywords: [
      'flat_shoe',
      'ballet',
      'slip-on',
      'slipper'
    ],
    sheet: [42, 32],
    shortName: 'womans_flat_shoe'
  },
  {
    name: 'High-Heeled Shoe',
    unified: '1F460',
    keywords: [
      'high_heeled_shoe',
      'fashion',
      'shoes',
      'female',
      'pumps',
      'stiletto'
    ],
    sheet: [14, 9],
    shortName: 'high_heel'
  },
  {
    name: 'Womans Sandal',
    unified: '1F461',
    keywords: [
      'woman_s_sandal',
      'shoes',
      'fashion',
      'flip flops'
    ],
    sheet: [14, 10],
    shortName: 'sandal'
  },
  {
    name: 'Ballet Shoes',
    unified: '1FA70',
    keywords: ['ballet_shoes', 'dance'],
    sheet: [52, 17],
    shortName: 'ballet_shoes'
  },
  {
    name: 'Womans Boots',
    unified: '1F462',
    keywords: [
      'woman_s_boot',
      'shoes',
      'fashion'
    ],
    sheet: [14, 11],
    shortName: 'boot'
  },
  {
    name: 'Crown',
    unified: '1F451',
    keywords: [
      'crown',
      'king',
      'kod',
      'leader',
      'royalty',
      'lord'
    ],
    sheet: [13, 52],
    shortName: 'crown'
  },
  {
    name: 'Womans Hat',
    unified: '1F452',
    keywords: [
      'woman_s_hat',
      'fashion',
      'accessories',
      'female',
      'lady',
      'spring'
    ],
    sheet: [13, 53],
    shortName: 'womans_hat'
  },
  {
    name: 'Top Hat',
    unified: '1F3A9',
    keywords: [
      'top_hat',
      'magic',
      'gentleman',
      'classy',
      'circus'
    ],
    sheet: [8, 7],
    shortName: 'tophat'
  },
  {
    name: 'Graduation Cap',
    unified: '1F393',
    keywords: [
      'graduation_cap',
      'school',
      'college',
      'degree',
      'university',
      'graduation',
      'cap',
      'hat',
      'legal',
      'learn',
      'education'
    ],
    sheet: [7, 48],
    shortName: 'mortar_board'
  },
  {
    name: 'Billed Cap',
    unified: '1F9E2',
    keywords: [
      'billed_cap',
      'cap',
      'baseball'
    ],
    sheet: [51, 45],
    shortName: 'billed_cap'
  },
  {
    name: 'Military Helmet',
    unified: '1FA96',
    keywords: [
      'military helmet',
      'army',
      'protection'
    ],
    sheet: [52, 38],
    shortName: 'military_helmet'
  },
  {
    name: 'Rescue Worker’s Helmet',
    unified: '26D1-FE0F',
    keywords: [
      'rescue_worker_s_helmet',
      'construction',
      'build'
    ],
    sheet: [55, 11],
    shortName: 'helmet_with_white_cross'
  },
  {
    name: 'Prayer Beads',
    unified: '1F4FF',
    keywords: [
      'prayer_beads',
      'dhikr',
      'religious'
    ],
    sheet: [27, 27],
    shortName: 'prayer_beads'
  },
  {
    name: 'Lipstick',
    unified: '1F484',
    keywords: [
      'lipstick',
      'female',
      'girl',
      'fashion',
      'woman'
    ],
    sheet: [24, 35],
    shortName: 'lipstick'
  },
  {
    name: 'Ring',
    unified: '1F48D',
    keywords: [
      'ring',
      'wedding',
      'propose',
      'marriage',
      'valentines',
      'diamond',
      'fashion',
      'jewelry',
      'gem',
      'engagement'
    ],
    sheet: [25, 25],
    shortName: 'ring'
  },
  {
    name: 'Gem Stone',
    unified: '1F48E',
    keywords: [
      'gem_stone',
      'blue',
      'ruby',
      'diamond',
      'jewelry'
    ],
    sheet: [25, 26],
    shortName: 'gem'
  },
  {
    name: 'Speaker with Cancellation Stroke',
    unified: '1F507',
    keywords: [
      'muted_speaker',
      'sound',
      'volume',
      'silence',
      'quiet'
    ],
    sheet: [27, 35],
    shortName: 'mute'
  },
  {
    name: 'Speaker',
    unified: '1F508',
    keywords: [
      'speaker_low_volume',
      'sound',
      'volume',
      'silence',
      'broadcast'
    ],
    sheet: [27, 36],
    shortName: 'speaker'
  },
  {
    name: 'Speaker with One Sound Wave',
    unified: '1F509',
    keywords: [
      'speaker_medium_volume',
      'volume',
      'speaker',
      'broadcast'
    ],
    sheet: [27, 37],
    shortName: 'sound'
  },
  {
    name: 'Speaker with Three Sound Waves',
    unified: '1F50A',
    keywords: [
      'speaker_high_volume',
      'volume',
      'noise',
      'noisy',
      'speaker',
      'broadcast'
    ],
    sheet: [27, 38],
    shortName: 'loud_sound'
  },
  {
    name: 'Public Address Loudspeaker',
    unified: '1F4E2',
    keywords: [
      'loudspeaker',
      'volume',
      'sound'
    ],
    sheet: [26, 57],
    shortName: 'loudspeaker'
  },
  {
    name: 'Cheering Megaphone',
    unified: '1F4E3',
    keywords: [
      'megaphone',
      'sound',
      'speaker',
      'volume'
    ],
    sheet: [27, 0],
    shortName: 'mega'
  },
  {
    name: 'Postal Horn',
    unified: '1F4EF',
    keywords: [
      'postal_horn',
      'instrument',
      'music'
    ],
    sheet: [27, 12],
    shortName: 'postal_horn'
  },
  {
    name: 'Bell',
    unified: '1F514',
    keywords: [
      'bell',
      'sound',
      'notification',
      'christmas',
      'xmas',
      'chime'
    ],
    sheet: [27, 48],
    shortName: 'bell'
  },
  {
    name: 'Bell with Cancellation Stroke',
    unified: '1F515',
    keywords: [
      'bell_with_slash',
      'sound',
      'volume',
      'mute',
      'quiet',
      'silent'
    ],
    sheet: [27, 49],
    shortName: 'no_bell'
  },
  {
    name: 'Musical Score',
    unified: '1F3BC',
    keywords: [
      'musical_score',
      'treble',
      'clef',
      'compose'
    ],
    sheet: [8, 26],
    shortName: 'musical_score'
  },
  {
    name: 'Musical Note',
    unified: '1F3B5',
    keywords: [
      'musical_note',
      'score',
      'tone',
      'sound'
    ],
    sheet: [8, 19],
    shortName: 'musical_note'
  },
  {
    name: 'Multiple Musical Notes',
    unified: '1F3B6',
    keywords: [
      'musical_notes',
      'music',
      'score'
    ],
    sheet: [8, 20],
    shortName: 'notes'
  },
  {
    name: 'Studio Microphone',
    unified: '1F399-FE0F',
    keywords: [
      'studio_microphone',
      'sing',
      'recording',
      'artist',
      'talkshow'
    ],
    sheet: [7, 51],
    shortName: 'studio_microphone'
  },
  {
    name: 'Level Slider',
    unified: '1F39A-FE0F',
    keywords: ['level_slider', 'scale'],
    sheet: [7, 52],
    shortName: 'level_slider'
  },
  {
    name: 'Control Knobs',
    unified: '1F39B-FE0F',
    keywords: ['control_knobs', 'dial'],
    sheet: [7, 53],
    shortName: 'control_knobs'
  },
  {
    name: 'Microphone',
    unified: '1F3A4',
    keywords: [
      'microphone',
      'sound',
      'music',
      'PA',
      'sing',
      'talkshow'
    ],
    sheet: [8, 2],
    shortName: 'microphone'
  },
  {
    name: 'Headphone',
    unified: '1F3A7',
    keywords: [
      'headphone',
      'music',
      'score',
      'gadgets'
    ],
    sheet: [8, 5],
    shortName: 'headphones'
  },
  {
    name: 'Radio',
    unified: '1F4FB',
    keywords: [
      'radio',
      'communication',
      'music',
      'podcast',
      'program'
    ],
    sheet: [27, 24],
    shortName: 'radio'
  },
  {
    name: 'Saxophone',
    unified: '1F3B7',
    keywords: [
      'saxophone',
      'music',
      'instrument',
      'jazz',
      'blues'
    ],
    sheet: [8, 21],
    shortName: 'saxophone'
  },
  {
    name: 'Accordion',
    unified: '1FA97',
    keywords: ['accordion', 'music'],
    sheet: [52, 39],
    shortName: 'accordion'
  },
  {
    name: 'Guitar',
    unified: '1F3B8',
    keywords: [
      'guitar',
      'music',
      'instrument'
    ],
    sheet: [8, 22],
    shortName: 'guitar'
  },
  {
    name: 'Musical Keyboard',
    unified: '1F3B9',
    keywords: [
      'musical_keyboard',
      'piano',
      'instrument',
      'compose'
    ],
    sheet: [8, 23],
    shortName: 'musical_keyboard'
  },
  {
    name: 'Trumpet',
    unified: '1F3BA',
    keywords: [
      'trumpet',
      'music',
      'brass'
    ],
    sheet: [8, 24],
    shortName: 'trumpet'
  },
  {
    name: 'Violin',
    unified: '1F3BB',
    keywords: [
      'violin',
      'music',
      'instrument',
      'orchestra',
      'symphony'
    ],
    sheet: [8, 25],
    shortName: 'violin'
  },
  {
    name: 'Banjo',
    unified: '1FA95',
    keywords: [
      'banjo',
      'music',
      'instructment'
    ],
    sheet: [52, 37],
    shortName: 'banjo'
  },
  {
    name: 'Drum with Drumsticks',
    unified: '1F941',
    keywords: [
      'drum',
      'music',
      'instrument',
      'drumsticks',
      'snare'
    ],
    sheet: [41, 25],
    shortName: 'drum_with_drumsticks'
  },
  {
    name: 'Long Drum',
    unified: '1FA98',
    keywords: ['long drum', 'music'],
    sheet: [52, 40],
    shortName: 'long_drum'
  },
  {
    name: 'Mobile Phone',
    unified: '1F4F1',
    keywords: [
      'mobile_phone',
      'technology',
      'apple',
      'gadgets',
      'dial'
    ],
    sheet: [27, 14],
    shortName: 'iphone'
  },
  {
    name: 'Mobile Phone with Rightwards Arrow at Left',
    unified: '1F4F2',
    keywords: [
      'mobile_phone_with_arrow',
      'iphone',
      'incoming'
    ],
    sheet: [27, 15],
    shortName: 'calling'
  },
  {
    name: 'Black Telephone',
    unified: '260E-FE0F',
    keywords: [
      'telephone',
      'technology',
      'communication',
      'dial',
      'telephone'
    ],
    sheet: [54, 2],
    shortNames: ['telephone'],
    shortName: 'phone'
  },
  {
    name: 'Telephone Receiver',
    unified: '1F4DE',
    keywords: [
      'telephone_receiver',
      'technology',
      'communication',
      'dial'
    ],
    sheet: [26, 53],
    shortName: 'telephone_receiver'
  },
  {
    name: 'Pager',
    unified: '1F4DF',
    keywords: [
      'pager',
      'bbcall',
      'oldschool',
      '90s'
    ],
    sheet: [26, 54],
    shortName: 'pager'
  },
  {
    name: 'Fax Machine',
    unified: '1F4E0',
    keywords: [
      'fax_machine',
      'communication',
      'technology'
    ],
    sheet: [26, 55],
    shortName: 'fax'
  },
  {
    name: 'Battery',
    unified: '1F50B',
    keywords: [
      'battery',
      'power',
      'energy',
      'sustain'
    ],
    sheet: [27, 39],
    shortName: 'battery'
  },
  {
    name: 'Electric Plug',
    unified: '1F50C',
    keywords: [
      'electric_plug',
      'charger',
      'power'
    ],
    sheet: [27, 40],
    shortName: 'electric_plug'
  },
  {
    name: 'Personal Computer',
    unified: '1F4BB',
    keywords: [
      'laptop',
      'technology',
      'laptop',
      'screen',
      'display',
      'monitor'
    ],
    sheet: [26, 18],
    shortName: 'computer'
  },
  {
    name: 'Desktop Computer',
    unified: '1F5A5-FE0F',
    keywords: [
      'desktop_computer',
      'technology',
      'computing',
      'screen'
    ],
    sheet: [30, 7],
    shortName: 'desktop_computer'
  },
  {
    name: 'Printer',
    unified: '1F5A8-FE0F',
    keywords: [
      'printer',
      'paper',
      'ink'
    ],
    sheet: [30, 8],
    shortName: 'printer'
  },
  {
    name: 'Keyboard',
    unified: '2328-FE0F',
    keywords: [
      'keyboard',
      'technology',
      'computer',
      'type',
      'input',
      'text'
    ],
    sheet: [53, 30],
    shortName: 'keyboard'
  },
  {
    name: 'Computer Mouse',
    unified: '1F5B1-FE0F',
    keywords: [
      'computer_mouse',
      'click'
    ],
    sheet: [30, 9],
    shortName: 'three_button_mouse'
  },
  {
    name: 'Trackball',
    unified: '1F5B2-FE0F',
    keywords: [
      'trackball',
      'technology',
      'trackpad'
    ],
    sheet: [30, 10],
    shortName: 'trackball'
  },
  {
    name: 'Minidisc',
    unified: '1F4BD',
    keywords: [
      'computer_disk',
      'technology',
      'record',
      'data',
      'disk',
      '90s'
    ],
    sheet: [26, 20],
    shortName: 'minidisc'
  },
  {
    name: 'Floppy Disk',
    unified: '1F4BE',
    keywords: [
      'floppy_disk',
      'oldschool',
      'technology',
      'save',
      '90s',
      '80s'
    ],
    sheet: [26, 21],
    shortName: 'floppy_disk'
  },
  {
    name: 'Optical Disc',
    unified: '1F4BF',
    keywords: [
      'optical_disk',
      'technology',
      'dvd',
      'disk',
      'disc',
      '90s'
    ],
    sheet: [26, 22],
    shortName: 'cd'
  },
  {
    name: 'Dvd',
    unified: '1F4C0',
    keywords: [
      'dvd',
      'cd',
      'disk',
      'disc'
    ],
    sheet: [26, 23],
    shortName: 'dvd'
  },
  {
    name: 'Abacus',
    unified: '1F9EE',
    keywords: ['abacus', 'calculation'],
    sheet: [51, 57],
    shortName: 'abacus'
  },
  {
    name: 'Movie Camera',
    unified: '1F3A5',
    keywords: [
      'movie_camera',
      'film',
      'record'
    ],
    sheet: [8, 3],
    shortName: 'movie_camera'
  },
  {
    name: 'Film Frames',
    unified: '1F39E-FE0F',
    keywords: ['film_frames', 'movie'],
    sheet: [7, 54],
    shortName: 'film_frames'
  },
  {
    name: 'Film Projector',
    unified: '1F4FD-FE0F',
    keywords: [
      'film_projector',
      'video',
      'tape',
      'record',
      'movie'
    ],
    sheet: [27, 26],
    shortName: 'film_projector'
  },
  {
    name: 'Clapper Board',
    unified: '1F3AC',
    keywords: [
      'clapper_board',
      'movie',
      'film',
      'record'
    ],
    sheet: [8, 10],
    shortName: 'clapper'
  },
  {
    name: 'Television',
    unified: '1F4FA',
    keywords: [
      'television',
      'technology',
      'program',
      'oldschool',
      'show',
      'television'
    ],
    sheet: [27, 23],
    shortName: 'tv'
  },
  {
    name: 'Camera',
    unified: '1F4F7',
    keywords: [
      'camera',
      'gadgets',
      'photography'
    ],
    sheet: [27, 20],
    shortName: 'camera'
  },
  {
    name: 'Camera with Flash',
    unified: '1F4F8',
    keywords: [
      'camera_with_flash',
      'photography',
      'gadgets'
    ],
    sheet: [27, 21],
    shortName: 'camera_with_flash'
  },
  {
    name: 'Video Camera',
    unified: '1F4F9',
    keywords: [
      'video_camera',
      'film',
      'record'
    ],
    sheet: [27, 22],
    shortName: 'video_camera'
  },
  {
    name: 'Videocassette',
    unified: '1F4FC',
    keywords: [
      'videocassette',
      'record',
      'video',
      'oldschool',
      '90s',
      '80s'
    ],
    sheet: [27, 25],
    shortName: 'vhs'
  },
  {
    name: 'Left-Pointing Magnifying Glass',
    unified: '1F50D',
    keywords: [
      'magnifying_glass_tilted_left',
      'search',
      'zoom',
      'find',
      'detective'
    ],
    sheet: [27, 41],
    shortName: 'mag'
  },
  {
    name: 'Right-Pointing Magnifying Glass',
    unified: '1F50E',
    keywords: [
      'magnifying_glass_tilted_right',
      'search',
      'zoom',
      'find',
      'detective'
    ],
    sheet: [27, 42],
    shortName: 'mag_right'
  },
  {
    name: 'Candle',
    unified: '1F56F-FE0F',
    keywords: ['candle', 'fire', 'wax'],
    sheet: [29, 4],
    shortName: 'candle'
  },
  {
    name: 'Electric Light Bulb',
    unified: '1F4A1',
    keywords: [
      'light_bulb',
      'light',
      'electricity',
      'idea'
    ],
    sheet: [25, 45],
    shortName: 'bulb'
  },
  {
    name: 'Electric Torch',
    unified: '1F526',
    keywords: [
      'flashlight',
      'dark',
      'camping',
      'sight',
      'night'
    ],
    sheet: [28, 8],
    shortName: 'flashlight'
  },
  {
    name: 'Izakaya Lantern',
    unified: '1F3EE',
    keywords: [
      'red_paper_lantern',
      'light',
      'paper',
      'halloween',
      'spooky'
    ],
    sheet: [10, 55],
    shortNames: ['lantern'],
    shortName: 'izakaya_lantern'
  },
  {
    name: 'Diya Lamp',
    unified: '1FA94',
    keywords: ['diya_lamp', 'lighting'],
    sheet: [52, 36],
    shortName: 'diya_lamp'
  },
  {
    name: 'Notebook with Decorative Cover',
    unified: '1F4D4',
    keywords: [
      'notebook_with_decorative_cover',
      'classroom',
      'notes',
      'record',
      'paper',
      'study'
    ],
    sheet: [26, 43],
    shortName: 'notebook_with_decorative_cover'
  },
  {
    name: 'Closed Book',
    unified: '1F4D5',
    keywords: [
      'closed_book',
      'read',
      'library',
      'knowledge',
      'textbook',
      'learn'
    ],
    sheet: [26, 44],
    shortName: 'closed_book'
  },
  {
    name: 'Open Book',
    unified: '1F4D6',
    keywords: [
      'open_book',
      'book',
      'read',
      'library',
      'knowledge',
      'literature',
      'learn',
      'study'
    ],
    sheet: [26, 45],
    shortNames: ['open_book'],
    shortName: 'book'
  },
  {
    name: 'Green Book',
    unified: '1F4D7',
    keywords: [
      'green_book',
      'read',
      'library',
      'knowledge',
      'study'
    ],
    sheet: [26, 46],
    shortName: 'green_book'
  },
  {
    name: 'Blue Book',
    unified: '1F4D8',
    keywords: [
      'blue_book',
      'read',
      'library',
      'knowledge',
      'learn',
      'study'
    ],
    sheet: [26, 47],
    shortName: 'blue_book'
  },
  {
    name: 'Orange Book',
    unified: '1F4D9',
    keywords: [
      'orange_book',
      'read',
      'library',
      'knowledge',
      'textbook',
      'study'
    ],
    sheet: [26, 48],
    shortName: 'orange_book'
  },
  {
    name: 'Books',
    unified: '1F4DA',
    keywords: [
      'books',
      'literature',
      'library',
      'study'
    ],
    sheet: [26, 49],
    shortName: 'books'
  },
  {
    name: 'Notebook',
    unified: '1F4D3',
    keywords: [
      'notebook',
      'stationery',
      'record',
      'notes',
      'paper',
      'study'
    ],
    sheet: [26, 42],
    shortName: 'notebook'
  },
  {
    name: 'Ledger',
    unified: '1F4D2',
    keywords: [
      'ledger',
      'notes',
      'paper'
    ],
    sheet: [26, 41],
    shortName: 'ledger'
  },
  {
    name: 'Page with Curl',
    unified: '1F4C3',
    keywords: [
      'page_with_curl',
      'documents',
      'office',
      'paper'
    ],
    sheet: [26, 26],
    shortName: 'page_with_curl'
  },
  {
    name: 'Scroll',
    unified: '1F4DC',
    keywords: [
      'scroll',
      'documents',
      'ancient',
      'history',
      'paper'
    ],
    sheet: [26, 51],
    shortName: 'scroll'
  },
  {
    name: 'Page Facing Up',
    unified: '1F4C4',
    keywords: [
      'page_facing_up',
      'documents',
      'office',
      'paper',
      'information'
    ],
    sheet: [26, 27],
    shortName: 'page_facing_up'
  },
  {
    name: 'Newspaper',
    unified: '1F4F0',
    keywords: [
      'newspaper',
      'press',
      'headline'
    ],
    sheet: [27, 13],
    shortName: 'newspaper'
  },
  {
    name: 'Rolled-Up Newspaper',
    unified: '1F5DE-FE0F',
    keywords: [
      'rolled_up_newspaper',
      'press',
      'headline'
    ],
    sheet: [30, 20],
    shortName: 'rolled_up_newspaper'
  },
  {
    name: 'Bookmark Tabs',
    unified: '1F4D1',
    keywords: [
      'bookmark_tabs',
      'favorite',
      'save',
      'order',
      'tidy'
    ],
    sheet: [26, 40],
    shortName: 'bookmark_tabs'
  },
  {
    name: 'Bookmark',
    unified: '1F516',
    keywords: [
      'bookmark',
      'favorite',
      'label',
      'save'
    ],
    sheet: [27, 50],
    shortName: 'bookmark'
  },
  {
    name: 'Label',
    unified: '1F3F7-FE0F',
    keywords: ['label', 'sale', 'tag'],
    sheet: [11, 9],
    shortName: 'label'
  },
  {
    name: 'Money Bag',
    unified: '1F4B0',
    keywords: [
      'money_bag',
      'dollar',
      'payment',
      'coins',
      'sale'
    ],
    sheet: [26, 7],
    shortName: 'moneybag'
  },
  {
    name: 'Coin',
    unified: '1FA99',
    keywords: [
      'coin',
      'money',
      'currency'
    ],
    sheet: [52, 41],
    shortName: 'coin'
  },
  {
    name: 'Banknote with Yen Sign',
    unified: '1F4B4',
    keywords: [
      'yen_banknote',
      'money',
      'sales',
      'japanese',
      'dollar',
      'currency'
    ],
    sheet: [26, 11],
    shortName: 'yen'
  },
  {
    name: 'Banknote with Dollar Sign',
    unified: '1F4B5',
    keywords: [
      'dollar_banknote',
      'money',
      'sales',
      'bill',
      'currency'
    ],
    sheet: [26, 12],
    shortName: 'dollar'
  },
  {
    name: 'Banknote with Euro Sign',
    unified: '1F4B6',
    keywords: [
      'euro_banknote',
      'money',
      'sales',
      'dollar',
      'currency'
    ],
    sheet: [26, 13],
    shortName: 'euro'
  },
  {
    name: 'Banknote with Pound Sign',
    unified: '1F4B7',
    keywords: [
      'pound_banknote',
      'british',
      'sterling',
      'money',
      'sales',
      'bills',
      'uk',
      'england',
      'currency'
    ],
    sheet: [26, 14],
    shortName: 'pound'
  },
  {
    name: 'Money with Wings',
    unified: '1F4B8',
    keywords: [
      'money_with_wings',
      'dollar',
      'bills',
      'payment',
      'sale'
    ],
    sheet: [26, 15],
    shortName: 'money_with_wings'
  },
  {
    name: 'Credit Card',
    unified: '1F4B3',
    keywords: [
      'credit_card',
      'money',
      'sales',
      'dollar',
      'bill',
      'payment',
      'shopping'
    ],
    sheet: [26, 10],
    shortName: 'credit_card'
  },
  {
    name: 'Receipt',
    unified: '1F9FE',
    keywords: [
      'receipt',
      'accounting',
      'expenses'
    ],
    sheet: [52, 15],
    shortName: 'receipt'
  },
  {
    name: 'Chart with Upwards Trend and Yen Sign',
    unified: '1F4B9',
    keywords: [
      'chart_increasing_with_yen',
      'green-square',
      'graph',
      'presentation',
      'stats'
    ],
    sheet: [26, 16],
    shortName: 'chart'
  },
  {
    name: 'Envelope',
    unified: '2709-FE0F',
    keywords: [
      'envelope',
      'letter',
      'postal',
      'inbox',
      'communication'
    ],
    sheet: [55, 47],
    shortNames: ['envelope'],
    shortName: 'email'
  },
  {
    name: 'E-Mail Symbol',
    unified: '1F4E7',
    keywords: [
      'e_mail',
      'communication',
      'inbox'
    ],
    sheet: [27, 4],
    shortName: 'e-mail'
  },
  {
    name: 'Incoming Envelope',
    unified: '1F4E8',
    keywords: [
      'incoming_envelope',
      'email',
      'inbox'
    ],
    sheet: [27, 5],
    shortName: 'incoming_envelope'
  },
  {
    name: 'Envelope with Downwards Arrow Above',
    unified: '1F4E9',
    keywords: [
      'envelope_with_arrow',
      'email',
      'communication'
    ],
    sheet: [27, 6],
    shortName: 'envelope_with_arrow'
  },
  {
    name: 'Outbox Tray',
    unified: '1F4E4',
    keywords: [
      'outbox_tray',
      'inbox',
      'email'
    ],
    sheet: [27, 1],
    shortName: 'outbox_tray'
  },
  {
    name: 'Inbox Tray',
    unified: '1F4E5',
    keywords: [
      'inbox_tray',
      'email',
      'documents'
    ],
    sheet: [27, 2],
    shortName: 'inbox_tray'
  },
  {
    name: 'Package',
    unified: '1F4E6',
    keywords: [
      'package',
      'mail',
      'gift',
      'cardboard',
      'box',
      'moving'
    ],
    sheet: [27, 3],
    shortName: 'package'
  },
  {
    name: 'Closed Mailbox with Raised Flag',
    unified: '1F4EB',
    keywords: [
      'closed_mailbox_with_raised_flag',
      'email',
      'inbox',
      'communication'
    ],
    sheet: [27, 8],
    shortName: 'mailbox'
  },
  {
    name: 'Closed Mailbox with Lowered Flag',
    unified: '1F4EA',
    keywords: [
      'closed_mailbox_with_lowered_flag',
      'email',
      'communication',
      'inbox'
    ],
    sheet: [27, 7],
    shortName: 'mailbox_closed'
  },
  {
    name: 'Open Mailbox with Raised Flag',
    unified: '1F4EC',
    keywords: [
      'open_mailbox_with_raised_flag',
      'email',
      'inbox',
      'communication'
    ],
    sheet: [27, 9],
    shortName: 'mailbox_with_mail'
  },
  {
    name: 'Open Mailbox with Lowered Flag',
    unified: '1F4ED',
    keywords: [
      'open_mailbox_with_lowered_flag',
      'email',
      'inbox'
    ],
    sheet: [27, 10],
    shortName: 'mailbox_with_no_mail'
  },
  {
    name: 'Postbox',
    unified: '1F4EE',
    keywords: [
      'postbox',
      'email',
      'letter',
      'envelope'
    ],
    sheet: [27, 11],
    shortName: 'postbox'
  },
  {
    name: 'Ballot Box with Ballot',
    unified: '1F5F3-FE0F',
    keywords: [
      'ballot_box_with_ballot',
      'election',
      'vote'
    ],
    sheet: [30, 25],
    shortName: 'ballot_box_with_ballot'
  },
  {
    name: 'Pencil',
    unified: '270F-FE0F',
    keywords: [
      'pencil',
      'stationery',
      'write',
      'paper',
      'writing',
      'school',
      'study'
    ],
    sheet: [56, 14],
    shortName: 'pencil2'
  },
  {
    name: 'Black Nib',
    unified: '2712-FE0F',
    keywords: [
      'black_nib',
      'pen',
      'stationery',
      'writing',
      'write'
    ],
    sheet: [56, 15],
    shortName: 'black_nib'
  },
  {
    name: 'Fountain Pen',
    unified: '1F58B-FE0F',
    keywords: [
      'fountain_pen',
      'stationery',
      'writing',
      'write'
    ],
    sheet: [29, 43],
    shortName: 'lower_left_fountain_pen'
  },
  {
    name: 'Pen',
    unified: '1F58A-FE0F',
    keywords: [
      'pen',
      'stationery',
      'writing',
      'write'
    ],
    sheet: [29, 42],
    shortName: 'lower_left_ballpoint_pen'
  },
  {
    name: 'Paintbrush',
    unified: '1F58C-FE0F',
    keywords: [
      'paintbrush',
      'drawing',
      'creativity',
      'art'
    ],
    sheet: [29, 44],
    shortName: 'lower_left_paintbrush'
  },
  {
    name: 'Crayon',
    unified: '1F58D-FE0F',
    keywords: [
      'crayon',
      'drawing',
      'creativity'
    ],
    sheet: [29, 45],
    shortName: 'lower_left_crayon'
  },
  {
    name: 'Memo',
    unified: '1F4DD',
    keywords: [
      'memo',
      'write',
      'documents',
      'stationery',
      'pencil',
      'paper',
      'writing',
      'legal',
      'exam',
      'quiz',
      'test',
      'study',
      'compose'
    ],
    sheet: [26, 52],
    shortNames: ['pencil'],
    shortName: 'memo'
  },
  {
    name: 'Briefcase',
    unified: '1F4BC',
    keywords: [
      'briefcase',
      'business',
      'documents',
      'work',
      'law',
      'legal',
      'job',
      'career'
    ],
    sheet: [26, 19],
    shortName: 'briefcase'
  },
  {
    name: 'File Folder',
    unified: '1F4C1',
    keywords: [
      'file_folder',
      'documents',
      'business',
      'office'
    ],
    sheet: [26, 24],
    shortName: 'file_folder'
  },
  {
    name: 'Open File Folder',
    unified: '1F4C2',
    keywords: [
      'open_file_folder',
      'documents',
      'load'
    ],
    sheet: [26, 25],
    shortName: 'open_file_folder'
  },
  {
    name: 'Card Index Dividers',
    unified: '1F5C2-FE0F',
    keywords: [
      'card_index_dividers',
      'organizing',
      'business',
      'stationery'
    ],
    sheet: [30, 12],
    shortName: 'card_index_dividers'
  },
  {
    name: 'Calendar',
    unified: '1F4C5',
    keywords: [
      'calendar',
      'calendar',
      'schedule'
    ],
    sheet: [26, 28],
    shortName: 'date'
  },
  {
    name: 'Tear-off Calendar',
    unified: '1F4C6',
    keywords: [
      'tear_off_calendar',
      'schedule',
      'date',
      'planning'
    ],
    sheet: [26, 29],
    shortName: 'calendar'
  },
  {
    name: 'Spiral Notepad',
    unified: '1F5D2-FE0F',
    keywords: [
      'spiral_notepad',
      'memo',
      'stationery'
    ],
    sheet: [30, 16],
    shortName: 'spiral_note_pad'
  },
  {
    name: 'Spiral Calendar',
    unified: '1F5D3-FE0F',
    keywords: [
      'spiral_calendar',
      'date',
      'schedule',
      'planning'
    ],
    sheet: [30, 17],
    shortName: 'spiral_calendar_pad'
  },
  {
    name: 'Card Index',
    unified: '1F4C7',
    keywords: [
      'card_index',
      'business',
      'stationery'
    ],
    sheet: [26, 30],
    shortName: 'card_index'
  },
  {
    name: 'Chart with Upwards Trend',
    unified: '1F4C8',
    keywords: [
      'chart_increasing',
      'graph',
      'presentation',
      'stats',
      'recovery',
      'business',
      'economics',
      'money',
      'sales',
      'good',
      'success'
    ],
    sheet: [26, 31],
    shortName: 'chart_with_upwards_trend'
  },
  {
    name: 'Chart with Downwards Trend',
    unified: '1F4C9',
    keywords: [
      'chart_decreasing',
      'graph',
      'presentation',
      'stats',
      'recession',
      'business',
      'economics',
      'money',
      'sales',
      'bad',
      'failure'
    ],
    sheet: [26, 32],
    shortName: 'chart_with_downwards_trend'
  },
  {
    name: 'Bar Chart',
    unified: '1F4CA',
    keywords: [
      'bar_chart',
      'graph',
      'presentation',
      'stats'
    ],
    sheet: [26, 33],
    shortName: 'bar_chart'
  },
  {
    name: 'Clipboard',
    unified: '1F4CB',
    keywords: [
      'clipboard',
      'stationery',
      'documents'
    ],
    sheet: [26, 34],
    shortName: 'clipboard'
  },
  {
    name: 'Pushpin',
    unified: '1F4CC',
    keywords: [
      'pushpin',
      'stationery',
      'mark',
      'here'
    ],
    sheet: [26, 35],
    shortName: 'pushpin'
  },
  {
    name: 'Round Pushpin',
    unified: '1F4CD',
    keywords: [
      'round_pushpin',
      'stationery',
      'location',
      'map',
      'here'
    ],
    sheet: [26, 36],
    shortName: 'round_pushpin'
  },
  {
    name: 'Paperclip',
    unified: '1F4CE',
    keywords: [
      'paperclip',
      'documents',
      'stationery'
    ],
    sheet: [26, 37],
    shortName: 'paperclip'
  },
  {
    name: 'Linked Paperclips',
    unified: '1F587-FE0F',
    keywords: [
      'linked_paperclips',
      'documents',
      'stationery'
    ],
    sheet: [29, 41],
    shortName: 'linked_paperclips'
  },
  {
    name: 'Straight Ruler',
    unified: '1F4CF',
    keywords: [
      'straight_ruler',
      'stationery',
      'calculate',
      'length',
      'math',
      'school',
      'drawing',
      'architect',
      'sketch'
    ],
    sheet: [26, 38],
    shortName: 'straight_ruler'
  },
  {
    name: 'Triangular Ruler',
    unified: '1F4D0',
    keywords: [
      'triangular_ruler',
      'stationery',
      'math',
      'architect',
      'sketch'
    ],
    sheet: [26, 39],
    shortName: 'triangular_ruler'
  },
  {
    name: 'Black Scissors',
    unified: '2702-FE0F',
    keywords: [
      'scissors',
      'stationery',
      'cut'
    ],
    sheet: [55, 44],
    shortName: 'scissors'
  },
  {
    name: 'Card File Box',
    unified: '1F5C3-FE0F',
    keywords: [
      'card_file_box',
      'business',
      'stationery'
    ],
    sheet: [30, 13],
    shortName: 'card_file_box'
  },
  {
    name: 'File Cabinet',
    unified: '1F5C4-FE0F',
    keywords: [
      'file_cabinet',
      'filing',
      'organizing'
    ],
    sheet: [30, 14],
    shortName: 'file_cabinet'
  },
  {
    name: 'Wastebasket',
    unified: '1F5D1-FE0F',
    keywords: [
      'wastebasket',
      'bin',
      'trash',
      'rubbish',
      'garbage',
      'toss'
    ],
    sheet: [30, 15],
    shortName: 'wastebasket'
  },
  {
    name: 'Lock',
    unified: '1F512',
    keywords: [
      'locked',
      'security',
      'password',
      'padlock'
    ],
    sheet: [27, 46],
    shortName: 'lock'
  },
  {
    name: 'Open Lock',
    unified: '1F513',
    keywords: [
      'unlocked',
      'privacy',
      'security'
    ],
    sheet: [27, 47],
    shortName: 'unlock'
  },
  {
    name: 'Lock with Ink Pen',
    unified: '1F50F',
    keywords: [
      'locked_with_pen',
      'security',
      'secret'
    ],
    sheet: [27, 43],
    shortName: 'lock_with_ink_pen'
  },
  {
    name: 'Closed Lock with Key',
    unified: '1F510',
    keywords: [
      'locked_with_key',
      'security',
      'privacy'
    ],
    sheet: [27, 44],
    shortName: 'closed_lock_with_key'
  },
  {
    name: 'Key',
    unified: '1F511',
    keywords: [
      'key',
      'lock',
      'door',
      'password'
    ],
    sheet: [27, 45],
    shortName: 'key'
  },
  {
    name: 'Old Key',
    unified: '1F5DD-FE0F',
    keywords: [
      'old_key',
      'lock',
      'door',
      'password'
    ],
    sheet: [30, 19],
    shortName: 'old_key'
  },
  {
    name: 'Hammer',
    unified: '1F528',
    keywords: [
      'hammer',
      'tools',
      'build',
      'create'
    ],
    sheet: [28, 10],
    shortName: 'hammer'
  },
  {
    name: 'Axe',
    unified: '1FA93',
    keywords: [
      'axe',
      'tool',
      'chop',
      'cut'
    ],
    sheet: [52, 35],
    shortName: 'axe'
  },
  {
    name: 'Pick',
    unified: '26CF-FE0F',
    keywords: ['pick', 'tools', 'dig'],
    sheet: [55, 10],
    shortName: 'pick'
  },
  {
    name: 'Hammer and Pick',
    unified: '2692-FE0F',
    keywords: [
      'hammer_and_pick',
      'tools',
      'build',
      'create'
    ],
    sheet: [54, 46],
    shortName: 'hammer_and_pick'
  },
  {
    name: 'Hammer and Wrench',
    unified: '1F6E0-FE0F',
    keywords: [
      'hammer_and_wrench',
      'tools',
      'build',
      'create'
    ],
    sheet: [36, 35],
    shortName: 'hammer_and_wrench'
  },
  {
    name: 'Dagger',
    unified: '1F5E1-FE0F',
    keywords: ['dagger', 'weapon'],
    sheet: [30, 21],
    shortName: 'dagger_knife'
  },
  {
    name: 'Crossed Swords',
    unified: '2694-FE0F',
    keywords: [
      'crossed_swords',
      'weapon'
    ],
    sheet: [54, 48],
    shortName: 'crossed_swords'
  },
  {
    name: 'Pistol',
    unified: '1F52B',
    keywords: [
      'pistol',
      'violence',
      'weapon',
      'pistol',
      'revolver'
    ],
    sheet: [28, 13],
    shortName: 'gun'
  },
  {
    name: 'Boomerang',
    unified: '1FA83',
    keywords: ['boomerang', 'weapon'],
    sheet: [52, 28],
    shortName: 'boomerang'
  },
  {
    name: 'Bow and Arrow',
    unified: '1F3F9',
    keywords: [
      'bow_and_arrow',
      'sports'
    ],
    sheet: [11, 11],
    shortName: 'bow_and_arrow'
  },
  {
    name: 'Shield',
    unified: '1F6E1-FE0F',
    keywords: [
      'shield',
      'protection',
      'security'
    ],
    sheet: [36, 36],
    shortName: 'shield'
  },
  {
    name: 'Carpentry Saw',
    unified: '1FA9A',
    keywords: [
      'carpentry saw',
      'cut',
      'chop'
    ],
    sheet: [52, 42],
    shortName: 'carpentry_saw'
  },
  {
    name: 'Wrench',
    unified: '1F527',
    keywords: [
      'wrench',
      'tools',
      'diy',
      'ikea',
      'fix',
      'maintainer'
    ],
    sheet: [28, 9],
    shortName: 'wrench'
  },
  {
    name: 'Screwdriver',
    unified: '1FA9B',
    keywords: ['screwdriver', 'tools'],
    sheet: [52, 43],
    shortName: 'screwdriver'
  },
  {
    name: 'Nut and Bolt',
    unified: '1F529',
    keywords: [
      'nut_and_bolt',
      'handy',
      'tools',
      'fix'
    ],
    sheet: [28, 11],
    shortName: 'nut_and_bolt'
  },
  {
    name: 'Gear',
    unified: '2699-FE0F',
    keywords: ['gear', 'cog'],
    sheet: [54, 52],
    shortName: 'gear'
  },
  {
    name: 'Clamp',
    unified: '1F5DC-FE0F',
    keywords: ['clamp', 'tool'],
    sheet: [30, 18],
    shortName: 'compression'
  },
  {
    name: 'Balance Scale',
    unified: '2696-FE0F',
    keywords: [
      'balance_scale',
      'law',
      'fairness',
      'weight'
    ],
    sheet: [54, 50],
    shortName: 'scales'
  },
  {
    name: 'Probing Cane',
    unified: '1F9AF',
    keywords: [
      'probing_cane',
      'accessibility'
    ],
    sheet: [43, 22],
    shortName: 'probing_cane'
  },
  {
    name: 'Link Symbol',
    unified: '1F517',
    keywords: ['link', 'rings', 'url'],
    sheet: [27, 51],
    shortName: 'link'
  },
  {
    name: 'Chains',
    unified: '26D3-FE0F',
    keywords: [
      'chains',
      'lock',
      'arrest'
    ],
    sheet: [55, 12],
    shortName: 'chains'
  },
  {
    name: 'Hook',
    unified: '1FA9D',
    keywords: ['hook', 'tools'],
    sheet: [52, 45],
    shortName: 'hook'
  },
  {
    name: 'Toolbox',
    unified: '1F9F0',
    keywords: [
      'toolbox',
      'tools',
      'diy',
      'fix',
      'maintainer',
      'mechanic'
    ],
    sheet: [52, 1],
    shortName: 'toolbox'
  },
  {
    name: 'Magnet',
    unified: '1F9F2',
    keywords: [
      'magnet',
      'attraction',
      'magnetic'
    ],
    sheet: [52, 3],
    shortName: 'magnet'
  },
  {
    name: 'Ladder',
    unified: '1FA9C',
    keywords: ['ladder', 'tools'],
    sheet: [52, 44],
    shortName: 'ladder'
  },
  {
    name: 'Alembic',
    unified: '2697-FE0F',
    keywords: [
      'alembic',
      'distilling',
      'science',
      'experiment',
      'chemistry'
    ],
    sheet: [54, 51],
    shortName: 'alembic'
  },
  {
    name: 'Test Tube',
    unified: '1F9EA',
    keywords: [
      'test_tube',
      'chemistry',
      'experiment',
      'lab',
      'science'
    ],
    sheet: [51, 53],
    shortName: 'test_tube'
  },
  {
    name: 'Petri Dish',
    unified: '1F9EB',
    keywords: [
      'petri_dish',
      'bacteria',
      'biology',
      'culture',
      'lab'
    ],
    sheet: [51, 54],
    shortName: 'petri_dish'
  },
  {
    name: 'Dna Double Helix',
    unified: '1F9EC',
    keywords: [
      'dna',
      'biologist',
      'genetics',
      'life'
    ],
    sheet: [51, 55],
    shortName: 'dna'
  },
  {
    name: 'Microscope',
    unified: '1F52C',
    keywords: [
      'microscope',
      'laboratory',
      'experiment',
      'zoomin',
      'science',
      'study'
    ],
    sheet: [28, 14],
    shortName: 'microscope'
  },
  {
    name: 'Telescope',
    unified: '1F52D',
    keywords: [
      'telescope',
      'stars',
      'space',
      'zoom',
      'science',
      'astronomy'
    ],
    sheet: [28, 15],
    shortName: 'telescope'
  },
  {
    name: 'Satellite Antenna',
    unified: '1F4E1',
    keywords: [
      'satellite_antenna',
      'communication',
      'future',
      'radio',
      'space'
    ],
    sheet: [26, 56],
    shortName: 'satellite_antenna'
  },
  {
    name: 'Syringe',
    unified: '1F489',
    keywords: [
      'syringe',
      'health',
      'hospital',
      'drugs',
      'blood',
      'medicine',
      'needle',
      'doctor',
      'nurse'
    ],
    sheet: [25, 21],
    shortName: 'syringe'
  },
  {
    name: 'Drop of Blood',
    unified: '1FA78',
    keywords: [
      'drop_of_blood',
      'period',
      'hurt',
      'harm',
      'wound'
    ],
    sheet: [52, 22],
    shortName: 'drop_of_blood'
  },
  {
    name: 'Pill',
    unified: '1F48A',
    keywords: [
      'pill',
      'health',
      'medicine',
      'doctor',
      'pharmacy',
      'drug'
    ],
    sheet: [25, 22],
    shortName: 'pill'
  },
  {
    name: 'Adhesive Bandage',
    unified: '1FA79',
    keywords: [
      'adhesive_bandage',
      'heal'
    ],
    sheet: [52, 23],
    shortName: 'adhesive_bandage'
  },
  {
    name: 'Stethoscope',
    unified: '1FA7A',
    keywords: ['stethoscope', 'health'],
    sheet: [52, 24],
    shortName: 'stethoscope'
  },
  {
    name: 'Door',
    unified: '1F6AA',
    keywords: [
      'door',
      'house',
      'entry',
      'exit'
    ],
    sheet: [34, 51],
    shortName: 'door'
  },
  {
    name: 'Elevator',
    unified: '1F6D7',
    keywords: ['elevator', 'lift'],
    sheet: [36, 34],
    shortName: 'elevator'
  },
  {
    name: 'Mirror',
    unified: '1FA9E',
    keywords: ['mirror', 'reflection'],
    sheet: [52, 46],
    shortName: 'mirror'
  },
  {
    name: 'Window',
    unified: '1FA9F',
    keywords: ['window', 'scenery'],
    sheet: [52, 47],
    shortName: 'window'
  },
  {
    name: 'Bed',
    unified: '1F6CF-FE0F',
    keywords: ['bed', 'sleep', 'rest'],
    sheet: [36, 28],
    shortName: 'bed'
  },
  {
    name: 'Couch and Lamp',
    unified: '1F6CB-FE0F',
    keywords: [
      'couch_and_lamp',
      'read',
      'chill'
    ],
    sheet: [36, 19],
    shortName: 'couch_and_lamp'
  },
  {
    name: 'Chair',
    unified: '1FA91',
    keywords: [
      'chair',
      'sit',
      'furniture'
    ],
    sheet: [52, 33],
    shortName: 'chair'
  },
  {
    name: 'Toilet',
    unified: '1F6BD',
    keywords: [
      'toilet',
      'restroom',
      'wc',
      'washroom',
      'bathroom',
      'potty'
    ],
    sheet: [36, 5],
    shortName: 'toilet'
  },
  {
    name: 'Plunger',
    unified: '1FAA0',
    keywords: ['plunger', 'toilet'],
    sheet: [52, 48],
    shortName: 'plunger'
  },
  {
    name: 'Shower',
    unified: '1F6BF',
    keywords: [
      'shower',
      'clean',
      'water',
      'bathroom'
    ],
    sheet: [36, 7],
    shortName: 'shower'
  },
  {
    name: 'Bathtub',
    unified: '1F6C1',
    keywords: [
      'bathtub',
      'clean',
      'shower',
      'bathroom'
    ],
    sheet: [36, 14],
    shortName: 'bathtub'
  },
  {
    name: 'Mouse Trap',
    unified: '1FAA4',
    keywords: ['mouse trap', 'cheese'],
    sheet: [52, 52],
    shortName: 'mouse_trap'
  },
  {
    name: 'Razor',
    unified: '1FA92',
    keywords: ['razor', 'cut'],
    sheet: [52, 34],
    shortName: 'razor'
  },
  {
    name: 'Lotion Bottle',
    unified: '1F9F4',
    keywords: [
      'lotion_bottle',
      'moisturizer',
      'sunscreen'
    ],
    sheet: [52, 5],
    shortName: 'lotion_bottle'
  },
  {
    name: 'Safety Pin',
    unified: '1F9F7',
    keywords: ['safety_pin', 'diaper'],
    sheet: [52, 8],
    shortName: 'safety_pin'
  },
  {
    name: 'Broom',
    unified: '1F9F9',
    keywords: [
      'broom',
      'cleaning',
      'sweeping',
      'witch'
    ],
    sheet: [52, 10],
    shortName: 'broom'
  },
  {
    name: 'Basket',
    unified: '1F9FA',
    keywords: ['basket', 'laundry'],
    sheet: [52, 11],
    shortName: 'basket'
  },
  {
    name: 'Roll of Paper',
    unified: '1F9FB',
    keywords: ['roll_of_paper', 'roll'],
    sheet: [52, 12],
    shortName: 'roll_of_paper'
  },
  {
    name: 'Bucket',
    unified: '1FAA3',
    keywords: [
      'bucket',
      'water',
      'container'
    ],
    sheet: [52, 51],
    shortName: 'bucket'
  },
  {
    name: 'Bar of Soap',
    unified: '1F9FC',
    keywords: [
      'soap',
      'bar',
      'bathing',
      'cleaning',
      'lather'
    ],
    sheet: [52, 13],
    shortName: 'soap'
  },
  {
    name: 'Toothbrush',
    unified: '1FAA5',
    keywords: [
      'toothbrush',
      'hygiene',
      'dental'
    ],
    sheet: [52, 53],
    shortName: 'toothbrush'
  },
  {
    name: 'Sponge',
    unified: '1F9FD',
    keywords: [
      'sponge',
      'absorbing',
      'cleaning',
      'porous'
    ],
    sheet: [52, 14],
    shortName: 'sponge'
  },
  {
    name: 'Fire Extinguisher',
    unified: '1F9EF',
    keywords: [
      'fire_extinguisher',
      'quench'
    ],
    sheet: [52, 0],
    shortName: 'fire_extinguisher'
  },
  {
    name: 'Shopping Trolley',
    unified: '1F6D2',
    keywords: [
      'shopping_cart',
      'trolley'
    ],
    sheet: [36, 31],
    shortName: 'shopping_trolley'
  },
  {
    name: 'Smoking Symbol',
    unified: '1F6AC',
    keywords: [
      'cigarette',
      'kills',
      'tobacco',
      'cigarette',
      'joint',
      'smoke'
    ],
    sheet: [34, 53],
    shortName: 'smoking'
  },
  {
    name: 'Coffin',
    unified: '26B0-FE0F',
    keywords: [
      'coffin',
      'vampire',
      'dead',
      'die',
      'death',
      'rip',
      'graveyard',
      'cemetery',
      'casket',
      'funeral',
      'box'
    ],
    sheet: [55, 2],
    shortName: 'coffin'
  },
  {
    name: 'Headstone',
    unified: '1FAA6',
    keywords: [
      'headstone',
      'death',
      'rip',
      'grave'
    ],
    sheet: [52, 54],
    shortName: 'headstone'
  },
  {
    name: 'Funeral Urn',
    unified: '26B1-FE0F',
    keywords: [
      'funeral_urn',
      'dead',
      'die',
      'death',
      'rip',
      'ashes'
    ],
    sheet: [55, 3],
    shortName: 'funeral_urn'
  },
  {
    name: 'Moyai',
    unified: '1F5FF',
    keywords: [
      'moai',
      'rock',
      'easter island',
      'moai'
    ],
    sheet: [30, 31],
    shortName: 'moyai'
  },
  {
    name: 'Placard',
    unified: '1FAA7',
    keywords: [
      'placard',
      'announcement'
    ],
    sheet: [52, 55],
    shortName: 'placard'
  },
  {
    name: 'Automated Teller Machine',
    unified: '1F3E7',
    keywords: [
      'atm_sign',
      'money',
      'sales',
      'cash',
      'blue-square',
      'payment',
      'bank'
    ],
    sheet: [10, 48],
    shortName: 'atm'
  },
  {
    name: 'Put Litter in Its Place Symbol',
    unified: '1F6AE',
    keywords: [
      'litter_in_bin_sign',
      'blue-square',
      'sign',
      'human',
      'info'
    ],
    sheet: [34, 55],
    shortName: 'put_litter_in_its_place'
  },
  {
    name: 'Potable Water Symbol',
    unified: '1F6B0',
    keywords: [
      'potable_water',
      'blue-square',
      'liquid',
      'restroom',
      'cleaning',
      'faucet'
    ],
    sheet: [34, 57],
    shortName: 'potable_water'
  },
  {
    name: 'Wheelchair Symbol',
    unified: '267F',
    keywords: [
      'wheelchair_symbol',
      'blue-square',
      'disabled',
      'accessibility'
    ],
    sheet: [54, 45],
    shortName: 'wheelchair'
  },
  {
    name: 'Mens Symbol',
    unified: '1F6B9',
    keywords: [
      'men_s_room',
      'toilet',
      'restroom',
      'wc',
      'blue-square',
      'gender',
      'male'
    ],
    sheet: [36, 1],
    shortName: 'mens'
  },
  {
    name: 'Womens Symbol',
    unified: '1F6BA',
    keywords: [
      'women_s_room',
      'purple-square',
      'woman',
      'female',
      'toilet',
      'loo',
      'restroom',
      'gender'
    ],
    sheet: [36, 2],
    shortName: 'womens'
  },
  {
    name: 'Restroom',
    unified: '1F6BB',
    keywords: [
      'restroom',
      'blue-square',
      'toilet',
      'refresh',
      'wc',
      'gender'
    ],
    sheet: [36, 3],
    shortName: 'restroom'
  },
  {
    name: 'Baby Symbol',
    unified: '1F6BC',
    keywords: [
      'baby_symbol',
      'orange-square',
      'child'
    ],
    sheet: [36, 4],
    shortName: 'baby_symbol'
  },
  {
    name: 'Water Closet',
    unified: '1F6BE',
    keywords: [
      'water_closet',
      'toilet',
      'restroom',
      'blue-square'
    ],
    sheet: [36, 6],
    shortName: 'wc'
  },
  {
    name: 'Passport Control',
    unified: '1F6C2',
    keywords: [
      'passport_control',
      'custom',
      'blue-square'
    ],
    sheet: [36, 15],
    shortName: 'passport_control'
  },
  {
    name: 'Customs',
    unified: '1F6C3',
    keywords: [
      'customs',
      'passport',
      'border',
      'blue-square'
    ],
    sheet: [36, 16],
    shortName: 'customs'
  },
  {
    name: 'Baggage Claim',
    unified: '1F6C4',
    keywords: [
      'baggage_claim',
      'blue-square',
      'airport',
      'transport'
    ],
    sheet: [36, 17],
    shortName: 'baggage_claim'
  },
  {
    name: 'Left Luggage',
    unified: '1F6C5',
    keywords: [
      'left_luggage',
      'blue-square',
      'travel'
    ],
    sheet: [36, 18],
    shortName: 'left_luggage'
  },
  {
    name: 'Warning Sign',
    unified: '26A0-FE0F',
    keywords: [
      'warning',
      'exclamation',
      'wip',
      'alert',
      'error',
      'problem',
      'issue'
    ],
    sheet: [54, 55],
    shortName: 'warning'
  },
  {
    name: 'Children Crossing',
    unified: '1F6B8',
    keywords: [
      'children_crossing',
      'school',
      'warning',
      'danger',
      'sign',
      'driving',
      'yellow-diamond'
    ],
    sheet: [36, 0],
    shortName: 'children_crossing'
  },
  {
    name: 'No Entry',
    unified: '26D4',
    keywords: [
      'no_entry',
      'limit',
      'security',
      'privacy',
      'bad',
      'denied',
      'stop',
      'circle'
    ],
    sheet: [55, 13],
    shortName: 'no_entry'
  },
  {
    name: 'No Entry Sign',
    unified: '1F6AB',
    keywords: [
      'prohibited',
      'forbid',
      'stop',
      'limit',
      'denied',
      'disallow',
      'circle'
    ],
    sheet: [34, 52],
    shortName: 'no_entry_sign'
  },
  {
    name: 'No Bicycles',
    unified: '1F6B3',
    keywords: [
      'no_bicycles',
      'cyclist',
      'prohibited',
      'circle'
    ],
    sheet: [35, 2],
    shortName: 'no_bicycles'
  },
  {
    name: 'No Smoking Symbol',
    unified: '1F6AD',
    keywords: [
      'no_smoking',
      'cigarette',
      'blue-square',
      'smell',
      'smoke'
    ],
    sheet: [34, 54],
    shortName: 'no_smoking'
  },
  {
    name: 'Do Not Litter Symbol',
    unified: '1F6AF',
    keywords: [
      'no_littering',
      'trash',
      'bin',
      'garbage',
      'circle'
    ],
    sheet: [34, 56],
    shortName: 'do_not_litter'
  },
  {
    name: 'Non-Potable Water Symbol',
    unified: '1F6B1',
    keywords: [
      'non_potable_water',
      'drink',
      'faucet',
      'tap',
      'circle'
    ],
    sheet: [35, 0],
    shortName: 'non-potable_water'
  },
  {
    name: 'No Pedestrians',
    unified: '1F6B7',
    keywords: [
      'no_pedestrians',
      'rules',
      'crossing',
      'walking',
      'circle'
    ],
    sheet: [35, 57],
    shortName: 'no_pedestrians'
  },
  {
    name: 'No Mobile Phones',
    unified: '1F4F5',
    keywords: [
      'no_mobile_phones',
      'iphone',
      'mute',
      'circle'
    ],
    sheet: [27, 18],
    shortName: 'no_mobile_phones'
  },
  {
    name: 'No One Under Eighteen Symbol',
    unified: '1F51E',
    keywords: [
      'no_one_under_eighteen',
      '18',
      'drink',
      'pub',
      'night',
      'minor',
      'circle'
    ],
    sheet: [28, 0],
    shortName: 'underage'
  },
  {
    name: 'Radioactive',
    unified: '2622-FE0F',
    keywords: [
      'radioactive',
      'nuclear',
      'danger'
    ],
    sheet: [54, 14],
    shortName: 'radioactive_sign'
  },
  {
    name: 'Biohazard',
    unified: '2623-FE0F',
    keywords: ['biohazard', 'danger'],
    sheet: [54, 15],
    shortName: 'biohazard_sign'
  },
  {
    name: 'Upwards Black Arrow',
    unified: '2B06-FE0F',
    keywords: [
      'up_arrow',
      'blue-square',
      'continue',
      'top',
      'direction'
    ],
    sheet: [56, 42],
    shortName: 'arrow_up'
  },
  {
    name: 'North East Arrow',
    unified: '2197-FE0F',
    keywords: [
      'up_right_arrow',
      'blue-square',
      'point',
      'direction',
      'diagonal',
      'northeast'
    ],
    sheet: [53, 23],
    shortName: 'arrow_upper_right'
  },
  {
    name: 'Black Rightwards Arrow',
    unified: '27A1-FE0F',
    keywords: [
      'right_arrow',
      'blue-square',
      'next'
    ],
    sheet: [56, 36],
    shortName: 'arrow_right'
  },
  {
    name: 'South East Arrow',
    unified: '2198-FE0F',
    keywords: [
      'down_right_arrow',
      'blue-square',
      'direction',
      'diagonal',
      'southeast'
    ],
    sheet: [53, 24],
    shortName: 'arrow_lower_right'
  },
  {
    name: 'Downwards Black Arrow',
    unified: '2B07-FE0F',
    keywords: [
      'down_arrow',
      'blue-square',
      'direction',
      'bottom'
    ],
    sheet: [56, 43],
    shortName: 'arrow_down'
  },
  {
    name: 'South West Arrow',
    unified: '2199-FE0F',
    keywords: [
      'down_left_arrow',
      'blue-square',
      'direction',
      'diagonal',
      'southwest'
    ],
    sheet: [53, 25],
    shortName: 'arrow_lower_left'
  },
  {
    name: 'Leftwards Black Arrow',
    unified: '2B05-FE0F',
    keywords: [
      'left_arrow',
      'blue-square',
      'previous',
      'back'
    ],
    sheet: [56, 41],
    shortName: 'arrow_left'
  },
  {
    name: 'North West Arrow',
    unified: '2196-FE0F',
    keywords: [
      'up_left_arrow',
      'blue-square',
      'point',
      'direction',
      'diagonal',
      'northwest'
    ],
    sheet: [53, 22],
    shortName: 'arrow_upper_left'
  },
  {
    name: 'Up Down Arrow',
    unified: '2195-FE0F',
    keywords: [
      'up_down_arrow',
      'blue-square',
      'direction',
      'way',
      'vertical'
    ],
    sheet: [53, 21],
    shortName: 'arrow_up_down'
  },
  {
    name: 'Left Right Arrow',
    unified: '2194-FE0F',
    keywords: [
      'left_right_arrow',
      'shape',
      'direction',
      'horizontal',
      'sideways'
    ],
    sheet: [53, 20],
    shortName: 'left_right_arrow'
  },
  {
    name: 'Leftwards Arrow with Hook',
    unified: '21A9-FE0F',
    keywords: [
      'right_arrow_curving_left',
      'back',
      'return',
      'blue-square',
      'undo',
      'enter'
    ],
    sheet: [53, 26],
    shortName: 'leftwards_arrow_with_hook'
  },
  {
    name: 'Rightwards Arrow with Hook',
    unified: '21AA-FE0F',
    keywords: [
      'left_arrow_curving_right',
      'blue-square',
      'return',
      'rotate',
      'direction'
    ],
    sheet: [53, 27],
    shortName: 'arrow_right_hook'
  },
  {
    name: 'Arrow Pointing Rightwards Then Curving Upwards',
    unified: '2934-FE0F',
    keywords: [
      'right_arrow_curving_up',
      'blue-square',
      'direction',
      'top'
    ],
    sheet: [56, 39],
    shortName: 'arrow_heading_up'
  },
  {
    name: 'Arrow Pointing Rightwards Then Curving Downwards',
    unified: '2935-FE0F',
    keywords: [
      'right_arrow_curving_down',
      'blue-square',
      'direction',
      'bottom'
    ],
    sheet: [56, 40],
    shortName: 'arrow_heading_down'
  },
  {
    name: 'Clockwise Downwards and Upwards Open Circle Arrows',
    unified: '1F503',
    keywords: [
      'clockwise_vertical_arrows',
      'sync',
      'cycle',
      'round',
      'repeat'
    ],
    sheet: [27, 31],
    shortName: 'arrows_clockwise'
  },
  {
    name: 'Anticlockwise Downwards and Upwards Open Circle Arrows',
    unified: '1F504',
    keywords: [
      'counterclockwise_arrows_button',
      'blue-square',
      'sync',
      'cycle'
    ],
    sheet: [27, 32],
    shortName: 'arrows_counterclockwise'
  },
  {
    name: 'Back with Leftwards Arrow Above',
    unified: '1F519',
    keywords: [
      'back_arrow',
      'arrow',
      'words',
      'return'
    ],
    sheet: [27, 53],
    shortName: 'back'
  },
  {
    name: 'End with Leftwards Arrow Above',
    unified: '1F51A',
    keywords: [
      'end_arrow',
      'words',
      'arrow'
    ],
    sheet: [27, 54],
    shortName: 'end'
  },
  {
    name: 'On with Exclamation Mark with Left Right Arrow Above',
    unified: '1F51B',
    keywords: [
      'on_arrow',
      'arrow',
      'words'
    ],
    sheet: [27, 55],
    shortName: 'on'
  },
  {
    name: 'Soon with Rightwards Arrow Above',
    unified: '1F51C',
    keywords: [
      'soon_arrow',
      'arrow',
      'words'
    ],
    sheet: [27, 56],
    shortName: 'soon'
  },
  {
    name: 'Top with Upwards Arrow Above',
    unified: '1F51D',
    keywords: [
      'top_arrow',
      'words',
      'blue-square'
    ],
    sheet: [27, 57],
    shortName: 'top'
  },
  {
    name: 'Place of Worship',
    unified: '1F6D0',
    keywords: [
      'place_of_worship',
      'religion',
      'church',
      'temple',
      'prayer'
    ],
    sheet: [36, 29],
    shortName: 'place_of_worship'
  },
  {
    name: 'Atom Symbol',
    unified: '269B-FE0F',
    keywords: [
      'atom_symbol',
      'science',
      'physics',
      'chemistry'
    ],
    sheet: [54, 53],
    shortName: 'atom_symbol'
  },
  {
    name: 'Om',
    unified: '1F549-FE0F',
    keywords: [
      'om',
      'hinduism',
      'buddhism',
      'sikhism',
      'jainism'
    ],
    sheet: [28, 32],
    shortName: 'om_symbol'
  },
  {
    name: 'Star of David',
    unified: '2721-FE0F',
    keywords: [
      'star_of_david',
      'judaism'
    ],
    sheet: [56, 19],
    shortName: 'star_of_david'
  },
  {
    name: 'Wheel of Dharma',
    unified: '2638-FE0F',
    keywords: [
      'wheel_of_dharma',
      'hinduism',
      'buddhism',
      'sikhism',
      'jainism'
    ],
    sheet: [54, 20],
    shortName: 'wheel_of_dharma'
  },
  {
    name: 'Yin Yang',
    unified: '262F-FE0F',
    keywords: ['yin_yang', 'balance'],
    sheet: [54, 19],
    shortName: 'yin_yang'
  },
  {
    name: 'Latin Cross',
    unified: '271D-FE0F',
    keywords: [
      'latin_cross',
      'christianity'
    ],
    sheet: [56, 18],
    shortName: 'latin_cross'
  },
  {
    name: 'Orthodox Cross',
    unified: '2626-FE0F',
    keywords: [
      'orthodox_cross',
      'suppedaneum',
      'religion'
    ],
    sheet: [54, 16],
    shortName: 'orthodox_cross'
  },
  {
    name: 'Star and Crescent',
    unified: '262A-FE0F',
    keywords: [
      'star_and_crescent',
      'islam'
    ],
    sheet: [54, 17],
    shortName: 'star_and_crescent'
  },
  {
    name: 'Peace Symbol',
    unified: '262E-FE0F',
    keywords: [
      'peace_symbol',
      'hippie'
    ],
    sheet: [54, 18],
    shortName: 'peace_symbol'
  },
  {
    name: 'Menorah with Nine Branches',
    unified: '1F54E',
    keywords: [
      'menorah',
      'hanukkah',
      'candles',
      'jewish'
    ],
    sheet: [28, 37],
    shortName: 'menorah_with_nine_branches'
  },
  {
    name: 'Six Pointed Star with Middle Dot',
    unified: '1F52F',
    keywords: [
      'dotted_six_pointed_star',
      'purple-square',
      'religion',
      'jewish',
      'hexagram'
    ],
    sheet: [28, 17],
    shortName: 'six_pointed_star'
  },
  {
    name: 'Aries',
    unified: '2648',
    keywords: [
      'aries',
      'sign',
      'purple-square',
      'zodiac',
      'astrology'
    ],
    sheet: [54, 25],
    shortName: 'aries'
  },
  {
    name: 'Taurus',
    unified: '2649',
    keywords: [
      'taurus',
      'purple-square',
      'sign',
      'zodiac',
      'astrology'
    ],
    sheet: [54, 26],
    shortName: 'taurus'
  },
  {
    name: 'Gemini',
    unified: '264A',
    keywords: [
      'gemini',
      'sign',
      'zodiac',
      'purple-square',
      'astrology'
    ],
    sheet: [54, 27],
    shortName: 'gemini'
  },
  {
    name: 'Cancer',
    unified: '264B',
    keywords: [
      'cancer',
      'sign',
      'zodiac',
      'purple-square',
      'astrology'
    ],
    sheet: [54, 28],
    shortName: 'cancer'
  },
  {
    name: 'Leo',
    unified: '264C',
    keywords: [
      'leo',
      'sign',
      'purple-square',
      'zodiac',
      'astrology'
    ],
    sheet: [54, 29],
    shortName: 'leo'
  },
  {
    name: 'Virgo',
    unified: '264D',
    keywords: [
      'virgo',
      'sign',
      'zodiac',
      'purple-square',
      'astrology'
    ],
    sheet: [54, 30],
    shortName: 'virgo'
  },
  {
    name: 'Libra',
    unified: '264E',
    keywords: [
      'libra',
      'sign',
      'purple-square',
      'zodiac',
      'astrology'
    ],
    sheet: [54, 31],
    shortName: 'libra'
  },
  {
    name: 'Scorpius',
    unified: '264F',
    keywords: [
      'scorpio',
      'sign',
      'zodiac',
      'purple-square',
      'astrology',
      'scorpio'
    ],
    sheet: [54, 32],
    shortName: 'scorpius'
  },
  {
    name: 'Sagittarius',
    unified: '2650',
    keywords: [
      'sagittarius',
      'sign',
      'zodiac',
      'purple-square',
      'astrology'
    ],
    sheet: [54, 33],
    shortName: 'sagittarius'
  },
  {
    name: 'Capricorn',
    unified: '2651',
    keywords: [
      'capricorn',
      'sign',
      'zodiac',
      'purple-square',
      'astrology'
    ],
    sheet: [54, 34],
    shortName: 'capricorn'
  },
  {
    name: 'Aquarius',
    unified: '2652',
    keywords: [
      'aquarius',
      'sign',
      'purple-square',
      'zodiac',
      'astrology'
    ],
    sheet: [54, 35],
    shortName: 'aquarius'
  },
  {
    name: 'Pisces',
    unified: '2653',
    keywords: [
      'pisces',
      'purple-square',
      'sign',
      'zodiac',
      'astrology'
    ],
    sheet: [54, 36],
    shortName: 'pisces'
  },
  {
    name: 'Ophiuchus',
    unified: '26CE',
    keywords: [
      'ophiuchus',
      'sign',
      'purple-square',
      'constellation',
      'astrology'
    ],
    sheet: [55, 9],
    shortName: 'ophiuchus'
  },
  {
    name: 'Twisted Rightwards Arrows',
    unified: '1F500',
    keywords: [
      'shuffle_tracks_button',
      'blue-square',
      'shuffle',
      'music',
      'random'
    ],
    sheet: [27, 28],
    shortName: 'twisted_rightwards_arrows'
  },
  {
    name: 'Clockwise Rightwards and Leftwards Open Circle Arrows',
    unified: '1F501',
    keywords: [
      'repeat_button',
      'loop',
      'record'
    ],
    sheet: [27, 29],
    shortName: 'repeat'
  },
  {
    name: 'Clockwise Rightwards and Leftwards Open Circle Arrows with Circled One Overlay',
    unified: '1F502',
    keywords: [
      'repeat_single_button',
      'blue-square',
      'loop'
    ],
    sheet: [27, 30],
    shortName: 'repeat_one'
  },
  {
    name: 'Black Right-Pointing Triangle',
    unified: '25B6-FE0F',
    keywords: [
      'play_button',
      'blue-square',
      'right',
      'direction',
      'play'
    ],
    sheet: [53, 49],
    shortName: 'arrow_forward'
  },
  {
    name: 'Black Right-Pointing Double Triangle',
    unified: '23E9',
    keywords: [
      'fast_forward_button',
      'blue-square',
      'play',
      'speed',
      'continue'
    ],
    sheet: [53, 32],
    shortName: 'fast_forward'
  },
  {
    name: 'Next Track Button',
    unified: '23ED-FE0F',
    keywords: [
      'next_track_button',
      'forward',
      'next',
      'blue-square'
    ],
    sheet: [53, 36],
    shortName: 'black_right_pointing_double_triangle_with_vertical_bar'
  },
  {
    name: 'Play or Pause Button',
    unified: '23EF-FE0F',
    keywords: [
      'play_or_pause_button',
      'blue-square',
      'play',
      'pause'
    ],
    sheet: [53, 38],
    shortName: 'black_right_pointing_triangle_with_double_vertical_bar'
  },
  {
    name: 'Black Left-Pointing Triangle',
    unified: '25C0-FE0F',
    keywords: [
      'reverse_button',
      'blue-square',
      'left',
      'direction'
    ],
    sheet: [53, 50],
    shortName: 'arrow_backward'
  },
  {
    name: 'Black Left-Pointing Double Triangle',
    unified: '23EA',
    keywords: [
      'fast_reverse_button',
      'play',
      'blue-square'
    ],
    sheet: [53, 33],
    shortName: 'rewind'
  },
  {
    name: 'Last Track Button',
    unified: '23EE-FE0F',
    keywords: [
      'last_track_button',
      'backward'
    ],
    sheet: [53, 37],
    shortName: 'black_left_pointing_double_triangle_with_vertical_bar'
  },
  {
    name: 'Up-Pointing Small Red Triangle',
    unified: '1F53C',
    keywords: [
      'upwards_button',
      'blue-square',
      'triangle',
      'direction',
      'point',
      'forward',
      'top'
    ],
    sheet: [28, 30],
    shortName: 'arrow_up_small'
  },
  {
    name: 'Black Up-Pointing Double Triangle',
    unified: '23EB',
    keywords: [
      'fast_up_button',
      'blue-square',
      'direction',
      'top'
    ],
    sheet: [53, 34],
    shortName: 'arrow_double_up'
  },
  {
    name: 'Down-Pointing Small Red Triangle',
    unified: '1F53D',
    keywords: [
      'downwards_button',
      'blue-square',
      'direction',
      'bottom'
    ],
    sheet: [28, 31],
    shortName: 'arrow_down_small'
  },
  {
    name: 'Black Down-Pointing Double Triangle',
    unified: '23EC',
    keywords: [
      'fast_down_button',
      'blue-square',
      'direction',
      'bottom'
    ],
    sheet: [53, 35],
    shortName: 'arrow_double_down'
  },
  {
    name: 'Pause Button',
    unified: '23F8-FE0F',
    keywords: [
      'pause_button',
      'pause',
      'blue-square'
    ],
    sheet: [53, 43],
    shortName: 'double_vertical_bar'
  },
  {
    name: 'Stop Button',
    unified: '23F9-FE0F',
    keywords: [
      'stop_button',
      'blue-square'
    ],
    sheet: [53, 44],
    shortName: 'black_square_for_stop'
  },
  {
    name: 'Record Button',
    unified: '23FA-FE0F',
    keywords: [
      'record_button',
      'blue-square'
    ],
    sheet: [53, 45],
    shortName: 'black_circle_for_record'
  },
  {
    name: 'Eject Button',
    unified: '23CF-FE0F',
    keywords: [
      'eject_button',
      'blue-square'
    ],
    sheet: [53, 31],
    shortName: 'eject'
  },
  {
    name: 'Cinema',
    unified: '1F3A6',
    keywords: [
      'cinema',
      'blue-square',
      'record',
      'film',
      'movie',
      'curtain',
      'stage',
      'theater'
    ],
    sheet: [8, 4],
    shortName: 'cinema'
  },
  {
    name: 'Low Brightness Symbol',
    unified: '1F505',
    keywords: [
      'dim_button',
      'sun',
      'afternoon',
      'warm',
      'summer'
    ],
    sheet: [27, 33],
    shortName: 'low_brightness'
  },
  {
    name: 'High Brightness Symbol',
    unified: '1F506',
    keywords: [
      'bright_button',
      'sun',
      'light'
    ],
    sheet: [27, 34],
    shortName: 'high_brightness'
  },
  {
    name: 'Antenna with Bars',
    unified: '1F4F6',
    keywords: [
      'antenna_bars',
      'blue-square',
      'reception',
      'phone',
      'internet',
      'connection',
      'wifi',
      'bluetooth',
      'bars'
    ],
    sheet: [27, 19],
    shortName: 'signal_strength'
  },
  {
    name: 'Vibration Mode',
    unified: '1F4F3',
    keywords: [
      'vibration_mode',
      'orange-square',
      'phone'
    ],
    sheet: [27, 16],
    shortName: 'vibration_mode'
  },
  {
    name: 'Mobile Phone off',
    unified: '1F4F4',
    keywords: [
      'mobile_phone_off',
      'mute',
      'orange-square',
      'silence',
      'quiet'
    ],
    sheet: [27, 17],
    shortName: 'mobile_phone_off'
  },
  {
    name: 'Female Sign',
    unified: '2640-FE0F',
    keywords: [
      'female_sign',
      'woman',
      'women',
      'lady',
      'girl'
    ],
    sheet: [54, 23],
    hidden: ['apple'],
    shortName: 'female_sign'
  },
  {
    name: 'Male Sign',
    unified: '2642-FE0F',
    keywords: [
      'male_sign',
      'man',
      'boy',
      'men'
    ],
    sheet: [54, 24],
    hidden: ['apple'],
    shortName: 'male_sign'
  },
  {
    name: 'Transgender Symbol',
    unified: '26A7-FE0F',
    keywords: [
      'transgender symbol',
      'lgbtq'
    ],
    sheet: [54, 57],
    shortName: 'transgender_symbol'
  },
  {
    name: 'Heavy Multiplication X',
    unified: '2716-FE0F',
    keywords: [
      'multiplication_sign',
      'math',
      'calculation'
    ],
    sheet: [56, 17],
    shortName: 'heavy_multiplication_x'
  },
  {
    name: 'Heavy Plus Sign',
    unified: '2795',
    keywords: [
      'plus_sign',
      'math',
      'calculation',
      'addition',
      'more',
      'increase'
    ],
    sheet: [56, 33],
    shortName: 'heavy_plus_sign'
  },
  {
    name: 'Heavy Minus Sign',
    unified: '2796',
    keywords: [
      'minus_sign',
      'math',
      'calculation',
      'subtract',
      'less'
    ],
    sheet: [56, 34],
    shortName: 'heavy_minus_sign'
  },
  {
    name: 'Heavy Division Sign',
    unified: '2797',
    keywords: [
      'division_sign',
      'divide',
      'math',
      'calculation'
    ],
    sheet: [56, 35],
    shortName: 'heavy_division_sign'
  },
  {
    name: 'Infinity',
    unified: '267E-FE0F',
    keywords: ['infinity', 'forever'],
    sheet: [54, 44],
    shortName: 'infinity'
  },
  {
    name: 'Double Exclamation Mark',
    unified: '203C-FE0F',
    keywords: [
      'double_exclamation_mark',
      'exclamation',
      'surprise'
    ],
    sheet: [53, 16],
    shortName: 'bangbang'
  },
  {
    name: 'Exclamation Question Mark',
    unified: '2049-FE0F',
    keywords: [
      'exclamation_question_mark',
      'wat',
      'punctuation',
      'surprise'
    ],
    sheet: [53, 17],
    shortName: 'interrobang'
  },
  {
    name: 'Black Question Mark Ornament',
    unified: '2753',
    keywords: [
      'question_mark',
      'doubt',
      'confused'
    ],
    sheet: [56, 27],
    shortName: 'question'
  },
  {
    name: 'White Question Mark Ornament',
    unified: '2754',
    keywords: [
      'white_question_mark',
      'doubts',
      'gray',
      'huh',
      'confused'
    ],
    sheet: [56, 28],
    shortName: 'grey_question'
  },
  {
    name: 'White Exclamation Mark Ornament',
    unified: '2755',
    keywords: [
      'white_exclamation_mark',
      'surprise',
      'punctuation',
      'gray',
      'wow',
      'warning'
    ],
    sheet: [56, 29],
    shortName: 'grey_exclamation'
  },
  {
    name: 'Heavy Exclamation Mark Symbol',
    unified: '2757',
    keywords: [
      'exclamation_mark',
      'heavy_exclamation_mark',
      'danger',
      'surprise',
      'punctuation',
      'wow',
      'warning'
    ],
    sheet: [56, 30],
    shortNames: [
      'heavy_exclamation_mark'
    ],
    shortName: 'exclamation'
  },
  {
    name: 'Wavy Dash',
    unified: '3030-FE0F',
    keywords: [
      'wavy_dash',
      'draw',
      'line',
      'moustache',
      'mustache',
      'squiggle',
      'scribble'
    ],
    sheet: [56, 48],
    shortName: 'wavy_dash'
  },
  {
    name: 'Currency Exchange',
    unified: '1F4B1',
    keywords: [
      'currency_exchange',
      'money',
      'sales',
      'dollar',
      'travel'
    ],
    sheet: [26, 8],
    shortName: 'currency_exchange'
  },
  {
    name: 'Heavy Dollar Sign',
    unified: '1F4B2',
    keywords: [
      'heavy_dollar_sign',
      'money',
      'sales',
      'payment',
      'currency',
      'buck'
    ],
    sheet: [26, 9],
    shortName: 'heavy_dollar_sign'
  },
  {
    name: 'Medical Symbol',
    unified: '2695-FE0F',
    keywords: [
      'medical_symbol',
      'health',
      'hospital'
    ],
    sheet: [54, 49],
    hidden: ['apple'],
    shortNames: ['staff_of_aesculapius'],
    shortName: 'medical_symbol'
  },
  {
    name: 'Black Universal Recycling Symbol',
    unified: '267B-FE0F',
    keywords: [
      'recycling_symbol',
      'arrow',
      'environment',
      'garbage',
      'trash'
    ],
    sheet: [54, 43],
    shortName: 'recycle'
  },
  {
    name: 'Fleur-De-Lis',
    unified: '269C-FE0F',
    keywords: [
      'fleur_de_lis',
      'decorative',
      'scout'
    ],
    sheet: [54, 54],
    shortName: 'fleur_de_lis'
  },
  {
    name: 'Trident Emblem',
    unified: '1F531',
    keywords: [
      'trident_emblem',
      'weapon',
      'spear'
    ],
    sheet: [28, 19],
    shortName: 'trident'
  },
  {
    name: 'Name Badge',
    unified: '1F4DB',
    keywords: [
      'name_badge',
      'fire',
      'forbid'
    ],
    sheet: [26, 50],
    shortName: 'name_badge'
  },
  {
    name: 'Japanese Symbol for Beginner',
    unified: '1F530',
    keywords: [
      'japanese_symbol_for_beginner',
      'badge',
      'shield'
    ],
    sheet: [28, 18],
    shortName: 'beginner'
  },
  {
    name: 'Heavy Large Circle',
    unified: '2B55',
    keywords: [
      'hollow_red_circle',
      'circle',
      'round'
    ],
    sheet: [56, 47],
    shortName: 'o'
  },
  {
    name: 'White Heavy Check Mark',
    unified: '2705',
    keywords: [
      'check_mark_button',
      'green-square',
      'ok',
      'agree',
      'vote',
      'election',
      'answer',
      'tick'
    ],
    sheet: [55, 45],
    shortName: 'white_check_mark'
  },
  {
    name: 'Ballot Box with Check',
    unified: '2611-FE0F',
    keywords: [
      'check_box_with_check',
      'ok',
      'agree',
      'confirm',
      'black-square',
      'vote',
      'election',
      'yes',
      'tick'
    ],
    sheet: [54, 3],
    shortName: 'ballot_box_with_check'
  },
  {
    name: 'Heavy Check Mark',
    unified: '2714-FE0F',
    keywords: [
      'check_mark',
      'ok',
      'nike',
      'answer',
      'yes',
      'tick'
    ],
    sheet: [56, 16],
    shortName: 'heavy_check_mark'
  },
  {
    name: 'Cross Mark',
    unified: '274C',
    keywords: [
      'cross_mark',
      'no',
      'delete',
      'remove',
      'cancel',
      'red'
    ],
    sheet: [56, 25],
    shortName: 'x'
  },
  {
    name: 'Negative Squared Cross Mark',
    unified: '274E',
    keywords: [
      'cross_mark_button',
      'x',
      'green-square',
      'no',
      'deny'
    ],
    sheet: [56, 26],
    shortName: 'negative_squared_cross_mark'
  },
  {
    name: 'Curly Loop',
    unified: '27B0',
    keywords: [
      'curly_loop',
      'scribble',
      'draw',
      'shape',
      'squiggle'
    ],
    sheet: [56, 37],
    shortName: 'curly_loop'
  },
  {
    name: 'Double Curly Loop',
    unified: '27BF',
    keywords: [
      'double_curly_loop',
      'tape',
      'cassette'
    ],
    sheet: [56, 38],
    shortName: 'loop'
  },
  {
    name: 'Part Alternation Mark',
    unified: '303D-FE0F',
    keywords: [
      'part_alternation_mark',
      'graph',
      'presentation',
      'stats',
      'business',
      'economics',
      'bad'
    ],
    sheet: [56, 49],
    shortName: 'part_alternation_mark'
  },
  {
    name: 'Eight Spoked Asterisk',
    unified: '2733-FE0F',
    keywords: [
      'eight_spoked_asterisk',
      'star',
      'sparkle',
      'green-square'
    ],
    sheet: [56, 21],
    shortName: 'eight_spoked_asterisk'
  },
  {
    name: 'Eight Pointed Black Star',
    unified: '2734-FE0F',
    keywords: [
      'eight_pointed_star',
      'orange-square',
      'shape',
      'polygon'
    ],
    sheet: [56, 22],
    shortName: 'eight_pointed_black_star'
  },
  {
    name: 'Sparkle',
    unified: '2747-FE0F',
    keywords: [
      'sparkle',
      'stars',
      'green-square',
      'awesome',
      'good',
      'fireworks'
    ],
    sheet: [56, 24],
    shortName: 'sparkle'
  },
  {
    name: 'Copyright Sign',
    unified: '00A9-FE0F',
    keywords: [
      'copyright',
      'ip',
      'license',
      'circle',
      'law',
      'legal'
    ],
    sheet: [0, 12],
    hidden: ['facebook'],
    shortName: 'copyright'
  },
  {
    name: 'Registered Sign',
    unified: '00AE-FE0F',
    keywords: [
      'registered',
      'alphabet',
      'circle'
    ],
    sheet: [0, 13],
    hidden: ['facebook'],
    shortName: 'registered'
  },
  {
    name: 'Trade Mark Sign',
    unified: '2122-FE0F',
    keywords: [
      'trade_mark',
      'trademark',
      'brand',
      'law',
      'legal'
    ],
    sheet: [53, 18],
    shortName: 'tm'
  },
  {
    name: 'Hash Key',
    unified: '0023-FE0F-20E3',
    keywords: [
      'keycap_',
      'symbol',
      'blue-square',
      'twitter'
    ],
    sheet: [0, 0],
    hidden: ['facebook'],
    shortName: 'hash'
  },
  {
    name: 'Keycap: *',
    unified: '002A-FE0F-20E3',
    keywords: [
      'keycap_',
      'star',
      'keycap'
    ],
    sheet: [0, 1],
    hidden: ['facebook'],
    shortName: 'keycap_star'
  },
  {
    name: 'Keycap 0',
    unified: '0030-FE0F-20E3',
    keywords: [
      'keycap_0',
      '0',
      'numbers',
      'blue-square',
      'null'
    ],
    sheet: [0, 2],
    hidden: ['facebook'],
    shortName: 'zero'
  },
  {
    name: 'Keycap 1',
    unified: '0031-FE0F-20E3',
    keywords: [
      'keycap_1',
      'blue-square',
      'numbers',
      '1'
    ],
    sheet: [0, 3],
    hidden: ['facebook'],
    shortName: 'one'
  },
  {
    name: 'Keycap 2',
    unified: '0032-FE0F-20E3',
    keywords: [
      'keycap_2',
      'numbers',
      '2',
      'prime',
      'blue-square'
    ],
    sheet: [0, 4],
    hidden: ['facebook'],
    shortName: 'two'
  },
  {
    name: 'Keycap 3',
    unified: '0033-FE0F-20E3',
    keywords: [
      'keycap_3',
      '3',
      'numbers',
      'prime',
      'blue-square'
    ],
    sheet: [0, 5],
    hidden: ['facebook'],
    shortName: 'three'
  },
  {
    name: 'Keycap 4',
    unified: '0034-FE0F-20E3',
    keywords: [
      'keycap_4',
      '4',
      'numbers',
      'blue-square'
    ],
    sheet: [0, 6],
    hidden: ['facebook'],
    shortName: 'four'
  },
  {
    name: 'Keycap 5',
    unified: '0035-FE0F-20E3',
    keywords: [
      'keycap_5',
      '5',
      'numbers',
      'blue-square',
      'prime'
    ],
    sheet: [0, 7],
    hidden: ['facebook'],
    shortName: 'five'
  },
  {
    name: 'Keycap 6',
    unified: '0036-FE0F-20E3',
    keywords: [
      'keycap_6',
      '6',
      'numbers',
      'blue-square'
    ],
    sheet: [0, 8],
    hidden: ['facebook'],
    shortName: 'six'
  },
  {
    name: 'Keycap 7',
    unified: '0037-FE0F-20E3',
    keywords: [
      'keycap_7',
      '7',
      'numbers',
      'blue-square',
      'prime'
    ],
    sheet: [0, 9],
    hidden: ['facebook'],
    shortName: 'seven'
  },
  {
    name: 'Keycap 8',
    unified: '0038-FE0F-20E3',
    keywords: [
      'keycap_8',
      '8',
      'blue-square',
      'numbers'
    ],
    sheet: [0, 10],
    hidden: ['facebook'],
    shortName: 'eight'
  },
  {
    name: 'Keycap 9',
    unified: '0039-FE0F-20E3',
    keywords: [
      'keycap_9',
      'blue-square',
      'numbers',
      '9'
    ],
    sheet: [0, 11],
    hidden: ['facebook'],
    shortName: 'nine'
  },
  {
    name: 'Keycap Ten',
    unified: '1F51F',
    keywords: [
      'keycap_10',
      'numbers',
      '10',
      'blue-square'
    ],
    sheet: [28, 1],
    shortName: 'keycap_ten'
  },
  {
    name: 'Input Symbol for Latin Capital Letters',
    unified: '1F520',
    keywords: [
      'input_latin_uppercase',
      'alphabet',
      'words',
      'blue-square'
    ],
    sheet: [28, 2],
    shortName: 'capital_abcd'
  },
  {
    name: 'Input Symbol for Latin Small Letters',
    unified: '1F521',
    keywords: [
      'input_latin_lowercase',
      'blue-square',
      'alphabet'
    ],
    sheet: [28, 3],
    shortName: 'abcd'
  },
  {
    name: 'Input Symbol for Numbers',
    unified: '1F522',
    keywords: [
      'input_numbers',
      'numbers',
      'blue-square'
    ],
    sheet: [28, 4],
    shortName: '1234'
  },
  {
    name: 'Input Symbol for Symbols',
    unified: '1F523',
    keywords: [
      'input_symbols',
      'blue-square',
      'music',
      'note',
      'ampersand',
      'percent',
      'glyphs',
      'characters'
    ],
    sheet: [28, 5],
    shortName: 'symbols'
  },
  {
    name: 'Input Symbol for Latin Letters',
    unified: '1F524',
    keywords: [
      'input_latin_letters',
      'blue-square',
      'alphabet'
    ],
    sheet: [28, 6],
    shortName: 'abc'
  },
  {
    name: 'Negative Squared Latin Capital Letter a',
    unified: '1F170-FE0F',
    keywords: [
      'a_button',
      'red-square',
      'alphabet',
      'letter'
    ],
    sheet: [0, 16],
    shortName: 'a'
  },
  {
    name: 'Negative Squared Ab',
    unified: '1F18E',
    keywords: [
      'ab_button',
      'red-square',
      'alphabet'
    ],
    sheet: [0, 20],
    shortName: 'ab'
  },
  {
    name: 'Negative Squared Latin Capital Letter B',
    unified: '1F171-FE0F',
    keywords: [
      'b_button',
      'red-square',
      'alphabet',
      'letter'
    ],
    sheet: [0, 17],
    shortName: 'b'
  },
  {
    name: 'Squared Cl',
    unified: '1F191',
    keywords: [
      'cl_button',
      'alphabet',
      'words',
      'red-square'
    ],
    sheet: [0, 21],
    shortName: 'cl'
  },
  {
    name: 'Squared Cool',
    unified: '1F192',
    keywords: [
      'cool_button',
      'words',
      'blue-square'
    ],
    sheet: [0, 22],
    shortName: 'cool'
  },
  {
    name: 'Squared Free',
    unified: '1F193',
    keywords: [
      'free_button',
      'blue-square',
      'words'
    ],
    sheet: [0, 23],
    shortName: 'free'
  },
  {
    name: 'Information Source',
    unified: '2139-FE0F',
    keywords: [
      'information',
      'blue-square',
      'alphabet',
      'letter'
    ],
    sheet: [53, 19],
    shortName: 'information_source'
  },
  {
    name: 'Squared Id',
    unified: '1F194',
    keywords: [
      'id_button',
      'purple-square',
      'words'
    ],
    sheet: [0, 24],
    shortName: 'id'
  },
  {
    name: 'Circled Latin Capital Letter M',
    unified: '24C2-FE0F',
    keywords: [
      'circled_m',
      'alphabet',
      'blue-circle',
      'letter'
    ],
    sheet: [53, 46],
    shortName: 'm'
  },
  {
    name: 'Squared New',
    unified: '1F195',
    keywords: [
      'new_button',
      'blue-square',
      'words',
      'start'
    ],
    sheet: [0, 25],
    shortName: 'new'
  },
  {
    name: 'Squared Ng',
    unified: '1F196',
    keywords: [
      'ng_button',
      'blue-square',
      'words',
      'shape',
      'icon'
    ],
    sheet: [0, 26],
    shortName: 'ng'
  },
  {
    name: 'Negative Squared Latin Capital Letter O',
    unified: '1F17E-FE0F',
    keywords: [
      'o_button',
      'alphabet',
      'red-square',
      'letter'
    ],
    sheet: [0, 18],
    shortName: 'o2'
  },
  {
    name: 'Squared Ok',
    unified: '1F197',
    keywords: [
      'ok_button',
      'good',
      'agree',
      'yes',
      'blue-square'
    ],
    sheet: [0, 27],
    shortName: 'ok'
  },
  {
    name: 'Negative Squared Latin Capital Letter P',
    unified: '1F17F-FE0F',
    keywords: [
      'p_button',
      'cars',
      'blue-square',
      'alphabet',
      'letter'
    ],
    sheet: [0, 19],
    shortName: 'parking'
  },
  {
    name: 'Squared Sos',
    unified: '1F198',
    keywords: [
      'sos_button',
      'help',
      'red-square',
      'words',
      'emergency',
      '911'
    ],
    sheet: [0, 28],
    shortName: 'sos'
  },
  {
    name: 'Squared Up with Exclamation Mark',
    unified: '1F199',
    keywords: [
      'up_button',
      'blue-square',
      'above',
      'high'
    ],
    sheet: [0, 29],
    shortName: 'up'
  },
  {
    name: 'Squared Vs',
    unified: '1F19A',
    keywords: [
      'vs_button',
      'words',
      'orange-square'
    ],
    sheet: [0, 30],
    shortName: 'vs'
  },
  {
    name: 'Squared Katakana Koko',
    unified: '1F201',
    keywords: [
      'japanese_here_button',
      'blue-square',
      'here',
      'katakana',
      'japanese',
      'destination'
    ],
    sheet: [4, 57],
    shortName: 'koko'
  },
  {
    name: 'Squared Katakana Sa',
    unified: '1F202-FE0F',
    keywords: [
      'japanese_service_charge_button',
      'japanese',
      'blue-square',
      'katakana'
    ],
    sheet: [5, 0],
    shortName: 'sa'
  },
  {
    name: 'Squared Cjk Unified Ideograph-6708',
    unified: '1F237-FE0F',
    keywords: [
      'japanese_monthly_amount_button',
      'chinese',
      'month',
      'moon',
      'japanese',
      'orange-square',
      'kanji'
    ],
    sheet: [5, 8],
    shortName: 'u6708'
  },
  {
    name: 'Squared Cjk Unified Ideograph-6709',
    unified: '1F236',
    keywords: [
      'japanese_not_free_of_charge_button',
      'orange-square',
      'chinese',
      'have',
      'kanji'
    ],
    sheet: [5, 7],
    shortName: 'u6709'
  },
  {
    name: 'Squared Cjk Unified Ideograph-6307',
    unified: '1F22F',
    keywords: [
      'japanese_reserved_button',
      'chinese',
      'point',
      'green-square',
      'kanji'
    ],
    sheet: [5, 2],
    shortName: 'u6307'
  },
  {
    name: 'Circled Ideograph Advantage',
    unified: '1F250',
    keywords: [
      'japanese_bargain_button',
      'chinese',
      'kanji',
      'obtain',
      'get',
      'circle'
    ],
    sheet: [5, 12],
    shortName: 'ideograph_advantage'
  },
  {
    name: 'Squared Cjk Unified Ideograph-5272',
    unified: '1F239',
    keywords: [
      'japanese_discount_button',
      'cut',
      'divide',
      'chinese',
      'kanji',
      'pink-square'
    ],
    sheet: [5, 10],
    shortName: 'u5272'
  },
  {
    name: 'Squared Cjk Unified Ideograph-7121',
    unified: '1F21A',
    keywords: [
      'japanese_free_of_charge_button',
      'nothing',
      'chinese',
      'kanji',
      'japanese',
      'orange-square'
    ],
    sheet: [5, 1],
    shortName: 'u7121'
  },
  {
    name: 'Squared Cjk Unified Ideograph-7981',
    unified: '1F232',
    keywords: [
      'japanese_prohibited_button',
      'kanji',
      'japanese',
      'chinese',
      'forbidden',
      'limit',
      'restricted',
      'red-square'
    ],
    sheet: [5, 3],
    shortName: 'u7981'
  },
  {
    name: 'Circled Ideograph Accept',
    unified: '1F251',
    keywords: [
      'japanese_acceptable_button',
      'ok',
      'good',
      'chinese',
      'kanji',
      'agree',
      'yes',
      'orange-circle'
    ],
    sheet: [5, 13],
    shortName: 'accept'
  },
  {
    name: 'Squared Cjk Unified Ideograph-7533',
    unified: '1F238',
    keywords: [
      'japanese_application_button',
      'chinese',
      'japanese',
      'kanji',
      'orange-square'
    ],
    sheet: [5, 9],
    shortName: 'u7533'
  },
  {
    name: 'Squared Cjk Unified Ideograph-5408',
    unified: '1F234',
    keywords: [
      'japanese_passing_grade_button',
      'japanese',
      'chinese',
      'join',
      'kanji',
      'red-square'
    ],
    sheet: [5, 5],
    shortName: 'u5408'
  },
  {
    name: 'Squared Cjk Unified Ideograph-7a7a',
    unified: '1F233',
    keywords: [
      'japanese_vacancy_button',
      'kanji',
      'japanese',
      'chinese',
      'empty',
      'sky',
      'blue-square'
    ],
    sheet: [5, 4],
    shortName: 'u7a7a'
  },
  {
    name: 'Circled Ideograph Congratulation',
    unified: '3297-FE0F',
    keywords: [
      'japanese_congratulations_button',
      'chinese',
      'kanji',
      'japanese',
      'red-circle'
    ],
    sheet: [56, 50],
    shortName: 'congratulations'
  },
  {
    name: 'Circled Ideograph Secret',
    unified: '3299-FE0F',
    keywords: [
      'japanese_secret_button',
      'privacy',
      'chinese',
      'sshh',
      'kanji',
      'red-circle'
    ],
    sheet: [56, 51],
    shortName: 'secret'
  },
  {
    name: 'Squared Cjk Unified Ideograph-55b6',
    unified: '1F23A',
    keywords: [
      'japanese_open_for_business_button',
      'japanese',
      'opening hours',
      'orange-square'
    ],
    sheet: [5, 11],
    shortName: 'u55b6'
  },
  {
    name: 'Squared Cjk Unified Ideograph-6e80',
    unified: '1F235',
    keywords: [
      'japanese_no_vacancy_button',
      'full',
      'chinese',
      'japanese',
      'red-square',
      'kanji'
    ],
    sheet: [5, 6],
    shortName: 'u6e80'
  },
  {
    name: 'Large Red Circle',
    unified: '1F534',
    keywords: [
      'red_circle',
      'shape',
      'error',
      'danger'
    ],
    sheet: [28, 22],
    shortName: 'red_circle'
  },
  {
    name: 'Large Orange Circle',
    unified: '1F7E0',
    keywords: [
      'orange_circle',
      'round'
    ],
    sheet: [36, 55],
    shortName: 'large_orange_circle'
  },
  {
    name: 'Large Yellow Circle',
    unified: '1F7E1',
    keywords: [
      'yellow_circle',
      'round'
    ],
    sheet: [36, 56],
    shortName: 'large_yellow_circle'
  },
  {
    name: 'Large Green Circle',
    unified: '1F7E2',
    keywords: ['green_circle', 'round'],
    sheet: [36, 57],
    shortName: 'large_green_circle'
  },
  {
    name: 'Large Blue Circle',
    unified: '1F535',
    keywords: [
      'blue_circle',
      'shape',
      'icon',
      'button'
    ],
    sheet: [28, 23],
    shortName: 'large_blue_circle'
  },
  {
    name: 'Large Purple Circle',
    unified: '1F7E3',
    keywords: [
      'purple_circle',
      'round'
    ],
    sheet: [37, 0],
    shortName: 'large_purple_circle'
  },
  {
    name: 'Large Brown Circle',
    unified: '1F7E4',
    keywords: ['brown_circle', 'round'],
    sheet: [37, 1],
    shortName: 'large_brown_circle'
  },
  {
    name: 'Medium Black Circle',
    unified: '26AB',
    keywords: [
      'black_circle',
      'shape',
      'button',
      'round'
    ],
    sheet: [55, 1],
    shortName: 'black_circle'
  },
  {
    name: 'Medium White Circle',
    unified: '26AA',
    keywords: [
      'white_circle',
      'shape',
      'round'
    ],
    sheet: [55, 0],
    shortName: 'white_circle'
  },
  {
    name: 'Large Red Square',
    unified: '1F7E5',
    keywords: ['red_square'],
    sheet: [37, 2],
    shortName: 'large_red_square'
  },
  {
    name: 'Large Orange Square',
    unified: '1F7E7',
    keywords: ['orange_square'],
    sheet: [37, 4],
    shortName: 'large_orange_square'
  },
  {
    name: 'Large Yellow Square',
    unified: '1F7E8',
    keywords: ['yellow_square'],
    sheet: [37, 5],
    shortName: 'large_yellow_square'
  },
  {
    name: 'Large Green Square',
    unified: '1F7E9',
    keywords: ['green_square'],
    sheet: [37, 6],
    shortName: 'large_green_square'
  },
  {
    name: 'Large Blue Square',
    unified: '1F7E6',
    keywords: ['blue_square'],
    sheet: [37, 3],
    shortName: 'large_blue_square'
  },
  {
    name: 'Large Purple Square',
    unified: '1F7EA',
    keywords: ['purple_square'],
    sheet: [37, 7],
    shortName: 'large_purple_square'
  },
  {
    name: 'Large Brown Square',
    unified: '1F7EB',
    keywords: ['brown_square'],
    sheet: [37, 8],
    shortName: 'large_brown_square'
  },
  {
    name: 'Black Large Square',
    unified: '2B1B',
    keywords: [
      'black_large_square',
      'shape',
      'icon',
      'button'
    ],
    sheet: [56, 44],
    shortName: 'black_large_square'
  },
  {
    name: 'White Large Square',
    unified: '2B1C',
    keywords: [
      'white_large_square',
      'shape',
      'icon',
      'stone',
      'button'
    ],
    sheet: [56, 45],
    shortName: 'white_large_square'
  },
  {
    name: 'Black Medium Square',
    unified: '25FC-FE0F',
    keywords: [
      'black_medium_square',
      'shape',
      'button',
      'icon'
    ],
    sheet: [53, 52],
    shortName: 'black_medium_square'
  },
  {
    name: 'White Medium Square',
    unified: '25FB-FE0F',
    keywords: [
      'white_medium_square',
      'shape',
      'stone',
      'icon'
    ],
    sheet: [53, 51],
    shortName: 'white_medium_square'
  },
  {
    name: 'Black Medium Small Square',
    unified: '25FE',
    keywords: [
      'black_medium_small_square',
      'icon',
      'shape',
      'button'
    ],
    sheet: [53, 54],
    shortName: 'black_medium_small_square'
  },
  {
    name: 'White Medium Small Square',
    unified: '25FD',
    keywords: [
      'white_medium_small_square',
      'shape',
      'stone',
      'icon',
      'button'
    ],
    sheet: [53, 53],
    shortName: 'white_medium_small_square'
  },
  {
    name: 'Black Small Square',
    unified: '25AA-FE0F',
    keywords: [
      'black_small_square',
      'shape',
      'icon'
    ],
    sheet: [53, 47],
    shortName: 'black_small_square'
  },
  {
    name: 'White Small Square',
    unified: '25AB-FE0F',
    keywords: [
      'white_small_square',
      'shape',
      'icon'
    ],
    sheet: [53, 48],
    shortName: 'white_small_square'
  },
  {
    name: 'Large Orange Diamond',
    unified: '1F536',
    keywords: [
      'large_orange_diamond',
      'shape',
      'jewel',
      'gem'
    ],
    sheet: [28, 24],
    shortName: 'large_orange_diamond'
  },
  {
    name: 'Large Blue Diamond',
    unified: '1F537',
    keywords: [
      'large_blue_diamond',
      'shape',
      'jewel',
      'gem'
    ],
    sheet: [28, 25],
    shortName: 'large_blue_diamond'
  },
  {
    name: 'Small Orange Diamond',
    unified: '1F538',
    keywords: [
      'small_orange_diamond',
      'shape',
      'jewel',
      'gem'
    ],
    sheet: [28, 26],
    shortName: 'small_orange_diamond'
  },
  {
    name: 'Small Blue Diamond',
    unified: '1F539',
    keywords: [
      'small_blue_diamond',
      'shape',
      'jewel',
      'gem'
    ],
    sheet: [28, 27],
    shortName: 'small_blue_diamond'
  },
  {
    name: 'Up-Pointing Red Triangle',
    unified: '1F53A',
    keywords: [
      'red_triangle_pointed_up',
      'shape',
      'direction',
      'up',
      'top'
    ],
    sheet: [28, 28],
    shortName: 'small_red_triangle'
  },
  {
    name: 'Down-Pointing Red Triangle',
    unified: '1F53B',
    keywords: [
      'red_triangle_pointed_down',
      'shape',
      'direction',
      'bottom'
    ],
    sheet: [28, 29],
    shortName: 'small_red_triangle_down'
  },
  {
    name: 'Diamond Shape with a Dot Inside',
    unified: '1F4A0',
    keywords: [
      'diamond_with_a_dot',
      'jewel',
      'blue',
      'gem',
      'crystal',
      'fancy'
    ],
    sheet: [25, 44],
    shortName: 'diamond_shape_with_a_dot_inside'
  },
  {
    name: 'Radio Button',
    unified: '1F518',
    keywords: [
      'radio_button',
      'input',
      'old',
      'music',
      'circle'
    ],
    sheet: [27, 52],
    shortName: 'radio_button'
  },
  {
    name: 'White Square Button',
    unified: '1F533',
    keywords: [
      'white_square_button',
      'shape',
      'input'
    ],
    sheet: [28, 21],
    shortName: 'white_square_button'
  },
  {
    name: 'Black Square Button',
    unified: '1F532',
    keywords: [
      'black_square_button',
      'shape',
      'input',
      'frame'
    ],
    sheet: [28, 20],
    shortName: 'black_square_button'
  },
  {
    name: 'Chequered Flag',
    unified: '1F3C1',
    keywords: [
      'chequered_flag',
      'contest',
      'finishline',
      'race',
      'gokart'
    ],
    sheet: [8, 31],
    shortName: 'checkered_flag'
  },
  {
    name: 'Triangular Flag on Post',
    unified: '1F6A9',
    keywords: [
      'triangular_flag',
      'mark',
      'milestone',
      'place'
    ],
    sheet: [34, 50],
    shortName: 'triangular_flag_on_post'
  },
  {
    name: 'Crossed Flags',
    unified: '1F38C',
    keywords: [
      'crossed_flags',
      'japanese',
      'nation',
      'country',
      'border'
    ],
    sheet: [7, 41],
    shortName: 'crossed_flags'
  },
  {
    name: 'Waving Black Flag',
    unified: '1F3F4',
    keywords: ['black_flag', 'pirate'],
    sheet: [11, 7],
    shortName: 'waving_black_flag'
  },
  {
    name: 'White Flag',
    unified: '1F3F3-FE0F',
    keywords: [
      'white_flag',
      'losing',
      'loser',
      'lost',
      'surrender',
      'give up',
      'fail'
    ],
    sheet: [11, 2],
    shortName: 'waving_white_flag'
  },
  {
    name: 'Rainbow Flag',
    unified: '1F3F3-FE0F-200D-1F308',
    keywords: [
      'rainbow_flag',
      'flag',
      'rainbow',
      'pride',
      'gay',
      'lgbt',
      'glbt',
      'queer',
      'homosexual',
      'lesbian',
      'bisexual',
      'transgender'
    ],
    sheet: [11, 0],
    shortName: 'rainbow-flag'
  },
  {
    name: 'Transgender Flag',
    unified: '1F3F3-FE0F-200D-26A7-FE0F',
    keywords: [
      'transgender flag',
      'lgbtq'
    ],
    sheet: [11, 1],
    hidden: ['facebook'],
    shortName: 'transgender_flag'
  },
  {
    name: 'Pirate Flag',
    unified: '1F3F4-200D-2620-FE0F',
    keywords: [
      'pirate_flag',
      'skull',
      'crossbones',
      'flag',
      'banner'
    ],
    sheet: [11, 3],
    shortName: 'pirate_flag'
  },
  {
    name: 'Ascension Island Flag',
    unified: '1F1E6-1F1E8',
    keywords: ['flag_ascension_island'],
    sheet: [0, 31],
    shortName: 'flag-ac'
  },
  {
    name: 'Andorra Flag',
    unified: '1F1E6-1F1E9',
    keywords: [
      'flag_andorra',
      'ad',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 32],
    shortName: 'flag-ad'
  },
  {
    name: 'United Arab Emirates Flag',
    unified: '1F1E6-1F1EA',
    keywords: [
      'flag_united_arab_emirates',
      'united',
      'arab',
      'emirates',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 33],
    shortName: 'flag-ae'
  },
  {
    name: 'Afghanistan Flag',
    unified: '1F1E6-1F1EB',
    keywords: [
      'flag_afghanistan',
      'af',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 34],
    shortName: 'flag-af'
  },
  {
    name: 'Antigua & Barbuda Flag',
    unified: '1F1E6-1F1EC',
    keywords: [
      'flag_antigua_barbuda',
      'antigua',
      'barbuda',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 35],
    shortName: 'flag-ag'
  },
  {
    name: 'Anguilla Flag',
    unified: '1F1E6-1F1EE',
    keywords: [
      'flag_anguilla',
      'ai',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 36],
    shortName: 'flag-ai'
  },
  {
    name: 'Albania Flag',
    unified: '1F1E6-1F1F1',
    keywords: [
      'flag_albania',
      'al',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 37],
    shortName: 'flag-al'
  },
  {
    name: 'Armenia Flag',
    unified: '1F1E6-1F1F2',
    keywords: [
      'flag_armenia',
      'am',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 38],
    shortName: 'flag-am'
  },
  {
    name: 'Angola Flag',
    unified: '1F1E6-1F1F4',
    keywords: [
      'flag_angola',
      'ao',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 39],
    shortName: 'flag-ao'
  },
  {
    name: 'Antarctica Flag',
    unified: '1F1E6-1F1F6',
    keywords: [
      'flag_antarctica',
      'aq',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 40],
    shortName: 'flag-aq'
  },
  {
    name: 'Argentina Flag',
    unified: '1F1E6-1F1F7',
    keywords: [
      'flag_argentina',
      'ar',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 41],
    shortName: 'flag-ar'
  },
  {
    name: 'American Samoa Flag',
    unified: '1F1E6-1F1F8',
    keywords: [
      'flag_american_samoa',
      'american',
      'ws',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 42],
    shortName: 'flag-as'
  },
  {
    name: 'Austria Flag',
    unified: '1F1E6-1F1F9',
    keywords: [
      'flag_austria',
      'at',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 43],
    shortName: 'flag-at'
  },
  {
    name: 'Australia Flag',
    unified: '1F1E6-1F1FA',
    keywords: [
      'flag_australia',
      'au',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 44],
    shortName: 'flag-au'
  },
  {
    name: 'Aruba Flag',
    unified: '1F1E6-1F1FC',
    keywords: [
      'flag_aruba',
      'aw',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 45],
    shortName: 'flag-aw'
  },
  {
    name: 'Åland Islands Flag',
    unified: '1F1E6-1F1FD',
    keywords: [
      'flag_aland_islands',
      'Åland',
      'islands',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 46],
    shortName: 'flag-ax'
  },
  {
    name: 'Azerbaijan Flag',
    unified: '1F1E6-1F1FF',
    keywords: [
      'flag_azerbaijan',
      'az',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 47],
    shortName: 'flag-az'
  },
  {
    name: 'Bosnia & Herzegovina Flag',
    unified: '1F1E7-1F1E6',
    keywords: [
      'flag_bosnia_herzegovina',
      'bosnia',
      'herzegovina',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 48],
    shortName: 'flag-ba'
  },
  {
    name: 'Barbados Flag',
    unified: '1F1E7-1F1E7',
    keywords: [
      'flag_barbados',
      'bb',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 49],
    shortName: 'flag-bb'
  },
  {
    name: 'Bangladesh Flag',
    unified: '1F1E7-1F1E9',
    keywords: [
      'flag_bangladesh',
      'bd',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 50],
    shortName: 'flag-bd'
  },
  {
    name: 'Belgium Flag',
    unified: '1F1E7-1F1EA',
    keywords: [
      'flag_belgium',
      'be',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 51],
    shortName: 'flag-be'
  },
  {
    name: 'Burkina Faso Flag',
    unified: '1F1E7-1F1EB',
    keywords: [
      'flag_burkina_faso',
      'burkina',
      'faso',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 52],
    shortName: 'flag-bf'
  },
  {
    name: 'Bulgaria Flag',
    unified: '1F1E7-1F1EC',
    keywords: [
      'flag_bulgaria',
      'bg',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 53],
    shortName: 'flag-bg'
  },
  {
    name: 'Bahrain Flag',
    unified: '1F1E7-1F1ED',
    keywords: [
      'flag_bahrain',
      'bh',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 54],
    shortName: 'flag-bh'
  },
  {
    name: 'Burundi Flag',
    unified: '1F1E7-1F1EE',
    keywords: [
      'flag_burundi',
      'bi',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 55],
    shortName: 'flag-bi'
  },
  {
    name: 'Benin Flag',
    unified: '1F1E7-1F1EF',
    keywords: [
      'flag_benin',
      'bj',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 56],
    shortName: 'flag-bj'
  },
  {
    name: 'St. Barthélemy Flag',
    unified: '1F1E7-1F1F1',
    keywords: [
      'flag_st_barthelemy',
      'saint',
      'barthélemy',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [0, 57],
    shortName: 'flag-bl'
  },
  {
    name: 'Bermuda Flag',
    unified: '1F1E7-1F1F2',
    keywords: [
      'flag_bermuda',
      'bm',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 0],
    shortName: 'flag-bm'
  },
  {
    name: 'Brunei Flag',
    unified: '1F1E7-1F1F3',
    keywords: [
      'flag_brunei',
      'bn',
      'darussalam',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 1],
    shortName: 'flag-bn'
  },
  {
    name: 'Bolivia Flag',
    unified: '1F1E7-1F1F4',
    keywords: [
      'flag_bolivia',
      'bo',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 2],
    shortName: 'flag-bo'
  },
  {
    name: 'Caribbean Netherlands Flag',
    unified: '1F1E7-1F1F6',
    keywords: [
      'flag_caribbean_netherlands',
      'bonaire',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 3],
    shortName: 'flag-bq'
  },
  {
    name: 'Brazil Flag',
    unified: '1F1E7-1F1F7',
    keywords: [
      'flag_brazil',
      'br',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 4],
    shortName: 'flag-br'
  },
  {
    name: 'Bahamas Flag',
    unified: '1F1E7-1F1F8',
    keywords: [
      'flag_bahamas',
      'bs',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 5],
    shortName: 'flag-bs'
  },
  {
    name: 'Bhutan Flag',
    unified: '1F1E7-1F1F9',
    keywords: [
      'flag_bhutan',
      'bt',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 6],
    shortName: 'flag-bt'
  },
  {
    name: 'Bouvet Island Flag',
    unified: '1F1E7-1F1FB',
    keywords: [
      'flag_bouvet_island',
      'norway'
    ],
    sheet: [1, 7],
    shortName: 'flag-bv'
  },
  {
    name: 'Botswana Flag',
    unified: '1F1E7-1F1FC',
    keywords: [
      'flag_botswana',
      'bw',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 8],
    shortName: 'flag-bw'
  },
  {
    name: 'Belarus Flag',
    unified: '1F1E7-1F1FE',
    keywords: [
      'flag_belarus',
      'by',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 9],
    shortName: 'flag-by'
  },
  {
    name: 'Belize Flag',
    unified: '1F1E7-1F1FF',
    keywords: [
      'flag_belize',
      'bz',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 10],
    shortName: 'flag-bz'
  },
  {
    name: 'Canada Flag',
    unified: '1F1E8-1F1E6',
    keywords: [
      'flag_canada',
      'ca',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 11],
    shortName: 'flag-ca'
  },
  {
    name: 'Cocos (keeling) Islands Flag',
    unified: '1F1E8-1F1E8',
    keywords: [
      'flag_cocos_islands',
      'cocos',
      'keeling',
      'islands',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 12],
    shortName: 'flag-cc'
  },
  {
    name: 'Congo - Kinshasa Flag',
    unified: '1F1E8-1F1E9',
    keywords: [
      'flag_congo_kinshasa',
      'congo',
      'democratic',
      'republic',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 13],
    shortName: 'flag-cd'
  },
  {
    name: 'Central African Republic Flag',
    unified: '1F1E8-1F1EB',
    keywords: [
      'flag_central_african_republic',
      'central',
      'african',
      'republic',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 14],
    shortName: 'flag-cf'
  },
  {
    name: 'Congo - Brazzaville Flag',
    unified: '1F1E8-1F1EC',
    keywords: [
      'flag_congo_brazzaville',
      'congo',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 15],
    shortName: 'flag-cg'
  },
  {
    name: 'Switzerland Flag',
    unified: '1F1E8-1F1ED',
    keywords: [
      'flag_switzerland',
      'ch',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 16],
    shortName: 'flag-ch'
  },
  {
    name: 'Côte D’ivoire Flag',
    unified: '1F1E8-1F1EE',
    keywords: [
      'flag_cote_d_ivoire',
      'ivory',
      'coast',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 17],
    shortName: 'flag-ci'
  },
  {
    name: 'Cook Islands Flag',
    unified: '1F1E8-1F1F0',
    keywords: [
      'flag_cook_islands',
      'cook',
      'islands',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 18],
    shortName: 'flag-ck'
  },
  {
    name: 'Chile Flag',
    unified: '1F1E8-1F1F1',
    keywords: [
      'flag_chile',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 19],
    shortName: 'flag-cl'
  },
  {
    name: 'Cameroon Flag',
    unified: '1F1E8-1F1F2',
    keywords: [
      'flag_cameroon',
      'cm',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 20],
    shortName: 'flag-cm'
  },
  {
    name: 'China Flag',
    unified: '1F1E8-1F1F3',
    keywords: [
      'flag_china',
      'china',
      'chinese',
      'prc',
      'flag',
      'country',
      'nation',
      'banner'
    ],
    sheet: [1, 21],
    shortNames: ['flag-cn'],
    shortName: 'cn'
  },
  {
    name: 'Colombia Flag',
    unified: '1F1E8-1F1F4',
    keywords: [
      'flag_colombia',
      'co',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 22],
    shortName: 'flag-co'
  },
  {
    name: 'Clipperton Island Flag',
    unified: '1F1E8-1F1F5',
    keywords: [
      'flag_clipperton_island'
    ],
    sheet: [1, 23],
    shortName: 'flag-cp'
  },
  {
    name: 'Costa Rica Flag',
    unified: '1F1E8-1F1F7',
    keywords: [
      'flag_costa_rica',
      'costa',
      'rica',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 24],
    shortName: 'flag-cr'
  },
  {
    name: 'Cuba Flag',
    unified: '1F1E8-1F1FA',
    keywords: [
      'flag_cuba',
      'cu',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 25],
    shortName: 'flag-cu'
  },
  {
    name: 'Cape Verde Flag',
    unified: '1F1E8-1F1FB',
    keywords: [
      'flag_cape_verde',
      'cabo',
      'verde',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 26],
    shortName: 'flag-cv'
  },
  {
    name: 'Curaçao Flag',
    unified: '1F1E8-1F1FC',
    keywords: [
      'flag_curacao',
      'curaçao',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 27],
    shortName: 'flag-cw'
  },
  {
    name: 'Christmas Island Flag',
    unified: '1F1E8-1F1FD',
    keywords: [
      'flag_christmas_island',
      'christmas',
      'island',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 28],
    shortName: 'flag-cx'
  },
  {
    name: 'Cyprus Flag',
    unified: '1F1E8-1F1FE',
    keywords: [
      'flag_cyprus',
      'cy',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 29],
    shortName: 'flag-cy'
  },
  {
    name: 'Czechia Flag',
    unified: '1F1E8-1F1FF',
    keywords: [
      'flag_czechia',
      'cz',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 30],
    shortName: 'flag-cz'
  },
  {
    name: 'Germany Flag',
    unified: '1F1E9-1F1EA',
    keywords: [
      'flag_germany',
      'german',
      'nation',
      'flag',
      'country',
      'banner'
    ],
    sheet: [1, 31],
    shortNames: ['flag-de'],
    shortName: 'de'
  },
  {
    name: 'Diego Garcia Flag',
    unified: '1F1E9-1F1EC',
    keywords: ['flag_diego_garcia'],
    sheet: [1, 32],
    shortName: 'flag-dg'
  },
  {
    name: 'Djibouti Flag',
    unified: '1F1E9-1F1EF',
    keywords: [
      'flag_djibouti',
      'dj',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 33],
    shortName: 'flag-dj'
  },
  {
    name: 'Denmark Flag',
    unified: '1F1E9-1F1F0',
    keywords: [
      'flag_denmark',
      'dk',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 34],
    shortName: 'flag-dk'
  },
  {
    name: 'Dominica Flag',
    unified: '1F1E9-1F1F2',
    keywords: [
      'flag_dominica',
      'dm',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 35],
    shortName: 'flag-dm'
  },
  {
    name: 'Dominican Republic Flag',
    unified: '1F1E9-1F1F4',
    keywords: [
      'flag_dominican_republic',
      'dominican',
      'republic',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 36],
    shortName: 'flag-do'
  },
  {
    name: 'Algeria Flag',
    unified: '1F1E9-1F1FF',
    keywords: [
      'flag_algeria',
      'dz',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 37],
    shortName: 'flag-dz'
  },
  {
    name: 'Ceuta & Melilla Flag',
    unified: '1F1EA-1F1E6',
    keywords: ['flag_ceuta_melilla'],
    sheet: [1, 38],
    shortName: 'flag-ea'
  },
  {
    name: 'Ecuador Flag',
    unified: '1F1EA-1F1E8',
    keywords: [
      'flag_ecuador',
      'ec',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 39],
    shortName: 'flag-ec'
  },
  {
    name: 'Estonia Flag',
    unified: '1F1EA-1F1EA',
    keywords: [
      'flag_estonia',
      'ee',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 40],
    shortName: 'flag-ee'
  },
  {
    name: 'Egypt Flag',
    unified: '1F1EA-1F1EC',
    keywords: [
      'flag_egypt',
      'eg',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 41],
    shortName: 'flag-eg'
  },
  {
    name: 'Western Sahara Flag',
    unified: '1F1EA-1F1ED',
    keywords: [
      'flag_western_sahara',
      'western',
      'sahara',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 42],
    shortName: 'flag-eh'
  },
  {
    name: 'Eritrea Flag',
    unified: '1F1EA-1F1F7',
    keywords: [
      'flag_eritrea',
      'er',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 43],
    shortName: 'flag-er'
  },
  {
    name: 'Spain Flag',
    unified: '1F1EA-1F1F8',
    keywords: [
      'flag_spain',
      'spain',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 44],
    shortNames: ['flag-es'],
    shortName: 'es'
  },
  {
    name: 'Ethiopia Flag',
    unified: '1F1EA-1F1F9',
    keywords: [
      'flag_ethiopia',
      'et',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 45],
    shortName: 'flag-et'
  },
  {
    name: 'European Union Flag',
    unified: '1F1EA-1F1FA',
    keywords: [
      'flag_european_union',
      'european',
      'union',
      'flag',
      'banner'
    ],
    sheet: [1, 46],
    shortName: 'flag-eu'
  },
  {
    name: 'Finland Flag',
    unified: '1F1EB-1F1EE',
    keywords: [
      'flag_finland',
      'fi',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 47],
    shortName: 'flag-fi'
  },
  {
    name: 'Fiji Flag',
    unified: '1F1EB-1F1EF',
    keywords: [
      'flag_fiji',
      'fj',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 48],
    shortName: 'flag-fj'
  },
  {
    name: 'Falkland Islands Flag',
    unified: '1F1EB-1F1F0',
    keywords: [
      'flag_falkland_islands',
      'falkland',
      'islands',
      'malvinas',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 49],
    shortName: 'flag-fk'
  },
  {
    name: 'Micronesia Flag',
    unified: '1F1EB-1F1F2',
    keywords: [
      'flag_micronesia',
      'micronesia',
      'federated',
      'states',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 50],
    shortName: 'flag-fm'
  },
  {
    name: 'Faroe Islands Flag',
    unified: '1F1EB-1F1F4',
    keywords: [
      'flag_faroe_islands',
      'faroe',
      'islands',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 51],
    shortName: 'flag-fo'
  },
  {
    name: 'France Flag',
    unified: '1F1EB-1F1F7',
    keywords: [
      'flag_france',
      'banner',
      'flag',
      'nation',
      'france',
      'french',
      'country'
    ],
    sheet: [1, 52],
    shortNames: ['flag-fr'],
    shortName: 'fr'
  },
  {
    name: 'Gabon Flag',
    unified: '1F1EC-1F1E6',
    keywords: [
      'flag_gabon',
      'ga',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 53],
    shortName: 'flag-ga'
  },
  {
    name: 'United Kingdom Flag',
    unified: '1F1EC-1F1E7',
    keywords: [
      'flag_united_kingdom',
      'united',
      'kingdom',
      'great',
      'britain',
      'northern',
      'ireland',
      'flag',
      'nation',
      'country',
      'banner',
      'british',
      'UK',
      'english',
      'england',
      'union jack'
    ],
    sheet: [1, 54],
    shortNames: ['uk', 'flag-gb'],
    shortName: 'gb'
  },
  {
    name: 'Grenada Flag',
    unified: '1F1EC-1F1E9',
    keywords: [
      'flag_grenada',
      'gd',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 55],
    shortName: 'flag-gd'
  },
  {
    name: 'Georgia Flag',
    unified: '1F1EC-1F1EA',
    keywords: [
      'flag_georgia',
      'ge',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 56],
    shortName: 'flag-ge'
  },
  {
    name: 'French Guiana Flag',
    unified: '1F1EC-1F1EB',
    keywords: [
      'flag_french_guiana',
      'french',
      'guiana',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [1, 57],
    shortName: 'flag-gf'
  },
  {
    name: 'Guernsey Flag',
    unified: '1F1EC-1F1EC',
    keywords: [
      'flag_guernsey',
      'gg',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 0],
    shortName: 'flag-gg'
  },
  {
    name: 'Ghana Flag',
    unified: '1F1EC-1F1ED',
    keywords: [
      'flag_ghana',
      'gh',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 1],
    shortName: 'flag-gh'
  },
  {
    name: 'Gibraltar Flag',
    unified: '1F1EC-1F1EE',
    keywords: [
      'flag_gibraltar',
      'gi',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 2],
    shortName: 'flag-gi'
  },
  {
    name: 'Greenland Flag',
    unified: '1F1EC-1F1F1',
    keywords: [
      'flag_greenland',
      'gl',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 3],
    shortName: 'flag-gl'
  },
  {
    name: 'Gambia Flag',
    unified: '1F1EC-1F1F2',
    keywords: [
      'flag_gambia',
      'gm',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 4],
    shortName: 'flag-gm'
  },
  {
    name: 'Guinea Flag',
    unified: '1F1EC-1F1F3',
    keywords: [
      'flag_guinea',
      'gn',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 5],
    shortName: 'flag-gn'
  },
  {
    name: 'Guadeloupe Flag',
    unified: '1F1EC-1F1F5',
    keywords: [
      'flag_guadeloupe',
      'gp',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 6],
    shortName: 'flag-gp'
  },
  {
    name: 'Equatorial Guinea Flag',
    unified: '1F1EC-1F1F6',
    keywords: [
      'flag_equatorial_guinea',
      'equatorial',
      'gn',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 7],
    shortName: 'flag-gq'
  },
  {
    name: 'Greece Flag',
    unified: '1F1EC-1F1F7',
    keywords: [
      'flag_greece',
      'gr',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 8],
    shortName: 'flag-gr'
  },
  {
    name: 'South Georgia & South Sandwich Islands Flag',
    unified: '1F1EC-1F1F8',
    keywords: [
      'flag_south_georgia_south_sandwich_islands',
      'south',
      'georgia',
      'sandwich',
      'islands',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 9],
    shortName: 'flag-gs'
  },
  {
    name: 'Guatemala Flag',
    unified: '1F1EC-1F1F9',
    keywords: [
      'flag_guatemala',
      'gt',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 10],
    shortName: 'flag-gt'
  },
  {
    name: 'Guam Flag',
    unified: '1F1EC-1F1FA',
    keywords: [
      'flag_guam',
      'gu',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 11],
    shortName: 'flag-gu'
  },
  {
    name: 'Guinea-Bissau Flag',
    unified: '1F1EC-1F1FC',
    keywords: [
      'flag_guinea_bissau',
      'gw',
      'bissau',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 12],
    shortName: 'flag-gw'
  },
  {
    name: 'Guyana Flag',
    unified: '1F1EC-1F1FE',
    keywords: [
      'flag_guyana',
      'gy',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 13],
    shortName: 'flag-gy'
  },
  {
    name: 'Hong Kong Sar China Flag',
    unified: '1F1ED-1F1F0',
    keywords: [
      'flag_hong_kong_sar_china',
      'hong',
      'kong',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 14],
    shortName: 'flag-hk'
  },
  {
    name: 'Heard & Mcdonald Islands Flag',
    unified: '1F1ED-1F1F2',
    keywords: [
      'flag_heard_mcdonald_islands'
    ],
    sheet: [2, 15],
    shortName: 'flag-hm'
  },
  {
    name: 'Honduras Flag',
    unified: '1F1ED-1F1F3',
    keywords: [
      'flag_honduras',
      'hn',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 16],
    shortName: 'flag-hn'
  },
  {
    name: 'Croatia Flag',
    unified: '1F1ED-1F1F7',
    keywords: [
      'flag_croatia',
      'hr',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 17],
    shortName: 'flag-hr'
  },
  {
    name: 'Haiti Flag',
    unified: '1F1ED-1F1F9',
    keywords: [
      'flag_haiti',
      'ht',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 18],
    shortName: 'flag-ht'
  },
  {
    name: 'Hungary Flag',
    unified: '1F1ED-1F1FA',
    keywords: [
      'flag_hungary',
      'hu',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 19],
    shortName: 'flag-hu'
  },
  {
    name: 'Canary Islands Flag',
    unified: '1F1EE-1F1E8',
    keywords: [
      'flag_canary_islands',
      'canary',
      'islands',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 20],
    shortName: 'flag-ic'
  },
  {
    name: 'Indonesia Flag',
    unified: '1F1EE-1F1E9',
    keywords: [
      'flag_indonesia',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 21],
    shortName: 'flag-id'
  },
  {
    name: 'Ireland Flag',
    unified: '1F1EE-1F1EA',
    keywords: [
      'flag_ireland',
      'ie',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 22],
    shortName: 'flag-ie'
  },
  {
    name: 'Israel Flag',
    unified: '1F1EE-1F1F1',
    keywords: [
      'flag_israel',
      'il',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 23],
    shortName: 'flag-il'
  },
  {
    name: 'Isle of Man Flag',
    unified: '1F1EE-1F1F2',
    keywords: [
      'flag_isle_of_man',
      'isle',
      'man',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 24],
    shortName: 'flag-im'
  },
  {
    name: 'India Flag',
    unified: '1F1EE-1F1F3',
    keywords: [
      'flag_india',
      'in',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 25],
    shortName: 'flag-in'
  },
  {
    name: 'British Indian Ocean Territory Flag',
    unified: '1F1EE-1F1F4',
    keywords: [
      'flag_british_indian_ocean_territory',
      'british',
      'indian',
      'ocean',
      'territory',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 26],
    shortName: 'flag-io'
  },
  {
    name: 'Iraq Flag',
    unified: '1F1EE-1F1F6',
    keywords: [
      'flag_iraq',
      'iq',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 27],
    shortName: 'flag-iq'
  },
  {
    name: 'Iran Flag',
    unified: '1F1EE-1F1F7',
    keywords: [
      'flag_iran',
      'iran',
      'islamic',
      'republic',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 28],
    shortName: 'flag-ir'
  },
  {
    name: 'Iceland Flag',
    unified: '1F1EE-1F1F8',
    keywords: [
      'flag_iceland',
      'is',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 29],
    shortName: 'flag-is'
  },
  {
    name: 'Italy Flag',
    unified: '1F1EE-1F1F9',
    keywords: [
      'flag_italy',
      'italy',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 30],
    shortNames: ['flag-it'],
    shortName: 'it'
  },
  {
    name: 'Jersey Flag',
    unified: '1F1EF-1F1EA',
    keywords: [
      'flag_jersey',
      'je',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 31],
    shortName: 'flag-je'
  },
  {
    name: 'Jamaica Flag',
    unified: '1F1EF-1F1F2',
    keywords: [
      'flag_jamaica',
      'jm',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 32],
    shortName: 'flag-jm'
  },
  {
    name: 'Jordan Flag',
    unified: '1F1EF-1F1F4',
    keywords: [
      'flag_jordan',
      'jo',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 33],
    shortName: 'flag-jo'
  },
  {
    name: 'Japan Flag',
    unified: '1F1EF-1F1F5',
    keywords: [
      'flag_japan',
      'japanese',
      'nation',
      'flag',
      'country',
      'banner'
    ],
    sheet: [2, 34],
    shortNames: ['flag-jp'],
    shortName: 'jp'
  },
  {
    name: 'Kenya Flag',
    unified: '1F1F0-1F1EA',
    keywords: [
      'flag_kenya',
      'ke',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 35],
    shortName: 'flag-ke'
  },
  {
    name: 'Kyrgyzstan Flag',
    unified: '1F1F0-1F1EC',
    keywords: [
      'flag_kyrgyzstan',
      'kg',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 36],
    shortName: 'flag-kg'
  },
  {
    name: 'Cambodia Flag',
    unified: '1F1F0-1F1ED',
    keywords: [
      'flag_cambodia',
      'kh',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 37],
    shortName: 'flag-kh'
  },
  {
    name: 'Kiribati Flag',
    unified: '1F1F0-1F1EE',
    keywords: [
      'flag_kiribati',
      'ki',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 38],
    shortName: 'flag-ki'
  },
  {
    name: 'Comoros Flag',
    unified: '1F1F0-1F1F2',
    keywords: [
      'flag_comoros',
      'km',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 39],
    shortName: 'flag-km'
  },
  {
    name: 'St. Kitts & Nevis Flag',
    unified: '1F1F0-1F1F3',
    keywords: [
      'flag_st_kitts_nevis',
      'saint',
      'kitts',
      'nevis',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 40],
    shortName: 'flag-kn'
  },
  {
    name: 'North Korea Flag',
    unified: '1F1F0-1F1F5',
    keywords: [
      'flag_north_korea',
      'north',
      'korea',
      'nation',
      'flag',
      'country',
      'banner'
    ],
    sheet: [2, 41],
    shortName: 'flag-kp'
  },
  {
    name: 'South Korea Flag',
    unified: '1F1F0-1F1F7',
    keywords: [
      'flag_south_korea',
      'south',
      'korea',
      'nation',
      'flag',
      'country',
      'banner'
    ],
    sheet: [2, 42],
    shortNames: ['flag-kr'],
    shortName: 'kr'
  },
  {
    name: 'Kuwait Flag',
    unified: '1F1F0-1F1FC',
    keywords: [
      'flag_kuwait',
      'kw',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 43],
    shortName: 'flag-kw'
  },
  {
    name: 'Cayman Islands Flag',
    unified: '1F1F0-1F1FE',
    keywords: [
      'flag_cayman_islands',
      'cayman',
      'islands',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 44],
    shortName: 'flag-ky'
  },
  {
    name: 'Kazakhstan Flag',
    unified: '1F1F0-1F1FF',
    keywords: [
      'flag_kazakhstan',
      'kz',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 45],
    shortName: 'flag-kz'
  },
  {
    name: 'Laos Flag',
    unified: '1F1F1-1F1E6',
    keywords: [
      'flag_laos',
      'lao',
      'democratic',
      'republic',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 46],
    shortName: 'flag-la'
  },
  {
    name: 'Lebanon Flag',
    unified: '1F1F1-1F1E7',
    keywords: [
      'flag_lebanon',
      'lb',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 47],
    shortName: 'flag-lb'
  },
  {
    name: 'St. Lucia Flag',
    unified: '1F1F1-1F1E8',
    keywords: [
      'flag_st_lucia',
      'saint',
      'lucia',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 48],
    shortName: 'flag-lc'
  },
  {
    name: 'Liechtenstein Flag',
    unified: '1F1F1-1F1EE',
    keywords: [
      'flag_liechtenstein',
      'li',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 49],
    shortName: 'flag-li'
  },
  {
    name: 'Sri Lanka Flag',
    unified: '1F1F1-1F1F0',
    keywords: [
      'flag_sri_lanka',
      'sri',
      'lanka',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 50],
    shortName: 'flag-lk'
  },
  {
    name: 'Liberia Flag',
    unified: '1F1F1-1F1F7',
    keywords: [
      'flag_liberia',
      'lr',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 51],
    shortName: 'flag-lr'
  },
  {
    name: 'Lesotho Flag',
    unified: '1F1F1-1F1F8',
    keywords: [
      'flag_lesotho',
      'ls',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 52],
    shortName: 'flag-ls'
  },
  {
    name: 'Lithuania Flag',
    unified: '1F1F1-1F1F9',
    keywords: [
      'flag_lithuania',
      'lt',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 53],
    shortName: 'flag-lt'
  },
  {
    name: 'Luxembourg Flag',
    unified: '1F1F1-1F1FA',
    keywords: [
      'flag_luxembourg',
      'lu',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 54],
    shortName: 'flag-lu'
  },
  {
    name: 'Latvia Flag',
    unified: '1F1F1-1F1FB',
    keywords: [
      'flag_latvia',
      'lv',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 55],
    shortName: 'flag-lv'
  },
  {
    name: 'Libya Flag',
    unified: '1F1F1-1F1FE',
    keywords: [
      'flag_libya',
      'ly',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 56],
    shortName: 'flag-ly'
  },
  {
    name: 'Morocco Flag',
    unified: '1F1F2-1F1E6',
    keywords: [
      'flag_morocco',
      'ma',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [2, 57],
    shortName: 'flag-ma'
  },
  {
    name: 'Monaco Flag',
    unified: '1F1F2-1F1E8',
    keywords: [
      'flag_monaco',
      'mc',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 0],
    shortName: 'flag-mc'
  },
  {
    name: 'Moldova Flag',
    unified: '1F1F2-1F1E9',
    keywords: [
      'flag_moldova',
      'moldova',
      'republic',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 1],
    shortName: 'flag-md'
  },
  {
    name: 'Montenegro Flag',
    unified: '1F1F2-1F1EA',
    keywords: [
      'flag_montenegro',
      'me',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 2],
    shortName: 'flag-me'
  },
  {
    name: 'St. Martin Flag',
    unified: '1F1F2-1F1EB',
    keywords: ['flag_st_martin'],
    sheet: [3, 3],
    shortName: 'flag-mf'
  },
  {
    name: 'Madagascar Flag',
    unified: '1F1F2-1F1EC',
    keywords: [
      'flag_madagascar',
      'mg',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 4],
    shortName: 'flag-mg'
  },
  {
    name: 'Marshall Islands Flag',
    unified: '1F1F2-1F1ED',
    keywords: [
      'flag_marshall_islands',
      'marshall',
      'islands',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 5],
    shortName: 'flag-mh'
  },
  {
    name: 'North Macedonia Flag',
    unified: '1F1F2-1F1F0',
    keywords: [
      'flag_north_macedonia',
      'macedonia',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 6],
    shortName: 'flag-mk'
  },
  {
    name: 'Mali Flag',
    unified: '1F1F2-1F1F1',
    keywords: [
      'flag_mali',
      'ml',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 7],
    shortName: 'flag-ml'
  },
  {
    name: 'Myanmar (burma) Flag',
    unified: '1F1F2-1F1F2',
    keywords: [
      'flag_myanmar',
      'mm',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 8],
    shortName: 'flag-mm'
  },
  {
    name: 'Mongolia Flag',
    unified: '1F1F2-1F1F3',
    keywords: [
      'flag_mongolia',
      'mn',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 9],
    shortName: 'flag-mn'
  },
  {
    name: 'Macao Sar China Flag',
    unified: '1F1F2-1F1F4',
    keywords: [
      'flag_macao_sar_china',
      'macao',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 10],
    shortName: 'flag-mo'
  },
  {
    name: 'Northern Mariana Islands Flag',
    unified: '1F1F2-1F1F5',
    keywords: [
      'flag_northern_mariana_islands',
      'northern',
      'mariana',
      'islands',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 11],
    shortName: 'flag-mp'
  },
  {
    name: 'Martinique Flag',
    unified: '1F1F2-1F1F6',
    keywords: [
      'flag_martinique',
      'mq',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 12],
    shortName: 'flag-mq'
  },
  {
    name: 'Mauritania Flag',
    unified: '1F1F2-1F1F7',
    keywords: [
      'flag_mauritania',
      'mr',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 13],
    shortName: 'flag-mr'
  },
  {
    name: 'Montserrat Flag',
    unified: '1F1F2-1F1F8',
    keywords: [
      'flag_montserrat',
      'ms',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 14],
    shortName: 'flag-ms'
  },
  {
    name: 'Malta Flag',
    unified: '1F1F2-1F1F9',
    keywords: [
      'flag_malta',
      'mt',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 15],
    shortName: 'flag-mt'
  },
  {
    name: 'Mauritius Flag',
    unified: '1F1F2-1F1FA',
    keywords: [
      'flag_mauritius',
      'mu',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 16],
    shortName: 'flag-mu'
  },
  {
    name: 'Maldives Flag',
    unified: '1F1F2-1F1FB',
    keywords: [
      'flag_maldives',
      'mv',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 17],
    shortName: 'flag-mv'
  },
  {
    name: 'Malawi Flag',
    unified: '1F1F2-1F1FC',
    keywords: [
      'flag_malawi',
      'mw',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 18],
    shortName: 'flag-mw'
  },
  {
    name: 'Mexico Flag',
    unified: '1F1F2-1F1FD',
    keywords: [
      'flag_mexico',
      'mx',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 19],
    shortName: 'flag-mx'
  },
  {
    name: 'Malaysia Flag',
    unified: '1F1F2-1F1FE',
    keywords: [
      'flag_malaysia',
      'my',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 20],
    shortName: 'flag-my'
  },
  {
    name: 'Mozambique Flag',
    unified: '1F1F2-1F1FF',
    keywords: [
      'flag_mozambique',
      'mz',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 21],
    shortName: 'flag-mz'
  },
  {
    name: 'Namibia Flag',
    unified: '1F1F3-1F1E6',
    keywords: [
      'flag_namibia',
      'na',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 22],
    shortName: 'flag-na'
  },
  {
    name: 'New Caledonia Flag',
    unified: '1F1F3-1F1E8',
    keywords: [
      'flag_new_caledonia',
      'new',
      'caledonia',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 23],
    shortName: 'flag-nc'
  },
  {
    name: 'Niger Flag',
    unified: '1F1F3-1F1EA',
    keywords: [
      'flag_niger',
      'ne',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 24],
    shortName: 'flag-ne'
  },
  {
    name: 'Norfolk Island Flag',
    unified: '1F1F3-1F1EB',
    keywords: [
      'flag_norfolk_island',
      'norfolk',
      'island',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 25],
    shortName: 'flag-nf'
  },
  {
    name: 'Nigeria Flag',
    unified: '1F1F3-1F1EC',
    keywords: [
      'flag_nigeria',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 26],
    shortName: 'flag-ng'
  },
  {
    name: 'Nicaragua Flag',
    unified: '1F1F3-1F1EE',
    keywords: [
      'flag_nicaragua',
      'ni',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 27],
    shortName: 'flag-ni'
  },
  {
    name: 'Netherlands Flag',
    unified: '1F1F3-1F1F1',
    keywords: [
      'flag_netherlands',
      'nl',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 28],
    shortName: 'flag-nl'
  },
  {
    name: 'Norway Flag',
    unified: '1F1F3-1F1F4',
    keywords: [
      'flag_norway',
      'no',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 29],
    shortName: 'flag-no'
  },
  {
    name: 'Nepal Flag',
    unified: '1F1F3-1F1F5',
    keywords: [
      'flag_nepal',
      'np',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 30],
    shortName: 'flag-np'
  },
  {
    name: 'Nauru Flag',
    unified: '1F1F3-1F1F7',
    keywords: [
      'flag_nauru',
      'nr',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 31],
    shortName: 'flag-nr'
  },
  {
    name: 'Niue Flag',
    unified: '1F1F3-1F1FA',
    keywords: [
      'flag_niue',
      'nu',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 32],
    shortName: 'flag-nu'
  },
  {
    name: 'New Zealand Flag',
    unified: '1F1F3-1F1FF',
    keywords: [
      'flag_new_zealand',
      'new',
      'zealand',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 33],
    shortName: 'flag-nz'
  },
  {
    name: 'Oman Flag',
    unified: '1F1F4-1F1F2',
    keywords: [
      'flag_oman',
      'om_symbol',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 34],
    shortName: 'flag-om'
  },
  {
    name: 'Panama Flag',
    unified: '1F1F5-1F1E6',
    keywords: [
      'flag_panama',
      'pa',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 35],
    shortName: 'flag-pa'
  },
  {
    name: 'Peru Flag',
    unified: '1F1F5-1F1EA',
    keywords: [
      'flag_peru',
      'pe',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 36],
    shortName: 'flag-pe'
  },
  {
    name: 'French Polynesia Flag',
    unified: '1F1F5-1F1EB',
    keywords: [
      'flag_french_polynesia',
      'french',
      'polynesia',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 37],
    shortName: 'flag-pf'
  },
  {
    name: 'Papua New Guinea Flag',
    unified: '1F1F5-1F1EC',
    keywords: [
      'flag_papua_new_guinea',
      'papua',
      'new',
      'guinea',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 38],
    shortName: 'flag-pg'
  },
  {
    name: 'Philippines Flag',
    unified: '1F1F5-1F1ED',
    keywords: [
      'flag_philippines',
      'ph',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 39],
    shortName: 'flag-ph'
  },
  {
    name: 'Pakistan Flag',
    unified: '1F1F5-1F1F0',
    keywords: [
      'flag_pakistan',
      'pk',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 40],
    shortName: 'flag-pk'
  },
  {
    name: 'Poland Flag',
    unified: '1F1F5-1F1F1',
    keywords: [
      'flag_poland',
      'pl',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 41],
    shortName: 'flag-pl'
  },
  {
    name: 'St. Pierre & Miquelon Flag',
    unified: '1F1F5-1F1F2',
    keywords: [
      'flag_st_pierre_miquelon',
      'saint',
      'pierre',
      'miquelon',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 42],
    shortName: 'flag-pm'
  },
  {
    name: 'Pitcairn Islands Flag',
    unified: '1F1F5-1F1F3',
    keywords: [
      'flag_pitcairn_islands',
      'pitcairn',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 43],
    shortName: 'flag-pn'
  },
  {
    name: 'Puerto Rico Flag',
    unified: '1F1F5-1F1F7',
    keywords: [
      'flag_puerto_rico',
      'puerto',
      'rico',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 44],
    shortName: 'flag-pr'
  },
  {
    name: 'Palestinian Territories Flag',
    unified: '1F1F5-1F1F8',
    keywords: [
      'flag_palestinian_territories',
      'palestine',
      'palestinian',
      'territories',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 45],
    shortName: 'flag-ps'
  },
  {
    name: 'Portugal Flag',
    unified: '1F1F5-1F1F9',
    keywords: [
      'flag_portugal',
      'pt',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 46],
    shortName: 'flag-pt'
  },
  {
    name: 'Palau Flag',
    unified: '1F1F5-1F1FC',
    keywords: [
      'flag_palau',
      'pw',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 47],
    shortName: 'flag-pw'
  },
  {
    name: 'Paraguay Flag',
    unified: '1F1F5-1F1FE',
    keywords: [
      'flag_paraguay',
      'py',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 48],
    shortName: 'flag-py'
  },
  {
    name: 'Qatar Flag',
    unified: '1F1F6-1F1E6',
    keywords: [
      'flag_qatar',
      'qa',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 49],
    shortName: 'flag-qa'
  },
  {
    name: 'Réunion Flag',
    unified: '1F1F7-1F1EA',
    keywords: [
      'flag_reunion',
      'réunion',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 50],
    shortName: 'flag-re'
  },
  {
    name: 'Romania Flag',
    unified: '1F1F7-1F1F4',
    keywords: [
      'flag_romania',
      'ro',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 51],
    shortName: 'flag-ro'
  },
  {
    name: 'Serbia Flag',
    unified: '1F1F7-1F1F8',
    keywords: [
      'flag_serbia',
      'rs',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 52],
    shortName: 'flag-rs'
  },
  {
    name: 'Russia Flag',
    unified: '1F1F7-1F1FA',
    keywords: [
      'flag_russia',
      'russian',
      'federation',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 53],
    shortNames: ['flag-ru'],
    shortName: 'ru'
  },
  {
    name: 'Rwanda Flag',
    unified: '1F1F7-1F1FC',
    keywords: [
      'flag_rwanda',
      'rw',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 54],
    shortName: 'flag-rw'
  },
  {
    name: 'Saudi Arabia Flag',
    unified: '1F1F8-1F1E6',
    keywords: [
      'flag_saudi_arabia',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 55],
    shortName: 'flag-sa'
  },
  {
    name: 'Solomon Islands Flag',
    unified: '1F1F8-1F1E7',
    keywords: [
      'flag_solomon_islands',
      'solomon',
      'islands',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 56],
    shortName: 'flag-sb'
  },
  {
    name: 'Seychelles Flag',
    unified: '1F1F8-1F1E8',
    keywords: [
      'flag_seychelles',
      'sc',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [3, 57],
    shortName: 'flag-sc'
  },
  {
    name: 'Sudan Flag',
    unified: '1F1F8-1F1E9',
    keywords: [
      'flag_sudan',
      'sd',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 0],
    shortName: 'flag-sd'
  },
  {
    name: 'Sweden Flag',
    unified: '1F1F8-1F1EA',
    keywords: [
      'flag_sweden',
      'se',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 1],
    shortName: 'flag-se'
  },
  {
    name: 'Singapore Flag',
    unified: '1F1F8-1F1EC',
    keywords: [
      'flag_singapore',
      'sg',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 2],
    shortName: 'flag-sg'
  },
  {
    name: 'St. Helena Flag',
    unified: '1F1F8-1F1ED',
    keywords: [
      'flag_st_helena',
      'saint',
      'helena',
      'ascension',
      'tristan',
      'cunha',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 3],
    shortName: 'flag-sh'
  },
  {
    name: 'Slovenia Flag',
    unified: '1F1F8-1F1EE',
    keywords: [
      'flag_slovenia',
      'si',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 4],
    shortName: 'flag-si'
  },
  {
    name: 'Svalbard & Jan Mayen Flag',
    unified: '1F1F8-1F1EF',
    keywords: [
      'flag_svalbard_jan_mayen'
    ],
    sheet: [4, 5],
    shortName: 'flag-sj'
  },
  {
    name: 'Slovakia Flag',
    unified: '1F1F8-1F1F0',
    keywords: [
      'flag_slovakia',
      'sk',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 6],
    shortName: 'flag-sk'
  },
  {
    name: 'Sierra Leone Flag',
    unified: '1F1F8-1F1F1',
    keywords: [
      'flag_sierra_leone',
      'sierra',
      'leone',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 7],
    shortName: 'flag-sl'
  },
  {
    name: 'San Marino Flag',
    unified: '1F1F8-1F1F2',
    keywords: [
      'flag_san_marino',
      'san',
      'marino',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 8],
    shortName: 'flag-sm'
  },
  {
    name: 'Senegal Flag',
    unified: '1F1F8-1F1F3',
    keywords: [
      'flag_senegal',
      'sn',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 9],
    shortName: 'flag-sn'
  },
  {
    name: 'Somalia Flag',
    unified: '1F1F8-1F1F4',
    keywords: [
      'flag_somalia',
      'so',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 10],
    shortName: 'flag-so'
  },
  {
    name: 'Suriname Flag',
    unified: '1F1F8-1F1F7',
    keywords: [
      'flag_suriname',
      'sr',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 11],
    shortName: 'flag-sr'
  },
  {
    name: 'South Sudan Flag',
    unified: '1F1F8-1F1F8',
    keywords: [
      'flag_south_sudan',
      'south',
      'sd',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 12],
    shortName: 'flag-ss'
  },
  {
    name: 'São Tomé & Príncipe Flag',
    unified: '1F1F8-1F1F9',
    keywords: [
      'flag_sao_tome_principe',
      'sao',
      'tome',
      'principe',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 13],
    shortName: 'flag-st'
  },
  {
    name: 'El Salvador Flag',
    unified: '1F1F8-1F1FB',
    keywords: [
      'flag_el_salvador',
      'el',
      'salvador',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 14],
    shortName: 'flag-sv'
  },
  {
    name: 'Sint Maarten Flag',
    unified: '1F1F8-1F1FD',
    keywords: [
      'flag_sint_maarten',
      'sint',
      'maarten',
      'dutch',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 15],
    shortName: 'flag-sx'
  },
  {
    name: 'Syria Flag',
    unified: '1F1F8-1F1FE',
    keywords: [
      'flag_syria',
      'syrian',
      'arab',
      'republic',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 16],
    shortName: 'flag-sy'
  },
  {
    name: 'Eswatini Flag',
    unified: '1F1F8-1F1FF',
    keywords: [
      'flag_eswatini',
      'sz',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 17],
    shortName: 'flag-sz'
  },
  {
    name: 'Tristan Da Cunha Flag',
    unified: '1F1F9-1F1E6',
    keywords: ['flag_tristan_da_cunha'],
    sheet: [4, 18],
    shortName: 'flag-ta'
  },
  {
    name: 'Turks & Caicos Islands Flag',
    unified: '1F1F9-1F1E8',
    keywords: [
      'flag_turks_caicos_islands',
      'turks',
      'caicos',
      'islands',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 19],
    shortName: 'flag-tc'
  },
  {
    name: 'Chad Flag',
    unified: '1F1F9-1F1E9',
    keywords: [
      'flag_chad',
      'td',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 20],
    shortName: 'flag-td'
  },
  {
    name: 'French Southern Territories Flag',
    unified: '1F1F9-1F1EB',
    keywords: [
      'flag_french_southern_territories',
      'french',
      'southern',
      'territories',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 21],
    shortName: 'flag-tf'
  },
  {
    name: 'Togo Flag',
    unified: '1F1F9-1F1EC',
    keywords: [
      'flag_togo',
      'tg',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 22],
    shortName: 'flag-tg'
  },
  {
    name: 'Thailand Flag',
    unified: '1F1F9-1F1ED',
    keywords: [
      'flag_thailand',
      'th',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 23],
    shortName: 'flag-th'
  },
  {
    name: 'Tajikistan Flag',
    unified: '1F1F9-1F1EF',
    keywords: [
      'flag_tajikistan',
      'tj',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 24],
    shortName: 'flag-tj'
  },
  {
    name: 'Tokelau Flag',
    unified: '1F1F9-1F1F0',
    keywords: [
      'flag_tokelau',
      'tk',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 25],
    shortName: 'flag-tk'
  },
  {
    name: 'Timor-Leste Flag',
    unified: '1F1F9-1F1F1',
    keywords: [
      'flag_timor_leste',
      'timor',
      'leste',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 26],
    shortName: 'flag-tl'
  },
  {
    name: 'Turkmenistan Flag',
    unified: '1F1F9-1F1F2',
    keywords: [
      'flag_turkmenistan',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 27],
    shortName: 'flag-tm'
  },
  {
    name: 'Tunisia Flag',
    unified: '1F1F9-1F1F3',
    keywords: [
      'flag_tunisia',
      'tn',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 28],
    shortName: 'flag-tn'
  },
  {
    name: 'Tonga Flag',
    unified: '1F1F9-1F1F4',
    keywords: [
      'flag_tonga',
      'to',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 29],
    shortName: 'flag-to'
  },
  {
    name: 'Turkey Flag',
    unified: '1F1F9-1F1F7',
    keywords: [
      'flag_turkey',
      'turkey',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 30],
    shortName: 'flag-tr'
  },
  {
    name: 'Trinidad & Tobago Flag',
    unified: '1F1F9-1F1F9',
    keywords: [
      'flag_trinidad_tobago',
      'trinidad',
      'tobago',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 31],
    shortName: 'flag-tt'
  },
  {
    name: 'Tuvalu Flag',
    unified: '1F1F9-1F1FB',
    keywords: [
      'flag_tuvalu',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 32],
    shortName: 'flag-tv'
  },
  {
    name: 'Taiwan Flag',
    unified: '1F1F9-1F1FC',
    keywords: [
      'flag_taiwan',
      'tw',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 33],
    shortName: 'flag-tw'
  },
  {
    name: 'Tanzania Flag',
    unified: '1F1F9-1F1FF',
    keywords: [
      'flag_tanzania',
      'tanzania',
      'united',
      'republic',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 34],
    shortName: 'flag-tz'
  },
  {
    name: 'Ukraine Flag',
    unified: '1F1FA-1F1E6',
    keywords: [
      'flag_ukraine',
      'ua',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 35],
    shortName: 'flag-ua'
  },
  {
    name: 'Uganda Flag',
    unified: '1F1FA-1F1EC',
    keywords: [
      'flag_uganda',
      'ug',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 36],
    shortName: 'flag-ug'
  },
  {
    name: 'U.s. Outlying Islands Flag',
    unified: '1F1FA-1F1F2',
    keywords: [
      'flag_u_s_outlying_islands'
    ],
    sheet: [4, 37],
    shortName: 'flag-um'
  },
  {
    name: 'United Nations Flag',
    unified: '1F1FA-1F1F3',
    keywords: [
      'flag_united_nations',
      'un',
      'flag',
      'banner'
    ],
    sheet: [4, 38],
    shortName: 'flag-un'
  },
  {
    name: 'United States Flag',
    unified: '1F1FA-1F1F8',
    keywords: [
      'flag_united_states',
      'united',
      'states',
      'america',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 39],
    shortNames: ['flag-us'],
    shortName: 'us'
  },
  {
    name: 'Uruguay Flag',
    unified: '1F1FA-1F1FE',
    keywords: [
      'flag_uruguay',
      'uy',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 40],
    shortName: 'flag-uy'
  },
  {
    name: 'Uzbekistan Flag',
    unified: '1F1FA-1F1FF',
    keywords: [
      'flag_uzbekistan',
      'uz',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 41],
    shortName: 'flag-uz'
  },
  {
    name: 'Vatican City Flag',
    unified: '1F1FB-1F1E6',
    keywords: [
      'flag_vatican_city',
      'vatican',
      'city',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 42],
    shortName: 'flag-va'
  },
  {
    name: 'St. Vincent & Grenadines Flag',
    unified: '1F1FB-1F1E8',
    keywords: [
      'flag_st_vincent_grenadines',
      'saint',
      'vincent',
      'grenadines',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 43],
    shortName: 'flag-vc'
  },
  {
    name: 'Venezuela Flag',
    unified: '1F1FB-1F1EA',
    keywords: [
      'flag_venezuela',
      've',
      'bolivarian',
      'republic',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 44],
    shortName: 'flag-ve'
  },
  {
    name: 'British Virgin Islands Flag',
    unified: '1F1FB-1F1EC',
    keywords: [
      'flag_british_virgin_islands',
      'british',
      'virgin',
      'islands',
      'bvi',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 45],
    shortName: 'flag-vg'
  },
  {
    name: 'U.s. Virgin Islands Flag',
    unified: '1F1FB-1F1EE',
    keywords: [
      'flag_u_s_virgin_islands',
      'virgin',
      'islands',
      'us',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 46],
    shortName: 'flag-vi'
  },
  {
    name: 'Vietnam Flag',
    unified: '1F1FB-1F1F3',
    keywords: [
      'flag_vietnam',
      'viet',
      'nam',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 47],
    shortName: 'flag-vn'
  },
  {
    name: 'Vanuatu Flag',
    unified: '1F1FB-1F1FA',
    keywords: [
      'flag_vanuatu',
      'vu',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 48],
    shortName: 'flag-vu'
  },
  {
    name: 'Wallis & Futuna Flag',
    unified: '1F1FC-1F1EB',
    keywords: [
      'flag_wallis_futuna',
      'wallis',
      'futuna',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 49],
    shortName: 'flag-wf'
  },
  {
    name: 'Samoa Flag',
    unified: '1F1FC-1F1F8',
    keywords: [
      'flag_samoa',
      'ws',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 50],
    shortName: 'flag-ws'
  },
  {
    name: 'Kosovo Flag',
    unified: '1F1FD-1F1F0',
    keywords: [
      'flag_kosovo',
      'xk',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 51],
    shortName: 'flag-xk'
  },
  {
    name: 'Yemen Flag',
    unified: '1F1FE-1F1EA',
    keywords: [
      'flag_yemen',
      'ye',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 52],
    shortName: 'flag-ye'
  },
  {
    name: 'Mayotte Flag',
    unified: '1F1FE-1F1F9',
    keywords: [
      'flag_mayotte',
      'yt',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 53],
    shortName: 'flag-yt'
  },
  {
    name: 'South Africa Flag',
    unified: '1F1FF-1F1E6',
    keywords: [
      'flag_south_africa',
      'south',
      'africa',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 54],
    shortName: 'flag-za'
  },
  {
    name: 'Zambia Flag',
    unified: '1F1FF-1F1F2',
    keywords: [
      'flag_zambia',
      'zm',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 55],
    shortName: 'flag-zm'
  },
  {
    name: 'Zimbabwe Flag',
    unified: '1F1FF-1F1FC',
    keywords: [
      'flag_zimbabwe',
      'zw',
      'flag',
      'nation',
      'country',
      'banner'
    ],
    sheet: [4, 56],
    shortName: 'flag-zw'
  },
  {
    name: 'England Flag',
    unified: '1F3F4-E0067-E0062-E0065-E006E-E0067-E007F',
    keywords: [
      'flag_england',
      'flag',
      'english'
    ],
    sheet: [11, 4],
    shortName: 'flag-england'
  },
  {
    name: 'Scotland Flag',
    unified: '1F3F4-E0067-E0062-E0073-E0063-E0074-E007F',
    keywords: [
      'flag_scotland',
      'flag',
      'scottish'
    ],
    sheet: [11, 5],
    shortName: 'flag-scotland'
  },
  {
    name: 'Wales Flag',
    unified: '1F3F4-E0067-E0062-E0077-E006C-E0073-E007F',
    keywords: [
      'flag_wales',
      'flag',
      'welsh'
    ],
    sheet: [11, 6],
    shortName: 'flag-wales'
  }
];
